import { IconButton, IconEdit, Checkbox, CheckboxGroup, GridCol, Toggle, GridRow, Heading} from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
//import { AgentHoursContext } from "../../../../context/AgentHoursContext";
import { hours, timezones } from "../../../../../constants/constants";
import { weekDays } from "../../../../../constants/time";
import { dayOpHours, hoursObject } from "../../../../../models/Agency";
//import { HoursContext } from "../../../../context/HoursContext";
import Select from 'react-select'
import { useContext, useState } from "react";
import _ from 'lodash';
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { useEffect, useRef } from "react";
import '../../../../../styles/Modals.css';
import CancelAndSaveButtons from "../../../../modals/CancelAndSaveButtons";

interface IOption {
    value: string;
    label: string;
}

const ModalHoursOfOperation = (props: { isAgent: boolean }) => {
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    const { operationHours, setOperationHours } = useContext(ProfileDataContext);
    const [copy, setCopy] = useState<hoursObject>(operationHours);
    var hourOptions: IOption[] = [];
    var timezoneOptions: IOption[] = [];
    hours.forEach((time) => { hourOptions.push({ value: time, label: time }) });
    timezones.forEach((timezone) => { timezoneOptions.push({ value: timezone, label: timezone }) });

    const fieldRef  = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        fieldRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        if (operationHours)
            setCopy(operationHours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operationHours]);

    const updateTimezone = (value: String | undefined) => {
        if (value != null) {
            setCopy({ ...copy, timezone: value });
        }
    }

    const getIndexOfDay = (day: String) => {
        return copy.opHours.findIndex((element: dayOpHours) => element.day === day);
    }

    const findAndUpdateHours = (day: String, value: string | undefined, am: boolean) => {
        if (value != null) {
            if (am) {// start time
                const index = copy.opHours.findIndex((element: dayOpHours) => element.day === day);
                let newOpHours = _.cloneDeep(copy.opHours);
                newOpHours[index].hours.begin = value;
                setCopy({ ...copy, opHours: newOpHours });
            }
            else {//end time
                const index = copy.opHours.findIndex((element: dayOpHours) => element.day === day);
                let newOpHours = _.cloneDeep(copy.opHours);
                newOpHours[index].hours.end = value;
                setCopy({ ...copy, opHours: newOpHours });
            }
        }

    };

    const findAndUpdateOpenValue = (day: String) => {
        const index = copy.opHours.findIndex((element: dayOpHours) => element.day === day);
        let newOpHours = _.cloneDeep(copy.opHours);
        let fillBegin : String = '-----';
        let fillEnd : String = '-----';
        newOpHours[index].open = !newOpHours[index].open;
        
        if(index > 0 && newOpHours[index].open){
            for(let i = index; i >= 0; i--){
                console.log("i:",i);
                if(!newOpHours[i].hours.begin.includes('-----') && !newOpHours[i].hours.end.includes('-----')){
                    fillBegin = newOpHours[i].hours.begin;
                    fillEnd = newOpHours[i].hours.end;
                    break;
                }
            }
        }

        newOpHours[index].hours = { begin: fillBegin, end: fillEnd};
        
        setCopy({ ...copy, opHours: newOpHours });
    };

    const getDefaultValue = (day: string, am: boolean) => {

        if (am) {

            const hourDefault = copy.opHours[getIndexOfDay(day)].hours.begin as string;
            return { value: hourDefault, label: hourDefault }

        }
        else {

            const hourDefault = copy.opHours[getIndexOfDay(day)].hours.end as string;
            return { value: hourDefault, label: hourDefault }

        }
    };

    const checkIfClosedAM = (day: string) => {
        if (copy.opHours[getIndexOfDay(day)].open) {
            return (
                <Select maxMenuHeight={300} minMenuHeight={300} options={hourOptions} id="max-content" onChange={(e) => { findAndUpdateHours(day, e?.value, true) }} value={getDefaultValue(day, true)} />
            );
        }

        return (
            <p>Closed</p>
        );
    }

    const checkIfClosedPM = (day: string) => {
        if (copy.opHours[getIndexOfDay(day)].open) {
            return (
                <Select maxMenuHeight={300} minMenuHeight={300} options={_.cloneDeep(hourOptions).reverse()} id="max-content" onChange={(e) => { findAndUpdateHours(day, e?.value, false) }} value={getDefaultValue(day, false)} />
            );
        }
        return null;
    }

    const cancelChanges = () => {
        setCopy(operationHours);
        modalsContext.showModal(tiles.HoursOfOperation, false);
    };

    const saveChanges = () => {
        console.log("COPY", copy);
        copy.opHours.forEach((day)=>{
            if(day.hours.begin.includes('-----') || day.hours.end.includes('-----')){
                day.open = false;
                day.hours.begin = '-----';
                day.hours.end = '-----';
            }
        })

        setOperationHours(copy);
        modalsContext.showModal(tiles.HoursOfOperation, false);
    }

    const renderHoursOfOperation = () => {

        return weekDays.map((day) => {

            return (
                <tr key={day}>
                    <td>
                        <h4>{day}: </h4>
                    </td>

                    <td><Toggle checked={copy.opHours[getIndexOfDay(day)].open} onChange={() => { findAndUpdateOpenValue(day); console.log(copy); }} compact showOnOff={false} /></td>
                    <td>
                        {checkIfClosedAM(day)}
                    </td>
                    <td>
                        {copy.opHours[getIndexOfDay(day)].open ? <h4 id="spacing">to</h4> : null}
                    </td>
                    <td>
                        {checkIfClosedPM(day)}
                    </td>
                </tr>

            );
        });
    }


    return (
        <>
            <Modal isOpen={modalsContext.hoursOfOperation} size="medium" onClose={() => cancelChanges()}>
                <ModalHeader>
                    <Heading className="modal-heading">Edit hours</Heading>
                </ModalHeader>
                <ModalBody className="modal-body">
              
                    {modalsContext.hoursOfOperation === true ?
                        <GridRow>
                            <GridCol base={12}>
                                <table >
                                    <tbody>
                                        {renderHoursOfOperation()}
                                        <tr>
                                            <td><h4>Time Zone:</h4></td>
                                            <td>
                                                <Select maxMenuHeight={125}  options={timezoneOptions} id="max-content" onFocus={handleClick} onChange={(e) => { updateTimezone(e?.value) }} value={{ label: copy.timezone, value: copy.timezone }} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="otherHours" style={{ zIndex: "-1"}}>
                                    <h4 style={{ paddingTop: "11px"}}>Other hours available upon request: </h4>
                                    <span style={{ paddingLeft: "10px"}}>
                                    <span style={{ position: "fixed"}}>
                                    <CheckboxGroup onChange={() => {

                                        const newOtherHours = !copy.otherhours;

                                        setCopy({ ...copy, otherhours: newOtherHours });
                                    }}>
                                        <Checkbox
                                            labelVisual="" checked={copy.otherhours}

                                        />
                                    </CheckboxGroup>
                                    </span>
                                    </span>
                                </div>
                            </GridCol>
                        </GridRow>
                        : null}
                    </ModalBody>
                    <ModalFooter className="modal-footer">
                        <CancelAndSaveButtons
                        cancelFunction={cancelChanges}
                        saveFunction={saveChanges}/>
                    </ModalFooter>
            </Modal>

            <IconButton id="hoursModalButton" onClick={() => {

                setCopy(operationHours);
                setOperationHours(operationHours);
                modalsContext.showModal(tiles.HoursOfOperation, true);

            }}>
                <IconEdit size="24" />
            </IconButton>
        </>
    )

};

export default ModalHoursOfOperation;
