import { Heading, IconAddItem, IconButton, IconEdit, Link, List, ListItem} from "@lmig/lmds-react";
import { useState } from "react";
import { Education} from "../../../models/Agent";
import EducationModal from "../../modals/EducationModal";

interface EducationSectionProps {
    education: Education[];
    updateEducation: (val:Education[]) => void;
}

function EducationSection(props : EducationSectionProps) {

    const [editEducation, setEditEducation] = useState<boolean>(false);
    const [add, setAdd] = useState<boolean>(false);

    const educationPresent = () => {
        if (props.education.length === 0) {
            return false;
        }

        for (let i = 0; i < props.education.length; i++) {
            if (props.education[i].schoolName !== "" || props.education[i].fieldOfStudy !== "") {
                return true;
            }
        }
        return false;
    }
    return (
        <>
        <div className="contact-information-section-edit-row">
        <Heading type="h5">Education</Heading>
        {educationPresent() &&
                        <div className="edit-btn-align-absolute">
                            <IconButton id="educationModalButton" onClick={()=>{setEditEducation(true)}}>
                                <IconEdit className="icon-edit-custom-size" size="24"/>
                            </IconButton>
                        </div>
                }
        </div>
                    {educationPresent() ?
                        <div className = "filed-group-margin-items indent-content">
                            <div style={{width:"100%"}}>
                                <List compact>
                                {props.education.map((item : Education, index: number) => {
                                    if (item.schoolName === "" && item.fieldOfStudy === "") {
                                        return (<></>);
                                    } else {
                                        return (<ListItem key={index}>{item.schoolName} - {item.fieldOfStudy}</ListItem>)
                                    }

                                })}
                                </List>
                            </div>
                            {props.education.length < 2 ?
                                <div className="indent-content-inner">
                                        <IconButton id="educationModalButton" onClick={() => [setEditEducation(true), setAdd(true)]}>
                                            <IconAddItem size = "24"></IconAddItem>
                                        </IconButton>
                                        <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => [setEditEducation(true), setAdd(true)]}>Add another education</Link>
                                </div>
                                : null
                            }
                        </div>
                        :
                        <div className='filed-group-margin-items indent-content-inner'>
                                <IconButton id="educationModalButton" onClick={() => {setEditEducation(true)}}>
                                    <IconAddItem size = "24"></IconAddItem>
                                </IconButton>
                                <div className="icon-text-long">
                                    <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => {setEditEducation(true)}}>Add education</Link>
                                </div>
                        </div>
                    }
            {editEducation ?
            <EducationModal education={props.education} edit={true} updateEdit={setEditEducation} add={add} setAdd={setAdd} updateEducation={props.updateEducation}/>
                : null}
        </>
    );
}

export default EducationSection;