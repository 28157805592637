import { Button, AlphanumericInput, GridRow, GridCol, FieldGroup, IconTrash, IconButton, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useContext, useState, useRef } from "react";
import _ from 'lodash';
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import { ModalsContext, IModalsContext, tiles } from "../../../../context/ModalsContext";
import { useEffect } from "react";
import AddItem from "../../../../../helpers/AddItem";
import CancelAndSaveButtons from "../../../../modals/CancelAndSaveButtons";
import { validateFBUrl, validateLIUrl, validateTWUrl } from "../../../../../constants/constants"
import '../../../../../styles/Modals.css';

type errorsObject = {
    linkedIn: string[],
    facebook: string[],
    twitter: string[]
};

type SocialMedia = {
    facebook: string,
    linkedIn: string,
    twitter: string
}

const ModalSocialMedia = (props: { focus?: string }) => {
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    const { socialMediaLinks, setSocialMediaLinks } = useContext(ProfileDataContext);
    const [copy, setCopy] = useState<SocialMedia>(socialMediaLinks);
    const [errorMessageObject, setErrorMessageObject] = useState<errorsObject>({ linkedIn: [], facebook: [], twitter: [] });
    const facebookInput = useRef<HTMLInputElement>(null);
    const linkedinInput = useRef<HTMLInputElement>(null);
    const twitterInput = useRef<HTMLInputElement>(null);
    const [showFacebook, setFacebook] = useState<boolean>((socialMediaLinks.facebook ?? []).length > 0);
    const [showLinkedin, setLinkedin] = useState<boolean>((socialMediaLinks.linkedIn ?? []).length > 0);
    const [showTwitter, setTwitter] = useState<boolean>((socialMediaLinks.twitter ?? []).length > 0);
    
    useEffect(() => {
        if (socialMediaLinks) {
            setCopy(socialMediaLinks);
            if (socialMediaLinks.facebook !== "") {
                setFacebook(true);
            }
            else {
                setFacebook(false);
            }
            if (socialMediaLinks.twitter !== "") {
                setTwitter(true);
            }
            else {
                setTwitter(false);
            }
            if (socialMediaLinks.linkedIn !== "") {
                setLinkedin(true);
            }
            else {
                setLinkedin(false);
            }
        
        
        
        }
            
    }, [socialMediaLinks, modalsContext.socialMedia]);

    useEffect(() => {
        if (modalsContext.socialMedia) {
            switch (props.focus) {
                case 'FACEBOOK':
                    setFacebook(true);
                    // console.log("settimg facebook focus", facebookInput.current);
                    // facebookInput.current?.focus();
                    break;
                case 'LINKEDIN':
                    setLinkedin(true);
                    // linkedinInput.current?.focus();
                    break;
                case 'TWITTER':
                    setTwitter(true);
                    // twitterInput.current?.focus()
                    break;
                default:
                // code block
            }
        }

    }, [props.focus, modalsContext.socialMedia]);

    useEffect(() => {
        if (showFacebook && props.focus) {
            facebookInput.current?.focus()
        }
    }, [showFacebook, props.focus]);

    useEffect(() => {
        if (showLinkedin && props.focus) {
            linkedinInput.current?.focus()
        }
    }, [showLinkedin, props.focus]);

    useEffect(() => {
        if (showTwitter && props.focus) {
            twitterInput.current?.focus()
        }
    }, [showTwitter, props.focus]);


    

    const checkForErrors = () => {
        let errors: errorsObject = { linkedIn: [], facebook: [], twitter: [] };
        if (copy.linkedIn.length > 2083) {
            errors.linkedIn.push("Your LinkedIn information cannot exceed 2083 characters");
        }
        if (copy.linkedIn !== "" && copy.linkedIn !== "http://" && !validateLIUrl.test(copy.linkedIn)) {
            errors.linkedIn.push("Please enter valid LinkedIn profile URL, for eg: https://linkedin.com/in/JohnDoe");
        }
        if (copy.facebook.length > 2083) {
            errors.facebook.push("Your Facebook information cannot exceed 2083 characters");
        }
        if (copy.facebook !== "" && copy.facebook !== "http://" && !validateFBUrl.test(copy.facebook)) {
            errors.facebook.push("Please enter valid Facebook profile URL, for eg: https://facebook.com/JohnDoe");
        }
        if (copy.twitter.length > 2083) {
            errors.twitter.push("Your Twitter information cannot exceed 2083 characters");
        }
        if (copy.twitter !== "" && copy.twitter !== "http://" && !validateTWUrl.test(copy.twitter)) {
            errors.twitter.push("Please enter valid Twitter profile URL, for eg: https://twitter.com/JohnDoe");
        }

        setErrorMessageObject(errors);

        return errors.linkedIn.length + errors.facebook.length + errors.twitter.length;
    };

    const cancelChanges = () => {
       
      
        modalsContext.showModal(tiles.SocialMedia, false);
        setErrorMessageObject({ linkedIn: [], facebook: [], twitter: [] });
        //updateShowMediaStates();
    };


    const renderFacebookSection = () => {

        if (showFacebook) {
            return (
                <>
                    <GridCol>
                        <AlphanumericInput
                            innerRef={facebookInput}
                            value={copy.facebook}
                            onChange={(e) => {
                                setCopy({ ...copy, facebook: e.currentTarget.value });
                            }}
                            onBlur={(e) => {if (copy.facebook !== ""){
                                setCopy({ ...copy, facebook: e.currentTarget.value.includes("http://") || e.currentTarget.value.includes("https://")?
                                e.currentTarget.value : "https://" + e.currentTarget.value });
                                }}}
                            autoFocus={true}
                            labelVisual="Facebook" placeholder="https://facebook.com/JohnDoe"
                        />
                    </GridCol>
                    <GridCol base={2}>
                        <a href={copy.facebook} target="_blank" rel="noopener noreferrer">
                            <Button dynamicWidth style={{marginTop:"6px"}} size="small">Check</Button>
                        </a>
                    </GridCol>
                    <GridCol base={1} >
                        <IconButton onClick={() => {
                            setCopy({ ...copy, facebook: "" });
                            setFacebook(false);

                        }}>
                            <IconTrash size="24" />
                        </IconButton>

                    </GridCol>
                </>

            );
        }

        return (
            <div><AddItem text="Add Facebook page" action={() => {

                setFacebook(true);

            }} />
            </div>
        );
    };


    const renderLinkedinSection = () => {
        if (showLinkedin) {
            return (
                <>
                    <GridCol>
                        <AlphanumericInput
                            innerRef={linkedinInput}
                            value={copy.linkedIn} onChange={(e) => setCopy({ ...copy, linkedIn: e.currentTarget.value })}
                            onBlur={(e) => {if (copy.linkedIn !== ""){
                                setCopy({ ...copy, linkedIn: e.currentTarget.value.includes("http://") || e.currentTarget.value.includes("https://")?
                                e.currentTarget.value : "https://" + e.currentTarget.value });
                                }}}
                            labelVisual="LinkedIn" placeholder="https://linkedin.com/in/JohnDoe"
                        />
                    </GridCol>
                    <GridCol base={2}>
                        <a href={copy.linkedIn} target="_blank" rel="noopener noreferrer">
                            <Button dynamicWidth style={{marginTop:"6px"}} size="small">Check</Button>
                        </a>
                    </GridCol>
                    <GridCol base={1}>
                        <IconButton onClick={() => {
                            setCopy({ ...copy, linkedIn: "" });
                            setLinkedin(false);

                        }}>
                            <IconTrash size="24" />
                        </IconButton>
                    </GridCol>
                </>

            );
        }


        return (

            <div><AddItem text="Add LinkedIn page" action={() => {

                setLinkedin(true);

            }} />
            </div>

        );
    };


    const renderTwitterSection = () => {

        if (showTwitter) {

            return (
                <>
                    <GridCol>
                        <AlphanumericInput value={copy.twitter}
                            innerRef={twitterInput}
                            onChange={(e) => setCopy({ ...copy, twitter: e.currentTarget.value })} 
                            onBlur={(e) => {if (copy.twitter !== ""){
                                setCopy({ ...copy, twitter: e.currentTarget.value.includes("http://") || e.currentTarget.value.includes("https://")?
                                e.currentTarget.value : "https://" + e.currentTarget.value });
                                }}}
                            labelVisual="Twitter" placeholder="https://twitter.com/JohnDoe"
                        />
                    </GridCol>
                    <GridCol base={2}>
                        <a href={copy.twitter} target="_blank" rel="noopener noreferrer">
                            <Button dynamicWidth style={{marginTop:"6px"}} size="small">Check</Button>
                        </a>
                    </GridCol>
                    <GridCol base={1}>
                        <IconButton onClick={() => {
                            setCopy({ ...copy, twitter: "" });
                            setTwitter(false);

                        }}>
                            <IconTrash size="24" />
                        </IconButton>
                    </GridCol>
                </>

            );
        }

        return (
            <div><AddItem text="Add Twitter page" action={() => {

                setTwitter(true);

            }} />
            </div>
        );


    };

    return (
        <>
            <Modal isOpen={modalsContext.socialMedia} size="small" onClose={() => cancelChanges()}>
                <ModalHeader><Heading className="modal-heading">Edit social media links</Heading></ModalHeader>
                <ModalBody className="modal-body">
                <GridRow gutters="horizontal" gutterSize="fixed-16">
                    <GridCol base={12}>
                        { showFacebook ?
                            <FieldGroup isFull id="formField"
                            messages={
                                errorMessageObject.facebook.map((error) => {
                                    return {
                                        text: error,
                                        type: 'error'
                                    };
                                })}/>:<></>}
                    </GridCol>
                    {renderFacebookSection()}
                    <GridCol base={12}>
                        { showLinkedin ?
                            <FieldGroup isFull id="formField"
                                messages={
                                    errorMessageObject.linkedIn.map((error) => {
                                        return {
                                            text: error,
                                            type: 'error'
                                        };
                                    })}/>: <></>}
                    </GridCol>
                    {renderLinkedinSection()}
                    <GridCol base={12}>
                        { showTwitter? 
                            <FieldGroup isFull id="formField"
                                messages={
                                    errorMessageObject.twitter.map((error) => {
                                        return {
                                            text: error,
                                            type: 'error'
                                        };
                                    })}/>:<></>}
                    </GridCol>
                    {renderTwitterSection()}
                </GridRow>
                </ModalBody>
                <ModalFooter className="modal-footer">
                    <CancelAndSaveButtons
                        cancelFunction={cancelChanges}
                        saveFunction={() => {
                            if (checkForErrors() === 0) {
                                setSocialMediaLinks(_.cloneDeep(copy));
                                modalsContext.showModal(tiles.SocialMedia, false);}}}/>  
                </ModalFooter>
            </Modal>

        </>
    )
};

export default ModalSocialMedia;
