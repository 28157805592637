import { BodyText, Heading, IconAddItem, IconButton, IconCaretDown, IconCaretUp, IconEdit, Link} from "@lmig/lmds-react";
import { useState } from "react";
import TextInputModal from "../../modals/TextInputModal";

interface SkillsProps {
    skills: string;
    updateSkills: (val:string) => void;
}
function SkillsSection(props : SkillsProps) {

    const [editSkills, setEditSkills] = useState<boolean>(false);
    const [showSkills, setShowSkills] = useState<boolean>(false);

    return (<>
        <div  className="contact-information-section-edit-row">
            <Heading type="h5">Skills and Expertise</Heading>
            {props.skills?.length > 0 && <IconButton id="skillsModalButton" onClick={()=>{setEditSkills(true)}}>
                <IconEdit className="icon-edit-custom-size" size="24"/>
            </IconButton>}
        </div>
        {props.skills?.length > 0 ?
                        <div className = "filed-group-margin-items indent-content">
                            {props.skills.length < 172 ?
                                <BodyText>{props.skills}</BodyText>
                                :
                                <>
                                    <div className={showSkills ? "" : "closed-summary"}>{props.skills}</div>
                                    {!showSkills ? <Link className="show-more-general" onClick={() => setShowSkills(true)} variant="standalone"  caretPosition="none" >Show more<IconCaretDown className="carrot-icon-margin" size="16" color="teal"/></Link> :
                                    <Link className="link-general" onClick={() => setShowSkills(false)} variant="standalone" caretPosition="none">Show less<IconCaretUp className="carrot-icon-margin" size="16" color="teal"/></Link>}
                                </>
                            }
                        </div>
                :
                <div className='indent-content-inner'>
                        <div className = "filed-group-margin-items">
                            <IconButton id="skillsModalButton" onClick={() => {setEditSkills(true)}}>
                                <IconAddItem size = "24"></IconAddItem>
                            </IconButton>
                        </div>
                        <div className="icon-text-general">
                            <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => {setEditSkills(true)}}>Add skills and expertise</Link>
                        </div>
                </div>
            }

            {editSkills ?
                <TextInputModal label={"Add skills and expertise"} text={props.skills} edit={true} updateEdit={setEditSkills} updateText={props.updateSkills} />
                : null}
    </>);

}

export default SkillsSection;