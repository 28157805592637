import { IconAddItem, IconButton } from "@lmig/lmds-react";

const AddItem = (props:{text?:string, action?:() => void, children?:any}) => {

    return (
        <><span onClick={() => props?.action ? props.action() : null} className="add-item-link">
            <IconButton>
                <IconAddItem size="24" />
            </IconButton>{props.text}
            {props.children}
            </span></>
    );
};

export default AddItem;
