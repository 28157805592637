import { AlphanumericInput, Button, FieldGroup, GridCol, GridRow, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import { useState, useContext } from "react";
import '../../styles/OptionsModal.css'
import '../../styles/Modals.css';
import { AgentDataContext } from "../context/AgentDataContext";

interface AgentSinceModalProps {
    agentSince: string,
    edit: boolean,
    updateEdit: (val : boolean) => void,
    updateAgentSince: (val : string) => void,
}

function AgentSinceModal (props : AgentSinceModalProps) {
    const [edit, setEdit] = useState<boolean>(props.edit);  
    const { otherInfo, setOtherInfo } = useContext(AgentDataContext);
    const [agentSince, setAgentSince] = useState<string>(_.cloneDeep(otherInfo));

    type errorsObject = {
        yearEstablished: string[]

    };
    const [errorMessageObject, setErrorMessageObject] = useState<errorsObject>({ yearEstablished: [] });

    const checkForErrors = () => {
        let errors: errorsObject = { yearEstablished: [] };
        if (agentSince !== "" && agentSince.length !== 4) {
            errors.yearEstablished.push("Please enter a valid four digit year");
        }
        if (agentSince.length === 4 && Number(agentSince) > new Date().getFullYear() + 1) {
            errors.yearEstablished.push("Year business established cannot occur more than 1 year in the future")
        }
        if (agentSince.length === 4 && Number(agentSince) < 1850) {
            errors.yearEstablished.push("Year business established cannot occur before 1850")
        }

        setErrorMessageObject(errors);

        return errors.yearEstablished.length;
    };

    const cancelChanges = () => {
        setEdit(false); 
        props.updateEdit(false); 
        setAgentSince(_.cloneDeep(otherInfo));
        setErrorMessageObject({ yearEstablished: [] });
    };

    return (

        <Modal
        isOpen={edit}
        size="small"
        onClose={() => cancelChanges()}
        >
            <ModalHeader><Heading className="modal-heading">Agent Since</Heading></ModalHeader>
            <ModalBody className="modal-body">
                <FieldGroup isFull id="formField"
                    messages={
                        errorMessageObject.yearEstablished.map((error) => {
                            return {
                                text: error,
                                type: 'error'
                            };
                        })
                    }
                >
                    <AlphanumericInput value={agentSince} onChange={(e: any) => { setAgentSince(e.target.value); } } labelVisual={"Date"}></AlphanumericInput>
                </FieldGroup>
            </ModalBody>
            <ModalFooter className="modal-footer"> 
                <GridRow gutterSize="fixed-16" gutters justify="end">
                    <GridCol className="button-container" base={3}>
                        <Button dynamicWidth size="small" onClick={() => cancelChanges()}> Cancel </Button>
                    </GridCol>
                    <GridCol className="button-container" base={3}>
                        <Button dynamicWidth size="small" variant = "primary" onClick={() => {
                            if(checkForErrors() === 0){
                                setEdit(false); 
                                props.updateEdit(false); 
                                props.updateAgentSince(agentSince); 
                                setOtherInfo(_.cloneDeep(agentSince)); 
                            }
                        }}> 
                            Save 
                        </Button>
                    </GridCol>
                </GridRow> 
            </ModalFooter>
        </Modal>

    )
}
export default AgentSinceModal;