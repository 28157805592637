import { useContext, useEffect, useState } from "react";
import AddItem from "../../../../../helpers/AddItem";
import { ProfileDataContext, ImageFile } from "../../../../context/ProfileDataContext";
import CropModal from "./CropModal";
import { Notification, IconButton, IconSeeMore, Link } from "@lmig/lmds-react";
import { Popover } from '@lmig/lmds-react-popover';
import "../../../../../styles/AgencyPhotos.css";
import AgencyPhoto from "./AgencyPhoto";

const ShowPhotos = () => {

    const { profileData, photos, setPhotos } = useContext(ProfileDataContext);
    const [open, setOpen] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ tooBig:boolean, tooMany:boolean, wrongType:boolean, duplicates:boolean}>({ tooBig:false, tooMany:false, wrongType:false, duplicates:false});
    const [prospectiveProfilePic, setProspectiveProfilePic] = useState<ImageFile>();
    const [hidePopover, setHidePopover] = useState<boolean>(true);

    useEffect(() => {
        if (profileData?.Photos || profileData?.CoverPhotos)
            setPhotos({
                mainPhoto: profileData.CoverPhotos ? profileData.CoverPhotos.map((photo: ImageFile) => {
                    photo.url = window.location.origin + window.env.PHOTO_PATH + photo.Blob_Id;
                    return photo;
                }) : [],
                galleryPhotos: profileData.Photos ? profileData.Photos.map((photo: ImageFile) => {
                    photo.url = window.location.origin + window.env.PHOTO_PATH + photo.Blob_Id;
                    return photo;
                }) : []
            });

    }, [profileData, setPhotos]);

    const validateImage = (photo: File | undefined): boolean => {
        //reset errors
        setErrors(errors => ({...errors, tooBig: false, wrongType:false}));
         if (photo && photo.size > 2097152) {// checks if file is greater than 2MB
             setErrors(errors => ({ ...errors, tooBig: true }));
             return true;
         }
         if (photo && photo.type !== "image/png" && photo.type !== "image/jpeg"){
             setErrors(errors => ({...errors, wrongType:true}));
             return true;
         }
         return false;
    }

    const validateImages = (agencyPhotos: FileList, isReplacing: boolean = false): boolean => {
        let hasErrors: boolean = false;
        if (agencyPhotos.length) {

            //reset errors
            setErrors(errors => ({...errors, tooMany:false, duplicates: false}))

            //check if too many photos were uploaded
            if (!isReplacing && photos.galleryPhotos.length + agencyPhotos.length > 5) { //dont check for too many photos when replacing one photo for another
                setErrors(errors => ({ ...errors, tooMany: true }));
                hasErrors = true;
            }

            //check for duplicate files
            let duplicateFiles: boolean = false;
            photos.galleryPhotos.filter(x => !x.IsDeleted).forEach((photo: ImageFile) => {
                if ([...agencyPhotos].filter(x => photo.name && x.name === photo.name).length >= 1) {
                //if (Array.from(agencyPhotos).filter(x => photo.name && x.name === photo.name).length >= 1) {
                    duplicateFiles = true;
                    hasErrors = true;
                    setErrors(errors => ({ ...errors, duplicates: duplicateFiles }));
                }
            });

            //check size and type
            for (let i:number = 0; i<agencyPhotos.length; i++ ) {
                let hasError: boolean = validateImage(agencyPhotos[i]);
                if (hasError)
                    return true;
            }
        }

        return hasErrors;
    }

    useEffect(() => {
        setHidePopover(true);
    }, [photos]);

    useEffect(() => {
        setErrors({ tooBig:false, tooMany:false, duplicates:false, wrongType:false});
    }, [hidePopover]);

    const addProfilePic = async (e: any) => {
        if (e.target.files) {

            let file:File = e.target.files[0];
            let error:boolean = validateImage(file);
            if (error)
                return;

            setProspectiveProfilePic({ name: file.name, url: URL.createObjectURL(file as any), Blob_Id: "", IsDeleted: false });
            setOpen(true);
        }
    }

    const uploadGalleryPhotos = async (e: any, index: number = -1) => {
        let target: HTMLInputElement = e.target;
        if (target.files !== null) {
            let error: boolean = validateImages(target.files, index === -1 ? false : true);
            if (error)
                return;

            [...target.files].forEach((file: File) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        const newPhoto = { name: file.name, url: URL.createObjectURL(file), IsDeleted: false, Blob_Id: "", base64:reader.result as string };
                        if (index === -1) // add a gallery photo
                            setPhotos(photos => ({ ...photos, galleryPhotos: [...photos.galleryPhotos, newPhoto] }));
                        else //replace a gallery photo
                            replacePhoto(newPhoto, index, false);
                    }


            });
        }
    }

    const replacePhoto = (newPhoto: ImageFile, index: number, profilePicture: boolean) => {
        if (profilePicture) {
            if (photos.mainPhoto[index] && photos.mainPhoto[index].Blob_Id && photos.mainPhoto[index].Blob_Id !== "") //keep track of deleted image so we can remove it from server later
                setPhotos(photos => ({ ...photos, mainPhoto: [...photos.mainPhoto.slice(0, index), newPhoto, ...photos.mainPhoto.slice(index + 1), {...photos.mainPhoto[index], IsDeleted: true}] }));
            else
                setPhotos(photos => ({ ...photos, mainPhoto: [...photos.mainPhoto.slice(0, index), newPhoto, ...photos.mainPhoto.slice(index + 1)] }));

            /*if a saved photo was removed and then replaced, don't let it be deleted
             we only need to do this with the profile picture since we allow the user to revert back to their previous photo*/
            if (photos.mainPhoto.filter(x => x.IsDeleted && x.Blob_Id === newPhoto.Blob_Id))
                setPhotos(photos => ({...photos, mainPhoto: photos.mainPhoto.filter(x => !(x.IsDeleted && x.Blob_Id === newPhoto.Blob_Id))}));
        }
        else {
            if (photos.galleryPhotos[index] && photos.galleryPhotos[index].Blob_Id && photos.galleryPhotos[index].Blob_Id !== "") //keep track of deleted image so we can remove it from server later
                setPhotos(photos => ({ ...photos, galleryPhotos: [...photos.galleryPhotos.slice(0, index), newPhoto, ...photos.galleryPhotos.slice(index + 1), {...photos.galleryPhotos[index], IsDeleted: true}] }));
            else
                setPhotos(photos => ({ ...photos, galleryPhotos: [...photos.galleryPhotos.slice(0, index), newPhoto, ...photos.galleryPhotos.slice(index + 1)] }));
        }
    }

    const deleteProfilePicture = (image: ImageFile) => {
        if (image.Blob_Id && image.Blob_Id !== "") //keep track of deleted image so we can remove it from server later
            setPhotos(photos => ({ ...photos, mainPhoto: [...photos.mainPhoto.filter(x => !(image.url === x.url)), {...image, IsDeleted: true}] }))
        else //image has never been saved to server, so no need to keep track of it
            setPhotos(photos => ({ ...photos, mainPhoto: photos.mainPhoto.filter(x => !(image.url === x.url)) }))
    }

    const deleteAgencyPhoto = (image: ImageFile) => {
        if (image.Blob_Id && image.Blob_Id !== "") //keep track of deleted image so we can remove it from server later
            setPhotos(photos => ({ ...photos, galleryPhotos: [...photos.galleryPhotos.filter(x => !(image.url === x.url)), {...image, IsDeleted: true}] }));
        else //image has never been saved to server, so no need to keep track of it
            setPhotos(photos => ({ ...photos, galleryPhotos: photos.galleryPhotos.filter(x => !(image.url === x.url)) }));
    }

    const updateAgencyPhoto = (image: ImageFile) => {
        if (image)
            setPhotos(photos => ({...photos, galleryPhotos:photos.galleryPhotos.map((x: ImageFile) => {
                if (x && x.url === image.url) {
                    return image;
                }
                else
                    return x;
            })}));
    }

    return (
        <>
            {open && <CropModal newPic={prospectiveProfilePic} isOpen={open} close={() => setOpen(false)} save={(image: ImageFile) => replacePhoto(image, 0, true)} isAgent={false} delete={(image: ImageFile) => {deleteProfilePicture(image);setOpen(false)}}  />}
            <div>
                {errors && <div className="errorWarningContainer">
                {errors && errors.tooMany && <Notification highlightType="negative" alert="Too many files were selected. Please select a maximum of five images" isDismissible />}
                {errors && errors.tooBig && <Notification highlightType="negative" alert="The maximum image size is 2MB. Please select a smaller image" isDismissible />}
                {errors && errors.wrongType && <Notification highlightType="negative" alert="Image must be a .JPG or .PNG" isDismissible />}
                {errors && errors.duplicates && <Notification highlightType="caution" alert="Multiple files with same name were selected" isDismissible />}
            </div>}
            {<div className="tileImageContainer">
                    {photos.mainPhoto.filter(x => !x.IsDeleted).length ? photos.mainPhoto.filter(x => !x.IsDeleted).map((image: ImageFile) =>
                            <div className={`photo-box ${hidePopover ? "hide-popover" : ""}`} key={image.Blob_Id}>
                                <Popover className="gallery-popover" trigger={<IconButton background="transparent">
                                    <IconSeeMore size="24" color="teal" onClick={() => setHidePopover(false)} />
                                </IconButton>}>
                                <ul>
                                    <li><Link onClick={() => {setProspectiveProfilePic(undefined);setOpen(true)} }>Crop</Link></li>
                                    <li><Link className="replace-button">
                                        <label htmlFor="profilePictureUpload">Replace</label>
                                        <input id="profilePictureUpload"
                                            type="file"
                                            accept="image/png, image/jpeg"
                                            onChange={(e) => addProfilePic(e)}/></Link></li>
                                    <li><Link onClick={() => deleteProfilePicture(image)}>Remove</Link></li>
                                </ul>
                            </Popover>
                            <img key={image.name} src={image.base64 ?? image.url} alt={image.name} className="profile-photo-image" onClick={() => {setProspectiveProfilePic(undefined);setOpen(true)}} />
                            <span className="agency-profile-picture">Profile Picture</span>
                            </div>
                    ) : <div className="add-item-box">
                            <AddItem>
                                <label htmlFor="profilePictureUpload">Add profile picture</label>
                                <input id="profilePictureUpload"
                                    type="file"
                                    name="profilePicture"
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => addProfilePic(e)} />
                            </AddItem>
                        </div>}
                    {photos.galleryPhotos && photos.galleryPhotos.map((image:ImageFile, index) =>
                                (!image.IsDeleted && <AgencyPhoto
                                    key={index}
                                    image={image}
                                    update={(image: ImageFile) => updateAgencyPhoto(image)}
                                    delete={(image: ImageFile) => deleteAgencyPhoto(image)}
                                    replace={(e: any) => uploadGalleryPhotos(e, index)}
                                    hidePopover={hidePopover}
                                    />))}
                    {photos.galleryPhotos.filter(x => !x.IsDeleted).length < 5 &&
                        <div className="add-item-box"><AddItem>
                            <label htmlFor="galleryPhotoUpload">Add agency photo</label>
                            <input id="galleryPhotoUpload"
                                type="file"
                                name="galleryPhotos"
                                multiple
                                accept="image/png, image/jpeg"
                                onChange={(e) => uploadGalleryPhotos(e)} />
                        </AddItem></div>}
                </div>}
            </div>
        </>
    );
};

export default ShowPhotos;
