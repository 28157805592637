import { AlphanumericInput, Badge, Button, FieldGroup, GridCol, GridRow, IconAddItem, IconButton, IconTrash, Link, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Group } from "../../models/Agent";
import '../../styles/OptionsModal.css'
import { AgentDataContext } from "../context/AgentDataContext";
import '../../styles/Modals.css';

interface GroupsAndAssociationsModalProps {
    data: Group[],
    edit: boolean,
    add: boolean,
    updateEdit: (val : boolean) => void,
    updateData: (val : Group[]) => void,
    setAdd: (val : boolean) => void,
}

function GroupsAndAssociationsModal (props : GroupsAndAssociationsModalProps) {
    const [edit, setEdit] = useState<boolean>(props.edit);
    const [add, setAdd] = useState<boolean>(props.add);
    const [groupsCounter, setGroupsCounter] = useState<number>(props.data.length);
    const { agentProfileData, groups, setGroups} = useContext(AgentDataContext);
    const [data, setData] = useState<Group[]>(_.cloneDeep(groups));

    useEffect(() => {
        if(add || groupsCounter === 0){
            if (groupsCounter !== 6) {
                setGroupsCounter(groupsCounter+1);
                setData([...data, {id:0, name:"", nameHtmlEncoded:null, agentProfileId:agentProfileData.agentId}]);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [errorMessageArray, setErrorMessageArray] = useState<string[][]>([[],[],[],[],[],[]]);

    const checkForErrors = () => {
        let errors: string[][] = [[],[],[],[],[],[]];
        data.forEach((group, index) => {
            if (group.name === "") {
                errors[index].push(`Group must have a name`);
            }
            if (group.name.length > 90) {
                errors[index].push("Group name cannot exceed 90 characters");
            }
        });

        setErrorMessageArray(errors);

        let countErrors = 0;
        errors.forEach((error) => {
            countErrors = countErrors + error.length;

        });

        return countErrors;
    };

    const cancelChanges = () => {
        setEdit(false);
        setAdd(false);
        props.updateEdit(false);
        props.setAdd(false);
        setData(_.cloneDeep(groups));
        setErrorMessageArray([[],[],[],[],[],[]]);
    };

    const renderGroups = () => {
        console.log("Data", data);
        return data.map((item: Group, idx:number) => {
            return (<React.Fragment key={idx}>
                                <FieldGroup isFull id="formField"
                                    messages={
                                        errorMessageArray[idx].map((error) => {
                                            return {
                                                text: error,
                                                type: 'error'
                                            };
                                        })
                                    }
                                >
                                <div className="trashable">
                                    <AlphanumericInput autoFocus labelVisual="Name" onChange={(e) => {updateData(idx, e)}} value={item.name}/>
                                    <IconTrash size="24" onClick={() => {if (groupsCounter !== 0) {setGroupsCounter(groupsCounter - 1);} removeData(idx); setErrorMessageArray([[],[],[],[],[],[]]);}}></IconTrash >
                                </div>
                            </FieldGroup>
                    </React.Fragment>)

        });

    }

    const updateData = (index: number, e : any) => {
        console.log("Index", index);
        let temp = [...data];
        temp[index]["name"] = e.target.value;
        setData([...temp]);
    }

    const removeData = (index : number) => {
        console.log("Data", data);
        let temp = [...data];
        temp.splice(index, 1);
        setData([...temp]);
    }

    return (
        
        <Modal
        isOpen={edit}
        size="small"
        onClose={() => cancelChanges()}
        >
            <ModalHeader><Heading className="modal-heading">Add groups and associations</Heading></ModalHeader>
            <ModalBody className="modal-body">
              
                                {renderGroups()}
              
                            <FieldGroup className="filed-group-margin-general">
                                {groupsCounter < 6 ?
                                    <div style={{display:'flex'}}>
                                        <div className = "filed-group-margin-items">
                                            <IconButton onClick={() => {
                                                if (groupsCounter !== 6) {
                                                    setGroupsCounter(groupsCounter+1);
                                                    setData([...data, {id:0, name:"", nameHtmlEncoded:null, agentProfileId:agentProfileData.agentId}]);
                                                }
                                            }}>
                                                <IconAddItem size = "24"></IconAddItem>
                                            </IconButton>
                                            </div>
                                                <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => {if (groupsCounter !== 6) {setGroupsCounter(groupsCounter+1); setData([...data, {id:0, name:"", nameHtmlEncoded:null, agentProfileId:agentProfileData.agentId}]);}}}>
                                                   {groupsCounter === 0
                                                        ? "Add groups and associations"
                                                        : "Add another group or association"
                                                   }
                                                </Link>
                                    </div>
                                    :
                                    <Badge highlightType="caution">Only up to 6 groups and associations allowed</Badge>
                                }
                            </FieldGroup>
            </ModalBody>
            <ModalFooter className="modal-footer">
                <GridRow gutterSize="fixed-16" gutters justify="end">
                    <GridCol className="button-container" base={3}>
                        <Button dynamicWidth style={{marginLeft:'auto'}} size="small" onClick={() => cancelChanges()}> Cancel </Button>
                    </GridCol>
                    <GridCol className="button-container" base={3}>    
                        <Button dynamicWidth size="small" variant = "primary" onClick={() => {
                            if(checkForErrors() === 0){
                                setEdit(false);
                                setAdd(false);
                                props.updateEdit(false);
                                props.setAdd(false);
                                props.updateData(data);
                                setGroups(_.cloneDeep(data));
                            }
                        }}>
                            Save
                        </Button>
                    </GridCol>
                </GridRow>
            </ModalFooter>
        </Modal>
    )
}
export default GroupsAndAssociationsModal;