import { useContext, useEffect } from "react";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import ModalAwards from "./ModalAwards";
import { IconButton, IconEdit } from "@lmig/lmds-react";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
type Award = {
    name: string,
    yearRecieved: string
}

const ShowAwardsAffiliations = () => {
    const { profileData, awards, setAwards, affiliations, setAffiliations } = useContext(ProfileDataContext);
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    useEffect(() => {

        try {

            var newAwards: Award[] = [];

            profileData.Awards.forEach((el: any) => {
                newAwards.push({ name: el.Name, yearRecieved: el.YearReceived })
            });

            var newAffiliations: string[] = [];

            profileData.assignedAffiliatoinCB.affiliationCB.forEach((el: any) => {
                if (el.Assigned)
                    newAffiliations.push(el.Affiliation_Name);
            })

            profileData.assignedAffiliatoinCB.additionalAffiliations.forEach((el: any) => {
                newAffiliations.push(el.Name);
            })

            setAffiliations(newAffiliations);
            setAwards(newAwards);

        }
        catch (err) {
            return;
        }

    }, [profileData, setAwards, setAffiliations]);



    return (
        <>
            {(awards.length > 0 ||  affiliations.length > 0 ) ? <IconButton className="edit-btn-align-absolute" onClick={() => modalsContext.showModal(tiles.Awards, true)}>
                <IconEdit size="24" />
            </IconButton> : null}
            <div className='indentContent'>
                <div>
                    <ModalAwards />
                </div>
            </div>
        </>
    );
};

export default ShowAwardsAffiliations;
