import { AlphanumericInput, FieldGroup, GridCol, GridRow, IconAddItem, IconButton, IconTrash, Link, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import { useState, useContext, useEffect } from "react";
import { Education } from "../../models/Agent";
import '../../styles/OptionsModal.css'
import { AgentDataContext } from "../context/AgentDataContext";
import CancelAndSaveButtons from  '../modals/CancelAndSaveButtons';
import '../../styles/Modals.css';

interface EducationModalProps {
    education: Education[],
    edit: boolean,
    add: boolean,
    updateEdit: (val : boolean) => void,
    updateEducation: (val : Education[]) => void,
    setAdd: (val : boolean) => void,
}

function EducationModal (props : EducationModalProps) {
    const [edit, setEdit] = useState<boolean>(props.edit);
    const [add, setAdd] = useState<boolean>(props.add);
    const [educationId, setEducationId] = useState<number>(props.education.length);
    const { education, setEducation } = useContext(AgentDataContext);
    const [educationList, setEducationList] = useState<Education[]>(_.cloneDeep(education));

    useEffect(() => {
        if(add || educationId === 0){
            setEducationList([...educationList, {id:educationId, schoolName:"", fieldOfStudy: "", schoolNameHtmlEncoded: null, fieldOfStudyHtmlEncoded:null, agentProfileId:0}]);
            setEducationId(educationId + 1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    type errorsObject = {
        school: string[],
        field: string[]
    };
    const [errorMessageArray, setErrorMessageArray] = useState<errorsObject[]>([{ school: [], field: [] }, { school: [], field: [] }]);

    const checkForErrors = () => {
        let errors: errorsObject[] = [{ school: [], field: [] }, { school: [], field: [] }];
        educationList.forEach((school, index) => {
            if (school.schoolName === "") {
                errors[index].school.push(`School must have a name`);
            }
            if (school.schoolName.length > 100) {
                errors[index].school.push("School name cannot exceed 100 characters");
            }
            if (school.fieldOfStudy === "") {
                errors[index].field.push(`Field of Study must have a name`);
            }
            if (school.fieldOfStudy.length > 100) {
                errors[index].field.push("Field of Study cannot exceed 100 characters");
            }
        });

        setErrorMessageArray(errors);

        let countErrors = 0;
        errors.forEach((error) => {
            countErrors = countErrors + error.school.length + error.field.length;

        });

        return countErrors;
    };

    const cancelChanges = () => {
        setEdit(false);
        setAdd(false);
        props.updateEdit(false);
        props.setAdd(false);
        setEducationList(_.cloneDeep(education));
        setErrorMessageArray([{ school: [], field: [] }, { school: [], field: [] }]);
    };

    const renderEducation = () => {
        let temp = [...educationList];
        return temp.map((element : Education, idx : number) => {
            return (
                <>
                        <GridCol base={6}>
                            <FieldGroup id="formField" isFull
                                style={{ marginTop: errorMessageArray[idx].school.length === 0 && errorMessageArray[idx].field.length ? "1.5rem" : "0rem" }}
                                messages={
                                    errorMessageArray[idx].school.map((error) => {
                                        return {
                                            text: error,
                                            type: 'error'
                                        };
                                    })
                                }
                            >
                                <AlphanumericInput autoFocus labelVisual="School" name = {idx + ""} value={element.schoolName} onChange ={(e) => {updateEducationObject(e.target.name, "School", e.target.value)}}/>
                            </FieldGroup>
                        </GridCol>
                        <GridCol>
                                <FieldGroup id="formField" isFull 
                                    style={{ marginTop: errorMessageArray[idx].field.length === 0 && errorMessageArray[idx].school.length ? "1.5rem" : "0rem" }}
                                    messages={
                                        errorMessageArray[idx].field.map((error) => {
                                            return {
                                                text: error,
                                                type: 'error'
                                            };
                                        })
                                    }
                                >
                                    <AlphanumericInput  labelVisual="Field of Study" name = {idx + ""} value={element.fieldOfStudy} onChange ={(e) => {updateEducationObject(e.target.name, "Field of Study", e.target.value)}}/>
                                </FieldGroup>
                        
                        </GridCol>
                        <div className="trashable" style={{ marginTop: errorMessageArray[idx].school.length || errorMessageArray[idx].field.length ? "rem" : "0rem" }}>
                            <GridCol base={1}>
                                <IconTrash size = "24" id = {idx + ""} onClick = {(e:any) => {removeEducationObject(idx + ""); setErrorMessageArray([{ school: [], field: [] }, { school: [], field: [] }])}}></IconTrash>
                            </GridCol>
                        </div>
                    </>
            )
        });

    }

    const updateEducationObject = (id :string, field: string, val : string) => {
        let temp = [...educationList];
        if (field === "School") {
            temp[parseInt(id)]["schoolName"] = val;
        } else {
            temp[parseInt(id)]["fieldOfStudy"] = val;
        }

        setEducationList([...temp]);
    }

    const removeEducationObject = (id: string) => {
        let temp = [...educationList];
        temp.splice(parseInt(id), 1);
        setEducationList([...temp]);
        setEducationId(temp.length);
    }


    return (

        <Modal
        isOpen={edit}
        size="small"
        onClose={() => cancelChanges()}
        >
            <ModalHeader><Heading className="modal-heading">Education</Heading></ModalHeader>
            <ModalBody className="modal-body">
            <GridRow gutters="horizontal" gutterSize="fixed-16">
                {renderEducation()}
            </GridRow>
                <FieldGroup id="formField">
                    {educationId < 2 ?
                            <div className = "filed-group-margin-items">
                                <IconButton onClick={()=>{setEducationList([...educationList, {id:educationId, schoolName:"", fieldOfStudy: "", schoolNameHtmlEncoded: null, fieldOfStudyHtmlEncoded:null, agentProfileId:0}]); setEducationId(educationId + 1);}}>
                                    <IconAddItem size = "24"></IconAddItem>
                                </IconButton>
                                <Link className="link-general" variant="standalone" caretPosition="none" onClick={()=>{setEducationList([...educationList, {id:educationId, schoolName:"", fieldOfStudy: "", schoolNameHtmlEncoded: null, fieldOfStudyHtmlEncoded:null, agentProfileId:0}]); setEducationId(educationId + 1);}}>
                                    {educationId === 0
                                        ? "Add education"
                                        : "Add another education"
                                    }
                                </Link>
                            </div>
                    : null}
                </FieldGroup>
            </ModalBody>
            <ModalFooter className="modal-footer">
                <CancelAndSaveButtons
                    cancelFunction={cancelChanges}
                    saveFunction={() => {
                        if(checkForErrors() === 0){
                            setEdit(false);
                            setAdd(false);
                            props.updateEdit(false);
                            props.setAdd(false);
                            props.updateEducation([...educationList]);
                            setEducation(_.cloneDeep(educationList));}}}/>
            </ModalFooter>
        </Modal>

    )
}
export default EducationModal;