import { Button, GridCol, GridRow} from "@lmig/lmds-react";
import '../../styles/Modals.css';

interface TestProps {
    cancelTitle?: string;
    cancelFunction: Function;
    saveTitle?: string;
    saveDisabled?: boolean,
    saveFunction: Function;
}

const CancelAndSaveButtons = ({cancelTitle="Cancel", cancelFunction, saveTitle="Save", saveFunction, saveDisabled=false} : TestProps) => {
    return(
        <GridRow gutterSize="fixed-16" gutters justify="end">
            <GridCol className="button-container" base={6}>
                <Button dynamicWidth onClick={() => cancelFunction()} >
                    {cancelTitle}
                </Button>
            </GridCol>
            <GridCol className="button-container" base={6}>
                <Button disabled={saveDisabled? saveDisabled: false} dynamicWidth variant="primary" onClick={() => saveFunction()}>
                    {saveTitle}
                </Button>
            </GridCol>
        </GridRow>
    )
};

export default CancelAndSaveButtons;