import { createContext, Dispatch, SetStateAction, useState } from "react"
import { weekDays } from "../../constants/time"
import { Article, CarrierModel, dayOpHours, hoursObject, ProductSpecialty } from "../../models/Agency"

type Props = {
  children: JSX.Element
}

type AboutAgency = {
  summary: string,
  yearEstablished: string
}

type Award = {
  name: string,
  yearRecieved: string
}

type officeLocation = {
  officeName?: string,
  addressLine1?: string,
  addressLine2?: string,
  city?: string,
  state?: string,
  zip?: string,
  phone?: string,
  isNew?: string,
  isDeleted?: string,
  id?: string

}

type OtherInfo = {
  otherInfo: boolean,
  mainContactName: string,
  fax: string,
  mobile: string
}

export type ImageFile = {
  name: string,
  url: string,
  IsDeleted: boolean,
  Blob_Id: string,
  base64?: string | null,
  Description?:string,
  size?: Number
}

type Photos = {
  mainPhoto: ImageFile[],
  galleryPhotos: ImageFile[]
}

type ContactInfo = {
  agencyName: string,
  website: string,
  email: string,
  phone: string,
  otherInfo: boolean,
  mainContactName: string,
  fax: string,
  mobile: string,
  address: string,
  chat: {
    google?:string,
    skype?:string,
    web?:string
  }
}

type SocialMedia = {
  facebook: string,
  linkedIn: string,
  twitter: string
}

export type agentData = {
  phone: string,
  email: string,
  photo: string,
  position: Number,
  name: string,
  agentId: string,
  percentComplete: Number,
  ordered: boolean,
  agencyId: string,
  agencyName: string,
  yearStarted: string | null,
  summary: string,
  show: boolean,
  profileStatus: string | null | undefined,
};

type Context = {
  profileData: any;
  setProfileData: Dispatch<SetStateAction<any>>;
  aboutAgency: AboutAgency;
  setAboutAgency: Dispatch<SetStateAction<AboutAgency>>;
  operationHours: hoursObject;
  setOperationHours: Dispatch<SetStateAction<hoursObject>>;
  articles: Article[];
  setArticles: Dispatch<SetStateAction<Article[]>>;
  awards: Award[];
  setAwards: Dispatch<SetStateAction<Award[]>>;
  officeLocations: officeLocation[];
  setOfficeLocations: Dispatch<SetStateAction<officeLocation[]>>;
  otherContactInfo: OtherInfo;
  setOtherContactInfo: Dispatch<SetStateAction<OtherInfo>>;
  photos: Photos;
  setPhotos: Dispatch<SetStateAction<Photos>>;
  preferredContactInfo: ContactInfo;
  setPreferredContactInfo: Dispatch<SetStateAction<ContactInfo>>;
  socialMediaLinks: SocialMedia;
  setSocialMediaLinks: Dispatch<SetStateAction<SocialMedia>>;
  affiliations: string[];
  setAffiliations: Dispatch<SetStateAction<string[]>>;
  deleteModalClose: boolean;
  setDeleteModalClose: Dispatch<SetStateAction<boolean>>;
  //clearCarriers:boolean;
  //setClearCarriers: Dispatch<SetStateAction<boolean>>;
  notes: string;
  setNotes: Dispatch<SetStateAction<string>>;
  products: ProductSpecialty[];
  setProducts: Dispatch<SetStateAction<ProductSpecialty[]>>;
  carriers: CarrierModel[];
  setCarriers: Dispatch<SetStateAction<CarrierModel[]>>;
  sortedAgentDataList: agentData[] | null;
  setSortedAgentDataList: Dispatch<SetStateAction<agentData[] | null>>;
  showReviews: boolean;
  setShowReviews: Dispatch<SetStateAction<boolean>>;
  modalOfficeOptions: { title: string, office?:officeLocation, function: Function} | null;
  setModalOfficeOptions: Dispatch<SetStateAction<{ title: string, office?:officeLocation, function: Function} | null>>;
  gaqDisabled: boolean;
  setGAQDisabled: Dispatch<SetStateAction<boolean>>;
};

var defaultHours: dayOpHours[] = [];
weekDays.forEach((day) => defaultHours.push({dayAbrv: ' ', day: day, hours: { 'begin': '---', 'end': '---' }, open: true }));

const initialContext: Context = {
  profileData: null,
  setProfileData: (): void => {
    throw new Error('setContext function must be overridden');
  },
  aboutAgency: { summary: '', yearEstablished: '' },
  setAboutAgency: (): void => {
    throw new Error('setContext function must be overridden');
  },
  operationHours: { 'timezone': '' as String, 'otherhours': false, 'opHours': defaultHours },
  setOperationHours: (): void => {
    throw new Error('setContext function must be overridden');
  },
  articles: [{ Title: '', Url: '' }],
  setArticles: (): void => {
    throw new Error('setContext function must be overridden');
  },
  awards: [{ name: '', yearRecieved: '' }],
  setAwards: (): void => {
    throw new Error('setContext function must be overridden');
  },
  officeLocations: [],
  setOfficeLocations: (): void => {
    throw new Error('setContext function must be overridden');
  },
  otherContactInfo: { otherInfo: true, mainContactName: '', fax: '', mobile: '' },
  setOtherContactInfo: (): void => {
    throw new Error('setContext function must be overridden');
  },
  photos: { mainPhoto: [], galleryPhotos: [] },
  setPhotos: (): void => {
    throw new Error('setContext function must be overridden');
  },
  preferredContactInfo: { agencyName: '', website: '', email: '', phone: '', otherInfo: true, address: '', mainContactName: '', fax: '', mobile: '', chat:{ google: "", skype: "", web: ""} },
  setPreferredContactInfo: (): void => {
    throw new Error('setContext function must be overridden');
  },
  socialMediaLinks: { facebook: '', linkedIn: '', twitter: '' },
  setSocialMediaLinks: (): void => {
    throw new Error('setContext function must be overridden');
  },
  affiliations: [],
  setAffiliations:  (): void => {
    throw new Error('setContext function must be overridden');
  },
  deleteModalClose: false,
  setDeleteModalClose: (): void => {
    throw new Error('setContext function must be overridden');
  },
  products: [],
  setProducts: (): void => {
    throw new Error('setContext function must be overridden');
  },
  carriers: [],
  setCarriers: (): void => {
    throw new Error('setContext function must be overridden');
  },
  sortedAgentDataList: null,
  setSortedAgentDataList: (): void => {
    throw new Error('setContext function must be overridden');
  },
  notes: '',
  setNotes: (): void => {
    throw new Error('setContext function must be overridden');
  },
  showReviews: true,
  setShowReviews: (): void => {
    throw new Error('setContext function must be overridden');
  },
  modalOfficeOptions: null,
  setModalOfficeOptions: (): void => {
    throw new Error('setContext function must be overridden');
  },
  gaqDisabled: true,
  setGAQDisabled: (): void => {
    throw new Error('setContext function must be overridden');
  },
};

export const ProfileDataContext = createContext(initialContext);
export function ProfileDataProvider({ children }: Props) {

  const [profileData, setProfileData] = useState<any>([]);
  const [aboutAgency, setAboutAgency] = useState<AboutAgency>({ summary: '', yearEstablished: '' });
  const [operationHours, setOperationHours] = useState<hoursObject>({ 'timezone': '---' as String, 'otherhours': false, 'opHours': defaultHours });
  const [articles, setArticles] = useState<Article[]>([]);
  const [awards, setAwards] = useState<Award[]>([]);
  const [officeLocations, setOfficeLocations] = useState<officeLocation[]>([]);
  const [otherContactInfo, setOtherContactInfo] = useState<OtherInfo>({ otherInfo: true, mainContactName: '', fax: '', mobile: '' });
  const [photos, setPhotos] = useState<Photos>({ mainPhoto: [], galleryPhotos: [] });
  const [preferredContactInfo, setPreferredContactInfo] = useState<ContactInfo>({ agencyName: '', website: '', email: '', address: '', phone: '', otherInfo: true, mainContactName: '', fax: '', mobile: '', chat:{} });
  const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMedia>({ facebook: '', linkedIn: '', twitter: '' });
  const [affiliations, setAffiliations] = useState<string[]>([]);
  const [deleteModalClose, setDeleteModalClose] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductSpecialty[]>([]);
  const [carriers, setCarriers] = useState<CarrierModel[]>([]);
  const [sortedAgentDataList, setSortedAgentDataList] = useState<agentData[] | null>(null); //default to null to represent that agents list has not been yet loaded
  const [notes, setNotes] = useState<string>('');
  const [showReviews, setShowReviews] = useState<boolean>(true);
  const [modalOfficeOptions, setModalOfficeOptions] = useState<{ title: string, office?:officeLocation, function: Function} | null>(null);
  const [gaqDisabled, setGAQDisabled] = useState<boolean>(true);

  const store = {
    profileData, setProfileData,
    aboutAgency, setAboutAgency,
    operationHours, setOperationHours,
    articles, setArticles,
    awards, setAwards,
    officeLocations, setOfficeLocations,
    otherContactInfo, setOtherContactInfo,
    photos, setPhotos,
    preferredContactInfo, setPreferredContactInfo,
    socialMediaLinks, setSocialMediaLinks,
    affiliations, setAffiliations,
    deleteModalClose, setDeleteModalClose,
    products, setProducts,
    carriers, setCarriers,
    sortedAgentDataList, setSortedAgentDataList,
    notes, setNotes,
    showReviews, setShowReviews,
    modalOfficeOptions, setModalOfficeOptions,
    gaqDisabled, setGAQDisabled,
  };

  return (
    <ProfileDataContext.Provider value={store}>
      {children}
    </ProfileDataContext.Provider>
  );
}