import { useLocation, Outlet } from 'react-router-dom';
import { ToastContainer } from "react-toast";
import { LogoSafecoHoriz, LogoSafecoVert } from '@lmig/lmds-react';
import { Footer, Disclaimer, Header, Heading, IconHelp, Link  } from '@lmig/lmds-react';
import { useEffect, useState } from 'react';
import { AgenciesContextProvider } from '../context/AgenciesContext';
import { UserContextProvider } from '../context/UserContext';

function Layout() {
  const [fullBleed, setFullBleed] = useState<boolean>(false);
  let location = useLocation();
  let date = new Date();

  useEffect(() => {
    if (location.pathname.indexOf('edit') > -1)
      setFullBleed(true);
    else if (fullBleed)
      setFullBleed(false);

  }, [location, fullBleed]);

  return (
      <div id="page-container">
        <Header fullWidth className={"faa-header " + (fullBleed ? "full-bleed" : "")}> {/*  Header  */}
                <LogoSafecoHoriz color='inverse' />
                <div className="faa-header-app-name">
                  <Heading type="h5" align="left">Find an Agent</Heading>
                  <Heading type="h3-medium" align="left">Profile Manager</Heading>
                </div>
                <Link  caretPosition="none" onBackground="blue" variant="standalone" target="_blank" href={window.env.HELP} className="help-link">
                  <IconHelp title="Help" size="16" />
                  <span>Help</span>
                </Link>
            <ToastContainer delay={6000} position={"top-right"}/>
        </Header>
        <div id="content-wrap">
          <UserContextProvider>
          <AgenciesContextProvider>
            <Outlet />
          </AgenciesContextProvider>
          </UserContextProvider>
        </div>
        <footer id="footer" className='footer'> {/*  Footer  */}
          <Footer>

            <div style={{ float: 'left',width:'28%'}} ><LogoSafecoVert style={{ width:'30%'}} color='inverse' /></div>
            <div style={{ float: 'left',width:'70%'}} >
              <Disclaimer style={{ color:"#FFF", marginTop:'25px'}} >
                  <p>Safeco Insurance. For agent use only.&nbsp;
                  <a style={{ color:"#FFF"}} rel="noopener noreferrer" href="https://open.dpec.safeco.com/viewtermsofuse" target="_blank">Terms of Use</a>.&nbsp;
                  <a style={{ color:"#FFF"}} rel="noopener noreferrer" href="https://www.Libertymutual.com/privacypolicy" target="_blank">Privacy notice</a>.&nbsp;
                  <a style={{ color:"#FFF"}} rel="noopener noreferrer" href="https://privacyportal-cdn.onetrust.com/dsarwebform/50577d86-8d39-4310-9583-33e6d3da8e17/72f160d9-f2c3-4918-bb13-13507fe371ed.html" target="_blank">Personal data request</a>.&nbsp;
                  © {date.getFullYear()} Liberty Mutual Insurance.</p>
              </Disclaimer>
            </div>

            </Footer>
        </footer>
      </div>
  );
}

export default Layout;