import { BodyText, GridRow, Heading} from "@lmig/lmds-react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lmig/lmds-react-modal';
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";
import '../../styles/Modals.css';


interface RestoreConfirmationModalProps {
    open: boolean,
    yesRestore: () => void,
    noRestore: () => void
}

function RestoreConfirmationModal(props: RestoreConfirmationModalProps) {

    return (
        <Modal
            isOpen={props.open}
            size="small"
            onClose={() => props.noRestore()}
        >
        <ModalHeader><Heading className="modal-heading">Restore Profile</Heading></ModalHeader>
        <ModalBody className="modal-body">
            <GridRow justify="center">
                <BodyText type="lead" style={{textAlign: 'center'}}>
                    <p>Are you sure you want to restore your profile to the published version? Changes to your profile will not be saved.</p>
                </BodyText>
            </GridRow>
        </ModalBody>
        <ModalFooter className="modal-footer">
            <CancelAndSaveButtons
            saveTitle="Restore"
            cancelFunction={props.noRestore}
            saveFunction={props.yesRestore}/>
        </ModalFooter>
        </Modal>
    )
}

export default RestoreConfirmationModal;
