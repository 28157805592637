import { Heading, IconAddItem, IconButton, IconEdit, Link, List, ListItem} from "@lmig/lmds-react";
import { useState } from "react";
import { Job } from "../../../models/Agent";
import JobModal from "../../modals/JobModal";

interface WorkEperienceSectionProps {
    pastJobs: Job[];
    currentJobs: Job[];
    updatePastJobs: (val:Job[]) => void;
    updateCurrentJobs: (val:Job[]) => void;
}

function WorkExperienceSection(props : WorkEperienceSectionProps) {
    const [editCurrentJobs, setEditCurrentJobs] = useState<boolean>(false);
    const [editPastJobs, setEditPastJobs] = useState<boolean>(false);
    const [add, setAdd] = useState<boolean>(false);


    const jobsPresent = (jobs : Job[]) => {
        if (jobs.length === 0) {
            return false;
        }

        for (let i = 0; i < jobs.length; i++) {
            if (jobs[i].company !== "" || jobs[i].title !== "") {
                return true;
            }
        }
        return false;
    }

    return (
        <>
        <Heading type="h5">Work Experience</Heading>
                    <div className = "filed-group-margin-items">
                        {jobsPresent(props.currentJobs) ?
                            <div className='indent-content'>
                                        <div className="contact-information-section-edit-row">
                                            <Heading type="h6" style={{textDecoration: "underline"}} noWrap>Current Roles</Heading>
                                            {jobsPresent(props.currentJobs) &&
                                                        <IconButton id="currentJobModalButton" style={{marginBottom:'.75em'}} onClick={()=>{setEditCurrentJobs(true)}}>
                                                            <IconEdit className="icon-edit-custom-size" size="24"/>
                                                        </IconButton>}
                                        </div>
                                        <List compact>
                                        {props.currentJobs.map((item : Job, idx : number) => {
                                            if (item.company !== "" && item.title !== "")
                                                return (<ListItem key={idx}>{item.title} at {item.company}</ListItem>)
                                            else
                                                return <></>

                                        })}
                                        </List>
                                        {props.currentJobs.length < 3 ?
                                                    <div  className="indent-content-inner">
                                                            <IconButton id="currentJobModalButton" onClick={() => [setEditCurrentJobs(true), setAdd(true)]}>
                                                                <IconAddItem size = "24"></IconAddItem>
                                                            </IconButton>
                                                            <div className="icon-text-long">
                                                                <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => [setEditCurrentJobs(true), setAdd(true)]}>Add another current role</Link>
                                                            </div>
                                                    </div> : null }
                            </div>
                            :
                            <div className='indent-content-inner filed-group-margin-items'>
                                <div className='indent-content'>
                                    <div className="contact-information-section-edit-row">
                                        <Heading type="h6" style={{textDecoration: "underline"}} noWrap>Current Roles</Heading>
                                    </div>
                                    <div className='filed-group-margin-items indent-content-inner'>
                                        <IconButton id="currentJobModalButton" onClick={() => {setEditCurrentJobs(true)}}>
                                            <IconAddItem size = "24"></IconAddItem>
                                        </IconButton>
                                        <div className="icon-text-long">
                                            <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => {setEditCurrentJobs(true)}}>Add current role</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            {editCurrentJobs ?
                <JobModal jobs={props.currentJobs} edit={true} updateEdit={setEditCurrentJobs} add={add} setAdd={setAdd} updateJobs={props.updateCurrentJobs} title="Current Jobs"/>
                : null}
                        <div className = "filed-group-margin-items">
                            {jobsPresent(props.pastJobs) ?
                                <div className='indent-content'>
                                    <div className="contact-information-section-edit-row">
                                        <Heading type="h6" style={{textDecoration: "underline", marginTop: "1em"}} noWrap>Past Jobs</Heading>
                                        {jobsPresent(props.pastJobs) &&
                                        <div className="edit-btn-align-work">
                                            <IconButton id="pastJobsModalButton" onClick={()=>{setEditPastJobs(true)}}>
                                                <IconEdit className="icon-edit-custom-size" size="24"/>
                                            </IconButton>
                                        </div>}
                                    </div>
                                    <List compact>
                                        {props.pastJobs.map((item : Job, idx : number) => {
                                            if (item.company !== "" && item.title !== "")
                                                return (<ListItem key={idx}>{item.title} at {item.company}</ListItem>)
                                            else
                                                return <></>

                                        })}
                                    </List>
                                    {props.pastJobs.length < 3 ?
                                            <div className="indent-content-inner">
                                                    <IconButton id="pastJobsModalButton" onClick={() => [setEditPastJobs(true), setAdd(true)]}>
                                                        <IconAddItem size = "24"></IconAddItem>
                                                    </IconButton>
                                                    <div className="icon-text-long">
                                                        <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => [setEditPastJobs(true), setAdd(true)]}>Add another past job</Link>
                                                    </div>
                                            </div>: null}
                                </div>
                                :
                                <div className='indent-content-inner filed-group-margin-items'>
                                    <div className='indent-content'>
                                        <div className="contact-information-section-edit-row">
                                            <Heading type="h6" style={{textDecoration: "underline", marginTop: "1em"}} noWrap>Past Jobs</Heading>
                                        </div>
                                        <div className='filed-group-margin-items indent-content-inner'>
                                        
                                        <IconButton id="pastJobsModalButton" onClick={() => {setEditPastJobs(true)}}>
                                            <IconAddItem size = "24"></IconAddItem>
                                        </IconButton>
                                        <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => {setEditPastJobs(true)}}>Add past jobs</Link>
                                        </div>
                                        
                                    </div>
                                </div>
                            }
                        </div>
            {editPastJobs ?
            <JobModal jobs={props.pastJobs} edit={true} updateEdit={setEditPastJobs} add={add} setAdd={setAdd} updateJobs={props.updatePastJobs} title="Past Jobs"/>
            : null}
        </>
    );
}

export default WorkExperienceSection;