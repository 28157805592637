import { Heading, IconFacebook, IconLinkedin, IconTwitter, IconEdit, IconButton } from "@lmig/lmds-react";
import { useContext, useEffect, useState } from "react";
import AddItem from "../../../../../helpers/AddItem";
import { ModalsContext, IModalsContext, tiles } from "../../../../context/ModalsContext";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import ModalSocialMedia from "./ModalSocialMedia";

function SocialMedia() {
    const { profileData, socialMediaLinks, setSocialMediaLinks } = useContext(ProfileDataContext);

    const modalsContext = useContext<IModalsContext>(ModalsContext);

    const [focused, setFocused] = useState<string>();
    useEffect(() => {
        try {
            setSocialMediaLinks({facebook: profileData.FacebookUrl ? profileData.FacebookUrl : '', twitter: profileData.TwitterUrl ? profileData.TwitterUrl : '', linkedIn: profileData.LinkedInUrl ? profileData.LinkedInUrl : ''});
        }
        catch  (err) {
            return;
        }

    }, [profileData, setSocialMediaLinks]);

    return (
        <>
         {socialMediaLinks.facebook || socialMediaLinks.linkedIn || socialMediaLinks.twitter ? <IconButton className="edit-btn-align-absolute" onClick={() => modalsContext.showModal(tiles.SocialMedia, true)}>
                <IconEdit size="24" />
            </IconButton> : null}
                <ModalSocialMedia focus={focused}/>
            <div className='indent-content-less'>
                    {socialMediaLinks.facebook !== '' ?
                        <Heading style={{marginLeft:"10px"}} type="h6-small" className="li" ><IconFacebook size="16" className="icon-normal-size" style={{marginRight: "10px"}}/> {socialMediaLinks.facebook === '' ? 'No Link Provided' : socialMediaLinks.facebook}</Heading>
                    : <div><AddItem text="Add Facebook page" action={() => {
                        setFocused('FACEBOOK');
                        modalsContext.showModal(tiles.SocialMedia, true);

                    }} /></div>}
                    {socialMediaLinks.linkedIn !== '' ?
                        <Heading style={{marginLeft:"10px"}} type="h6-small" className="li" ><IconLinkedin size="16" className="icon-normal-size" style={{marginRight: "10px"}}/>{socialMediaLinks.linkedIn === '' ? 'No Link Provided' : socialMediaLinks.linkedIn}</Heading>
                    : <div><AddItem text="Add LinkedIn profile" action={() => {
                        setFocused('LINKEDIN');
                        modalsContext.showModal(tiles.SocialMedia, true);

                    }} /></div>}
                    {socialMediaLinks.twitter !== '' ?
                        <Heading style={{marginLeft:"10px"}} type="h6-small" className="li" ><IconTwitter size="16" className="icon-normal-size" style={{marginRight: "10px"}}/> {socialMediaLinks.twitter === '' ? 'No Link Provided' : socialMediaLinks.twitter}</Heading>
                    : <div><AddItem text="Add Twitter" action={() => {
                        setFocused('TWITTER');
                        modalsContext.showModal(tiles.SocialMedia, true);

                    }} /></div>}

            </div>
        </>
    )
}

export default SocialMedia;