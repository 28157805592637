import { useState, useContext} from "react";
import { Heading } from "@lmig/lmds-react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lmig/lmds-react-modal';
import { ProfileDataContext } from "../../../context/ProfileDataContext";
import _ from "lodash";
import { Article, Award } from "../../../../models/Agency";
import { AdditionalPhone, AgentDataContext, SocialMedia, SupportedComms } from "../../../context/AgentDataContext";
import { Education, Job, Group } from "../../../../models/Agent";
import CancelAndSaveButtons from "../../../modals/CancelAndSaveButtons";
import "../../../../styles/Modals.css"

interface IState {
    isNormalOpen: boolean;
}

type ContactInfo = {
    agencyName: string,
    website: string,
    email: string,
    phone: string,
    otherInfo: boolean,
    mainContactName: string,
    fax: string,
    mobile: string,
    address: string,
    chat: {
        skype?:string, google?:string, web?:string
    }
  }

type officeLocation = {
    [key: string]: string,
    officeName: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    phone: string,
    isNew: string,
    isDeleted: string,
    id: string
}

type SocialMediaLinks = {
    facebook: string,
    linkedIn: string,
    twitter: string
  }

  type ImageFile = {
    name: string,
    url: string,
    IsDeleted: boolean,
    Blob_Id: string,
    base64?: string | null
}

const ModalDeleteContents = (props: {normalOpen:boolean, removeType:string}) => {
    const [state, setState] = useState<IState>({
        isNormalOpen: props.normalOpen || false,
    });

    var { preferredContactInfo, setPreferredContactInfo,
        officeLocations, setOfficeLocations,
        socialMediaLinks, setSocialMediaLinks,
        affiliations, setAffiliations,
        awards, setAwards,
        articles, setArticles,
        photos, setPhotos,
        deleteModalClose, setDeleteModalClose,
        carriers, setCarriers} = useContext(ProfileDataContext);

    var { additionalPhone, setAdditionalPhone,
        supportedCommunication, setSupportedCommunication,
        socialMedia, setSocialMedia,
        groups, setGroups,
        pastJobs, setPastJobs,
        education, setEducation,
        deleteModalClose2, setDeleteModalClose2,
        setClearLanguages } = useContext(AgentDataContext);

    var setClone;
    if(props.removeType === "otherCarriers"){
        setClone = carriers;
    }
    else if(props.removeType === "contactInfo"){
        setClone = preferredContactInfo;
    }
    else if(props.removeType === "addOfficeLocations"){
        setClone = officeLocations;
    }
    else if(props.removeType === "socialMediaLinks"){
        setClone = socialMediaLinks;
    }
    else if(props.removeType === "professionalAffiliations"){
        setClone = affiliations;
    }
    else if(props.removeType === "awards"){
        setClone = awards;
    }
    else if(props.removeType === "articles"){
        setClone = articles;
    }
    else if(props.removeType === "galleryPhotos"){
        setClone = photos.galleryPhotos;
    }
    else if(props.removeType === "additionalPhone"){
        setClone = additionalPhone;
    }
    else if(props.removeType === "supportedCommunication"){
        setClone = supportedCommunication;
    }
    else if(props.removeType === "socialMedia"){
        setClone = socialMedia;
    }
    else if(props.removeType === "groups"){
        setClone = groups;
    }
    else if(props.removeType === "pastJobs"){
        setClone = pastJobs;
    }
    else if(props.removeType === "education"){
        setClone = education;
    }
    const [copy] = useState(_.cloneDeep(setClone));

    const clearFields = () => {
        if(props.removeType === "otherCarriers"){
            var blankCarriers = carriers.map((x:any) => ({ id: x.id, carrierName:x.carrierName, selected: false}))
            setCarriers(blankCarriers);
        }
        else if(props.removeType === "contactInfo"){
            var blankPreferredContactInfo:ContactInfo = {...preferredContactInfo, chat:{google: "", skype: "", web: ""}};
            setPreferredContactInfo(_.cloneDeep(blankPreferredContactInfo));
        }
        else if(props.removeType === "addOfficeLocations"){
            var locationArray:officeLocation[] = [];
            (copy as officeLocation[])?.forEach((index) => {
                index.isDeleted = 'true';
                locationArray.push(index);
            })
            setOfficeLocations(_.cloneDeep(locationArray));
        }
        else if(props.removeType === "socialMediaLinks"){
            var blankSocialMediaLinks:SocialMediaLinks = {facebook: '', linkedIn: '', twitter: ''};
            setSocialMediaLinks(_.cloneDeep(blankSocialMediaLinks));
        }
        else if(props.removeType === "professionalAffiliations"){
            var affiliationsArray:string[] = [];
            setAffiliations(_.cloneDeep(affiliationsArray));
        }
        else if(props.removeType === "awards"){
            var awardsArray:Award[] = [];
            setAwards(_.cloneDeep(awardsArray));
        }
        else if(props.removeType === "articles"){
            var articlesArray:Article[] = [];
            setArticles(_.cloneDeep(articlesArray));
        }
        else if(props.removeType === "galleryPhotos"){
            var galleryPhotosArray:ImageFile[] = [];
            (copy as ImageFile[])?.forEach((index) => {
                index.IsDeleted = true;
                galleryPhotosArray.push(index);
            })
            setPhotos({...photos, galleryPhotos:_.cloneDeep(galleryPhotosArray)});
        }
        else if(props.removeType === "additionalPhone"){
            var blankAddPhone:AdditionalPhone = {otherPhone: '' , selectedOtherPhoneType: ''};
            setAdditionalPhone(_.cloneDeep(blankAddPhone));
        }
        else if(props.removeType === "supportedCommunication"){
            var blankComms:SupportedComms = {supportedCommunicationChannelsNA: false, googleChat: '', skype: ''}
            setSupportedCommunication(_.cloneDeep(blankComms));
        }
        else if(props.removeType === "socialMedia"){
            var blankSocialMedia:SocialMedia = {facebookUrl: '', linkedInUrl: ''};
            setSocialMedia(_.cloneDeep(blankSocialMedia));
        }
        else if(props.removeType === "languages"){
            setClearLanguages(true);
        }
        else if(props.removeType === "groups"){
            var blankGroups: Group[] = [];
            setGroups(_.cloneDeep(blankGroups));
        }
        else if(props.removeType === "pastJobs"){
            var blankPastJobs: Job[] = [];
            setPastJobs(_.cloneDeep(blankPastJobs));
        }
        else if(props.removeType === "education"){
            var blankEducation: Education[] = [];
            setEducation(_.cloneDeep(blankEducation));
        }
    }

    const displayText = () => {
        if(props.removeType === "otherCarriers"){
            return("Delete all other carrier selections?")
        }
        else if(props.removeType === "contactInfo"){
            return("Delete all other contact information?")
        }
        else if(props.removeType === "addOfficeLocations"){
            return("Delete all additional office locations?")
        }
        else if(props.removeType === "socialMedia" || props.removeType === "socialMediaLinks"){
            return("Delete all social media links?")
        }
        else if(props.removeType === "professionalAffiliations"){
            return("Delete all professional affiliations?")
        }
        else if(props.removeType === "awards"){
            return("Delete all awards?")
        }
        else if(props.removeType === "articles"){
            return("Delete all articles?")
        }
        else if(props.removeType === "galleryPhotos"){
            return("Delete all gallery pictures?")
        }
        else if(props.removeType === "additionalPhone"){
            return("Delete additional phone number?")
        }
        else if(props.removeType === "supportedCommunication"){
            return("Delete google chat and skype usernames?")
        }
        else if(props.removeType === "languages"){
            return("Delete all extra languages?")
        }
        else if(props.removeType === "groups"){
            return("Delete all groups and associations?")
        }
        else if(props.removeType === "pastJobs"){
            return("Delete all past jobs?")
        }
        else if(props.removeType === "education"){
            return("Delete all education entries?")
        }
    }

    return (
        <>
            <Modal
                mandatory
                isOpen={state.isNormalOpen}
                size="small"
                onClose={ () => {
                    setState({ ...state, isNormalOpen: false })
                }}
            >

            <ModalHeader><Heading className="modal-heading">Delete Contents?</Heading></ModalHeader>
                <ModalBody className="modal-body">
                    <p>
                        {displayText()}
                    </p>
                    </ModalBody>
                    <ModalFooter className="modal-footer">
                        <CancelAndSaveButtons
                        saveTitle="Delete"
                        cancelFunction={() => {
                            try{
                                setDeleteModalClose(!deleteModalClose);
                            }
                            catch{
                                setDeleteModalClose2(!deleteModalClose2);
                            }
                            setState({ ...state, isNormalOpen: false });
                        }}
                        saveFunction={() => {
                            clearFields();
                            setState({ ...state, isNormalOpen: false });
                        }}/>
                    </ModalFooter>
            </Modal>
        </>
    )
}

export default ModalDeleteContents