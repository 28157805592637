import { Heading, IconButton, IconEdit, IconEmail, IconPhone, IconSmartphone, IconChat, IconDocument, IconAgent, IconHelp } from "@lmig/lmds-react";
import { Tooltip } from '@lmig/lmds-react-tooltip';
import { useContext, useEffect, useState } from "react"; 
import AddItem from "../../../../../helpers/AddItem";
import { nameCase, isEmpty, isPhoneFieldEmpty } from "../../../../../helpers/StringFormatter";
import { formatPhoneNumber } from "../../../../../services/FAAApi";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import ModalChatOptions from "./ModalChatOptions";
import ModalPreferredContactInfo from "./ModalPreferredContactInfo";
import '../../../../../styles/ShowPreferredContactInfo.css'

const ShowPreferredContactInfo = () => {
    const { profileData, preferredContactInfo, setPreferredContactInfo } = useContext(ProfileDataContext);
    const [focused, setFocused] = useState<string>();
    const modalsContext = useContext<IModalsContext>(ModalsContext);

    useEffect(() => {
        try {
            setPreferredContactInfo({
                agencyName: profileData.PreferredAgencyName ?? "",
                website: profileData.PreferredAgencyWebsite ?? "",
                email: profileData.PreferredAgencyEmail ?? "",
                phone: profileData.PreferredAgencyPhone ?? "",
                otherInfo: profileData.OtherContactInfoNA,
                address: profileData.AddressLine1 ?? "",
                mainContactName: profileData.MainContact ?? "",
                fax: profileData.FaxNumber ?? "",
                mobile: profileData.MobilePhone ?? "",
                chat: {
                    google: profileData.GoogleChatUsername,
                    skype: profileData.SkypeUsername,
                    web: profileData.WebsiteChatUrl
                }
            });
        }
        catch (err) {
            return;
        }
    }, [profileData, setPreferredContactInfo]);
    
    return (
        <>
            {modalsContext.contactInfo && <ModalPreferredContactInfo focus={focused} />}
            {modalsContext.chat && <ModalChatOptions focus={focused} />}
            <div className='indentContent'>
            <div className='contact-information-section-edit-row'>
                        <Heading type="h5">Name and phone</Heading>
                        <IconButton style={{ marginBottom: '.75em'}} onClick={() => { setFocused(undefined); modalsContext.showModal(tiles.ContactInfo, true)  }}>
                            <IconEdit size="24" />
                        </IconButton>
                </div>
            <div className='contact-information-subsection'>
                    <p className="fieldStyle" style={{whiteSpace:"nowrap", overflow: "hidden", textOverflow:"ellipsis", fontStyle: isEmpty(preferredContactInfo.agencyName) ? "italic" : ""}}>
                        {isEmpty(preferredContactInfo.agencyName) ? nameCase(profileData?.AgencyName) : preferredContactInfo.agencyName}
                    </p>
                    <p className="fieldStyle" style={{ fontStyle: isPhoneFieldEmpty(preferredContactInfo.phone) ? "italic" : ""  }}>
                        <IconPhone size="24" style={{ width: "18px", marginRight: "5px" }} />
                        { isPhoneFieldEmpty(profileData.AgencyPhone) && isPhoneFieldEmpty(preferredContactInfo.phone) ? 
                                <Tooltip direction="above" align="start" content="Edit contact information to add your phone number">
                                    <span><IconHelp color="teal" size="16" /></span>
                                </Tooltip> : ( isPhoneFieldEmpty(preferredContactInfo.phone) ? formatPhoneNumber(profileData.AgencyPhone) : preferredContactInfo.phone ) }
                        
                    </p>
                    <p className="fieldStyle" style={{ fontStyle: isEmpty(preferredContactInfo.website) ? "italic" : "" }}>
                        <span style={{marginRight: "1px"}}>
                        <svg className="page-header-icon" xmlns="http://www.w3.org/2000/svg" version="1.0" width="18px" height="25px" style={{verticalAlign:'top'}} viewBox="0 0 24.000000 24.000000" preserveAspectRatio="xMidYMid meet">
                            <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)" fill="#06748c" stroke="none">
                                <path d="M94 213 c-66 -12 -92 -110 -42 -159 37 -37 99 -37 136 0 68 67 3 177 -94 159z m106 -88 c0 -56 -33 -76 -50 -30 -7 19 -17 25 -40 25 -36 0 -37 9 -4 35 14 11 31 25 37 32 16 16 57 -29 57 -62z m-105 -48 c18 -36 18 -37 3 -37 -24 0 -58 43 -58 73 0 30 0 30 20 12 11 -10 27 -32 35 -48z" />
                            </g>
                        </svg> </span>
                        { isEmpty(profileData.AgencyWeb) && isEmpty(preferredContactInfo.website) ? 
                            <Tooltip direction="above" align="start" content="Edit contact information to add your website url">
                            <span><IconHelp color="teal" size="16" /></span>
                            </Tooltip> : (profileData && isEmpty(preferredContactInfo.website) ? profileData.AgencyWeb : preferredContactInfo.website )
                        }
                    </p>
                    <p className="fieldStyle" style={{ fontStyle: isEmpty(preferredContactInfo.email) ? "italic" : "" }}>
                        <IconEmail size="24" style={{ width: "18px", marginRight: "5px" }} />
                        { isEmpty(profileData.AgencyEmail) && isEmpty(preferredContactInfo.email) ? 
                            <Tooltip direction="above" align="start" content="Edit contact information to add your email">
                            <span><IconHelp color="teal" size="16" /></span>
                            </Tooltip> : ( profileData && isEmpty(preferredContactInfo.email) ? profileData.AgencyEmail : preferredContactInfo.email)
                        }
                    </p>
                    <p className="fieldStyle">
                        {!isEmpty(preferredContactInfo.mainContactName) ?
                         <><IconAgent size="24" title="Main Contact" style={{ width: "18px", marginRight: "1.25px" }} /> {preferredContactInfo.mainContactName}</>
                         :""
                        }
                    </p>
                    <p className="fieldStyle">
                        {!isEmpty(preferredContactInfo.fax) ?
                         <><IconDocument size="24" title="Fax" style={{ width: "18px", marginRight: "1.25px" }} /> {preferredContactInfo.fax}</>
                         :""
                        }
                    </p>
                    <p className="fieldStyle">
                        { !isEmpty(preferredContactInfo.mobile) ?
                          <><IconSmartphone size="24" title="Mobile" style={{ width: "18px", marginRight: "1.25px" }} /> {preferredContactInfo.mobile}</>
                          :""
                        }
                    </p>
                    <p className="fieldStyle">{isEmpty(preferredContactInfo.mainContactName) ? <AddItem text="Add main contact" action={() => { setFocused("MAINCONTACT"); modalsContext.showModal(tiles.ContactInfo, true) }} />
                        : ""
                        }</p>
                    <p className="fieldStyle">{isEmpty(preferredContactInfo.fax) ? <AddItem text="Add fax" action={() => { setFocused("FAX"); modalsContext.showModal(tiles.ContactInfo, true) }} />
                        : ""
                        }</p>
                    <p className="fieldStyle">{isEmpty(preferredContactInfo.mobile)
                        ? <AddItem text="Add mobile phone" action={() => { setFocused("MOBILE"); modalsContext.showModal(tiles.ContactInfo, true) }} />
                        : ""
                        }</p>
                </div>

            <div className='contact-information-section-edit-row'>
                    <Heading type="h5">Chat options</Heading>
                    <IconButton onClick={() => { modalsContext.showModal(tiles.Chat, true) }}><IconEdit size="24" /></IconButton>
            </div>
            <div className='contact-information-subsection'>
                {!isEmpty(preferredContactInfo.chat.google) && <>
                    <p className="fieldStyle" title="Google chat">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.4 499.98" style={{verticalAlign:'middle'}} width="18px" height="18px"><title>google-chat</title><path className="cls-1" d="M121.24,275.69V113.19h-71a37.86,37.86,0,0,0-37.8,37.9V487c0,16.9,20.4,25.3,32.3,13.4l78.1-78.1h222.4a37.77,37.77,0,0,0,37.8-37.8v-71H159.14A37.86,37.86,0,0,1,121.24,275.69Z" transform="translate(-12.44 -5.99)"/><path className="cls-2" d="M454,6H159.14a37.77,37.77,0,0,0-37.8,37.8v69.4h223.9A37.77,37.77,0,0,1,383,151v162.4h71a37.77,37.77,0,0,0,37.8-37.8V43.79A37.77,37.77,0,0,0,454,6Z" transform="translate(-12.44 -5.99)"/><path className="cls-3" d="M345.24,113.19h-224v162.4a37.77,37.77,0,0,0,37.8,37.8h223.9V151.09A37.71,37.71,0,0,0,345.24,113.19Z" transform="translate(-12.44 -5.99)"/></svg>
                    <span style={{marginLeft:'4px'}}>{preferredContactInfo.chat.google}</span>
                    </p>
                </>}
                {!isEmpty(preferredContactInfo.chat.skype) && <>
                    <p className="fieldStyle" title="Skype">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{verticalAlign:'middle'}} width="18px" height="18px"><path fill="#03A9F4" d="M15.716 8.083c0-4.155-3.416-7.524-7.631-7.524-.444 0-.88.038-1.305.11A4.453 4.453 0 0 0 4.426 0C1.981 0 0 1.953 0 4.364a4.3 4.3 0 0 0 .608 2.207 7.464 7.464 0 0 0-.155 1.512c0 4.156 3.417 7.525 7.631 7.525a7.85 7.85 0 0 0 1.398-.126 4.46 4.46 0 0 0 2.093.518C14.019 16 16 14.046 16 11.637c0-.696-.166-1.354-.46-1.938a7.43 7.43 0 0 0 .176-1.616z"/><path fill="#FAFAFA" d="M11.999 11.679c-.353.493-.874.883-1.551 1.159-.669.274-1.47.415-2.384.415-1.096 0-2.015-.19-2.733-.566a3.547 3.547 0 0 1-1.256-1.1c-.325-.46-.488-.917-.488-1.36 0-.274.107-.514.318-.709.209-.195.477-.292.797-.292.262 0 .488.077.672.23.175.148.326.366.447.645.135.306.282.564.437.765.151.197.366.361.641.49.277.128.65.195 1.108.195.631 0 1.149-.134 1.537-.395.381-.255.565-.563.565-.939 0-.296-.097-.53-.294-.713a2.104 2.104 0 0 0-.814-.444 16.188 16.188 0 0 0-1.4-.342c-.793-.167-1.466-.364-2-.59-.547-.23-.989-.548-1.311-.945-.328-.406-.494-.913-.494-1.509 0-.568.174-1.08.518-1.522.341-.439.839-.782 1.482-1.015.633-.231 1.386-.347 2.239-.347.681 0 1.28.078 1.781.232.503.154.927.362 1.26.619.336.26.586.535.742.823.158.29.239.579.239.858 0 .269-.105.514-.313.726-.21.214-.474.322-.784.322-.282 0-.504-.069-.657-.202-.143-.125-.292-.32-.456-.598a2.507 2.507 0 0 0-.685-.836c-.257-.193-.685-.289-1.275-.289-.546 0-.992.108-1.322.322-.318.205-.473.441-.473.721 0 .171.05.314.153.437.108.132.261.245.455.341.2.099.407.179.614.235.212.058.567.145 1.056.256.618.13 1.185.277 1.687.436.509.159.947.356 1.307.587.365.235.654.535.86.895.206.363.31.808.31 1.326a2.833 2.833 0 0 1-.535 1.678z"/></svg>
                    <span> {preferredContactInfo.chat.skype} </span>
                    </p>
                </>}
                {!isEmpty(preferredContactInfo.chat.web) && <>
                    <p className="fieldStyle" title="Web chat">
                    <IconChat size="16" color="teal" className="contact-link-icon" />
                    <span style={{marginLeft:'6px'}}>{preferredContactInfo.chat.web} </span>
                    </p>
                </>}
                {isEmpty(preferredContactInfo.chat.google) &&
                    <p>
                        <AddItem text="Add Google chat" action={() => { setFocused("GOOGLE"); modalsContext.showModal(tiles.Chat, true) }} />
                    </p>
                }
                {isEmpty(preferredContactInfo.chat.skype) &&
                    <p>
                        <AddItem text="Add Skype" action={() => { setFocused("SKYPE"); modalsContext.showModal(tiles.Chat, true) }} />
                    </p>
                }
                {isEmpty(preferredContactInfo.chat.web) &&
                    <p>
                        <AddItem text="Add Website chat" action={() => { setFocused("WEBCHAT"); modalsContext.showModal(tiles.Chat, true) }} />
                    </p>
               }
               </div>
               </div>
        </>
    );
};

export default ShowPreferredContactInfo;
