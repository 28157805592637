import { IconButton, IconEdit, IconTrash } from "@lmig/lmds-react";
import { useContext, useEffect } from "react";
import AddItem from "../../../../../helpers/AddItem";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import OfficeLocationsModal from "./OfficeLocationsModal";
import { guid } from '../../../../../helpers/Helper';

export type officeLocation = {
    officeName?: string,
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    state?: string,
    zip?: string,
    phone?: string,
    isNew?: string,
    isDeleted?: string,
    id?: string
}

const ShowOfficeLocations = () => {
    const { profileData, officeLocations, setOfficeLocations, modalOfficeOptions, setModalOfficeOptions } = useContext(ProfileDataContext);

    const checkNull = (field: string | null) => {  return field ? field : ''; }
    const modalsContext = useContext<IModalsContext>(ModalsContext);

    useEffect(() => {
        try {
            var newLocations: officeLocation[] = [];

            profileData.OtherOfficeLocations.forEach((el: any) => {
                newLocations.push({
                    officeName: checkNull(el.OfficeName),
                    addressLine1: checkNull(el.AddressLine1),
                    addressLine2: checkNull(el.AddressLine2),
                    city: checkNull(el.City),
                    state: checkNull(el.State),
                    zip: checkNull(el.Zip),
                    phone: checkNull(el.Phone),
                    isNew: 'false',
                    isDeleted: 'false',
                    id: `${el.Id}`})
            });

            setOfficeLocations(newLocations);
        }
        catch  (err) {
            return;
        }
    }, [profileData, setOfficeLocations]);


    const getValidOfficeLocationsLength = () => {

        return officeLocations.filter((location: officeLocation) => location.isDeleted !== 'true').length;

    };

    const renderLocations = () => {
        if (officeLocations.length) {
            return officeLocations.map((location: officeLocation, index: number) => {
                if (location.isDeleted === 'false') {
                    return (

                        <div key={index} style={{ paddingBottom: "15px" }}>
                                 <div style={{display: "inline-flex" , float: "right"}}>
                <IconButton style={{ marginLeft:"auto" }} onClick={() => {setModalOfficeOptions({ title:`Edit ${location.officeName} location`, office:location, function: (location: officeLocation) => editLocation(location)});modalsContext.showModal(tiles.OfficeLocation, true);}}>
                                    <IconEdit size="24" />
                                </IconButton>
                                <IconButton onClick={() => setOfficeLocations([...officeLocations.filter((loc:officeLocation) => location.id !== loc.id), { ...location, isDeleted: 'true'}])}>
                                    <IconTrash size="24" />
                                </IconButton>

                </div>
                                <div key={index} style={{ paddingBottom: "15px" }}>
                    <div>

                        <p >
                            <b>{location.officeName ? location.officeName : ''}</b>
                        </p>

                    </div>





                    <p>
                        {location.addressLine1}

                    </p>

                    <p>
                        {`${location.city}, ${location.state} ${location.zip}`}

                    </p>

                    <p>
                        {location.phone}

                    </p>

                </div>




                        </div>
                    );
                }
                return null;
            });
        }

    };

    const addLocation = (location: officeLocation) => {
        setOfficeLocations(officeLocations => ([...officeLocations, {...location, id:guid(),  isDeleted: 'false', isNew: 'true'}]))
        setModalOfficeOptions(null);
    }


    const editLocation = (location: officeLocation) => {
        setOfficeLocations([location, ...officeLocations.filter((loc:officeLocation) => location.id !== loc.id)]);
        setModalOfficeOptions(null);
    }





    return (
        <>
            <OfficeLocationsModal
                title={modalOfficeOptions?.title ? modalOfficeOptions.title : ""}
                office={modalOfficeOptions?.office}
                save={(location: officeLocation) => modalOfficeOptions?.function(location)} />
            <div className='indentContent other-offices'>
                {renderLocations()}
                {getValidOfficeLocationsLength() < 3 && <AddItem text="Add another office location" action={() =>  { setModalOfficeOptions({ title: "Add a new office location", office: undefined, function: (location: officeLocation) => addLocation(location)});modalsContext.showModal(tiles.OfficeLocation, true) }}/>}
            </div>
        </>
    );
};

export default ShowOfficeLocations;
