import { createContext, Dispatch, SetStateAction, useState } from "react";
import { weekDays } from "../../constants/time"
import { dayOpHours } from "../../models/Agency"
import { Education, Group, Job, Language, ProductSpecialty, ProfileAdmin } from "../../models/Agent"; /*EditAgentWrapper,*/ 

type Props = {
    children: JSX.Element
}

export type AdditionalPhone = {
    otherPhone: string,
    selectedOtherPhoneType: string
}

export type SupportedComms = {
    supportedCommunicationChannelsNA: boolean,
    googleChat: string,
    skype: string
}

export type SocialMedia = {
    facebookUrl: string,
    linkedInUrl: string
}

export type ImageFile = {
  name: string,
  url: string,
  IsDeleted: boolean,
  Blob_Id: string,
  base64?: string | null
}

type Photos = {
  mainPhoto: ImageFile[],
  galleryPhotos: ImageFile[]
}

type PreferredContactInfo = {
    name: string,
    email: string,
    phone: string
}

type Context = {
    agentProfileData: any; //EditAgentWrapper?
    setAgentProfileData: Dispatch<SetStateAction<any>>;
    preferredContactInfo: PreferredContactInfo;
    setPreferredContactInfo: Dispatch<SetStateAction<PreferredContactInfo>>;
    additionalPhone: AdditionalPhone;
    setAdditionalPhone: Dispatch<SetStateAction<AdditionalPhone>>;
    supportedCommunication: SupportedComms;
    setSupportedCommunication: Dispatch<SetStateAction<SupportedComms>>;
    socialMedia: SocialMedia;
    setSocialMedia: Dispatch<SetStateAction<SocialMedia>>;
    otherInfo: string;
    setOtherInfo: Dispatch<SetStateAction<string>>;
    productSpecialties: ProductSpecialty[];
    setProductSpecialties: Dispatch<SetStateAction<ProductSpecialty[]>>;
    languages: Language[];
    setLanguages: Dispatch<SetStateAction<Language[]>>;
    summary: string;
    setSummary: Dispatch<SetStateAction<string>>;
    specialties: string;
    setSpecialties: Dispatch<SetStateAction<string>>;
    groups: Group[];
    setGroups: Dispatch<SetStateAction<Group[]>>;
    currentJob: Job[];
    setCurrentJob: Dispatch<SetStateAction<Job[]>>;
    pastJobs: Job[];
    setPastJobs: Dispatch<SetStateAction<Job[]>>;
    education: Education[];
    setEducation: Dispatch<SetStateAction<Education[]>>;
    image: Photos;
    setImage: Dispatch<SetStateAction<Photos>>;
    deleteModalClose2: boolean;
    setDeleteModalClose2: Dispatch<SetStateAction<boolean>>;
    clearLanguages:boolean;
    setClearLanguages: Dispatch<SetStateAction<boolean>>;
    notes:string;
    setNotes: Dispatch<SetStateAction<string>>;
    isAdmin: boolean;
    setIsAdmin: Dispatch<SetStateAction<boolean>>;
    profileAdmins: ProfileAdmin[];
    setProfileAdmins: Dispatch<SetStateAction<ProfileAdmin[]>>;
  };

var defaultHours: dayOpHours[] = [];
weekDays.forEach((day) => defaultHours.push({dayAbrv: ' ', day: day, hours: { 'begin': '---', 'end': '---' }, open: true }));

const initialContext: Context = {
    agentProfileData: null,
    setAgentProfileData: (): void => {
      throw new Error('setContext function must be overridden');
    },
    preferredContactInfo: {name: '', email: '', phone: ''},
    setPreferredContactInfo: (): void => {
      throw new Error('setContext function must be overridden');
    },
    additionalPhone: { otherPhone: '', selectedOtherPhoneType: '' },
    setAdditionalPhone: (): void => {
      throw new Error('setContext function must be overridden');
    },
    supportedCommunication: { supportedCommunicationChannelsNA: false, googleChat: '', skype: '' },
    setSupportedCommunication: (): void => {
      throw new Error('setContext function must be overridden');
    },
    socialMedia: { facebookUrl: '', linkedInUrl: '' },
    setSocialMedia: (): void => {
      throw new Error('setContext function must be overridden');
    },
    otherInfo:  '',
    setOtherInfo: (): void => {
      throw new Error('setContext function must be overridden');
    },
    productSpecialties: [],
    setProductSpecialties: (): void => {
      throw new Error('setContext function must be overridden');
    },
    languages: [],
    setLanguages: (): void => {
      throw new Error('setContext function must be overridden');
    },
    summary: '',
    setSummary:  (): void => {
      throw new Error('setContext function must be overridden');
    },
    specialties: '',
    setSpecialties:  (): void => {
      throw new Error('setContext function must be overridden');
    },
    groups: [],
    setGroups:  (): void => {
      throw new Error('setContext function must be overridden');
    },
    currentJob: [],
    setCurrentJob:  (): void => {
      throw new Error('setContext function must be overridden');
    },
    pastJobs: [],
    setPastJobs:  (): void => {
      throw new Error('setContext function must be overridden');
    },
    education: [],
    setEducation:  (): void => {
      throw new Error('setContext function must be overridden');
    },
    image: { mainPhoto: [], galleryPhotos: [] },
    setImage:  (): void => {
      throw new Error('setContext function must be overridden');
    },
    deleteModalClose2: false,
    setDeleteModalClose2: (): void => {
        throw new Error('setContext function must be overridden');
    },
    clearLanguages: false,
    setClearLanguages: (): void => {
      throw new Error('setContext function must be overridden');
    },
    notes: "",
    setNotes: (): void => {
      throw new Error('setContext function must be overridden');
    },
    isAdmin: false,
    setIsAdmin: (): void => {
        throw new Error('setContext function must be overridden');
    },
    profileAdmins: [],
    setProfileAdmins:  (): void => {
      throw new Error('setContext function must be overridden');
    },
  };

export const AgentDataContext = createContext(initialContext);
export function AgentDataProvider({ children }: Props) {

    const [agentProfileData, setAgentProfileData] = useState<any>([{ title: '', link: '' }]);
    const [preferredContactInfo, setPreferredContactInfo] = useState<PreferredContactInfo>({name: '', email: '', phone: ''});
    const [additionalPhone, setAdditionalPhone] = useState<AdditionalPhone>({ otherPhone: '', selectedOtherPhoneType: '' });
    const [supportedCommunication, setSupportedCommunication] = useState<SupportedComms>({ supportedCommunicationChannelsNA: false, googleChat: '', skype: '' });
    const [socialMedia, setSocialMedia] = useState<SocialMedia>({ facebookUrl: '', linkedInUrl: '' });
    const [otherInfo, setOtherInfo] = useState<string>('');
    const [productSpecialties, setProductSpecialties] = useState<ProductSpecialty[]>([]);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [summary, setSummary] = useState<string>('');
    const [specialties, setSpecialties] = useState<string>('');
    const [groups, setGroups] = useState<Group[]>([]);
    const [currentJob, setCurrentJob] = useState<Job[]>([]);
    const [pastJobs, setPastJobs] = useState<Job[]>([]);
    const [education, setEducation] = useState<Education[]>([]);
    const [image, setImage] = useState<Photos>({ mainPhoto: [], galleryPhotos: [] });
    const [deleteModalClose2, setDeleteModalClose2] = useState<boolean>(false);
    const [clearLanguages, setClearLanguages] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>('');
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [profileAdmins, setProfileAdmins] = useState<ProfileAdmin[]>([]);

    const store = { agentProfileData, setAgentProfileData, 
                    preferredContactInfo, setPreferredContactInfo,
                    additionalPhone, setAdditionalPhone, 
                    supportedCommunication, setSupportedCommunication, 
                    socialMedia, setSocialMedia, 
                    otherInfo, setOtherInfo, 
                    productSpecialties, setProductSpecialties, 
                    languages, setLanguages, 
                    summary, setSummary, 
                    specialties, setSpecialties, 
                    groups, setGroups,
                    currentJob, setCurrentJob,
                    pastJobs, setPastJobs,
                    education, setEducation,
                    image, setImage,
                    deleteModalClose2, setDeleteModalClose2,
                    clearLanguages, setClearLanguages,
                    notes, setNotes,
                    isAdmin, setIsAdmin,
                    profileAdmins, setProfileAdmins,
                };

    return (
        <AgentDataContext.Provider value={store}>
            {children}
        </AgentDataContext.Provider>
    );
}