import { FieldGroup, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import { useState, useContext, useEffect } from "react";
import { Textarea } from '@lmig/lmds-react-textarea';
import '../../styles/OptionsModal.css'
import { AgentDataContext } from "../context/AgentDataContext";
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";
import '../../styles/Modals.css';

interface TextInputModalProps {
    label: string,
    text: string;
    edit: boolean,
    updateEdit: (val : boolean) => void,
    updateText: (val : string) => void,
}

function TextInputModal (props : TextInputModalProps) {
    const [edit, setEdit] = useState<boolean>(props.edit);
    const { summary, setSummary, specialties, setSpecialties } = useContext(AgentDataContext);
    const isSummary = (props.label === "Edit summary" ? true : false);
    const [text, setText] = useState<string>(_.cloneDeep(isSummary ? summary : specialties));

    useEffect(() => {
        const focusField = document.getElementById("focusField");
        focusField?.focus();
    }, []);

    type errorsObject = {
        summary: string[],
    };
    const [errorMessageObject, setErrorMessageObject] = useState<errorsObject>({ summary: [] });

    const checkForErrors = () => {
        let errors: errorsObject = { summary: [] };
        if (text.includes('<') || text.includes('>') || text.includes('%') || text.includes('=')) {
            errors.summary.push("Please avoid use of the following characters: '<', '>', '%', and '='.");
        }
        if (text.length > 500) {
            errors.summary.push("Please limit to 500 characters");
        }

        setErrorMessageObject(errors);

        return errors.summary.length;
    };

    const cancelChanges = () => {
        setEdit(false); 
        props.updateEdit(false); 
        setText(_.cloneDeep(isSummary ? summary : specialties));
        setErrorMessageObject({ summary: [] });
    };

    return (
        <Modal
        isOpen={edit}
        size="small"
        onClose={() => cancelChanges()}
        >
        <ModalHeader><Heading className="modal-heading">{props.label}</Heading></ModalHeader>
            <ModalBody className="modal-body">
                <FieldGroup isFull id="formField"
                    messages={
                        errorMessageObject.summary.map((error) => {
                            return {
                                text: error,
                                type: 'error'
                            };
                        })
                    }
                >
                    <Textarea  id="focusField"  placeholder="Type Here..." cols={54} value = {text} onChange={(e:any) => {setText(e.target.value)}}>                   
                    </Textarea>
                </FieldGroup>
            </ModalBody>
            <ModalFooter className="modal-footer"> 
                <CancelAndSaveButtons
                cancelFunction={cancelChanges}
                saveFunction={() => {
                    if(checkForErrors() === 0){
                        setEdit(false); 
                        props.updateEdit(false);
                        props.updateText(text); 
                        if (isSummary) {
                            setSummary(_.cloneDeep(text)) 
                        } 
                        else { 
                            setSpecialties(_.cloneDeep(text))
                        };
                    }
                }}/> 
            </ModalFooter>
        </Modal>
    )
}

export default TextInputModal;
