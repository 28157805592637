import { useContext, useEffect } from "react";
// import { AgentHoursContext } from "../../../../context/AgentHoursContext";
// import { HoursContext } from "../../../../context/HoursContext";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import ModalHoursOfOperation from "./ModalHoursOfOperation";
import { hoursObject, dayOpHours} from "../../../../../models/Agency"

type weekday =  {
    Day: string,
    DayName: string,
    Start: string,
    Stop: string
};

const ShowHoursOfOperation = (props: any) => {

    const { profileData, operationHours, setOperationHours } = useContext(ProfileDataContext);

    useEffect(() => {

        try {

            var newOperationHours: hoursObject;
            var opHours: dayOpHours[] = [];
            if (props?.hoursOfOperation !== undefined && props?.hoursOfOperation !== null) {
                props?.hoursOfOperation.forEach((element: any) => {
                    opHours.push({dayAbrv: element.Day, day: element.dayName, hours: {begin: element.start, end: element.stop}, open: element.start !== '-----' || element.stop  !== '-----'});
                });
                newOperationHours = {timezone: props?.timeZone, otherhours: props?.otherHours, opHours };
            } else {
                profileData.OperatingTimes?.forEach((element: weekday) => {
                    opHours.push({dayAbrv: element.Day, day: element.DayName, hours: {begin: element.Start, end: element.Stop}, open: element.Start !== '-----' || element.Stop  !== '-----'});
                });
                newOperationHours = {timezone: profileData.TimeZone, otherhours: profileData.OffHours,opHours };
            }
            setOperationHours(newOperationHours);

        }
        catch  (err) {
            console.error("Error when rendering hours of operation", err);
            return;
        }
    }, [profileData, setOperationHours, props?.hoursOfOperation, props?.timeZone, props?.otherHours]);

    function renderHoursOfOperation(): JSX.Element[] {
        return operationHours.opHours.map((opHour: dayOpHours) => {

            return (

                <tr key={opHour.day as string}>
                    <td style={{paddingRight:'60px'}}>
                        {opHour.day}:
                    </td>
                    <td style={{width:'70px'}}>
                        {opHour.open ? opHour.hours.begin : <span>Closed</span>}
                    </td>
                    <td style={{ padding: " 0 2px" }}>
                        {opHour.open ? <span>to</span> : null}
                    </td>
                    <td style={{width:'70px'}}>
                        {opHour.open ? opHour.hours.end : null}
                    </td>
                </tr>

            );
        });
    }

    return (
        <div>
            <div className="edit-btn-align-absolute">
                <ModalHoursOfOperation isAgent={props?.isAgent} />
            </div>
            <table className='indentHoursContent'>
                <tbody >
                    {renderHoursOfOperation()}
                    <tr>
                        <td>Time zone: </td>
                        <td>
                            {operationHours.timezone}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='indentHoursContent'>
                <div style={{}}>Other hours available: <span style={{textTransform: 'capitalize'}}>{`${operationHours.otherhours}`}</span></div>
            </div>
        </div >
    );
};

export default ShowHoursOfOperation;