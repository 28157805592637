// Helper - Reusable functions
export const guid = () => {
    // eslint-disable-next-line
    return (([1e7] as any)+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, (c: any) =>
    // eslint-disable-next-line
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
}

export const checkImageSize = (photo: File | undefined): boolean => {
    // checks if file is greater than 2MB
    if (photo && photo.size > 2097152) {
        return true;
    }
    // check file type
    if (photo && photo.type !== "image/png" && photo.type !== "image/jpeg"){
        return true;
    }
    return false;
}
