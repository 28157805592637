import { getGoogleAPIKey } from '../services/FAAApi';

export const LoadGoogleMapApi = async (): Promise<void> => {
  try{  
    const apiKey = await getGoogleAPIKey();
    const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    return new Promise((resolve, reject) => {
        // Check if the Google Maps API is already loaded
        /*global google*/
        /*eslint no-undef: "error"*/
        if (typeof google !== 'undefined' && google.maps) {
        resolve();
        return;
        }                

        // Check if the script is already present in the document
        const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);
        if(existingScript){
            existingScript.addEventListener('load', () => resolve());
            existingScript.addEventListener('error', (error) => {
              console.error('Error loading existing Google Maps API script:', error);
              reject(error)
            });
            return;
        }
        
        // Create a new script element to load the Google Maps API
        const script = document.createElement('script');
        script.src = scriptSrc;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = (error) => {
          console.error('Error loading new Google Maps API script:', error);
          reject(error);
        };
        document.head.appendChild(script);
  });
}catch (error) {
    console.error('Failed to load Google Maps API key:', error);
    throw error;
}
};