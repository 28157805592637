import { FieldGroup, Form, GridRow, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useState, useContext } from "react";
import { getIcon } from "../../helpers/ProductIconsMapper";
import { CarrierModel } from "../../models/Agency";
import { ProductSpecialty } from "../../models/Agent";
import '../../styles/OptionsModal.css'
import '../../styles/Modals.css';
import { ProfileDataContext } from "../context/ProfileDataContext";
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons"

interface OptionsModalProps {
  title: string,
  edit: boolean,
  updateEdit: (val:boolean) => void,
  options: any,
  isProduct: boolean
}

function AgencyOptionsModal (props : OptionsModalProps) {

  const [options, setOptions] = useState<CarrierModel[] | ProductSpecialty[]>(props.options);
  const { setCarriers, setProducts } = useContext(ProfileDataContext);

  const changeProductCheckbox = (e : React.ChangeEvent<HTMLInputElement>) => {
    let p : any = options ? options.map((x : any) => Object.assign({}, x)) : []; // need deep copy to update state correctly
    for (let i = 0; i < p.length; i++) {
        if (e.target.name === p[i].productName) {
            p[i].selected = !p[i].selected;
            break;
        }
    }
    setOptions(p);
  }
  const changeCheckboxCarrier = (e: any) => {
    let p : any = options ? options.map((x : any) => Object.assign({}, x)) : []; // need deep copy to update state correctly
    for (let i = 0; i < p.length; i++) {
        if (e.target.name === p[i].carrierName) {
            p[i].selected = !p[i].selected;
            break;
        }
    }
    setOptions(p);

}

const generateProductCheckboxes = () => {
  return options?.map((product : any, index) => {
      return (
              <div key={index} className={"checkbox custom-size"}>
                <input type="checkbox" onChange={changeProductCheckbox} name={product.productName} id={product.productName} checked = {product.selected}/>
                <div className={"box-with-icon"}>
                  <div className="product-icon-wrapper">
                    {
                      getIcon(product.productName, "32")
                    }
                  </div>
                  <p>{product.productName}</p>
                </div>
             </div>
      );
  })
}

  const generateCarrierCheckboxes = () => {
    return options?.slice(1).map((carrier : any, index) => {
        return (
          <div key={index} className={"checkbox "}>
            <input type="checkbox" onChange={changeCheckboxCarrier} name={carrier.carrierName} id={carrier.carrierName} checked = {carrier.selected}/>
            <div className={"box " }>
              <p>{carrier.carrierName}</p>
            </div>
          </div>
        );
    })
}
  return (
    <>
    <Modal isOpen={props.edit} size="small" onClose={() => { props.updateEdit(false);}}>
      <ModalHeader><Heading className="modal-heading">{props.title}</Heading></ModalHeader>
      <ModalBody className="modal-body">
        <Form id="form--isFull">
            <FieldGroup isFull disableMargins="all">
              <GridRow className="center-content">
                {props.isProduct? generateProductCheckboxes() : generateCarrierCheckboxes()}
              </GridRow>
            </FieldGroup>
        </Form>
      </ModalBody>
      <ModalFooter className="modal-footer">
        <CancelAndSaveButtons
          cancelFunction={() => {props.updateEdit(false); }}
          saveFunction={() => {props.updateEdit(false);  props.isProduct ? setProducts(options as ProductSpecialty[]) : setCarriers(options as CarrierModel[]) }}/> 
      </ModalFooter>
    </Modal>
  </>
      )
}
export default AgencyOptionsModal;
