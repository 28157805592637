

import { createContext, useState } from "react"

export type IModalsContext = {
    contactInfo:boolean,
    hoursOfOperation:boolean,
    aboutTheAgency:boolean,
    officeLocation:boolean,
    socialMedia:boolean,
    articles:boolean,
    products:boolean,
    carriers:boolean,
    awards:boolean,
    affiliations:boolean,
    chat:boolean,
    googleReviews:boolean,
    showModal:(modal:string, show:boolean) => void
};

const initialState: IModalsContext = {
    contactInfo:false,
    hoursOfOperation:false,
    aboutTheAgency:false,
    officeLocation:false,
    socialMedia:false,
    articles:false,
    products:false,
    carriers:false,
    awards:false,
    affiliations:false,
    chat:false,
    googleReviews:false,
    showModal:(modal:string, show:boolean) => {}
  };

export const ModalsContext = createContext(initialState);

export const enum tiles {
    ContactInfo = 'CONTACTINFO',
    HoursOfOperation = 'HOURSOFOPERATION',
    AboutTheAgency = 'ABOUTHEAGENCY',
    OfficeLocation = 'OFFICELOCATION',
    SocialMedia = 'SOCIALMEDIA',
    Articles = 'ARTICLES',
    Products = 'PRODUCTS',
    Carriers = 'CARRIERS',
    Awards = 'AWARDS',
    Affiliations = 'AFFILIATIONS',
    Chat = 'CHAT',
    GoogleReviews = "GOOGLEREVIEWS"
}


export function ModalsContextProvider({ children }: { children: React.ReactNode }) {
  const [modals, setModals] = useState<{[key in tiles]?:boolean}>({});

  let shared: IModalsContext = {
      contactInfo: modals[tiles.ContactInfo] ?? false,
      hoursOfOperation: modals[tiles.HoursOfOperation] ?? false,
      aboutTheAgency: modals[tiles.AboutTheAgency] ?? false,
      officeLocation: modals[tiles.OfficeLocation] ?? false,
      socialMedia: modals[tiles.SocialMedia] ?? false,
      articles: modals[tiles.Articles] ?? false,
      products: modals[tiles.Products] ?? false,
      carriers: modals[tiles.Carriers] ?? false,
      awards: modals[tiles.Awards] ?? false,
      affiliations: modals[tiles.Awards] ?? false,
      chat: modals[tiles.Chat] ?? false,
      googleReviews: modals[tiles.GoogleReviews] ?? false,
      showModal: (modal:string, show:boolean) => { setModals(modals => ({...modals, [modal]:show}))}
  }

  return (
    <ModalsContext.Provider value={shared}>
      {children}
    </ModalsContext.Provider>
  );
}