import { Card, FieldGroup, IconButton, IconEdit } from "@lmig/lmds-react";
import { useContext, useEffect } from "react";
import AddItem from "../../../helpers/AddItem";
import { getIcon } from "../../../helpers/ProductIconsMapper";
import { ProductSpecialty } from "../../../models/Agency";
import { ModalsContext, IModalsContext, tiles } from "../../context/ModalsContext";
import { ProfileDataContext } from "../../context/ProfileDataContext";
import AgencyOptionsModal from "../../modals/AgencyOptionsModal";

function Products() {
    const { profileData, products, setProducts } = useContext(ProfileDataContext);
    const modalsContext = useContext<IModalsContext>(ModalsContext);

    useEffect(() => {
        if (profileData.AssignedProducts)
            setProducts(profileData.AssignedProducts.map((x: any) => ({ id: x.Product_Id, productName: x.Product_Name, selected: x.Assigned === true })))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData]);

    // displays selected languages / products
    const getNamesCheckedInTable = (boxes: ProductSpecialty[]) => {

        boxes.sort();
        return boxes.filter(x => x.selected).map((item: ProductSpecialty, index) =>
            <div key={index} className="product-and-languages-wrapper">
                <> <div className="product-icon-wrapper-small">{getIcon(item.productName, "16")}</div> <p className="product-text-spacing">{item.productName}</p> </>
            </div>);
    }

    return (
        <>
            <Card title="Products" style={{ height: "100%" }}>
                {products && products.find(product => product.selected) && <IconButton className="edit-btn-align-absolute">
                    <IconEdit id="productsModalButton" size="24" onClick={() => modalsContext.showModal(tiles.Products, true)} />
                </IconButton>}
                <div className="indent-content">
                    <FieldGroup className="field-group-margin-top selected-products">
                        {getNamesCheckedInTable(products)}
                    </FieldGroup>
                </div>
                {products.find(x => !x.selected) && <div style={{ "paddingLeft": "20px" }}>
                    <AddItem text={products.find(x => x.selected) ? "Add more products" : "Add products"} action={() => modalsContext.showModal(tiles.Products, true)} />
                </div>}
            </Card>
            {modalsContext.products && <AgencyOptionsModal edit={modalsContext.products}
                updateEdit={(show: boolean) => modalsContext.showModal(tiles.Products, show)}
                options={products ? products : []}
                title="Select products" isProduct={true}></AgencyOptionsModal>}
        </>
    );
}


export default Products;