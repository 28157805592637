

export const timezones = ['-----','AKST', 'PST', 'MST', 'CST', 'EST'];

export const states = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];



export const hours = ['7:00 AM', '7:30 AM', '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM', '8:00 PM', '8:30 PM', '9:00 PM'];

export const products =["Auto", "Home", "Renters", "Condo", "Boat/Watercraft", "Motorcycle", "Recreational Vehicle", "Umbrella", "Landlord Protection"];

export const carriers = ["Safeco", "Progressive" ,"Travelers" ,"Safeway Insurance Group" ,"Hartford" ,"Mercury General" ,"Erie Insurance" ,"Auto-Owners", "Kemper P&C" ,"Integon" ,"Infinity" ,"Hanover Insurance" ,"ALLIED (Nationwide)" ,"Encompass (Allstate)" ,"State Auto" , "Grange Mutual Casualty", "Home State Insurance" ,"Safety Group" ,"Foremost Group (Farmers)" ,"Chubb" ,"Cincinnati Insurance", "QBE Americas Group" ,"Westfield Group" ,"The Republic Group" , "Plymouth Rock Companies"];

export const affiliations = ['CPCU', 'NAIFA', 'PIA', 'IIABA', 'NAHU'];

export const validateFBUrl = new RegExp('^((https?|HTTPS?)://)?(www.)?(facebook|FACEBOOK).(com|COM)/.+$');
export const validateLIUrl = new RegExp(`^((https?|HTTPS?)://)?(www.)?(linkedin|LINKEDIN).(com|COM)/.+$`);
export const validateTWUrl = new RegExp(`^((https?|HTTPS?)://)?(www.)?(twitter|TWITTER).(com|COM)/.+$`);
