import { Heading, IconAddItem, IconButton, IconEdit, Link, List, ListItem } from "@lmig/lmds-react";
import { useState } from "react";
import { Group } from "../../../models/Agent";
import GroupsAndAssociationsModal from "../../modals/GroupsAndAssociationsModal";

interface GroupsSectionProps {
    groups: Group[];
    updateGroups: (val:Group[]) => void;
}

function GroupsSection(props : GroupsSectionProps) {

    const [editGroups, setEditGroups] = useState<boolean>(false);
    const [add, setAdd] = useState<boolean>(false);

    const groupsPresent = () => {
        if (props.groups.length === 0) {
            return false;
        }
        for (let i = 0; i < props.groups.length; i++) {
            if (props.groups[i].name !== "") {
                return true;
            }
        }
        return false;
    }


    return (
        <>
        <div className="contact-information-section-edit-row">
            <Heading type="h5">Groups and Associations</Heading>
            {groupsPresent() &&
            <IconButton id="groupsModalButton" onClick={()=>{setEditGroups(true)}}>
                <IconEdit className="icon-edit-custom-size" size="24"/>
            </IconButton>}
        </div>

                            {groupsPresent() ?
                            <>
                                <div className="indent-content filed-group-margin-items">
                                        <List compact>
                                        {props.groups.map((item : Group, index: number) => {
                                            return (<ListItem key={index}>{item.name}</ListItem>)
                                        })}
                                        </List>
                                        {props.groups.length < 6 ?
                                                <div className="indent-content-inner">
                                                        <div className = "filed-group-margin-items">
                                                            <IconButton id="groupsModalButton" onClick={() => [setEditGroups(true), setAdd(true)]}>
                                                                <IconAddItem size = "24"></IconAddItem>
                                                            </IconButton>
                                                        </div>
                                                        <div className="icon-text-general">
                                                            <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => [setEditGroups(true), setAdd(true)]}>Add another group or association</Link>
                                                        </div>
                                                </div>
                                                : null
                                            }
                                </div>
                                </>
                                :
                                <div className='indent-content-inner'>
                                        <div className = "filed-group-margin-items">
                                            <IconButton id="groupsModalButton" onClick={() => {setEditGroups(true)}}>
                                                <IconAddItem size = "24"></IconAddItem>
                                            </IconButton>
                                        </div>
                                        <div className="icon-text-general">
                                            <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => {setEditGroups(true)}}>Add groups or associations</Link>
                                        </div>
                                </div>
                            }
            {editGroups ?
                <GroupsAndAssociationsModal data={props.groups} edit={true} updateEdit={setEditGroups} add={add} setAdd={setAdd} updateData={props.updateGroups} />
                : null}
        </>
    );
}

export default GroupsSection;