import { Card, GridRow, Heading, IconAddItem, IconButton, IconEdit, IconEmail, IconPhone, Link, IconHelp } from "@lmig/lmds-react";
import { Tooltip } from '@lmig/lmds-react-tooltip';
import { useContext, useEffect, useState } from "react";
import '../../styles/PreferredAgentContactInformation.css'
import ChatOptionsModal from "../modals/ChatOptionsModal";
import OfficeLocationModal from "../modals/OfficeLocationModal";
import PreferredAgentContactInformationModal from "../modals/PreferredAgentContactInformationModal";
import { Location } from '../../models/Agent';
import { AgentDataContext } from "../context/AgentDataContext";
import { nameCase, isEmpty, isPhoneFieldEmpty } from "../../helpers/StringFormatter";
import { formatPhoneNumber } from "../../services/FAAApi";

interface PreferredAgentContactInformationProps {
    preferredName: string,
    preferredEmail: string,
    preferredPhone: string,
    otherPhone: string,
    otherPhoneType: string,
    otherOfficeLocations: Location[],
    skype: string,
    googleChat: string,
    communicationsNA: boolean,
    primaryLocation: boolean,
    addressLine1: string,
    agencyProfileOtherOfficeId: any,

    updatePreferredName: (val: string) => void,
    updatePreferredEmail: (val: string) => void,
    updatePreferredPhone: (val: string) => void,
    updateOtherPhone: (val: string) => void,
    updateOtherPhoneType: (val: string) => void,
    updateSkype: (val: string) => void,
    updateGoogleChat: (val: string) => void,
    updateCommunicationsNA: (val: boolean) => void,
    updatePrimaryLocation: (val: boolean) => void,
    updateAddressLine1: (val: string) => void,
    updateAgencyProfileOtherOfficeId: (val: any) => void
}

function PreferredAgentContactInformation(props: PreferredAgentContactInformationProps) {

    const [edit, setEdit] = useState<boolean>(false);
    const [editChat, setEditChat] = useState<boolean>(false);
    const [editOfficeLocations, setEditOfficeLocations] = useState<boolean>(false);
    const { agentProfileData } = useContext(AgentDataContext);
    const [addPhone, setAddPhone] = useState<boolean>(false);
    const [addGoogle, setAddGoogle] = useState<boolean>(false);
    const [addSkype, setAddSkype] = useState<boolean>(false);

    useEffect(() => {
        if (isEmpty(props.preferredName) && agentProfileData.name) {
            props.updatePreferredName(nameCase(agentProfileData.name));
        }
        if (isEmpty(props.preferredEmail) && agentProfileData.email) {
            props.updatePreferredEmail(agentProfileData.email);
        }
        if (isPhoneFieldEmpty(props.preferredPhone) && agentProfileData.phone) {
            props.updatePreferredPhone("(" + (agentProfileData.phone).slice(0, 3) + ") " + agentProfileData.phone.slice(3, 6) + "-" + agentProfileData.phone.slice(6, 10));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.preferredName, props.preferredEmail, props.preferredPhone]);

    /*---------------- Callback Functions --------------------------*/
    const updateEdit = (edit: boolean) => {
        setEdit(edit);
    }
    /*---------------- End Callback Functions ----------------------*/

    const getAddressLine = (id: string) => {
        let addr: any = "";
        props.otherOfficeLocations.forEach(item => {
            if (item.Id === Number(id)) {
                addr = item.AddressLine1;
            }
        });
        return addr;
    }

    return (
        <Card title="Contact Information" >
            <div className='indent-content'>
                <div className='contact-information-section-edit-row'>
                        <Heading type="h5">Name and phone</Heading>
                        <IconButton id="preferredContactModalButton" style={{ marginBottom: '.75em'}} onClick={() => { setEdit(true) }}>
                            <IconEdit className="icon-edit-custom-size" size="24" />
                        </IconButton>
                </div>
                    <div className='contact-information-subsection'>
                        <Heading type="h6-small"
                            className="li"
                            style={{
                                height: "25.77px",
                                fontStyle: props.preferredName === nameCase(agentProfileData.name || "") ? "italic" : ""
                            }}>
                             {isEmpty(props.preferredName) ? nameCase(agentProfileData.name) : props.preferredName}
                        </Heading>
                        <Heading
                            type="h6-small"
                            className="li"
                            style={{
                                fontStyle: isEmpty(props.preferredEmail) === agentProfileData.email ? "italic" : ""
                            }}
                        >
                             <IconEmail size="24" style={{ width: "18px", marginRight: "5px" }} />
                             { isEmpty(props.preferredEmail) && isEmpty(agentProfileData.email)? 
                                <Tooltip direction="above" align="start" content="Edit contact information to add your email">
                                    <span><IconHelp color="teal" size="16" /></span>
                                </Tooltip> : ( isEmpty(props.preferredEmail) ? agentProfileData.email : props.preferredEmail ) }
                        </Heading>
                        <Heading
                            type="h6-small"
                            noWrap
                            style={{
                                fontStyle:
                                    agentProfileData.phone
                                        ? (JSON.stringify([agentProfileData.phone.slice(0, 3), agentProfileData.phone.slice(3, 6), agentProfileData.phone.slice(6, 10)]) === JSON.stringify(props.preferredPhone?.match(/\d+/g))
                                            ? "italic"
                                            : "")
                                        : ""
                            }}
                        >
                            <IconPhone size="24" style={{ width: "18px", marginRight: "5px" }} />
                            { isPhoneFieldEmpty(props.preferredPhone) && isPhoneFieldEmpty(agentProfileData.phone) ? 
                                <Tooltip direction="above" align="start" content="Edit contact information to add your phone number">
                                    <span><IconHelp color="teal" size="16" /> </span>
                                </Tooltip> : ( isPhoneFieldEmpty(props.preferredPhone) ? formatPhoneNumber(agentProfileData.phone) : props.preferredPhone ) }
                        </Heading>

                        {props.otherPhone === "" || props.otherPhoneType === "" ?
                            <div className="add-additional-phone">
                                    <IconButton onClick={() => [setEdit(true), setAddPhone(true)]}>
                                        <IconAddItem size="24"></IconAddItem>
                                    </IconButton>
                                    <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => [setEdit(true), setAddPhone(true)]}>
                                        Add additional phone
                                    </Link>
                            </div>
                            :
                            <div className="phone-row">
                                <Heading type="h6-small" noWrap>
                                    <IconPhone size="24" style={{ width: "18px" }} /> {props.otherPhone}
                                </Heading>
                                <Heading type="h6-small" noWrap>Type: {props.otherPhoneType}</Heading>
                            </div>
                        }
                    </div>
                {edit ?
                    <PreferredAgentContactInformationModal edit={true} updateEdit={updateEdit} preferredName={props.preferredName}
                        preferredEmail={props.preferredEmail}
                        preferredPhone={props.preferredPhone}
                        addPhone={props.otherPhone}
                        type={props.otherPhoneType}
                        add={addPhone}
                        updateAddPhone={props.updateOtherPhone}
                        updateType={props.updateOtherPhoneType}
                        updatePreferredName={props.updatePreferredName}
                        updatePreferredEmail={props.updatePreferredEmail}
                        updatePreferredPhone={props.updatePreferredPhone}
                        setAdd={setAddPhone}
                    />
                    : <></>}
                <div className="contact-information-section-edit-row">
                    <Heading type="h5">Chat options</Heading>
                    {(props.googleChat !== "" || props.skype !== "") &&
                        <IconButton id="communicationModalButton" onClick={() => { setEditChat(true) }}>
                            <IconEdit className="icon-edit-custom-size" size="24" />
                        </IconButton>
                    }
                </div>
                <div className="contact-information-subsection">
                    {props.googleChat !== "" ?
                        <p title="Google chat">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.4 499.98" style={{verticalAlign:'middle'}} width="18px" height="18px"><title>google-chat</title><path className="cls-1" d="M121.24,275.69V113.19h-71a37.86,37.86,0,0,0-37.8,37.9V487c0,16.9,20.4,25.3,32.3,13.4l78.1-78.1h222.4a37.77,37.77,0,0,0,37.8-37.8v-71H159.14A37.86,37.86,0,0,1,121.24,275.69Z" transform="translate(-12.44 -5.99)"/><path className="cls-2" d="M454,6H159.14a37.77,37.77,0,0,0-37.8,37.8v69.4h223.9A37.77,37.77,0,0,1,383,151v162.4h71a37.77,37.77,0,0,0,37.8-37.8V43.79A37.77,37.77,0,0,0,454,6Z" transform="translate(-12.44 -5.99)"/><path className="cls-3" d="M345.24,113.19h-224v162.4a37.77,37.77,0,0,0,37.8,37.8h223.9V151.09A37.71,37.71,0,0,0,345.24,113.19Z" transform="translate(-12.44 -5.99)"/></svg>
                            <span style={{marginLeft:'4px'}}>{props.googleChat}</span>
                        </p>
                        :
                        <div>
                            <GridRow onClick={() => [setEditChat(true), setAddGoogle(true)]}>
                                <IconButton id="communicationModalButton" >
                                    <IconAddItem size="24"></IconAddItem>
                                </IconButton>
                                <Link className="link-general" variant="standalone" caretPosition="none">
                                    Add Google chat
                                </Link>
                            </GridRow>
                        </div>
                    }

                    {props.skype !== "" ?
                        <p title="Skype">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{verticalAlign:'middle'}} width="18px" height="18px"><path fill="#03A9F4" d="M15.716 8.083c0-4.155-3.416-7.524-7.631-7.524-.444 0-.88.038-1.305.11A4.453 4.453 0 0 0 4.426 0C1.981 0 0 1.953 0 4.364a4.3 4.3 0 0 0 .608 2.207 7.464 7.464 0 0 0-.155 1.512c0 4.156 3.417 7.525 7.631 7.525a7.85 7.85 0 0 0 1.398-.126 4.46 4.46 0 0 0 2.093.518C14.019 16 16 14.046 16 11.637c0-.696-.166-1.354-.46-1.938a7.43 7.43 0 0 0 .176-1.616z"/><path fill="#FAFAFA" d="M11.999 11.679c-.353.493-.874.883-1.551 1.159-.669.274-1.47.415-2.384.415-1.096 0-2.015-.19-2.733-.566a3.547 3.547 0 0 1-1.256-1.1c-.325-.46-.488-.917-.488-1.36 0-.274.107-.514.318-.709.209-.195.477-.292.797-.292.262 0 .488.077.672.23.175.148.326.366.447.645.135.306.282.564.437.765.151.197.366.361.641.49.277.128.65.195 1.108.195.631 0 1.149-.134 1.537-.395.381-.255.565-.563.565-.939 0-.296-.097-.53-.294-.713a2.104 2.104 0 0 0-.814-.444 16.188 16.188 0 0 0-1.4-.342c-.793-.167-1.466-.364-2-.59-.547-.23-.989-.548-1.311-.945-.328-.406-.494-.913-.494-1.509 0-.568.174-1.08.518-1.522.341-.439.839-.782 1.482-1.015.633-.231 1.386-.347 2.239-.347.681 0 1.28.078 1.781.232.503.154.927.362 1.26.619.336.26.586.535.742.823.158.29.239.579.239.858 0 .269-.105.514-.313.726-.21.214-.474.322-.784.322-.282 0-.504-.069-.657-.202-.143-.125-.292-.32-.456-.598a2.507 2.507 0 0 0-.685-.836c-.257-.193-.685-.289-1.275-.289-.546 0-.992.108-1.322.322-.318.205-.473.441-.473.721 0 .171.05.314.153.437.108.132.261.245.455.341.2.099.407.179.614.235.212.058.567.145 1.056.256.618.13 1.185.277 1.687.436.509.159.947.356 1.307.587.365.235.654.535.86.895.206.363.31.808.31 1.326a2.833 2.833 0 0 1-.535 1.678z"/></svg>
                        <span> {props.skype} </span>
                        </p>
                        :
                        <div>
                            <GridRow onClick={() => [setEditChat(true), setAddSkype(true)]}>
                                <IconButton>
                                    <IconAddItem size="24"></IconAddItem>
                                </IconButton>
                                <Link className="link-general" variant="standalone" caretPosition="none">
                                    Add Skype chat
                                </Link>
                            </GridRow>
                        </div>
                    }
                </div>

                {editChat ?
                    <ChatOptionsModal
                        edit={true}
                        skype={props.skype !== ""}
                        skypeUsername={props.skype}
                        google={props.googleChat !== ""}
                        googleUsername={props.googleChat}
                        none={props.communicationsNA}
                        addSkype={addSkype}
                        addGoogle={addGoogle}
                        updateEdit={setEditChat}
                        updateSkypeUsername={props.updateSkype}
                        updateGoogleUsername={props.updateGoogleChat}
                        updateNone={props.updateCommunicationsNA}
                        setAddSkype={setAddSkype}
                        setAddGoogle={setAddGoogle}
                    />
                    : <></>
                }

                {props.otherOfficeLocations.length > 0 && <>
                        <div className="contact-information-section-edit-row">
                            <Heading type="h5">Office Location</Heading>
                            {(props.agencyProfileOtherOfficeId !== null || props.primaryLocation) &&
                                            <IconButton id="officeAddressModalButton" onClick={() => { setEditOfficeLocations(true) }}>
                                                <IconEdit className="icon-edit-custom-size" size="24" />
                                            </IconButton>
                            }
                        </div>
                        <div className="contact-information-subsection">
                                    {props.primaryLocation ?
                                        <Heading type="h6-small" className="li" >I am located at my Agency's primary location.</Heading>
                                        : (props.agencyProfileOtherOfficeId !== null) ?
                                            <Heading type="h6-small" className="li" >{getAddressLine(props.agencyProfileOtherOfficeId)}</Heading> :
                                            <div className='indent-content-inner' style={{ marginTop: "0px", marginLeft: "-18px" }}>
                                                <GridRow onClick={() => setEditOfficeLocations(true)}>
                                                    <IconButton id="officeAddressModalButton">
                                                        <IconAddItem size="24"></IconAddItem>
                                                    </IconButton>
                                                    <Link className="link-general" variant="standalone" caretPosition="none">
                                                        Add Primary Office
                                                    </Link>
                                                </GridRow>
                                            </div>
                                    }
                        </div>
                        </>
                }
                {editOfficeLocations ?
                    <OfficeLocationModal edit={true}
                        otherOfficeLocations={props.otherOfficeLocations}
                        updateEdit={setEditOfficeLocations}
                        addressLine1={props.addressLine1}
                        primaryChecked={props.primaryLocation}
                        updatePrimaryChecked={props.updatePrimaryLocation}
                        selectedOtherOfficeId={props.agencyProfileOtherOfficeId}
                        updateOtherOfficeId={props.updateAgencyProfileOtherOfficeId} />
                    : <></>}
            </div>
        </Card>

    );
}

export default PreferredAgentContactInformation;