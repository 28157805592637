

import { createContext, Dispatch, SetStateAction, useState } from "react"
import { weekDays, dayAbrvs } from "../../constants/time";
import { dayOpHours, hoursObject } from "../../models/Agency";




type Props = {

  children: JSX.Element
}

type Context = {
  operationHours: hoursObject;
  setOperationHours:  Dispatch<SetStateAction<hoursObject>>;
};

var defaultHours: dayOpHours[] = [];
weekDays.forEach((day, index) => defaultHours.push({dayAbrv: dayAbrvs[index], day: day, hours: { 'begin': '---', 'end': '---' }, open: true}));

const initialContext: Context = {
  operationHours: { 'timezone': '' as String, 'otherhours': false, 'opHours': defaultHours },
  setOperationHours: (): void => {
    throw new Error('setContext function must be overridden');
  }
};




export const AgentHoursContext = createContext(initialContext);
export function AgentHoursProvider({ children }: Props) {
  const [operationHours, setOperationHours] = useState<hoursObject>({ 'timezone': '---' as String, 'otherhours': false, 'opHours': defaultHours });


  return (
    <AgentHoursContext.Provider value={{operationHours, setOperationHours }}>
      {children}
    </AgentHoursContext.Provider>
  );
}