import { IconButton, IconEdit } from "@lmig/lmds-react";
import { useContext, useEffect } from "react";
import AddItem from "../../../../../helpers/AddItem";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import ModalAboutAgency from "./ModalAboutAgency";

const ShowAboutAgency = () => {
    const { profileData, aboutAgency, setAboutAgency } = useContext(ProfileDataContext);
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    useEffect(() => {
        try {
            setAboutAgency({summary: profileData.About ? profileData.About : '', yearEstablished: profileData.Year});
        }
        catch  (err) {
            return;
        }
    }, [profileData, setAboutAgency]);

    return (
        <>
        {aboutAgency.summary || aboutAgency.yearEstablished ? <IconButton className="edit-btn-align-absolute" onClick={() => modalsContext.showModal(tiles.AboutTheAgency, true)}>
            <IconEdit size="24" />
        </IconButton> : null}
            <ModalAboutAgency/>
            <div className='indentContent'>
                <div>
                    {aboutAgency.summary ? <p style={{width: "100%"}}>
                        Summary: {`${aboutAgency.summary}`}
                    </p>: <AddItem text="Add summary" action={() => modalsContext.showModal(tiles.AboutTheAgency, true)} />}
                    {aboutAgency.yearEstablished ? <p style={{paddingTop: aboutAgency.summary ? "20px" : "0px"}}>
                        Year Established: {`${aboutAgency.yearEstablished}`}
                    </p> : <AddItem text="Add year established" action={() => modalsContext.showModal(tiles.AboutTheAgency, true)} /> }
                </div>
            </div>
        </>
    );
};

export default ShowAboutAgency;
