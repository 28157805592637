import { AlphanumericInput, FieldGroup, IconTrash, IconButton, Heading, GridRow, GridCol } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useContext, useRef, useState } from "react";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import { useEffect } from "react";
import AddItem from "../../../../../helpers/AddItem";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { checkEmpty, nameCase } from "../../../../../helpers/StringFormatter";
import '../../../../../styles/Modals.css';
import CancelAndSaveButtons from "../../../../modals/CancelAndSaveButtons";

type ContactInfo = {
    agencyName: string,
    website: string,
    email: string,
    phone: string,
    otherInfo: boolean,
    mainContactName: string,
    fax: string,
    mobile: string,
    address: string,
    chat: { google?:string, skype?:string, web?:string }
}

type errorsObject = {
    agencyName: string[],
    website: string[],
    mainContactName: string[],
    email: string[],
    phone: string[],
    mobile: string[],
    fax: string[]
};

const ModalPreferredContactInfo = (props:{ focus?:string}) => {
    const [errorMessageObject, setErrorMessageObject] = useState<errorsObject>({ agencyName: [], website: [], mainContactName: [], email: [], phone: [], mobile: [], fax: [] });
    const {preferredContactInfo, setPreferredContactInfo, profileData } = useContext(ProfileDataContext);
    const [copy, setCopy] = useState<ContactInfo>(preferredContactInfo);
    const modalsContext = useContext<IModalsContext>(ModalsContext);

    /*ref elements used for focusing*/
    const webInput= useRef<HTMLInputElement>(null);
    const mainContactInput= useRef<HTMLInputElement>(null);
    const emailInput= useRef<HTMLInputElement>(null);
    const phoneInput= useRef<HTMLInputElement>(null);
    const faxInput = useRef<HTMLInputElement>(null);
    const mobileInput = useRef<HTMLInputElement>(null);

    const [showMainContact, setShowMainContact] = useState<boolean>(preferredContactInfo.mainContactName.length > 0);
    const [showFax, setShowFax] = useState<boolean>(preferredContactInfo.fax.length > 0);
    const [showMobile, setShowMobile] = useState<boolean>(preferredContactInfo.mobile.length > 0);

    useEffect(() => {
        setCopy(preferredContactInfo);
    }, [preferredContactInfo])
    useEffect(()=> {
        switch(props.focus) {
            case "WEBSITE":
              webInput.current?.focus();
              break;
            case "MAINCONTACT":
              setShowMainContact(true);
              break;
            case "EMAIL":
              emailInput.current?.focus();
              break;
            case "PHONE":
              phoneInput.current?.focus();
              break;
            case "FAX":
              setShowFax(true);
              faxInput.current?.focus();
              break;
            case "MOBILE":
              setShowMobile(true);
              mobileInput.current?.focus();
              break;
            default:
              // code block
          }
    },[props.focus, modalsContext.contactInfo]);

    useEffect(() => {
        if (showMainContact && props.focus) {
            mainContactInput.current?.focus()
    }}, [showMainContact, props.focus]);

    useEffect(() => {
        if (showMobile && props.focus) {
            mobileInput.current?.focus()
    }}, [showMobile, props.focus]);

    useEffect(() => {
        if (showFax && props.focus) {
            faxInput.current?.focus()
    }}, [showFax, props.focus]);

    const checkForErrors = () => {
        let errors: errorsObject = { agencyName: [], website: [], mainContactName: [], email: [], phone: [], mobile: [], fax: [] };

        if (copy.agencyName.length > 60) {
            errors.agencyName.push("Preferred Agency Name cannot exceed 60 characters")
        }
        if (copy.agencyName.includes('<') || copy.agencyName.includes('>') || copy.agencyName.includes('%') || copy.agencyName.includes('=')) {
            errors.agencyName.push("Please avoid use of the following characters: '<', '>', '%', and '='.");
        }
        if (copy.website.length > 160) {
            errors.website.push("Preferred Agency Website Url cannot exceed 160 characters.");
        }
        var urlExpression = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
        var regexWebsite = new RegExp(urlExpression);

        if (copy.website !== "" && !copy.website.match(regexWebsite)) {
            errors.website.push("Preferred Agency Website Url is invalid");
        }
        if (copy.mainContactName.length > 60) {
            errors.mainContactName.push("Your main contact name cannot exceed 60 characters");
        }
        if (copy.mainContactName !== "" && !copy.mainContactName.match(/^[-\sa-zA-Z]+$/)) {
            errors.mainContactName.push("Main contact name must contain alphabetic characters, spaces, and hyphens only");
        }
        if (copy.email.length > 60) {
            errors.email.push("Preferred email address must be less than 60 characters");
        }
        const emailRegex = /^\w+([-,.]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
        if (copy.email !== "" && !emailRegex.test(copy.email)) {
            errors.email.push("Invalid email format");
        }
        if (copy.phone.length > 14) {
            errors.phone.push("Phone number cannot exceed 14 characters");
        }
        const phoneRegexFormatted = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        const phoneRegexUnFormatted = /^\d{10}$/;
        if (copy.phone !== "" && !(phoneRegexFormatted.test(copy.phone) || phoneRegexUnFormatted.test(copy.phone))) {
            errors.phone.push("Invalid phone number");
        }
        if (copy.mobile.length > 14) {
            errors.mobile.push("Mobile phone number cannot exceed 14 characters");
        }

        if (copy.mobile !== "" && !(phoneRegexFormatted.test(copy.mobile) || phoneRegexUnFormatted.test(copy.mobile))) {
            errors.mobile.push("Invalid mobile phone number");
        }
        if (copy.fax.length > 14) {
            errors.fax.push("Fax phone number cannot exceed 14 characters");
        }

        if (copy.fax !== "" && !phoneRegexFormatted.test(copy.fax)) {
            errors.fax.push("Invalid fax phone number");
        }

        setErrorMessageObject(errors);

        return errors.agencyName.length + errors.website.length + errors.mainContactName.length + errors.email.length + errors.phone.length + errors.mobile.length + errors.fax.length;
    };


    const isEmpty = (field?: string) => {
        return field === '---' || field === '' || field === undefined || field === null;
    }

    const cancelChanges = () => {
        setShowMainContact(false);
        setShowFax(false);
        setShowMobile(false);
        setCopy(preferredContactInfo);
        modalsContext.showModal(tiles.ContactInfo, false);
        setErrorMessageObject({ agencyName: [], website: [], mainContactName: [], email: [], phone: [], mobile: [], fax: [] });
    };

    return (
        <>
            <Modal isOpen={modalsContext.contactInfo} size="small" onClose={() => cancelChanges()}>
                <ModalHeader>
                <Heading className="modal-heading"> Edit contact information</Heading>
                </ModalHeader>
                <ModalBody className="modal-body">
                <GridRow gutters="horizontal" gutterSize="fixed-16">
                <GridCol base={12}>
                    {/* AGENCY NAME */}
                    <FieldGroup id="formField"
                        isFull
                        messages={
                            errorMessageObject.agencyName.map((error) => {
                                return { text: error, type: 'error'};
                            })
                        }>
                        <AlphanumericInput
                            value={isEmpty(copy.agencyName) ? nameCase(profileData.AgencyName) : copy.agencyName} onChange={(e) => {
                                setCopy({ ...copy, agencyName: e.currentTarget.value });
                            }}
                            labelVisual="Agency Name" />
                    </FieldGroup>
                    </GridCol>
                    {/* WEBSITE */}
                    <GridCol base={12}>
                    <FieldGroup id="formField"
                        isFull
                        messages={
                            errorMessageObject.website.map((error) => {
                                return { text: error, type: 'error' };
                            })
                        }>
                        <AlphanumericInput
                            value={isEmpty(copy.website) ? checkEmpty(profileData.AgencyWeb) : copy.website} onChange={(e) => {
                                setCopy({ ...copy, website: e.currentTarget.value });
                            }}
                            labelVisual="Website"
                            innerRef={webInput}
                        />
                    </FieldGroup>
                    </GridCol>
                    {/* EMAIL & PHONE ERRORS */}
                    <GridCol base={12}>
                        <FieldGroup isFull id="formField"
                        style={{ marginRight: "0rem", marginTop: errorMessageObject.email.length === 0 && errorMessageObject.phone.length ? "1.2rem" : "0rem" }}
                            messages={
                                errorMessageObject.email.map((error) => {
                                    return { text: error, type: 'error' };
                                })
                            }>
                            </FieldGroup>
                            <FieldGroup isFull id="formField"
                            style={{ marginLeft: "0rem", marginTop: errorMessageObject.phone.length === 0 && errorMessageObject.email.length ? "1.2rem" : "0rem" }}
                            messages={
                                errorMessageObject.phone.map((error) => {
                                    return { text: error, type: 'error' };
                                })
                            }></FieldGroup>
                    </GridCol>
                    {/* EMAIL */}
                    <GridCol>
                            <AlphanumericInput
                                value={isEmpty(copy.email) ? checkEmpty(profileData.AgencyEmail) : copy.email} onChange={(e) => {
                                    setCopy({ ...copy, email: e.currentTarget.value });

                                }}
                                labelVisual="Email"
                                innerRef={emailInput}
                            />

                    </GridCol>
                    {/* PHONE */}
                      <GridCol base={5}>
                        <AlphanumericInput
                            value={isEmpty(copy.phone) ? checkEmpty(profileData.AgencyPhone) : copy.phone} onChange={(e) => {
                                setCopy({ ...copy, phone: e.currentTarget.value });
                            }}
                            labelVisual="Phone"
                            mask='phone'
                            innerRef={phoneInput}
                        />
                    </GridCol>
                     {/* MAIN CONTACT */}
                    <GridCol base={12}>
                        {showMainContact ? <>
                        <FieldGroup isFull id="formField" messages={
                                errorMessageObject.mainContactName.map((error) => {
                                    return { text: error,  type: 'error' };
                                })
                            }/></> :<></>
                        }
                    </GridCol>
                    {showMainContact ? <>
                        <GridCol>
                            <AlphanumericInput labelVisual="Main Contact Name" value={isEmpty(copy.mainContactName) ? nameCase(profileData.MainContact) : copy.mainContactName } onChange={(e) => {
                                setCopy({ ...copy, mainContactName: e.currentTarget.value });
                            }}
                            innerRef={mainContactInput} />
                        </GridCol>
                        <div style={{alignItems:"center", display:"flex"}}>
                            <GridCol base={1}><IconButton><IconTrash size="24" onClick={() => {setCopy({ ...copy, mainContactName: ''});setShowMainContact(false)}}/></IconButton></GridCol>
                        </div>
                        </> : <AddItem text="Add main contact name" action={() => setShowMainContact(true) } />}

                    {/* FAX */}
                    <GridCol base={12}>
                        {showFax ? <>
                        <FieldGroup isFull id="formField" messages={
                                errorMessageObject.fax.map((error) => {
                                    return { text: error, type: 'error' };
                                })
                            }/></>:<></>
                        }
                    </GridCol>
                    {showFax ? <>
                        <GridCol>
                            <AlphanumericInput labelVisual="Fax Number" mask='phone' value={isEmpty(copy.fax) ? checkEmpty(profileData.FaxNumber) : copy.fax} onChange={(e) => setCopy({ ...copy, fax: e.currentTarget.value })} innerRef={faxInput}/>
                        </GridCol>
                        <div style={{alignItems:"center", display:"flex"}}>
                           <GridCol base={1}><IconButton><IconTrash size="24" onClick={() => {setCopy({ ...copy, fax: '' });setShowFax(false)}} /></IconButton></GridCol>
                        </div>
                        </> : <AddItem text="Add fax" action={() => setShowFax(true) } />}


                    {/* MOBILE */}
                    <GridCol base={12}>
                        {showMobile ? <>
                            <FieldGroup isFull id="formField"
                                messages={
                                errorMessageObject.mobile.map((error) => {
                                    return { text: error, type: 'error' };
                                })}/></> : <></>
                        }
                    </GridCol>

                    {showMobile ? <>
                        <GridCol>
                            <AlphanumericInput labelVisual="Mobile Phone" mask='phone'
                                value={isEmpty(copy.mobile) ? checkEmpty(profileData.MobilePhone) : copy.mobile}
                                onChange={(e) => {
                                    setCopy({ ...copy, mobile: e.currentTarget.value });
                                }}
                                innerRef={mobileInput}/>
                        </GridCol>
                        <div style={{alignItems:"center", display:"flex"}}>
                            <GridCol base={1}><IconButton><IconTrash size="24" onClick={() => { setCopy({ ...copy, mobile: '' });setShowMobile(false)}}/></IconButton></GridCol>
                        </div>
                        </> : <AddItem text="Add mobile phone" action={() => setShowMobile(true) } />}
                    </GridRow>
                </ModalBody>
                <ModalFooter className="modal-footer">
                <CancelAndSaveButtons
                    cancelFunction={cancelChanges}
                    saveFunction={() => {
                        if (checkForErrors() === 0) {
                            setPreferredContactInfo(copy);
                            modalsContext.showModal(tiles.ContactInfo, false);}}}/>
                </ModalFooter>
            </Modal>
        </>
    )

};

export default ModalPreferredContactInfo;
