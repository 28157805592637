import { Heading, IconButton, IconSeeMore} from "@lmig/lmds-react";
import { Modal, ModalBody, ModalHeader } from '@lmig/lmds-react-modal';
import { useContext, useEffect, useState } from "react";
import { findPlaceFromPhoneNumber, getPlaceDetails } from "../../../../../services/googleServices";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import "../../../../../styles/Modals.css"
import ReviewCard from "./ReviewCard";


function ModalGoogleReviews(){
    const {profileData} = useContext(ProfileDataContext);
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    let [placeDetails, setPlaceDetails] = useState<google.maps.places.PlaceReview[]>();
    let [place, setPlace] = useState<google.maps.places.PlaceResult>();

    useEffect(() => {
        async function getPlace() {
            var phone = (profileData.PreferredAgencyPhone ? profileData.PreferredAgencyPhone : profileData.AgencyPhone)?.replace(new RegExp(/(\D+)/g), "");
            var data = (await findPlaceFromPhoneNumber(phone) as google.maps.places.PlaceResult[]);
            if(data.length === 1){
                setPlace(data[0]);
            }
            else{
                for (var i = 0; i < data.length; i++) {
                    if (data[i] && data[i].formatted_address?.split(' ')[0] === profileData.AddressLine1.split(' ')[0]) {
                        if (data[i].rating && data[i].user_ratings_total){
                            setPlace(data[i]);
                        }
                    }
                }
            }
        }
        getPlace();
    }, [profileData.AgencyPhone]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getReviews() {
            var placedetes = (await getPlaceDetails(place?.place_id) as google.maps.places.PlaceReview[]);
            setPlaceDetails(placedetes);
        }
        getReviews();
    }, [place]);

    const cancelChanges = () => {
        modalsContext.showModal(tiles.GoogleReviews, false);
    };

    return(
    <>
        <Modal
            isOpen={modalsContext.googleReviews}
            size="small"
            onClose={() => cancelChanges()}
            className="googleReviewModal"
        >

        <ModalHeader><Heading className="modal-heading">Google Reviews</Heading></ModalHeader>
        <ModalBody className="modal-body">
            <div className='agency-page-reviews'>
                <span className="rating stars" style={{"--rating" : place?.rating ?? 0, fontSize: "24px"} as React.CSSProperties}>
                    {place?.rating?.toPrecision(2)}&nbsp;&nbsp;
                </span>
                {place?.place_id ? <a rel="noreferrer" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${place?.formatted_address}&query_place_id=${place?.place_id}`}><small>{`${place?.user_ratings_total} review${place?.user_ratings_total !== 1 ? 's' : ''}`}</small></a> : null}
            </div>
            {placeDetails ? placeDetails.map((review: google.maps.places.PlaceReview, i) => (
                <ReviewCard key={i} {...review} />
            )) : null}
        </ModalBody>
        </Modal>
        <IconButton id="googleModalButton" onClick={() => {
            modalsContext.showModal(tiles.GoogleReviews, true);
        }}>
            <IconSeeMore  size="24"/>
        </IconButton>
    </>
    )
}

export default ModalGoogleReviews;
