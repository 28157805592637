import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import { Agent, AgencyAction, AgentAction } from '../../models/Agency';
import { getAgencyById, getAgentsById, getAgencyActions, getAgentActions, setAgencyShowStatus, setAgentShowStatus, getAllAgencies, getAgencyProfileData, getMatchingAgencies } from '../../services/FAAApi';
import { Badge, Heading, IconLinkCaretRight, Table, TableCell, TableRow, Toggle } from '@lmig/lmds-react';
import { formatAgencyName, nameCase } from '../../helpers/StringFormatter';
import { Modal, ModalBody, ModalHeader } from '@lmig/lmds-react-modal';
import { IconTime } from '@lmig/lmds-react-icons';
import { toast } from 'react-toast'
import { CircularProgressSpinner } from '@lmig/sales-ui-components';
import '../../styles/AgencyProfile.css';
import '../../styles/Modals.css';
import { AgenciesContext } from '../context/AgenciesContext';
import { UserContext } from '../context/UserContext';

function AgencyProfile() {
    const { id } = useParams();

    const [agents, setAgents] = useState<Agent[]>();
    const [agencyHistory, setAgencyHistory] = useState<AgencyAction[]>([]);
    const [agentsHistory, setAgentsHistory] = useState<AgentAction[][]>([]);
    const [agencyHistoryModalOpen, setAgencyHistoryModalOpen] = useState<boolean>(false);
    const [agentHistoryModalOpen, setAgentHistoryModalOpen] = useState<boolean>(false);
    const [agentHistoryModalIndex, setAgentHistoryModalIndex] = useState<number>(-1); //stores index of agent history to be displayed
    const [sortAscending, setSortAscending] = useState<boolean>(true);
    const [sortCategory, setSortCategory] = useState<string>("");
    const [isMarketingAdmin, setIsMarketingAdmin] = useState<boolean | undefined>(undefined);
    const { agency, setAgency } = useContext(AgenciesContext);
    const { user, setUser, loaded } = useContext(UserContext);
    const [associatedAgencies, setAssociatedAgencies] = useState<string[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => { 
        if (agency && !agency.Agents && agents)
            setAgency({...agency, Agents: agents});
        else if (agency?.Agents && !agents)
            setAgents(agency.Agents);
    }, [agency, agents, setAgency]);

    useEffect(() => {
        document.title = "Agency Profiles Management"

        const getAgencies = async () => {
            setAssociatedAgencies([]);
            try{
                await getMatchingAgencies(id?.slice(8,))
                .then((response) =>{
                    setAssociatedAgencies(response.agencies.ListItems.map((agency)=>{
                        return agency.AgencyId.toString();
                    }));

                    setIsLoaded(true);
                }).catch(err =>{
                    setIsLoaded(true);
                    setAssociatedAgencies([]);
                })
            } catch (e) {}

            try{
                await getAgencyById(id)
                .then((response) => {
                    setAgency({ AgencyId: response.agencyId, AgencyName: response.agencyName, FollowUp: response.followUp, PercentComplete: response.percentComplete, ModifiedDate: response.modifiedDate, Show: response.show === "True", ProfileStatus: response.profileStatus, AgencyLocationId: response.agencyLocationId});
                }).catch(err => {
                });
            } catch (e) {}

        }

        const getAgents = async () => {
            
            let agentsList : Agent [] = [];
            
            Promise.allSettled(associatedAgencies.map((agencyId)=>{
                return getAgentsById(agencyId);
            })).then((responseArr) =>{
                responseArr.forEach((response)=>{
                    if(response.status !== "rejected"){
                        response.value.agentMetaViewItems.forEach((agent)=>{agentsList.push(agent)});
                    }
                })
                
                let filteredAgents : Agent [] = [];

                agentsList.forEach((agent)=>{
                    let add = true;
                    filteredAgents.forEach((addedAgent, index)=>{
                        if(agent.agentId === addedAgent.agentId){
                            add = false;
                            if(agent.modifiedDate > addedAgent.modifiedDate){
                                filteredAgents[index] = agent;
                            }
                        }
                    });
                    if(add){
                        filteredAgents.push(agent);
                    }
                })

                if (agency)
                    setAgency({...agency, Agents: filteredAgents.sort((a,b)=>a.agentName.localeCompare(b.agentName))});
                setAgents(filteredAgents.sort((a,b)=>a.agentName.localeCompare(b.agentName)));
            })
        
        }

        const getAgencyHistory = async () => {
            try{
                await getAgencyActions(id)
                .then((response) => {
                    setAgencyHistory(response.Actions);
                }).catch(err => {
                    //console.log(err);
                });
            } catch (e) {}
        }

        const getUserType = async () => {
            try{
                await getAllAgencies()
                .then((response) => {
                    setUser(response.agencies.User)
                }).catch(err => {
                    //console.log(err);
                });
            } catch (e) {}
        }

        const getAgentAdmin = async () => {
            try{
                await getAgencyProfileData(id)
                .then((response) => {
                    setIsMarketingAdmin(true);
                }).catch(err => {
                    setIsMarketingAdmin(false);
                });
            } catch (e) {}
        }

        if(isLoaded){
            if (!loaded)
                getUserType();
            if (!agents)
                getAgents();

            getAgencyHistory(); 
        }else{
            getAgencies();
        }

        if(isMarketingAdmin === undefined){
            getAgentAdmin();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded, user]);

    useEffect(() => {
        const getAgentsHistory = async () => {
            let newArr : AgentAction[][] = [];

            if(agents){

                Promise.allSettled(agents.map((agent)=>{
                    return getAgentActions(id, agent.agentId.toString())
                })).then((responseArr)=>{
                    responseArr.forEach((response)=>{
                        if(response.status !== "rejected"){
                            newArr.push(response.value.Actions);
                        }
                    })
                })
            }
            setAgentsHistory(newArr);
        }
        getAgentsHistory();
    }, [agents, id]);

    const getAgentHistory = (agentId : string) : AgentAction[]  => {
        let agentHistory : AgentAction[] = [];

        agentsHistory.forEach((agent: AgentAction[]) => {
            agent.forEach((item : AgentAction) => {
                if ( agentId === item.AgentId){
                    agentHistory.push(item);
                }
            })
        });
        
        return agentHistory;
    }

    const sort = (sortBy: String, ascending: boolean) => {

        var sortedArray = JSON.parse(JSON.stringify(agents));
        if (sortBy === 'name'){
            sortedArray.sort((a: Agent, b: Agent) => {
                if(ascending){
                    return a.agentName.localeCompare(b.agentName);
                }
                else{
                    return b.agentName.localeCompare(a.agentName);
                }
            });
        }
        else if (sortBy === 'lastModified'){
            sortedArray.sort((a: Agent, b: Agent) => {
                var d1 = Date.parse(a.modifiedDate);
                var d2 = Date.parse(b.modifiedDate);
                if(ascending){
                    return d2 - d1;
                }
                else{
                    return d1 - d2;
                }
            });
        }
        else if (sortBy === 'percentComplete'){
            sortedArray.sort((a: Agent, b: Agent) => {
                if(ascending){
                    return a.percentComplete - b.percentComplete;
                }
                else{
                    return b.percentComplete - a.percentComplete;
                }
            });
        }
        setAgents(sortedArray);
    };

    const handleSortClick = (sortBy : string) => {
        let ascending = false;

        if(sortBy === sortCategory){
            ascending = !sortAscending;
        }
        else{
            setSortCategory(sortBy);
            ascending = true;
        }
        setSortAscending(ascending);
        sort(sortBy, ascending);
    }

    //HISTORY MODALS
    const showAgencyHistoryModal = () => {
        return <Table id="agency-history-table">
            <thead>
                <TableRow>
                    <TableCell dataHeader="date" type="colHead">
                        Date
                    </TableCell>
                    <TableCell dataHeader="action" type="colHead">
                        Action
                    </TableCell>
                    <TableCell dataHeader="modifiedBy" type="colHead">
                        By
                    </TableCell>
                    <TableCell dataHeader="notes" type="colHead">
                        Notes
                    </TableCell>
                </TableRow>
            </thead>
            <tbody>
            {agencyHistory.map((action : AgencyAction, index) => {
                return <TableRow key = {index}>
                    <TableCell dataHeader="date">
                        {action.ModifiedDate}
                    </TableCell>
                    <TableCell dataHeader="action">
                        {action.ActionType}
                    </TableCell>
                    <TableCell dataHeader="modifiedBy">
                        {action.ModifiedBy}
                    </TableCell>
                    <TableCell dataHeader="notes">
                        {action.Notes}
                    </TableCell>
                </TableRow>
            })}
            </tbody>
        </Table>
    }

    const showAgentHistoryModal = () => {
        let agentHistory : AgentAction[] = [];

        if(agentHistoryModalIndex !== -1 && agents){
            let agentId : string = agents[agentHistoryModalIndex].agentId.toString();
            agentHistory = getAgentHistory(agentId);
        }

        return <Table id="agent-history-table">
            <thead>
                <TableRow>
                    <TableCell dataHeader="date" type="colHead">
                        Date
                    </TableCell>
                    <TableCell dataHeader="action" type="colHead">
                        Action
                    </TableCell>
                    <TableCell dataHeader="modifiedBy" type="colHead">
                        By
                    </TableCell>
                    <TableCell dataHeader="notes" type="colHead">
                        Notes
                    </TableCell>
                </TableRow>
            </thead>
            <tbody>
            {agentHistory.map((action : AgentAction, index) => {
                return <TableRow key = {index}>
                    <TableCell dataHeader="date">
                        {action.ModifiedDate}
                    </TableCell>
                    <TableCell dataHeader="action">
                        {action.ActionType}
                    </TableCell>
                    <TableCell dataHeader="modifiedBy">
                        {action.ModifiedBy}
                    </TableCell>
                    <TableCell dataHeader="notes">
                        {action.Notes}
                    </TableCell>
                </TableRow>
            })}
            </tbody>
        </Table>
    }
    
    const spacerRow = (classname:string) => {
        return(
            <TableRow key= '' className={classname}>
                <TableCell className = "spacerCell" dataHeader="name"></TableCell>
                <TableCell className = "spacerCell" dataHeader="lastModified"></TableCell>
                <TableCell className = "spacerCell" dataHeader="profileStatus"></TableCell>
                <TableCell className = "spacerCell" dataHeader="actions"></TableCell>
                <TableCell className = "spacerCell" dataHeader="history"></TableCell>
                <TableCell className = "spacerCell" dataHeader="showProfile"></TableCell>
                <TableCell className = "spacerCell" dataHeader="percentComplete"></TableCell>
            </TableRow>
        )
    }

    const showStatusToast = (name:string, bool:boolean) => {
        var toastText = (bool ? name + " will be set to be show" : name + " will be set to be hidden") + " on Safeco's Find An Agent Portal. Note: It may take up to 24 hours for the change to be reflected in the Safeco Find An Agent Portal search results."
        toast.info(toastText, {
            backgroundColor: "#28387E",
            color: "#ffffff"
        });
    }

    const agencyActionsLink = () => {
        if(user.Type === "AGENT" || (user.Type === "EMPLOYEE" && user.IsLMAdmin === true)){
            return(<Link to={agency ? `${window.env.EDIT_AGENCY_PROFILE_PATH}${agency.AgencyId}` : '/'}>{"Edit Profile"}</Link>);
        }
        else{
            return(<Link to={agency ? `${window.env.EDIT_AGENCY_PROFILE_PATH}${agency.AgencyId}` : '/'}>{"View Profile"}</Link>);
        }
    }

    const agencyToggle = () => {
        if (agency) {
            if((user.Type === "AGENT" && isMarketingAdmin === true ) || (user.Type === "EMPLOYEE" && user.IsLMAdmin === true)){
                return(<Toggle checked = {agency.Show === true} dynamicWidth showOnOff = {false} onChange = {() => {
                    setAgency({...agency, Show: !agency.Show})
                    setAgencyShowStatus(agency.AgencyId.toString(), !agency.Show)
                    showStatusToast(nameCase(agency.AgencyName), !agency.Show);
                }}/>);
            }
            else if((user.Type === "AGENT" && isMarketingAdmin === false ) || ( user.Type === "EMPLOYEE" && user.IsLMAdmin === false)){
                return(<Toggle checked = {agency.Show} disabled dynamicWidth showOnOff = {false} />);
            }
            else{
                return (<CircularProgressSpinner aria-label="Loading Data" size="24" />)
            }
        }

    }

    const agentActionsLink = (agent:Agent) => {
        if(user.Type === "AGENT" || (user.Type === "EMPLOYEE" && user.IsLMAdmin === true)){
            return(<Link to={agency? `${window.env.EDIT_AGENT_PROFILE_PATH}${agent.agencyId}/${agent.agentId}` : '/'}>{"Edit Profile"}</Link>);
        }
        else{
            return(<Link to={agency ? `${window.env.EDIT_AGENT_PROFILE_PATH}${agent.agencyId}/${agent.agentId}` : '/'}>{"View Profile"}</Link>);
        }
    }

    const agentToggle = (agent:Agent) => {
        if((user.Type === "AGENT" && isMarketingAdmin) || (user.Type === "EMPLOYEE" && user.IsLMAdmin === true)){
            return(<Toggle checked = {agent.show.toString().toLowerCase() === "true"} dynamicWidth showOnOff = {false} onChange = {() => {
                agent.show = agent.show.toString().toLowerCase() === "true" ? "False" : "True";
                if (agents)
                    setAgents([...agents]);
                setAgentShowStatus(agent.agencyId.toString(), agent.agentId.toString(), (agent.show === "True"))
                showStatusToast(nameCase(agent.agentName), agent.show === "True");
            }}/>);
        }
        else if((user.Type === "AGENT" && isMarketingAdmin === false ) || ( user.Type === "EMPLOYEE" && user.IsLMAdmin === false)){
            return(<Toggle checked = {agent.show === "True"} disabled dynamicWidth showOnOff = {false} />);
        }
        else{
            return (<CircularProgressSpinner aria-label="Loading Data" size="24" />)
        }
    }

    const toTableBody = () => {
        return(
            <React.Fragment key={'tbody fragment'}>
            <tbody className='spacerBody' key={'tbody spacer'}>
                {spacerRow('')}
            </tbody>
            <tbody className="agencyBox">
                {toTableRow()}
            </tbody>
            <tbody className='spacerBody' key={'tbody spacer 2'}>
                {spacerRow('')}
            </tbody>
            </React.Fragment>
        )
    }

    const toTableRow = () => {
        var i = 0;
        return(
            agents && agents.map((agent, index) => {
                i++;
                if( i !== agents.length){
                    return (
                        <React.Fragment key = {agent.agentId +'rowFragment'}>
                            <TableRow key = {agent.agentId}>
                                <TableCell dataHeader="name" className='tableCell'>
                                    {nameCase(agent.agentName)}
                                </TableCell>
                                <TableCell dataHeader="lastModified" className='tableCell'>
                                    {agent.modifiedDate}
                                </TableCell>
                                <TableCell dataHeader="profileStatus" className='tableCell'>
                                    {agent.show.toString().toLowerCase() === "false"
                                        ? <Badge className='statusBadge' highlightType="informative">Hidden</Badge>
                                        : ( agent.show.toString().toLowerCase() === "true" &&  agent.modifiedDate && agent.profileStatus === 'Published'
                                            ? <Badge className='statusBadge' highlightType="positive">Published</Badge> :
                                            <Badge className='statusBadge' highlightType="caution">{agent.profileStatus}</Badge> 
                                     )}
                                </TableCell>
                                <TableCell dataHeader="actions" className='tableCell'>
                                    {agentActionsLink(agent)}
                                </TableCell>
                                <TableCell dataHeader="history" className='tableCell'>
                                    <div className = "historyIcon" onClick={() => {
                                        setAgentHistoryModalOpen(true);
                                        setAgentHistoryModalIndex(index)}
                                    }>
                                        <IconTime title = "Show History"/>
                                    </div>
                                </TableCell>
                                <TableCell dataHeader="showProfile" className='tableCell'>
                                    {agentToggle(agent)}
                                </TableCell>
                                <TableCell dataHeader="percentComplete" className='tableCell'>
                                    {agent.modifiedDate ? ( agent.percentComplete === 0 ? "25%" : agent.percentComplete + "%" ) : "Base Profile"}
                                </TableCell>
                            </TableRow>
                            {spacerRow('spacerRow')}
                        </React.Fragment>
                    )
                }
                else{
                    return(
                        <TableRow key = {agent.agentId}>
                            <TableCell dataHeader="name" className='tableCell'>
                                {nameCase(agent.agentName)}
                            </TableCell>
                            <TableCell dataHeader="lastModified" className='tableCell'>
                                {agent.modifiedDate}
                            </TableCell>
                            <TableCell dataHeader="profileStatus" className='tableCell'>
                                    {agent.show.toString().toLowerCase() === "false"
                                        ? <Badge className='statusBadge' highlightType="informative">Hidden</Badge>
                                        : ( agent.show.toString().toLowerCase() === "true" &&  agent.modifiedDate && agent.profileStatus === 'Published'
                                        ? <Badge className='statusBadge' highlightType="positive">Published</Badge> :
                                        <Badge className='statusBadge' highlightType="caution">{agent.profileStatus}</Badge> 
                                     )}
                            </TableCell>
                            <TableCell dataHeader="actions" className='tableCell'>
                                {agentActionsLink(agent)}
                            </TableCell>
                            <TableCell dataHeader="history" className='tableCell'>
                                <div className = "historyIcon" onClick={() => {
                                    setAgentHistoryModalOpen(true)
                                    setAgentHistoryModalIndex(index)}
                                }>
                                    <IconTime title = "Show History"/>
                                </div>
                            </TableCell>
                            <TableCell dataHeader="showProfile" className='tableCell'>
                                {agentToggle(agent)}
                            </TableCell>
                            <TableCell dataHeader="percentComplete" className='tableCell'>
                                {agent.modifiedDate ? (agent.percentComplete === 0 ? "25%" : agent.percentComplete + "%" ) : "Base Profile"}
                            </TableCell>
                        </TableRow>
                    )
                }
            })
        )
    }

    return (
        <div className = "agencyProfile">
            <h6 className='breadcrumb-line' style={{paddingLeft: 0}}>
                <Link className='breadcrumb-links' to={`${window.env.ROOT}`} >{"Agency List"}</Link>
                <IconLinkCaretRight color='blue'/>
                <p className='breadcrumb-p'>{agency ? "Manage Profiles - " + formatAgencyName(agency.AgencyName) : "Manage Profiles"}</p>
            </h6>
            <Heading type="h3" className='safeco-color'>Manage Profiles</Heading>
            <Table id="agency-table">
                    <thead className = "table-header-box">
                        <TableRow>
                            <TableCell dataHeader="name" type="colHead">
                                <div style={{width: "300px"}}>
                                Agency Name
                                </div>
                            </TableCell>
                            <TableCell dataHeader="lastModified" type="colHead">
                                <div style={{width: "100px"}}>Last Modified </div>
                            </TableCell>
                            <TableCell dataHeader="profileStatus" type="colHead">
                                <div style={{width: "120px"}}>Profile Status</div>
                            </TableCell>
                            <TableCell dataHeader="actions" type="colHead">
                                <div style={{width: "100px"}}>Actions </div>
                            </TableCell>
                            <TableCell dataHeader="history" type="colHead">
                                <div style={{width: "100px"}}>History </div>
                            </TableCell>
                            <TableCell dataHeader="showProfile" type="colHead">
                                <div style={{width: "100px"}}>Show Profile? </div>
                            </TableCell>
                            <TableCell dataHeader="percentComplete" type="colHead">
                                <div style={{width: "100px"}}>% Complete </div>
                            </TableCell>
                        </TableRow>
                    </thead>
{ agency ? <><tbody className='spacerBody'>
                    {spacerRow('')}
                    </tbody>
                    <tbody className="agencyBox">
                        <TableRow>
                            <TableCell dataHeader="name" className='tableCell'>
                                {formatAgencyName(agency.AgencyName)}
                            </TableCell>
                            <TableCell dataHeader="lastModified" className='tableCell'>
                                {agency.ModifiedDate}
                            </TableCell>
                            <TableCell dataHeader="profileStatus" className='tableCell'>
                                {!agency.Show
                                    ? <Badge className='statusBadge' highlightType="informative">Hidden</Badge>
                                    : (agency.Show && agency.ModifiedDate && agency.ProfileStatus === 'Published')
                                        ? <Badge className='statusBadge' highlightType="positive">Published</Badge>
                                        : <Badge className='statusBadge' highlightType="caution">{agency.ProfileStatus}</Badge>
                                }
                            </TableCell>
                            <TableCell dataHeader="actions" className='tableCell'>
                            {agencyActionsLink()}
                            </TableCell>
                            <TableCell dataHeader="history" className='tableCell'>
                                <div className = "historyIcon" onClick={() => setAgencyHistoryModalOpen(true)}>
                                    <IconTime title = "Show History"/>
                                </div>
                            </TableCell>
                            <TableCell dataHeader="showProfile" className='tableCell'>
                                {agencyToggle()}
                            </TableCell>
                            <TableCell dataHeader="percentComplete" className='tableCell'>
                                {agency.ModifiedDate ? ( agency.PercentComplete === 0 ? "25%" : agency.PercentComplete + "%" ) : "Base Profile"}
                            </TableCell>
                        </TableRow>
                    </tbody>
                    <tbody className='spacerBody'>
                    {spacerRow('')}
                    </tbody></> : null}
                </Table>
                {!agency ? <div className="table-loading-spinner"><CircularProgressSpinner aria-label="Loading Data" size="64" /></div> : null}
                <Table id="agents-table" className={ (agents && agents.length>1) ? "agents-table-incr-width" : "agents-table-decr-width"}>

                    <thead className = "table-header-box">
                        <TableRow>
                            <TableCell dataHeader="name" type="colHead">
								<div style={{width: "300px"}} onClick={() => handleSortClick('name')}>
									<div className = "margin-text-4" style={{width: "95px"}}>Agent Name</div>
								</div>
                            </TableCell>
                            <TableCell dataHeader="lastModified" type="colHead">
                                <span style={{width: "100px"}} className="inline">
                                    <div className = "margin-text-4" onClick={() => handleSortClick('lastModified')}>Last Modified</div>
                                </span>
                            </TableCell>
                            <TableCell dataHeader="profileStatus" type="colHead">
                                <div className = "margin-text" style={{width: "120px"}} >Profile Status</div>
                            </TableCell>
                            <TableCell dataHeader="actions" type="colHead">
                                <div className = "margin-text" style={{width: "100px"}} >Actions</div>
                            </TableCell>
                            <TableCell dataHeader="history" type="colHead">
                                <div className = "margin-text" style={{width: "100px"}}>History</div>
                            </TableCell>
                            <TableCell dataHeader="showProfile" type="colHead">
                                <div className = "margin-text" style={{width: "100px"}}>Show Profile?</div>
                            </TableCell>
                            <TableCell dataHeader="percentComplete" type="colHead">
                                <span className="inline" style={{width: "100px"}}>
                                    <div className = "margin-text-4" onClick={() => handleSortClick('percentComplete')}>% Complete</div>
                                </span>
                            </TableCell>
                        </TableRow>
                    </thead>
                    {agency && agency.Agents ? toTableBody() : null}
                </Table>
                {!agency?.Agents ? <div className="table-loading-spinner"><CircularProgressSpinner aria-label="Loading Data" size="64" /></div> : null}
                <Modal isOpen={agencyHistoryModalOpen} size="small" onClose={() => setAgencyHistoryModalOpen(false)}>
                    <ModalHeader><Heading className='modal-heading'>Agency History</Heading></ModalHeader>
                    <ModalBody className="modal-body">
                        {showAgencyHistoryModal()}
                    </ModalBody>
                </Modal>
                <Modal isOpen={agentHistoryModalOpen} size="small" onClose={() => setAgentHistoryModalOpen(false)}>
                    <ModalHeader><Heading className='modal-heading'>Agent History</Heading></ModalHeader>    
                    <ModalBody className="modal-body">
                            {showAgentHistoryModal()}
                    </ModalBody>
                </Modal>
                                
        </div>
    );
}

export default AgencyProfile;