
import { createContext, useState } from "react"
import { User } from "../../models/Agency";

export type IUserContext = {
  loaded: boolean,
  user: User,
  setUser: (user: User) => void
};

const initialState: IUserContext = {
  user: {Id:"",
  Type:"AGENT",
  IsLMAdmin:false,
  IsAgent:true,
  IsFaaLmItAdmin:false,
  FormattedUserId:""},
  setUser: (user: User) => {},
  loaded: false
};

export const UserContext = createContext(initialState);

export function UserContextProvider({ children }: { children: React.ReactNode }) {
    const [user, setUser] = useState<User>(initialState.user);
    const [loaded, setLoaded] = useState<boolean>(initialState.loaded);

    let shared: IUserContext = {
      user: user,
      setUser: (user: User) => { setLoaded(true); setUser(user)},
      loaded: loaded
    }

  return (
    <UserContext.Provider value={shared}>
      {children}
    </UserContext.Provider>
  );
}