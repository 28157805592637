import { IconButton, AlphanumericInput, GridCol, GridRow, IconTrash, Badge, FieldGroup, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useContext, useState } from "react";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import AddItem from "../../../../../helpers/AddItem";
import CancelAndSaveButtons from "../../../../modals/CancelAndSaveButtons";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { useEffect, useRef } from "react";
import '../../../../../styles/Modals.css';

type Article = {
    Title: string,
    Url: string
}

type error = {
    Title: string[],
    Url: string[]
};

const ModalArticles = () => {
    const [errorMessageArray, setErrorMessageArray] = useState<error[]>([{ Title: [], Url: [] }, { Title: [], Url: [] }, { Title: [], Url: [] }]);
    const { articles, setArticles } = useContext(ProfileDataContext);
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    const [copy, setCopy] = useState<Article[]>(articles);

    /*ref elements used for focusing*/
    const article1= useRef<HTMLInputElement>(null);
    const article2= useRef<HTMLInputElement>(null);
    const article3= useRef<HTMLInputElement>(null);

    const [artTitle1Error, setArtTitle1Error] = useState(false);
    const [artUrl1Error, setArtUrl1Error] = useState(false);
    const [artTitle2Error, setArtTitle2Error] = useState(false);
    const [artUrl2Error, setArtUrl2Error] = useState(false);
    const [artTitle3Error, setArtTitle3Error] = useState(false);
    const [artUrl3Error, setArtUrl3Error] = useState(false);
    
    useEffect(() => {
        if(copy.length === 1){
            article1.current?.focus();
        }else if(copy.length === 2){
            article2.current?.focus();
        }else if(copy.length === 3){
            article3.current?.focus();        
        }else {
            article1.current?.focus();  
        }
    }, [copy.length]);

    useEffect(() => {
        if (articles)
            setCopy(articles.map((article:any) => ({ Title:article.Title, Url:article.Url})));
    }, [articles])
    const checkForErrors = () => {
        let errors: error[] = [{ Title: [], Url: [] }, { Title: [], Url: [] }, { Title: [], Url: [] }];
        copy.forEach((article, index) => {
            if (article.Title === "") {
                errors[index].Title.push(`Article${index+1}: must have a title`);
            }
            if (article.Title.length > 50) {
                errors[index].Title.push(`Article${index+1}: you have exceeded the character limit on title.`);
            } 
            if (article.Url === "") {
                errors[index].Url.push(`Article${index+1}: must have a Url`);
            }
            if (article.Url.length > 2083) {
                errors[index].Url.push(`Article${index+1}: you have exceeded the character limit on url`);
            }
            var urlExpression = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
            var regexWebsite = new RegExp(urlExpression);

            if (article.Url !== "" && !article.Url.match(regexWebsite)) {
                errors[index].Url.push(`Article${index+1}: url is invalid`);

            }

            if(index===0 && errors[index].Title.length > 0)
            {
                setArtTitle1Error(true);
            } else if(index===0 && errors[index].Title.length === 0){
                setArtTitle1Error(false);
            }
            if(index===1 && errors[index].Title.length > 0)
            {
                setArtTitle2Error(true);
            } else if(index===1 && errors[index].Title.length === 0){
                setArtTitle2Error(false);
            }
            if(index===2 && errors[index].Title.length > 0)
            {
                setArtTitle3Error(true);
            } else if(index===2 && errors[index].Title.length === 0){
                setArtTitle3Error(false);
            }

            if(index===0 && errors[index].Url.length > 0)
            {
                setArtUrl1Error(true);
            } else if(index===0 && errors[index].Url.length === 0){
                setArtUrl1Error(false);
            }
            if(index===1 && errors[index].Url.length > 0)
            {
                setArtUrl2Error(true);
            } else if(index===1 && errors[index].Url.length === 0){
                setArtUrl2Error(false);
            }
            if(index===2 && errors[index].Url.length > 0)
            {
                setArtUrl3Error(true);
            } else if(index===2 && errors[index].Url.length === 0){
                setArtUrl3Error(false);
            }
        });

        setErrorMessageArray(errors);
        let countErrors = 0;
        errors.forEach((error) => {
            countErrors = countErrors + error.Title.length + error.Url.length;

        });

        return countErrors;
    };

    const cancelChanges = () => {
        setCopy(articles.map((article:any) => ({ Title:article.Title, Url:article.Url})));
        setErrorMessageArray([{ Title: [], Url: [] }, { Title: [], Url: [] }, { Title: [], Url: [] }]);
        modalsContext.showModal(tiles.Articles, false);        
    };

    const resetErrorHighlight = () => {
        setArtTitle1Error(false);
        setArtTitle2Error(false);
        setArtTitle3Error(false);
        setArtUrl1Error(false);
        setArtUrl2Error(false);
        setArtUrl3Error(false);  
    };

    const renderErrorMsgs = () => {
        let errList: any =[];

        errorMessageArray.forEach((err) => {
            if(err.Title.length>0)
            {
                errList.push( {
                    text: err.Title[0],
                    type: 'error'
                });
            }
            if(err.Url.length>0){
                errList.push( {
                    text: err.Url[0],
                    type: 'error'
                });
            }
        });
        
        return errList;
    }


    const renderArticles = () => {
        return copy.map((_article, index) => {
            return (
                <GridRow style={{margin:'0px'}} key={index}>
                    <GridCol className="col-gutters" base={5}>
                        {(index === 0 ) &&         
                            <AlphanumericInput highlightType={artTitle1Error? 'error' :undefined} innerRef= {article1} labelVisual="Article Name" value={copy[index].Title} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].Title = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} /> } 
                        {(index === 1 ) &&         
                            <AlphanumericInput highlightType={artTitle2Error? 'error' :undefined} innerRef= {article2} labelVisual="Article Name" value={copy[index].Title} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].Title = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} /> } 
                        {(index === 2 ) &&         
                            <AlphanumericInput highlightType={artTitle3Error? 'error' :undefined} innerRef= {article3} labelVisual="Article Name" value={copy[index].Title} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].Title = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} /> } 
                    </GridCol>
                    <GridCol className="col-gutters" base={6}>  
                            {(index === 0 ) &&                       
                            <AlphanumericInput highlightType={ artUrl1Error? 'error' :undefined} labelVisual="Article Link" value={copy[index].Url}
                                onBlur={(e) => {
                                    var newArray = copy.slice();
                                    newArray[index].Url = e.currentTarget.value.includes("http://") || e.currentTarget.value.includes("https://") ? e.currentTarget.value : "http://" + e.currentTarget.value;
                                    setCopy([...newArray])
                                }}

                                onChange={(e) => {
                                    var newArray = copy.slice();
                                    newArray[index].Url = e.currentTarget.value;
                                    setCopy([...newArray]);
                                }} />
                            }
                            {(index === 1 ) &&                       
                            <AlphanumericInput highlightType={ artUrl2Error? 'error' :undefined}  labelVisual="Article Link" value={copy[index].Url}
                                onBlur={(e) => {
                                    var newArray = copy.slice();
                                    newArray[index].Url = e.currentTarget.value.includes("http://") || e.currentTarget.value.includes("https://") ? e.currentTarget.value : "http://" + e.currentTarget.value;
                                    setCopy([...newArray])
                                }}

                                onChange={(e) => {
                                    var newArray = copy.slice();
                                    newArray[index].Url = e.currentTarget.value;
                                    setCopy([...newArray]);
                                }} />
                            }
                            {(index === 2 ) &&                       
                            <AlphanumericInput highlightType={ artUrl3Error? 'error' :undefined}  labelVisual="Article Link" value={copy[index].Url}
                                onBlur={(e) => {
                                    var newArray = copy.slice();
                                    newArray[index].Url = e.currentTarget.value.includes("http://") || e.currentTarget.value.includes("https://") ? e.currentTarget.value : "http://" + e.currentTarget.value;
                                    setCopy([...newArray])
                                }}

                                onChange={(e) => {
                                    var newArray = copy.slice();
                                    newArray[index].Url = e.currentTarget.value;
                                    setCopy([...newArray]);
                                }} />
                            }
                    </GridCol>
                    <GridCol className="col-gutters" base={1}>
                        <FieldGroup id="formField">
                            <IconButton onClick={(e: any) => {
                                var newArray = copy.slice();
                                newArray.splice(index, 1);
                                setCopy([...newArray]);
                                setErrorMessageArray([{ Title: [], Url: [] }, { Title: [], Url: [] }, { Title: [], Url: [] }]);
                            }}>
                                <IconTrash size="24"></IconTrash>
                            </IconButton></FieldGroup>
                    </GridCol>
                </GridRow>
            );
        })
    };


    return (
        <>
            <Modal size="small" onClose={() => cancelChanges()} isOpen={modalsContext.articles}>
                <ModalHeader>
                <Heading className="modal-heading">Edit articles</Heading>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <FieldGroup disableMargins="all" className="formField" isFull messages={renderErrorMsgs()}> 
                        {renderArticles()}
                    </FieldGroup>
                    {copy.length < 3 && <AddItem text={copy.length === 0 ? "Add articles" : "Add another article"} 
                     action={() => { setCopy([...copy, { Title: '', Url: '' }]); }} />}
                    {copy.length >= 3 && <Badge highlightType="caution">Only up to 3 articles may be added</Badge>}
                    </ModalBody>
                    <ModalFooter className="modal-footer">
                    <CancelAndSaveButtons
                        cancelFunction={cancelChanges}
                        saveFunction={() => {
                            if (checkForErrors() === 0) {
                                setArticles(copy);
                                modalsContext.showModal(tiles.Articles, false)}}}/>  
                </ModalFooter>
            </Modal>
            <GridRow onClick={() => {
                setTimeout(() => {
                    let newCopy = articles.slice();                
                    newCopy.push({ Title: "", Url: "" });               
                    setCopy(newCopy); 
                    resetErrorHighlight();
                    checkForErrors();
                }, 500);
                modalsContext.showModal(tiles.Articles, true);
            }
            }>
               {articles.length > 0 ?  (articles.length < 3 ? <AddItem text="Add another article" /> : null)  : <AddItem text="Add articles" />}
            </GridRow>
        </>
    )

};

export default ModalArticles;
