import { Heading, IconButton, IconHelp } from "@lmig/lmds-react";
import { Popover } from "@lmig/lmds-react-popover";
import { OperatingTime } from "../../models/Agent";
import '../../styles/AgencyContactInformation.css'
import { ImageFile } from "../context/ProfileDataContext";
import MainPhotoHeader from "../pages/EditAgencyPage/MainPhotoHeader";

interface AgencyContactInformationProps {
    agentName: string,
    agencyName: string,
    addressLine1: string,
    city: string,
    state: string,
    zip: string,
    phone: string,
    email: string,
    agencyTimeZone: string,
    agencyOperatingTimes: OperatingTime[],
    image?: ImageFile,
    updateImage: (image: ImageFile) => void
}

function AgencyContactInformation(props : AgencyContactInformationProps) {

    return (
        <>
        <div id="contactInfoCard">
                <div className="edit-profile-banner">
                        <MainPhotoHeader updateImage={(image: ImageFile) => props.updateImage(image)} image={props.image} />

                        <div className="edit-profile-banner-section">
                            <Heading type="h4-light">Address and Phone</Heading>
                            <p>{props.addressLine1 + props.city + ", " + props.state + " " + props.zip}</p>
                            <p>Phone: {props.phone}</p>
                        </div>
                        <div className="edit-profile-banner-section">
                            <Heading type="h4-light">Online Resources</Heading>
                            <p>Email: {props.email}</p>
                        </div>
                </div>
                <div className="settings-info">
                    <p className="settings-text">Why can't I edit these settings?</p>
                    <Popover
                        className="settings-popover"
                        trigger={<IconButton background="transparent"><IconHelp color="inverse" size="16" /></IconButton>}>
                    To make changes to contact information, please contact your agency admin.
                    </Popover>
                </div>
        </div>
        </>
    );
}


export default AgencyContactInformation;