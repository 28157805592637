import { BodyText, GridRow, Heading} from "@lmig/lmds-react";
import { Modal, ModalBody, ModalHeader, ModalFooter} from '@lmig/lmds-react-modal';
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";


interface CancelChangesModalProps {
    open: boolean,
    yesCancel: () => void,
    noCancel: () => void
}

function TextInputModal(props: CancelChangesModalProps) {



    return (
        <Modal
            isOpen={props.open}
            size="small"
            onClose={() => props.noCancel()}
        >
        <ModalHeader><Heading className="modal-heading">Cancel Profile Changes</Heading></ModalHeader>
        <ModalBody className="modal-body">
        <GridRow justify="center">
            <BodyText type="lead" style={{textAlign: 'center'}}>
                <p>Are you sure you want to leave without publishing this profile?</p>
            </BodyText>
        </GridRow>
        </ModalBody>
        <ModalFooter className="modal-footer">
            <CancelAndSaveButtons 
                cancelTitle="No"
                saveTitle="Yes"
                cancelFunction={props.noCancel}
                saveFunction={props.yesCancel}/>
        </ModalFooter>
        </Modal>
    )
}

export default TextInputModal;
