import { Button, FieldGroup, Form, Table, TableRow, TableCell, GridRow, Alert, Heading } from "@lmig/lmds-react";
import { Modal, ModalBody, ModalHeader } from '@lmig/lmds-react-modal';
import  React, { useState } from "react";
import { SFDataFile } from "../../models/SFDataFile";
import '../../styles/UploadSFDataModal.css';
import '../../styles/Modals.css';
import { CircularProgressSpinner } from '@lmig/sales-ui-components';
import { uploadSFDataFile, updateSFData } from "../../services/FAAApi";

interface OptionsModalProps {
  title: string,
  sfDataUploadModalOpen: boolean,  
  updateSFDataUploadModalOpen: (val:boolean) => void,
  options: any,  
}

function UploadSFDataModal (props : OptionsModalProps) {

  const [sfDataUploadModalOpen, setSFDataUploadModalOpen] = useState<boolean>(props.sfDataUploadModalOpen);
  const [options, setOptions] = useState<SFDataFile>(props.options);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorType, setErrorType] = useState<string>("positive");
  const [showProcessing, setShowProcessing] = useState(false);
  
  const formatFileSizeUnits = (bytes: any) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(bytes, 10) || 0;
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  const formatDate = (dateStr: any) => {    
    let formattedDate;
    if (dateStr.toString().includes('/Date') ){
      formattedDate = new Date(parseFloat(dateStr.substr(6)));  
    } else {
      formattedDate = new Date(dateStr);
    }
     
    // format👇️ "09/24/2022"
    return formattedDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });    
  }
  
  //Creates a tbody
  const toTableBody = () => { 
    console.log(options);
    return (
      <tbody className='spacerBody'>
          <TableRow className="lm-Table-row"> 
                <TableCell className = "spacerCell" dataHeader="profileStatus"></TableCell>
                <TableCell className = "spacerCell" dataHeader="agencyName"></TableCell>
                <TableCell className = "spacerCell" dataHeader="addressLine"></TableCell>
                <TableCell className = "spacerCell" dataHeader="lastModified"></TableCell>               
            </TableRow>
      
          <TableRow>
            {console.log(options)}
              <td><div style={{   textAlign: "left"  }} > {options.IsFileExists ? options.FileName : "-"} </div>  </td>
              <td><div style={{   textAlign: "center"  }} > {options.IsFileExists ? formatDate(options.LastModified) : "-"}   </div></td>
              <td><div style={{   textAlign: "center"  }} > {options.IsFileExists ? formatFileSizeUnits(options.FileSize) : "-"}  </div> </td>
              <td><div style={{   textAlign: "right"  }} >  
              <Button disabled={!options.IsFileExists} size="small" variant="primary" onClick={async ()  => { 
                  var res = await updateSFData(options.ClientSessionToken);                  
                  setShowProcessing(true);
                  setErrorMessage("");
                  setErrorType("positive");
                  if(res.Status === 1) {
                    setErrorMessage(res.Message);
                    setErrorType("positive");
                  } else 
                  {
                    setErrorMessage("Failed to update SF Data ");
                    setErrorType("negative");
                  }
                  setShowProcessing(false);
               }}>
                                Update DB
              </Button>  </div> </td>
          </TableRow>
      </tbody>
  );           
  }

  return (
    <>
    <Modal
    isOpen={sfDataUploadModalOpen}
    size="small"
    onClose={() => {setSFDataUploadModalOpen(false);props.updateSFDataUploadModalOpen(false); }}
    >
    <ModalHeader><Heading className="modal-heading">{props.title}</Heading></ModalHeader>
    <ModalBody className="modal-body">
      <Form id="form--isFull">
          <FieldGroup isFull >    
            <GridRow className="right-content">            
              <label htmlFor="fileupload" className="lmig-Button lmig-Button--small lmig-Button--primary">
              Upload Salesforce file     
              </label> 
              <input type="file" id="fileupload" accept=".txt" onChange={async (e : any) => {
                setShowProcessing(true);
                setErrorMessage("");
                setErrorType("positive");
                  if (e.target.files !== null) {
                    console.log(e.target.files[0])
                      if(e.target.files[0].name !== "Agency_SFDC_Data.txt") {
                        setErrorMessage("Please upload valid SF Data file (file name should be Agency_SFDC_Data.txt).");
                        setErrorType("negative");
                        console.log("name " + e.target.files[0].name);
                        return;
                      }                      
                      if (e.target.files[0].size > 5242880) {// checks if file is greater than 5MB
                        setErrorMessage("File size should be less than 5mb.");
                        setErrorType("negative");
                        console.log("name " + e.target.files[0].size);
                        return;
                      }
                      var process = window.confirm("Selected file will be stored on the FTP server and overwrite the current file. Do you want to copy the file to FTP?");
                      if (process) {
                      var res = await uploadSFDataFile(options.ClientSessionToken, e.target.files[0])
                      console.log(res);
                      
                      if(res.Status === 1) {
                        let updateOptions : SFDataFile = options ;
                        updateOptions.FileName = e.target.files[0].name;
                        updateOptions.FileSize = e.target.files[0].size;
                        updateOptions.LastModified = e.target.files[0].lastModified;
                        updateOptions.IsFileExists = true;
                        setOptions(updateOptions);
                        setErrorMessage(res.Message);
                        setErrorType("positive");
                        e.target.value = null;
                      } else 
                      {
                        setErrorMessage("Failed to upload to FTP server");
                        setErrorType("negative");
                        e.target.value = null;
                      }
                    } else {
                      e.target.value = null;
                    }
                      
                  }
                  setShowProcessing(false);
              }}/>           
            </GridRow> 
          </FieldGroup>
      </Form>
      { showProcessing ?
        <div className="loading">
            <div className="spinner">
                <CircularProgressSpinner aria-label="Loading Data" size="24" />
            </div>
        </div>  : null
      }
      <FieldGroup>
        <Table id="demo-table" className = "margin-content" >
            <thead className = "table-header-box">
                <TableRow> 
                    <TableCell dataHeader="profileStatus" type="colHead">
                        <span className="table-header">
                            <div className = "margin-text">FTP File Name</div>
                        </span>
                    </TableCell>
                    <TableCell dataHeader="agencyName" type="colHead">
                        <span className="table-header">
                        <div className = "margin-text">Last Modified</div>
                        </span>
                    </TableCell>
                    <TableCell dataHeader="addressLine" type="colHead">
                        <span className="table-header">
                        <div className = "margin-text">File Size</div> 
                        </span>
                    </TableCell>
                    <TableCell dataHeader="lastModified" type="colHead">
                        <span className="table-header">
                        <div className = "margin-text">
                         
                        </div>
                        </span>
                    </TableCell>
                </TableRow>
            </thead>   
            {  toTableBody() }             
          </Table> 
          <div className={errorMessage === "" ? "hide-error" : "show-error" } >
          <Alert highlightType={errorType === "positive" ? "positive" : "negative"}> {errorMessage} </Alert>
          </div>
      </FieldGroup> 
    </ModalBody>
    </Modal>
  </>
      )
}
export default UploadSFDataModal;
