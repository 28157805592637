import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import AgencyProfile from './components/pages/AgencyProfile';
import EditAgency from './components/pages/EditAgencyPage/EditAgency';
import EditAgentProfile from './components/pages/EditAgentProfile';
import { ProfileDataProvider } from './components/context/ProfileDataContext';
import { AgentDataProvider } from './components/context/AgentDataContext';
import { ModalsContextProvider } from './components/context/ModalsContext';
import { Content } from '@lmig/lmds-react';
import './App.css';
import { AgentHoursProvider } from './components/context/AgentHoursContext';
import Layout from './components/pages/Layout';
import { useEffect, useState } from 'react';

function App() {
  const [usev2, setUsev2 ] = useState<boolean>(); //controls whether the angular (v1) app or the react (v2) app is the primary experience
  useEffect(() => {
    if (window.env && window.env?.USE_V1 !== undefined) {
      let experience: string | null = sessionStorage.getItem(`v${window.env.ENVIRONMENT}`);
      if (window.env.USE_V1 && window.location.pathname.indexOf('v2') === -1 && experience !== "v2") {
        window.location.href = `https://${window.location.hostname}${window.env.ROOT}/v1`;
      }
      else {
        setUsev2(true);
        sessionStorage.setItem(`v${window.env.ENVIRONMENT}`, "v2");
      }
    }
    else {
      console.log('test - env undefined');
      window.location.reload();
    }
  }, []);

  return (usev2 ? <Routes>
      <Route path={window.env.ROOT} element={<Layout />}>
        <Route path="v2" element={<Content><Home /></Content>} />  {/*  Agent List Screen  */}
        <Route path={window.env.ROOT} element={<Content><Home /></Content>} />  {/*  Agent List Screen  */}
        <Route path={window.env.AGENCY_PROFILE} element={<Content><AgencyProfile /></Content>} />  {/*  Profile Management Screen  */}
        <Route path={window.env.EDIT_AGENCY_PROFILE} element={<Content fullWidth><ProfileDataProvider><ModalsContextProvider><EditAgency /></ModalsContextProvider></ProfileDataProvider></Content>} /> {/*  Agency Profile Edit  */}
        <Route path={window.env.EDIT_AGENT_PROFILE} element={<Content fullWidth><AgentDataProvider><AgentHoursProvider><EditAgentProfile /></AgentHoursProvider></AgentDataProvider></Content>} />  {/*  Agent Profile Edit  */}
      </Route>
      <Route path="*" element={<Navigate to={window.env.ROOT} />} />
    </Routes> : <></>
  );
}

export default App;