import { AlphanumericInput, GridCol, GridRow, FieldGroup, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useContext, useState } from "react";
import _ from 'lodash';
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import { Textarea } from '@lmig/lmds-react-textarea';
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { useEffect } from "react";
import '../../../../../styles/Modals.css';
import CancelAndSaveButtons from "../../../../modals/CancelAndSaveButtons";

type AboutAgency = {
    summary: string,
    yearEstablished: string
}

type errorsObject = {
    summary: string[],
    yearEstablished: string[]

};

const ModalAboutAgency = () => {
    const { aboutAgency, setAboutAgency } = useContext(ProfileDataContext);
    const [copy, setCopy] = useState<AboutAgency>(aboutAgency);
    const [errorMessageObject, setErrorMessageObject] = useState<errorsObject>({ summary: [], yearEstablished: [] });
    const modalsContext = useContext<IModalsContext>(ModalsContext);

    useEffect(() => {
        setCopy(aboutAgency);
    }, [aboutAgency]);

    const checkForErrors = () => {
        let errors: errorsObject = { summary: [], yearEstablished: [] };
        if (copy.summary.includes('<') || copy.summary.includes('>') || copy.summary.includes('%') || copy.summary.includes('=')) {
            errors.summary.push("Please avoid use of the following characters: '<', '>', '%', and '='.");
        }
        if (copy.summary.length > 500) {
            errors.summary.push("Please limit to 500 characters");
        }

        if (copy.yearEstablished !== undefined && copy.yearEstablished !== null) {
            if (copy.yearEstablished !== "" && copy.yearEstablished.length !== 4) {
                errors.yearEstablished.push("Please enter a valid four digit year");
            }
            if (copy.yearEstablished.length === 4 && Number(copy.yearEstablished) > new Date().getFullYear() + 1) {
                errors.yearEstablished.push("Year business established cannot occur more than 1 year in the future")
            }
            if (copy.yearEstablished.length === 4 && Number(copy.yearEstablished) < 1850) {
                errors.yearEstablished.push("Year business established cannot occur before 1850")
            }
        }

        setErrorMessageObject(errors);

        return errors.summary.length + errors.yearEstablished.length;
    };

    const cancelChanges = () => {
        setCopy(_.cloneDeep(aboutAgency));
        setErrorMessageObject({ summary: [], yearEstablished: [] });
        modalsContext.showModal(tiles.AboutTheAgency, false);
    };

    return (
        <>
            <Modal isOpen={modalsContext.aboutTheAgency} size="small" onClose={() => cancelChanges()}>
                <ModalHeader><Heading className="modal-heading">Edit other agency information</Heading></ModalHeader>
                <ModalBody className="modal-body">
                    <FieldGroup isFull id="formField"
                        messages={
                            errorMessageObject.summary.map((error) => {
                                return { text: error, type: 'error' };
                            })
                        }>
                        <Textarea labelVisual="About the agency" value={copy.summary} onChange={(e) => {
                            setCopy({ ...copy, summary: e.currentTarget.value });
                        }}></Textarea>

                    </FieldGroup>
                    <GridRow>
                        <GridCol base={6} id="row1-col1">
                            <FieldGroup isFull id="formField"
                                messages={
                                    errorMessageObject.yearEstablished.map((error) => {
                                        return { text: error, type: 'error' };
                                    })
                                }>
                                <div id="inline">
                                    <p style={{ padding: "0 16px 0 0" }}><b>Year Business Established: </b></p>
                                    <AlphanumericInput
                                        labelVisual="Year"
                                        filter={/^[0-9]*$/}
                                        value={copy.yearEstablished}
                                        highlightType={errorMessageObject.yearEstablished.length ? "error" : undefined}
                                        onChange={(e) => setCopy({ ...copy, yearEstablished: e.currentTarget.value })}
                                    />
                                </div>
                            </FieldGroup>
                        </GridCol>
                    </GridRow>
                </ModalBody>
                <ModalFooter className="modal-footer">
                    <CancelAndSaveButtons
                        cancelFunction={cancelChanges}
                        saveFunction={() => {
                            if (checkForErrors() === 0) {
                                setAboutAgency(_.cloneDeep(copy));
                                modalsContext.showModal(tiles.AboutTheAgency, false)}}}/>
                </ModalFooter>
            </Modal>
        </>
    )
};

export default ModalAboutAgency;
