import { Card, IconButton, IconEdit, List, ListItem } from "@lmig/lmds-react";
import { useContext, useEffect } from "react";
import AddItem from "../../../helpers/AddItem";
import { CarrierModel } from "../../../models/Agency";
import { IModalsContext, ModalsContext, tiles } from "../../context/ModalsContext";
import { ProfileDataContext } from "../../context/ProfileDataContext";
import AgencyOptionsModal from "../../modals/AgencyOptionsModal";

function Carriers() {
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    const { profileData, carriers, setCarriers } = useContext(ProfileDataContext);

    useEffect(() => {
        if (profileData.AssignedCarriers)
            setCarriers(profileData.AssignedCarriers.map((x: any) => ({ id: x.Carrier_Id, carrierName: x.Carrier_Name, selected: x.Assigned })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData]);

    /*---------------- Callback Functions --------------------------*/

    const getNamesCheckedInTable = (boxes: CarrierModel[]) => {
        boxes.sort();
        return boxes.slice(1).filter(x => x.selected).map((item: CarrierModel, index) =>
            <div key={index} className="product-and-languages-wrapper">
                <List compact className="carrier-list-spacing">
                    <ListItem className="carrier-li-spacing">
                        <p className="carrier-text-spacing">{item.carrierName}</p>
                    </ListItem>
                </List>
            </div>);
    }

    return (
        <>
            <Card title="Carriers">
            <p className="additional-text-format">(In addition to Safeco)</p>
                {carriers && carriers.find(carrier => carrier.selected) && <IconButton className="edit-btn-align-absolute">
                    <IconEdit size="24" onClick={() => { modalsContext.showModal(tiles.Carriers, true) }} />
                </IconButton>}
                <div className="indent-content">
                    {getNamesCheckedInTable(carriers)}
                </div>
                {carriers.find(carrier => !carrier.selected) && <div style={{ "paddingLeft": "30px" }}>
                    <AddItem text={carriers.find(carrier => carrier.selected) ? "Add more carriers" : "Add carriers"} action={() => modalsContext.showModal(tiles.Carriers, true)} />
                </div>}
            </Card>
            {modalsContext.carriers && <AgencyOptionsModal
                edit={modalsContext.carriers}
                updateEdit={(show: boolean) => modalsContext.showModal(tiles.Carriers, show)}
                options={carriers}
                title="Select Carriers"
                isProduct={false}></AgencyOptionsModal>}

        </>
    );
}

export default Carriers;
