import {
    preReplacementRegexPatterns,
    postReplacementRegexPatterns,
    knownShortWords,
    stateAbbreviation
} from "./AgencyNameHelper";


export const checkEmpty = (text: string) => {
    if (!text || text === undefined) {
        return "";
    }

    return text;

}

export const isEmpty = (field?: string) => {
    return field === '---' || field?.trim() === '' || field === undefined || field === null;
}

export const isPhoneFieldEmpty = (field?: string) => {
    return field === '---' || field?.trim() === '-   -' || field?.trim() === '' || field === undefined || field === null;
}

export const nameCase = (text: string) : string => {
    if (!text || text === undefined) {
        return "";
    }
    var regex = /(\b[a-z])/g;
    return text.toLowerCase().replace(regex, function (match) {
      return match.toUpperCase();
    });
  }
  
export const formatAgencyName = (agencyName: string | undefined): string => {
    let lowercaseRegexPattern = /[a-z]/gm;

    if (!agencyName)
        return "";
    else if (lowercaseRegexPattern.test(agencyName))
        return agencyName;

    let articleAndConjunctionsRegexPattern = /(^an$|^and$|^at$|^but$|^by$|^for$|^in$|^nor$|^of$|^on$|^or$|^so$|^the$|^to$|^up$|^yet$)/gmi;
    let ordinalNumbersRegexPattern = /([\d]+ ?ST{1,2}\b|[\d]+ ?ND{1,2}\b|[\d]+ ?RD{1,2}\b|[\d]+ ?TH{1,2}\b)/gmi;
    let romanNumbersRegexPattern =  /^(M{1,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})|M{0,4}(CM|C?D|D?C{1,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})|M{0,4}(CM|CD|D?C{0,3})(XC|X?L|L?X{1,3})(IX|IV|V?I{0,3})|M{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|I?V|V?I{1,3}))$/gm
    let apostropheCharacterRegexPattern = /([\w]+ ?'S{0,1})/gmi;
    let vowels = ['A', 'E', 'I', 'O', 'U'];
    const vowelCheck = (e : string) => vowels.indexOf(e) >= -1;
    let containsNumberRegexPattern = /\d\D/gmi;

    if (agencyName.match(ordinalNumbersRegexPattern)) {
        let m = agencyName.match(ordinalNumbersRegexPattern);
        if (m && m.length > 0) {
            agencyName = agencyName.replace(m[0], m[0].replace(' ', '').toLowerCase());
        }
    }
    if (agencyName.match(apostropheCharacterRegexPattern)) {
        let m = agencyName.match(apostropheCharacterRegexPattern);
        if (m && m.length > 0) {
            agencyName = agencyName.replace(m[0], m[0].charAt(0).toUpperCase() + m[0].substring(1).replace(' ', '').toLowerCase());
        }
    }

    preReplacementRegexPatterns.forEach((value, index, array) => {
        agencyName = agencyName?.replace(value.pattern, value.replacementText);
    });

    let agencyNameParserArray = agencyName.split(' ');
    agencyNameParserArray.forEach((v, i, a) => {
        agencyNameParserArray[i] = v;

        // If the word is a character or contains a number or State Abbreviation or preprocess lowercase character or doesn't contain vowel, then leave them in AS IS state ie., either CAPITALIZED or pre-processed state
        if (v.length === 1 ||
            v.match(containsNumberRegexPattern) ||
            (v.length === 2 && stateAbbreviation.indexOf(v) !== -1) ||
            (v.length === 3 && stateAbbreviation.indexOf(v.replace(",", "")) !== -1) ||
            lowercaseRegexPattern.test(v))
            return;

        // If the word is less than or equal to 4, then CAPITALIZE/Title case except known non acronyms, article or conjunctions.
        if (i === 0 && v.length <= 4) {
            if (knownShortWords.indexOf(v) !== -1 || v.match(articleAndConjunctionsRegexPattern)) {
                agencyNameParserArray[i] = v.charAt(0).toUpperCase() + v.substring(1).toLowerCase();
            }
        } else {
            if (v.match(articleAndConjunctionsRegexPattern)) {
                agencyNameParserArray[i] = v.replace(articleAndConjunctionsRegexPattern, "$1").toLowerCase();
            } else if (v.indexOf('-') !== -1) {
                let hyphenatedWordArray = v.split('-');
                hyphenatedWordArray.forEach((val, ind, arr) => {
                    hyphenatedWordArray[ind] = val.charAt(0).toUpperCase() + val.substring(1).toLowerCase();
                })
                agencyNameParserArray[i] = hyphenatedWordArray.join('-');
            } else if (v.indexOf('&') !== -1) {
                let ampersandWordArray = v.split('&');
                ampersandWordArray.forEach((val, ind, arr) => {
                    ampersandWordArray[ind] = val.charAt(0).toUpperCase() + val.substring(1).toLowerCase();
                })
                agencyNameParserArray[i] = ampersandWordArray.join('&');
            } else if (v.indexOf('/') !== -1) {
                let forwardSlashWordArray = v.split('/');
                forwardSlashWordArray.forEach((val, ind, arr) => {
                    forwardSlashWordArray[ind] = val.charAt(0).toUpperCase() + val.substring(1).toLowerCase();
                })
                agencyNameParserArray[i] = forwardSlashWordArray.join('/');
            } else if (v.split('').some(vowelCheck) === false || romanNumbersRegexPattern.test(v)) {
                agencyNameParserArray[i] = v;
            } else {
                agencyNameParserArray[i] = v.charAt(0).toUpperCase() + v.substring(1).toLowerCase();
            }
        }
        return;
    })

    agencyName = agencyNameParserArray.join(' ');

    postReplacementRegexPatterns.forEach((value, index, array) => {
        agencyName = agencyName?.replace(value.pattern, value.replacementText);
    });

    return agencyName.trim();
}