import { Card, FieldGroup } from "@lmig/lmds-react";
import { Education, Group, Job } from "../../models/Agent";

import '../../styles/AgentInformation.css'
import { ImageFile } from "../context/ProfileDataContext";
import MainPhotoHeader from "../pages/EditAgencyPage/MainPhotoHeader";
import AgentSinceSection from "./sections/AgentSince";
import EducationSection  from "./sections/Education";
import GroupsSection from "./sections/Groups";
import SkillsSection from "./sections/Skills";
import SummarySection from "./sections/Summary";
import WorkExperienceSection from "./sections/WorkExperience";

interface AgentInformationProps {
    groups: Group[];
    education: Education[];
    pastJobs: Job[];
    currentJobs: Job[];
    summary: string;
    skills: string;
    agentSince: string;
    image?: ImageFile;
    updateImage: (image: ImageFile) => void;
    //photoId: string;
    updateSummary: (val:string) => void;
    updateSkills: (val:string) => void;
    updateAgentSince: (val:string) => void;
    updateGroups: (val:Group[]) => void;
    updateEducation: (val:Education[]) => void;
    updatePastJobs: (val:Job[]) => void;
    updateCurrentJobs: (val:Job[]) => void;
    //updatePhoto: (val:any) => void;
}

function AgentInformation(props : AgentInformationProps) {
    return (
        <Card title="About Me">
            <div className='indent-content'>
                <SummarySection summary={props.summary} updateSummary={props.updateSummary}/>
                <SkillsSection skills={props.skills} updateSkills={props.updateSkills}/>
                <GroupsSection groups={props.groups} updateGroups={props.updateGroups}/>
                <WorkExperienceSection pastJobs={props.pastJobs} currentJobs={props.currentJobs} updatePastJobs={props.updatePastJobs} updateCurrentJobs={props.updateCurrentJobs}/>
                <EducationSection education={props.education} updateEducation={props.updateEducation}/>
                <AgentSinceSection agentSince= {props.agentSince} updateAgentSince={props.updateAgentSince}/>
                <FieldGroup className = "filed-group-margin-general" labelStyle="h5" labelVisual="Profile Picture">
                    <MainPhotoHeader image={props.image} updateImage={(image: ImageFile) => props.updateImage(image)} />
                </FieldGroup>
            </div>
        </Card>
    );
}
export default AgentInformation;