import { Checkbox, CheckboxGroup } from '@lmig/lmds-react';
import { Box, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { findPlaceFromPhoneNumber, getPlaceDetails } from '../../../../../services/googleServices';
import { ProfileDataContext } from '../../../../context/ProfileDataContext';
import ModalGoogleReviews from './ModalGoogleReviews';

const ShowGoogleReviews = ()  => {

    const { profileData, showReviews, setShowReviews, gaqDisabled, setGAQDisabled} = useContext(ProfileDataContext);
    let [placeDetails, setPlaceDetails] = useState<google.maps.places.PlaceReview[]>();
    let [place, setPlace] = useState<google.maps.places.PlaceResult>();

    useEffect(() => {
        try {
            setShowReviews(profileData.ShowGoogleReviews);
            setGAQDisabled(profileData.GAQDisabled);
        }
        catch  (err) {
            return;
        }
    }, [profileData.ShowGoogleReviews, setShowReviews, profileData.GAQDisabled, setGAQDisabled, profileData.State]);

    useEffect(() => {
        async function getPlace() {
            var phone = (profileData.PreferredAgencyPhone ? profileData.PreferredAgencyPhone : profileData.AgencyPhone)?.replace(new RegExp(/(\D+)/g), "");
            var data = (await findPlaceFromPhoneNumber(phone) as google.maps.places.PlaceResult[]);

            for (var i = 0; i < data.length; i++) {
                //make sure the place found has some components of the address in common with what we have stored for that agency's address
                //if we update this logic, faa-client should be updated with the same
                if (data[i] && (data[i].formatted_address ?? "").toLocaleLowerCase().indexOf(profileData.AddressLine1.split(' ').slice(0,2).join(' ').toLocaleLowerCase()) > -1) {
                    if (data[i].rating && data[i].user_ratings_total){
                        setPlace(data[i]);
                    }
                }
            }

        }
        getPlace();
    }, [profileData.AgencyPhone]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function getReviews() {
            var placedetes = (await getPlaceDetails(place?.place_id) as google.maps.places.PlaceReview[]);
            setPlaceDetails(placedetes);
        }
        getReviews();
    }, [place]);

    const updateCheckbox = () => {
        setShowReviews(!showReviews);
    }

    const updateQuoteOptions = () => {
        setGAQDisabled(!gaqDisabled);
    }

    const reviewText = (text:string) => {
        var puncIndex = text.indexOf(".");
        if((text.indexOf("?") < puncIndex && text.indexOf("?") !== -1) || puncIndex === -1){
            puncIndex = text.indexOf("?");
        }
        if((text.indexOf("!") < puncIndex && text.indexOf("!") !== -1) || puncIndex === -1){
            puncIndex = text.indexOf("!");
        }
        if(puncIndex === -1){
            return text;
        }
        return text.substring(0,puncIndex+1);
    }

    /*** disable quote option for agencies from California state ***/
    const isDisabled = () => {        
       return profileData.State === 'CA' ?  true : false;
    }

    return (
        <div>
            <div style={{paddingLeft: "30px"}}>
            <div className="edit-btn-align-absolute">
                <ModalGoogleReviews/>
            </div>
            <div>
                {placeDetails
                    ? placeDetails.length === 0
                        ? <div>Google reviews not found.</div>
                        : placeDetails.map((review: google.maps.places.PlaceReview, i) => (
                            <div className="review-row" key={i}>
                                <img style={{height: "40px"}} referrerPolicy="no-referrer" src={review.profile_photo_url} alt={review.author_name} />
                                <div className="review-text" >
                                    <div style={{fontSize: "14px", paddingTop: "8px", paddingLeft: "5px"}}>"{reviewText(review.text)}"</div>
                                    <span className="stars tile-stars" style={{"--rating" : review?.rating ?? 0}  as React.CSSProperties}></span>
                                </div>
                            </div>
                        ))
                    : <div>Google reviews not found.</div>
                }
            </div>
            <div style = {{paddingLeft: "12px"}}>
                <Tooltip title="If Google reviews matching both phone number and address for this profile are found, they will automatically be displayed unless they are disabled here." placement="bottom-start">
                    <Box>
                        <CheckboxGroup onChange={() => updateCheckbox()}>
                            <Checkbox name="googleReview" labelVisual="Display Google Reviews" checked={showReviews} />
                        </CheckboxGroup>
                    </Box>
                </Tooltip>
            </div>
            </div>

            <div style = {{display: isDisabled() ? "none" : ""}}>
                <h2 className="lm-Card-title"> Get a Quote Options</h2>
                <div style = {{paddingLeft: "12px"}}>                
                    <Box>
                        <CheckboxGroup onChange={() => updateQuoteOptions()}>
                            <Checkbox 
                            name="gaqDisabled" 
                            disabled={isDisabled()}
                            labelVisual="Hide Get A Quote Link" 
                            checked={gaqDisabled} />
                        </CheckboxGroup>
                    </Box>                
                </div>
            </div>

        </div>
        
    );
};

export default ShowGoogleReviews;
