import { Checkbox, CheckboxGroup, FieldGroup, Select, SelectOption, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useState } from "react";
import '../../styles/OptionsModal.css'
import { Location } from '../../models/Agent';
import CancelAndSaveButtons from '../modals/CancelAndSaveButtons';
import '../../styles/Modals.css';

interface OfficeLocationModalProps {
    otherOfficeLocations: Location[],
    edit: boolean,
    updateEdit: (val : boolean) => void,
    addressLine1: string,
    primaryChecked: boolean,
    updatePrimaryChecked: (val : boolean) => void,
    selectedOtherOfficeId: any,
    updateOtherOfficeId: (val : any) => void
}

function OfficeLocationModal (props : OfficeLocationModalProps) {
    const [edit, setEdit] = useState<boolean>(props.edit);
    const [primaryChecked, setPrimaryChecked] = useState<boolean>(props.primaryChecked);
    const [selectedOtherOfficeId, setSelectedOtherOfficeId] = useState<any>(props.selectedOtherOfficeId);
    
    const renderAddressOptions = () => {
        return props.otherOfficeLocations.map((location : Location) => {
            return (<SelectOption value={location.Id.toString()}>
                    {location.AddressLine1}
            </SelectOption>);
        });
    }
    
    return (
        <>
          <Modal isOpen={edit} size="small" onClose={() => {setEdit(false); props.updateEdit(false);}}>
            <ModalHeader><Heading className="modal-heading">Edit my office location</Heading></ModalHeader>
            <ModalBody className="modal-body">
                <div>
                    <FieldGroup className="remove-margin-top" labelStyle="h4-light" labelVisual="My Primary Office">  
                    </FieldGroup>
                </div>
                <Select
                    id="select--default"
                    className="select-address"
                    isRequired
                    onChange={(e : any) => {                         
                        setPrimaryChecked(false);  
                        if( primaryChecked ){
                            setSelectedOtherOfficeId(null);
                        } else {
                            setSelectedOtherOfficeId(e.target.value); 
                        }  
                    }}
                    value = { !primaryChecked ? selectedOtherOfficeId : ""}
                    labelVisual="Address 1">
                    {renderAddressOptions()}
                </Select>
                <div>
                    <FieldGroup className="remove-margin-top" >
                        <CheckboxGroup className="checkbox-align" onChange={function onChange(){ 
                            setPrimaryChecked(!primaryChecked); 
                            if(primaryChecked){
                                setSelectedOtherOfficeId(null);
                             } 
                            }}>
                            <Checkbox id="location" labelVisual="I am located at my Agency's primary location." name="default" checked={primaryChecked} />
                        </CheckboxGroup>
                    </FieldGroup>    
                </div>
            </ModalBody>
            <ModalFooter className="modal-footer">
                <CancelAndSaveButtons
                    cancelFunction={() => {setEdit(false); props.updateEdit(false); }}
                    saveFunction={() => {setEdit(false); props.updateEdit(false); props.updateOtherOfficeId(selectedOtherOfficeId); props.updatePrimaryChecked(primaryChecked)}}/>
            </ModalFooter>
          </Modal>
        </>
    )
}
export default OfficeLocationModal;