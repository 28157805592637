import React, { useContext, useEffect, useRef, useState } from 'react';
import { Agency } from '../../models/Agency';
import { SFDataFile } from "../../models/SFDataFile";
import { getAllAgencies, getMatchingAgencies, getMatchingDropdownAgencies, getSFFileDetails } from '../../services/FAAApi';
import { AlphanumericInput, Badge, Button, Heading, IconButton, IconClose, Table, TableRow, TableCell, FieldGroup } from '@lmig/lmds-react';
import { Link } from 'react-router-dom';
import '../../styles/Home.css'
import { formatAgencyName } from '../../helpers/StringFormatter';
import UploadSFDataModal from "../../components/modals/UploadSFDataModal";
import { CircularProgressSpinner } from '@lmig/sales-ui-components';
import { AgenciesContext } from '../context/AgenciesContext';
import { UserContext } from '../context/UserContext';

function Home() {

    const [listAgencies, setListAgencies] = useState<Agency[]>([]);
    const [filteredAgencies, setFilteredAgencies] = useState<Agency[]>([]);
    const [groupedAgencies, setGroupedAgencies] = useState<{ [key: string]: Agency[] }>({});
    const [sortCategory, setSortCategory] = useState<string>('name');
    const [sortAscending, setSortAscending] = useState<boolean>(true);
    const [dropdownAgencies, setDropdownAgencies] = useState<Agency[]>([]);
    const [tableContents, setTableContents] = useState<JSX.Element | JSX.Element[]>();
    const [sfDataUploadAccess, setSFDataUploadAccess] = useState<boolean>(false);
    const [sfFileDetails, setSFileDetails] = useState<SFDataFile>();
    const { agencies, setAgencies, setAgencyId, searchTerm, setSearchTerm } = useContext(AgenciesContext);
    const [searchBar, setSearchBar] = useState<string | null>(null);
    const { user, setUser, loaded } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const searchBox = useRef<HTMLInputElement>(null);

    useEffect(() => {
        document.title = "Agency Profiles List";
        const getAgencies = async () => {
            try {
                setIsLoading(true);
                await getAllAgencies()
                    .then((response) => {
                        setListAgencies(response.agencies.ListItems ?? []);
                        setFilteredAgencies(response.agencies.ListItems ?? []);
                        setAgencies(response.agencies.ListItems ?? null);

                        if (!loaded)
                            setUser(response.agencies.User)
                    }).catch(err => { setIsLoading(false); setAgencies([]) });
            } catch (e) {
                setIsLoading(false);
                setAgencies([]);
            }
        }

        if (agencies)
            setListAgencies(agencies);
        else if (!isLoading && user.Type === 'AGENT') //employees will initially be type AGENT until this lookup returns their user type
            getAgencies();

    }, [agencies, user, loaded, setAgencies, setUser, isLoading]);

    useEffect(() => { //update isLoading only once agencies or user is initialized to prevent making redundant requests
        if (user.Type === 'AGENT' && isLoading && (agencies || loaded))
            setIsLoading(false);
    }, [agencies, isLoading, loaded, user.Type]);

    useEffect(() => {
        setTableContents(toTableBody());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupedAgencies]);

    //Default sorting
    useEffect(() => {
        groupAndSort('name', true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredAgencies]);

    useEffect(() => {
        if (searchBar)
            handleFilter(searchBar);
        else if (agencies)
            setFilteredAgencies(agencies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listAgencies]);

    useEffect(() => {
        //keep search term up to date with searchbar so that we can navigate to other pages and then come back to the same results
        if (searchTerm && searchBar === null && searchBar !== searchTerm)
            setSearchBar(searchTerm);
    }, [searchBar, searchTerm, setSearchTerm])

    //Sorts groups by the specified value in ascending order
    const groupSortAscending = (sortBy: String, agencies: { [key: string]: Agency[] }) => {
        var groupSortedMap: { [key: string]: Agency[] } = {};
        var groupSortOrder: string[] = [];
        
        if (sortBy === 'code') {
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                return (agencies[a][0].AgencyId.toString().substring(8, 16)).localeCompare(agencies[b][0].AgencyId.toString().substring(8, 16));
            });
        }
        else if (sortBy === 'name') {
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                return agencies[a][0].AgencyName.localeCompare(agencies[b][0].AgencyName);
            });
        }
        else if (sortBy === 'percent') {
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                return agencies[a][0].PercentComplete - agencies[b][0].PercentComplete;
            });
        }
        else if (sortBy === 'address') {
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                return agencies[a][0]?.AddressLine1?.localeCompare(agencies[b][0]?.AddressLine1 ?? "") ?? -1;
            });
        }
        else { //sortBy === Date
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                if (agencies[a][0].ModifiedDate === '') {
                    return 1;
                }
                if (agencies[b][0].ModifiedDate === '') {
                    return -1;
                }
                var d1 = Date.parse(agencies[a][0].ModifiedDate);
                var d2 = Date.parse(agencies[b][0].ModifiedDate);
                return d2 - d1;
            });
        }
        for (var i = 0; i < groupSortOrder.length; i++) {
            groupSortedMap[' ' + groupSortOrder[i]] = agencies[groupSortOrder[i]];
        };
        return groupSortedMap;
    };

    //Sorts groups by the specified value in descending order
    const groupSortDescending = (sortBy: String, agencies: { [key: string]: Agency[] }) => {
        var groupSortedMap: { [key: string]: Agency[] } = {};
        var groupSortOrder: string[] = [];
        if (sortBy === 'code') {
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                return (agencies[b][0].AgencyId.toString().substring(8, 16)).localeCompare(agencies[a][0].AgencyId.toString().substring(8, 16));
            });
        }
        else if (sortBy === 'name') {
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                return agencies[b][0].AgencyName.localeCompare(agencies[a][0].AgencyName);
            });
        }
        else if (sortBy === 'percent') {
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                return agencies[b][0].PercentComplete - agencies[a][0].PercentComplete;
            });
        }
        else if (sortBy === 'address') {
            groupSortOrder = Object.keys(agencies).sort((a: string, b: string) => {
                return agencies[b][0]?.AddressLine1?.localeCompare(agencies[a][0]?.AddressLine1 ?? "") ?? -1;
            });
        }
        else { //sortBy === Date
            groupSortOrder = Object.keys(agencies).sort(function (a: string, b: string) {
                if (agencies[a][0].ModifiedDate === '') {
                    return 1;
                }
                if (agencies[b][0].ModifiedDate === '') {
                    return -1;
                }
                var d1 = Date.parse(agencies[a][0].ModifiedDate);
                var d2 = Date.parse(agencies[b][0].ModifiedDate);
                return d1 - d2;
            });
        }
        for (var i = 0; i < groupSortOrder.length; i++) {
            groupSortedMap[' ' + groupSortOrder[i]] = agencies[groupSortOrder[i]];
        };
        return groupSortedMap;
    };

    //Sorts within groups by date, or other values if date is not available
    const valueSort = (agencies: Agency[]) => {
        var tempArray = [];
        tempArray = agencies.sort((a: Agency, b: Agency) => {
            var d1 = 0;
            var d2 = 0;
            if(a.ModifiedDate !== ""){
                d1 = Date.parse(a.ModifiedDate)
            }
            if( b.ModifiedDate !== ""){
                d2 = Date.parse(b.ModifiedDate)
            }
            return d2 - d1;
        });
        var modifiedAgencies = tempArray.filter((agency)=>{
            return agency.ModifiedDate !== "";
        })

        var unmodifiedAgencies = tempArray.filter((agency)=>{
            return agency.ModifiedDate === "";
        })
        unmodifiedAgencies = unmodifiedAgencies.sort((a: Agency, b: Agency) => {
            return b.AgencyId - a.AgencyId;
        });
        var repAgency;
        for (var i = 0; i < unmodifiedAgencies.length; i++) {
            if (unmodifiedAgencies[i].AgencyLocationIndicator === true) {
                repAgency = unmodifiedAgencies.splice(i, 1);
                unmodifiedAgencies.unshift(repAgency[0]);
            }
        }
        return modifiedAgencies.concat(unmodifiedAgencies);
    };

    //updates search bar contents on change
    const handleChange = (term: string) => {
        if (term.length >= 3)
            setError(false);
        setSearchBar(term);

        if (term.match(/[1-9]-/))
            term = term.replace(/-/g, "");

        if (term.length > 2 && user.Type !== "AGENT") {
            try {
                getMatchingDropdownAgencies(encodeURIComponent(term))
                    .then((response) => {
                        setDropdownAgencies(response);
                    }).catch(err => {
                        //console.log(err);
                    });
            } catch (e) {

            }
        }

        if (user.Type === "AGENT") {
            handleFilter(term);
        }
    }

    const clearSearch = () => {
        setSearchTerm("");
        setSearchBar("");
        if (searchBox.current)
            searchBox.current.focus();
        if (user.Type === "AGENT")
            handleFilter("");
        else
            setError(false)
    }

    //Filters all entries by the current search bar value
    const handleFilter = (e: string) => {
        //store search term on filter so we can navigate back to the same filtered results from another tab
        if (e && e !== searchTerm)
            setSearchTerm(e);

        var filteredArray = listAgencies.filter((item: Agency) => {
            let agencyName: string = item.AgencyName.replaceAll('.', '').toLowerCase();
            //treat and/& as synonyms
            agencyName = e.toLowerCase().includes('&') ? agencyName.replace(/ and/g, ' &') : agencyName;
            agencyName = e.toLowerCase().includes(' and') ? agencyName.replace(/&/g, 'and') : agencyName;
			agencyName = e.toLowerCase().includes(' agency') ? agencyName.replace(' agy', ' agency') : agencyName;
            agencyName = e.toLowerCase().includes(' service') ? agencyName.replace(' svc', ' service') : agencyName;
            agencyName = e.toLowerCase().includes(' management') ? agencyName.replace(' mgmt', ' management') : agencyName;
            agencyName = e.toLowerCase().includes(' group') ? agencyName.replace(' grp', ' group') : agencyName;
            agencyName = e.toLowerCase().includes(' inc.') ? agencyName.replace(' in', ' inc.') : agencyName;

            return (agencyName.includes(e.replaceAll('.', '').toLowerCase())
                || (item.AgencyId.toString().substring(8, 16)).includes(e.replaceAll('-', '').toLowerCase()));
        })

        var Agencies : Agency[] = [];
        var isAdded : boolean;
        
        listAgencies.forEach((item: Agency)=>{
            isAdded = false;
            var isMatchedFound = false;
            filteredArray.forEach((filteredItem: Agency)=>{
                //need to iterate through entirearray first, otherwise future items will be missed
                if ( item.AgencyLocationId === filteredItem.AgencyLocationId && filteredItem.AgencyLocationId !== ""){
                    if (item.AgencyId !== filteredItem.AgencyId){
                        if (isAdded === false ){
                            isAdded = true;
                        } 
                    }else {
                        isMatchedFound = true;
                    }
                }   
            });
            if(isAdded && isMatchedFound === false){
                Agencies.push(item);
            }
        });
        Agencies.forEach((item: Agency)=>{
            filteredArray.push(item);
        })
        setFilteredAgencies(filteredArray);
    }

    const handleKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && user.Type !== "AGENT") //only execute searches for employees
            handleSearch();
    }

    const handleSearch = () => { //search is only used for employees
        setDropdownAgencies([]);
        if (searchBar && searchBar.length < 3 && user.Type !== "AGENT") {
            setError(true);
            return;
        }

        //store searchbar in search term on search so we can navigate back to the same results from another tab
        if (searchBar && searchBar !== searchTerm)
            setSearchTerm(searchBar);

        let e: string | undefined = searchBar?.replace(/\.|-/g, "");
        if (e) {
            try {
                setIsLoading(true);
                getMatchingAgencies(e)
                    .then((response) => {
                        if (dropdownAgencies.length) //close this if it sticks open once results are back
                            setDropdownAgencies([]);
                        setAgencies(response.agencies.ListItems);
                        setFilteredAgencies(response.agencies.ListItems ?? []);
                        setListAgencies(response.agencies.ListItems ?? []);
                        setIsLoading(false);
                    }).catch(err => { setAgencies([]); setIsLoading(false); });
            } catch (e) {
                setIsLoading(false);
                setAgencies([]);
            }
        }

    }

    //Groups all entries by PhysicalLocationId, or AgencyId if not available
    const groupAgencies = () => {
        var groupedMap: { [key: string]: Agency[] } = {};
        var filteredArray = JSON.parse(JSON.stringify(filteredAgencies));
        var key;
        filteredArray.map((item: Agency) => 
        { 
            if (item.AgencyLocationId !== '') {
                key = item.AgencyLocationId;
                
            }
            else {
                key = item.AgencyId;
            }
            if (!groupedMap[item.AgencyLocationId] && !groupedMap[item.AgencyId]) {
                groupedMap[key] = [];
            }
            groupedMap[key].push(item);
            return (groupedMap);
        }, {});
        return (groupedMap);
    }

    //Manages grouping, value sort, and group sort
    //Runs by default on page start and then whenever header carets are selected
    const groupAndSort = (sortType: string, onLoad: boolean) => {
        var groupedMap = groupAgencies();
        var keySortedMap: { [key: string]: Agency[] } = {};
        var valueSortedMap: { [key: string]: Agency[] } = {};

        Object.keys(groupedMap).forEach(key => {
            valueSortedMap[key]= valueSort(groupedMap[key]);
        });

        var sortDirection: boolean = false;
        if (sortType === sortCategory && onLoad === true) {
            sortDirection = sortAscending;
        }
        else if (sortType === sortCategory) {
            sortDirection = (sortAscending ? false : true);
        }
        else {
            setSortCategory(sortType);
            sortDirection = true;
        }
        setSortAscending(sortDirection);
         

        if (sortDirection) {
            keySortedMap = groupSortAscending(sortType, valueSortedMap);
        }
        else {
            keySortedMap = groupSortDescending(sortType, valueSortedMap);
        }

        setGroupedAgencies(keySortedMap);
    }
    /*---------------- Callback Functions --------------------------*/
    const updateSFDataUploadModalAccess = (sfDataUploadAccess: boolean) => {
        setSFDataUploadAccess(sfDataUploadAccess);
    }
    /*---------------- End Callback Functions ----------------------*/

    const swapSearchBar = () => {
        return (
            <div className="searchBar">
                <FieldGroup
                    className="search-input"
                    id="fieldgroup--error"
                    messages={error ? [
                    {
                        text: 'A minimum of three characters are required to complete a search',
                        type: 'error'
                    }] : []}
                >
                <AlphanumericInput
                    highlightType={error ? "error" : undefined}
                    value={searchBar ?? ""}
                    onBlur={(e) => setDropdownAgencies([])}
                    labelVisual={user.Type === 'AGENT' ? "Filter by agency name or agency code" : "Search by agency name or agency code"}
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyUp={handleKeyPressSearch}
                    innerRef={searchBox}>
                    {searchBar
                        ? <IconButton onClick={clearSearch} className="clearButton">
                            <IconClose size="16" />
                        </IconButton>
                        : null}
                </AlphanumericInput></FieldGroup>
                {user.Type === 'EMPLOYEE' && <>
                    {dropdownAgencies.length > 0 ? <div className="agency-search-dropdown">
                        {searchBar && searchBar.length > 2 &&
                            dropdownAgencies.slice(0, 10).map((item, index) => {
                                return (<div className="agency-search-dropdown-item" key={index}>
                                    <div onMouseDown={(e) => { setSearchBar(formatAgencyName(item.AgencyName)); setDropdownAgencies([]); handleSearch(); }}>
                                        <div>{formatAgencyName(item.AgencyName)}</div>
                                        <div className="agency-search-dropdown-agency-code">{item.AgencyId.toString().substring(8, 16)}</div>
                                    </div>
                                </div>);
                            })}
                    </div> : null}
                    <Button size="large" variant="primary" onClick={handleSearch}>
                        Search
                    </Button>
                    {user.IsFaaLmItAdmin && <><Button style={{ "marginLeft": "auto" }} size="large" variant="primary" onClick={async () => {
                        try {
                            await getSFFileDetails()
                                .then((response) => {
                                    setSFileDetails(response);
                                    setSFDataUploadAccess(true);
                                }).catch(err => {
                                    //console.log(err);
                                });
                        } catch (e) {
                            throw e;
                        }
                    }}>
                        Update Salesforce Data
                    </Button>
                        {sfDataUploadAccess ? <UploadSFDataModal sfDataUploadModalOpen={sfDataUploadAccess} updateSFDataUploadModalOpen={updateSFDataUploadModalAccess} options={sfFileDetails ? sfFileDetails : []} title="Update Salesforce data" ></UploadSFDataModal> : <></>}
                    </>
                    }</>}
            </div>
        );
    }

    const spacerRow = (classname: string) => {
        return (
            <TableRow key='' className={classname}>
                <TableCell className="spacerCell" dataHeader="profileStatus"></TableCell>
                <TableCell className="spacerCell" dataHeader="agencyName"></TableCell>
                <TableCell className="spacerCell" dataHeader="addressLine"></TableCell>
                <TableCell className="spacerCell" dataHeader="lastModified"></TableCell>
                <TableCell className="spacerCell" dataHeader="percentComplete"></TableCell>
                <TableCell className="spacerCell" dataHeader="agencyId"></TableCell>
            </TableRow>
        )
    }

    //Creates a tbody for each group and adds whitespace between groups
    const toTableBody = () => {
        if (Object.keys(groupedAgencies).length === 0) {
            return (
                <tbody>
                    <TableRow>
                        <td colSpan={6} className="noMatches">No Agencies Found</td>
                    </TableRow>
                </tbody>
            );
        }
        else {
            return Object.keys(groupedAgencies).map((key, index, arr) => {
                if (index === arr.length - 1) {
                    return (
                        <React.Fragment key={key + ' fragment'}>
                            <tbody className='spacerBody' key={key + 'spacer'}>
                                {spacerRow('')}
                            </tbody>
                            <tbody className="agencyBox" key={key}>
                                {toTableRow(key)}
                            </tbody>
                        </React.Fragment>
                    );
                }
                else {
                    return (
                        <React.Fragment key={key + ' fragment'}>
                            <tbody className='spacerBody' key={key + 'spacer'}>
                                {spacerRow('')}
                            </tbody>
                            <tbody className="agencyBox" key={key}>
                                {toTableRow(key)}
                            </tbody>
                            <tbody className='spacerBody' key={key + 'spacer2'}>
                                {spacerRow('')}
                            </tbody>
                        </React.Fragment>
                    );
                }
            });
        }
    }

    const renderTableBody = () => {
        return tableContents;
    }

    //Creates rows for each entry in a groups
    const toTableRow = (key: string) => {
        var i = 0;
        return groupedAgencies[key].map((item) => {
            i++;
            if (groupedAgencies[key].length === 1) {
                return (
                    <React.Fragment key={item.AgencyId + 'rowFragment'}>
                        <TableRow key={item.AgencyId} className="firstRow">
                            <TableCell dataHeader="profileStatus" className='tableCell'>
                                {item.Show === false
                                    ? <Badge className='statusBadge' highlightType="informative">Hidden</Badge>
                                    : ( item.Show === true &&  item.ModifiedDate )
                                        ? <Badge className='statusBadge' highlightType="positive">Published</Badge>
                                        : <Badge className='statusBadge' highlightType="caution">Not Started</Badge>
                                }
                            </TableCell>
                            <TableCell dataHeader="agencyName" className='tableCell'>
                                <Link onClick={() => setAgencyId(item.AgencyId)} to={`${window.env.AGENCY_PROFILE_PATH}${item.AgencyId}`}>{formatAgencyName(item.AgencyName)}</Link>
                            </TableCell>
                            <TableCell dataHeader="addressLine" className='tableCell'>
                                {item.AddressLine1}
                            </TableCell>
                            <TableCell dataHeader="lastModified" className='tableCell'>
                                {item.ModifiedDate}
                            </TableCell>
                            <TableCell dataHeader="percentComplete" className='tableCell'>
                                { item.ModifiedDate ? ( item.PercentComplete === 0 ? "25%" : item.PercentComplete + "%" ) : "Base Profile"}
                            </TableCell>
                            <TableCell dataHeader="agencyId" className='tableCell'>
                                {item.AgencyId.toString().substring(8, 10) + '-' + item.AgencyId.toString().substring(10, 12) + '-' + item.AgencyId.toString().substring(12,)}
                            </TableCell>
                        </TableRow>
                        {spacerRow('spacerRow')}
                    </React.Fragment>
                );
            }
            else {
                return (
                    <TableRow key={item.AgencyId} className={i > 1  ? "grayOut" : ""}>
                        <TableCell dataHeader="profileStatus" className='tableCell'>
                            <div style={{ opacity: i > 1 ? 0.4 : 1 }}>
                                {item.Show === false
                                    ? <Badge className='statusBadge' highlightType="informative">Hidden</Badge>
                                    : item.ProfileStatus === 'Published'
                                        ? <Badge className='statusBadge' highlightType="positive">Published</Badge>
                                        : <Badge className='statusBadge' highlightType="caution">Not Started</Badge>
                                }
                            </div>
                        </TableCell>
                        <TableCell dataHeader="agencyName" className='tableCell'>
                            <Link onClick={() => setAgencyId(item.AgencyId)} style={{ color: i > 1 ? "gray" : "" }} to={`${window.env.AGENCY_PROFILE_PATH}${item.AgencyId}`}>{formatAgencyName(item.AgencyName)}</Link>
                        </TableCell>
                        <TableCell dataHeader="addressLine" className='tableCell'>
                            {item.AddressLine1}
                        </TableCell>
                        <TableCell dataHeader="lastModified" className='tableCell'>
                            {item.ModifiedDate}
                        </TableCell>
                        <TableCell dataHeader="percentComplete" className='tableCell'>
                            { item.ModifiedDate ? ( item.PercentComplete === 0 ? "25%" : item.PercentComplete + "%" ) : "Base Profile"}
                        </TableCell>
                        <TableCell dataHeader="agencyId" className='tableCell'>
                            {item.AgencyId.toString().substring(8, 10) + '-' + item.AgencyId.toString().substring(10, 12) + '-' + item.AgencyId.toString().substring(12,)}
                        </TableCell>
                    </TableRow>
                )
            }
        });
    };

    return (
        <>
            {swapSearchBar()}
            <Heading type="h3" className='safeco-color'>Agency List</Heading>
            <Table className="agency-list-table" >
                <thead className="table-header-box">
                    <TableRow>
                        <TableCell dataHeader="profileStatus" type="colHead">
                            <span className="table-header">
                                <div className="margin-text">FAA Profile</div>
                            </span>
                        </TableCell>
                        <TableCell dataHeader="agencyName" type="colHead">
                            <span className="table-header">
                                <div className="margin-text-4" onClick={() => groupAndSort('name', false)}>Agency Name</div>
                            </span>
                        </TableCell>
                        <TableCell dataHeader="addressLine" type="colHead">
                            <span className="table-header">
                                <div className="margin-text-4" onClick={() => groupAndSort('address', false)}>Address</div>
                            </span>
                        </TableCell>
                        <TableCell dataHeader="lastModified" type="colHead">
                            <span className="table-header">
                                <div className="margin-text-4" onClick={() => groupAndSort('date', false)}>Last Modified</div>
                            </span>
                        </TableCell>
                        <TableCell dataHeader="percentComplete" type="colHead">
                            <span className="table-header">
                                <div className="margin-text-4" onClick={() => groupAndSort('percent', false)}>% Complete</div>
                            </span>
                        </TableCell>
                        <TableCell dataHeader="agencyId" type="colHead">
                            <span className="table-header">
                                <div className="margin-text-4" onClick={() => groupAndSort('code', false)}>Agency Code</div>
                            </span>
                        </TableCell>
                    </TableRow>
                </thead>
                {!isLoading && (user.Type === "AGENT" ? toTableBody() : renderTableBody())}
            </Table>
            {isLoading && <div className="table-loading-spinner"><CircularProgressSpinner aria-label="Loading Data" size="64" /></div>}
        </>
    );
}

export default Home;