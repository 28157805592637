import { Card, Heading, IconButton, IconEdit, IconFacebook, IconLinkedin, IconAddItem } from "@lmig/lmds-react";
import { useState } from "react";
import SocialMediaModal from "../modals/SocialMediaModal";



interface SocialMediaProps {
    facebook: string,
    linkedIn: string,
    updateFacebook: (val : string) => void,
    updateLinkedIn: (val : string) => void,
}



function SocialMedia(props : SocialMediaProps) {
    const [edit, setEdit] = useState<boolean>(false);


    const linksPresent = () => {
        if (props.facebook !== "" || props.linkedIn !== "") {
            return true;
        }
        return false;
    }

    return (
        <Card title = "Social Media">
                    <div className='indent-content-less'>
                            { (props.facebook !== "" &&  props.facebook !== null ) ?
                                 <Heading type="h6-small" className="li" style={{marginLeft:"10px"}} ><IconFacebook size="16" style={{marginRight: "10px"}} className="icon-normal-size"/> {props.facebook}</Heading>
                                :
                                <div> <span onClick={() => setEdit(true) } className="add-item-link">
                                            <IconButton id="socialMediaModalButton">
                                                <IconAddItem size="24" />
                                            </IconButton>Add Facebook page
                                    </span>
                                </div>
                            }
                            { (props.linkedIn !== "" && props.linkedIn !== null) ?
                                    <Heading type="h6-small" className="li" style={{marginLeft:"10px"}}  ><IconLinkedin size="16" style={{marginRight: "10px"}} className="icon-normal-size"/> {props.linkedIn}</Heading>
                                :
                                <div><span onClick={() => setEdit(true) } className="add-item-link">
                                            <IconButton>
                                                <IconAddItem size="24" />
                                            </IconButton>Add LinkedIn page
                                    </span>
                                </div>
                            }
                    </div>
                {linksPresent() ?
                        <div className="edit-btn-align-absolute">
                            <IconButton id="socialMediaModalButton" onClick={()=>{setEdit(true)}}>
                                <IconEdit className="icon-edit-custom-size" size="24"/>
                            </IconButton>
                        </div>
                    : null}
            {edit ?
                    <SocialMediaModal edit={true} facebook={props.facebook} linkedIn={props.linkedIn} updateFacebook={props.updateFacebook} updateLinkedIn={props.updateLinkedIn} updateEdit={setEdit}/>
                    : <></>
            }
        </Card>
    )
}

export default SocialMedia;