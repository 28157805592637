import { FieldGroup, GridCol, Heading } from "@lmig/lmds-react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from '@lmig/lmds-react-modal';
import { Textarea } from '@lmig/lmds-react-textarea';
import _ from "lodash";
import { useState } from "react";
import { useContext } from "react";
import { AgentDataContext } from "../context/AgentDataContext";
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import '../../styles/Modals.css';

interface AgentNotesModalNonAdminProps {
  publish: any,
  notes: string,
  profileAdmins: any,
  edit: boolean,
  updateEdit: (val: boolean) => void,
  cancel: any,
}

function AgentNotesModal(this: any, props: AgentNotesModalNonAdminProps) {
  const [edit, setEdit] = useState<boolean>(props.edit);
  const { notes, setNotes } = useContext(AgentDataContext);
  const [errorMessageArray, setErrorMessageArray] = useState<string[]>([]);
  const [textAreaCount, setTextAreaCount] = useState(0);
  const { profileAdmins, setProfileAdmins } = useContext(AgentDataContext);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const checkForErrors = () => {
    let errors: string[] = [];
    if (notes === "") {
      errors.push("Note is required.");
    }
    if (notes.length > 200) {
      errors.push("You have exceeded the character limit on notes.");
    }

    setErrorMessageArray(errors);

    return errors.length;
  };

  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Modal
      isOpen={edit}
      size="small"
      onClose={() => { setEdit(false); props.updateEdit(false); props.cancel() }}
    >
      <ModalHeader><Heading className="modal-heading">Submit profile for approval</Heading></ModalHeader>
      <ModalBody className="modal-body">
        <GridCol base={12}>
          <FieldGroup isFull id="formField" style={{ marginTop: errorMessageArray.length ? ".5rem" : "0rem" }}
            messages={
              errorMessageArray.map((error) => {
                return {
                  text: error,
                  type: 'error'
                };

              })
            }
          >
            <p>Please select an approver(s) at your agency to review and approve your profile for publishing.</p>
            <FormControl sx={{ m: 0, width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Select your approver(s)</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Select your approver(s)" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {profileAdmins.map((name) => (
                  <MenuItem key={name.FullName} value={name.FullName}>
                    <Checkbox checked={personName.indexOf(name.FullName) > -1} />
                    <ListItemText primary={name.FullName + " , " + name.EmailAddress} onSelect={(e: any) => { setProfileAdmins(_.cloneWith(e.target.value)); setTextAreaCount(e.target.value.length) }} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p>Please enter notes associated with this change.({textAreaCount}/200 character max)</p>
            <Textarea className="textarea-general" placeholder="Please enter your notes here." rows={4} cols={54} value={notes} onChange={(e: any) => { setNotes(e.target.value); setTextAreaCount(e.target.value.length) }}>
            </Textarea>
            <p>Are you sure you want to submit your profile for approval?</p>
          </FieldGroup>
        </GridCol>
      </ModalBody>
      <ModalFooter className="modal-footer">
        <CancelAndSaveButtons
          saveTitle="Submit"
          cancelFunction={() => { setEdit(false); props.updateEdit(false); props.cancel() }}
          saveFunction={(e: any) => {
            if (checkForErrors() > 0) return; setEdit(false); props.updateEdit(false);
            setNotes(_.cloneDeep(notes)); setProfileAdmins(profileAdmins); props.publish()
          }} />
      </ModalFooter>
    </Modal>
  )
}
export default AgentNotesModal;
