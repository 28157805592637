import { IconButton, AlphanumericInput, GridCol, GridRow, IconTrash, Badge, BodyText, FieldGroup, Heading, List, ListItem } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useContext, useState, useEffect, useRef } from "react";
import _ from 'lodash';
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import AddItem from "../../../../../helpers/AddItem";
import CancelAndSaveButtons from "../../../../modals/CancelAndSaveButtons"
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import '../../../../../styles/Modals.css';

type Award = {
    name: string,
    yearRecieved: string
}

type error = {
    name: string[],
    yearRecieved: string[]
};

const ModalAwards = () => {
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    const [errorAwardMessageArray, setErrorAwardMessageArray] = useState<error[]>([{ name: [], yearRecieved: [] }, { name: [], yearRecieved: [] }, { name: [], yearRecieved: [] }]);
    const { awards, setAwards } = useContext(ProfileDataContext);
    const [copy, setCopy] = useState<Award[]>(awards);

    
    const [affiliationOptions, setAffiliationOptions] = useState<string[]>(['CPCU', 'NAIFA', 'PIA', 'NAHU', 'IIABA']);
    const [duplicateAffiliations, setDuplicateAffiliations] = useState(false);
    const [numAdditionalAffiliations, setNumAdditionalAffiliations] = useState(0);
    const { affiliations, setAffiliations } = useContext(ProfileDataContext);
    const [errorAffiliationsMessageArray, setErrorAffiliationsMessageArray] = useState<string[][]>([[],[],[],[],[],[],[],[],[],[]]);
    const [copyAffiliations, setCopyAffiliations] = useState<string[]>(affiliations);

    /*ref elements used for focusing*/
    const award1= useRef<HTMLInputElement>(null);
    const award2= useRef<HTMLInputElement>(null);
    const award3= useRef<HTMLInputElement>(null);
    const affl1= useRef<HTMLInputElement>(null);
    const affl2 = useRef<HTMLInputElement>(null);
    const affl3 = useRef<HTMLInputElement>(null);
    const affl4 = useRef<HTMLInputElement>(null);
    const affl5 = useRef<HTMLInputElement>(null);
    const affl6= useRef<HTMLInputElement>(null);
    const affl7 = useRef<HTMLInputElement>(null);
    const affl8 = useRef<HTMLInputElement>(null);
    const affl9 = useRef<HTMLInputElement>(null);
    const affl10 = useRef<HTMLInputElement>(null);

    const [awardName1Error, setAwardName1Error] = useState(false);
    const [awardYear1Error, setAwardYear1Error] = useState(false);
    const [awardName2Error, setAwardName2Error] = useState(false);
    const [awardYear2Error, setAwardYear2Error] = useState(false);
    const [awardName3Error, setAwardName3Error] = useState(false);
    const [awardYear3Error, setAwardYear3Error] = useState(false);

    const [affi1Error, setAffi1Error] = useState(false);
    const [affi2Error, setAffi2Error] = useState(false);
    const [affi3Error, setAffi3Error] = useState(false);
    const [affi4Error, setAffi4Error] = useState(false);
    const [affi5Error, setAffi5Error] = useState(false);
    const [affi6Error, setAffi6Error] = useState(false);
    const [affi7Error, setAffi7Error] = useState(false);
    const [affi8Error, setAffi8Error] = useState(false);
    const [affi9Error, setAffi9Error] = useState(false);
    const [affi10Error, setAffi10Error] = useState(false);

    const fieldRef  = useRef<HTMLInputElement>(null);
    const handleClick = () => {
        fieldRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        if(copy.length === 1){
            award1.current?.focus();
        }else if(copy.length === 2){
            award2.current?.focus();
        }else if(copy.length === 3){
            award3.current?.focus();       
        }else {
            award1.current?.focus();
        }
    }, [copy.length]);

    useEffect(() => {
    if(copyAffiliations.length === 1){
        affl1.current?.focus();
    }else if(copyAffiliations.length === 2){
        affl2.current?.focus();
    }else if(copyAffiliations.length === 3){
        affl3.current?.focus();        
    }else if(copyAffiliations.length === 4){
        affl4.current?.focus();
    }else if(copyAffiliations.length === 5){
        affl5.current?.focus();        
    }else if(copyAffiliations.length === 6){
        affl6.current?.focus();
    }else if(copyAffiliations.length === 7){
        affl7.current?.focus();        
    }else if(copyAffiliations.length === 8){
        affl8.current?.focus();
    }else if(copyAffiliations.length === 9){
        affl9.current?.focus();        
    }else if(copyAffiliations.length === 10){
        affl10.current?.focus();
    }else {
        affl1.current?.focus();  
    }
    }, [copyAffiliations.length]);

    useEffect(() => {
        if (affiliations)
            setCopyAffiliations(affiliations);
    }, [affiliations]);

    useEffect(() => {
        const originalOptions: string[] = ['CPCU', 'NAIFA', 'PIA', 'NAHU', 'IIABA'];
        const additionalAffiliations = copyAffiliations.filter(el => !originalOptions.includes(el));
        const updatedAffiliations = originalOptions.filter(el => !copyAffiliations.includes(el));
        setAffiliationOptions([...updatedAffiliations])
        setNumAdditionalAffiliations(additionalAffiliations.length);
        setDuplicateAffiliations(new Set(copyAffiliations).size !== copyAffiliations.length);//check for duplicates
    }, [copyAffiliations]);

    const showAffiliations = () => {
        return (
            <List compact>
            {affiliations.map((affiliation) => {
                if (affiliation !== '')
                    return (<ListItem key={affiliation}>{`${affiliation}`}</ListItem>)
                else
                    return <></>

            })}
            </List>
        )
    }

    const showAwards = () => {
        return (
            <List compact>
            {awards.map((award) => {
                if (award.name !== '')
                    return (<ListItem key={award.name}>{`${award.name} ${award.yearRecieved}`}</ListItem>)
                else
                    return <></>

            })}
            </List>
        )            
    }

    const checkForErrors = () => {
        let errors: error[] = [{ name: [], yearRecieved: [] }, { name: [], yearRecieved: [] }, { name: [], yearRecieved: [] }];
        copy.forEach((award, index) => {
            if (award.name === "") {
                errors[index].name.push(`Award${index+1}: Must have a name`);               
            } 
            if (award.name.length > 100) {
                errors[index].name.push(`Award${index+1}: You have exceeded the character limit on award name.`);
            } 

            if (award.yearRecieved === "" || award.yearRecieved.length !== 4) {
                errors[index].yearRecieved.push(`Award${index+1}: Must be four digits`);
            } 
            if ( award.yearRecieved.length === 4 && Number(award.yearRecieved) > new Date().getFullYear() + 1) {
                errors[index].yearRecieved.push(`Award${index+1}: Year < ${new Date().getFullYear() + 1}`)
            }
            if (award.yearRecieved.length === 4 && Number(award.yearRecieved) < 1900) {
                errors[index].yearRecieved.push(`Award${index+1}: Year > 1900`)
            }
            
            if(index===0 && errors[index].name.length > 0)
            {
                setAwardName1Error(true);
            } else if(index===0 && errors[index].name.length === 0){
                setAwardName1Error(false);
            }
            if(index===1 && errors[index].name.length > 0)
            {
                setAwardName2Error(true);
            } else if(index===1 && errors[index].name.length === 0){
                setAwardName2Error(false);
            }
            if(index===2 && errors[index].name.length > 0)
            {
                setAwardName3Error(true);
            } else if(index===2 && errors[index].name.length === 0){
                setAwardName3Error(false);
            }

            if(index===0 && errors[index].yearRecieved.length > 0)
            {
                setAwardYear1Error(true);
            } else if(index===0 && errors[index].yearRecieved.length === 0){
                setAwardYear1Error(false);
            }
            if(index===1 && errors[index].yearRecieved.length > 0)
            {
                setAwardYear2Error(true);
            } else if(index===1 && errors[index].yearRecieved.length === 0){
                setAwardYear2Error(false);
            }
            if(index===2 && errors[index].yearRecieved.length > 0)
            {
                setAwardYear3Error(true);
            } else if(index===2 && errors[index].yearRecieved.length === 0){
                setAwardYear3Error(false);
            }
        });
        
        setErrorAwardMessageArray(errors);

        let errorAff: string[][] = [[],[],[],[],[],[],[],[],[],[]];
        copyAffiliations.forEach((affiliation, index) => {
            if (affiliation === "")
                errorAff[index].push(`Affiliation${index+1}: must have a name`);

            if (affiliation.length > 100) {
                errorAff[index].push(`Affiliation${index+1}: You have exceeded the character limit on affiliation name.`);
            } 

            if (affiliation.includes('<') || affiliation.includes('>') || affiliation.includes('%') || affiliation.includes('=')) {
                errorAff[index].push(`Affiliation${index+1}: Please avoid use of the following characters: '<', '>', '%', and '='.`);
            }

            if(index===0 && errorAff[index].length > 0){
                setAffi1Error(true);
            } else if(index===0 && errorAff[index].length === 0){
                setAffi1Error(false);
            }

            if(index===1 && errorAff[index].length > 0){
                setAffi2Error(true);
            } else if(index===1 && errorAff[index].length === 0){
                setAffi2Error(false);
            }
            if(index===2 && errorAff[index].length > 0){
                setAffi3Error(true);
            } else if(index===2 && errorAff[index].length === 0){
                setAffi3Error(false);
            }
            if(index===3 && errorAff[index].length > 0){
                setAffi4Error(true);
            } else if(index===3 && errorAff[index].length === 0){
                setAffi4Error(false);
            }
            if(index===4 && errorAff[index].length > 0){
                setAffi5Error(true);
            } else if(index===4 && errorAff[index].length === 0){
                setAffi5Error(false);
            }
            if(index===5 && errorAff[index].length > 0){
                setAffi6Error(true);
            } else if(index===5 && errorAff[index].length === 0){
                setAffi6Error(false);
            }
            if(index===6 && errorAff[index].length > 0){
                setAffi7Error(true);
            } else if(index===6 && errorAff[index].length === 0){
                setAffi7Error(false);
            }
            if(index===7 && errorAff[index].length > 0){
                setAffi8Error(true);
            } else if(index===7 && errorAff[index].length === 0){
                setAffi8Error(false);
            }
            if(index===9 && errorAff[index].length > 0){
                setAffi9Error(true);
            } else if(index===8 && errorAff[index].length === 0){
                setAffi9Error(false);
            }
            if(index===9 && errorAff[index].length > 0){
                setAffi10Error(true);
            } else if(index===9 && errorAff[index].length === 0){
                setAffi10Error(false);
            }
            
        });

        setErrorAffiliationsMessageArray(errorAff);
        
        let countErrors = 0;
        errors.forEach((error) => {
            countErrors = countErrors + error.name.length + error.yearRecieved.length;
        });
        errorAff.forEach((error) => {
            countErrors = countErrors + error.length;

        });

        return countErrors;
    };

    const cancelChanges = () => {
        setCopy(_.cloneDeep(awards));
        setErrorAwardMessageArray([{ name: [], yearRecieved: [] }, { name: [], yearRecieved: [] }, { name: [], yearRecieved: [] }]);
        setCopyAffiliations(affiliations);
        setErrorAffiliationsMessageArray([[],[],[],[],[],[],[],[],[],[]]);
        modalsContext.showModal(tiles.Awards, false);
    };

    const resetErrorHighlight = () => {
        setAwardName1Error(false);
        setAwardName2Error(false);
        setAwardName3Error(false);
        setAwardYear1Error(false);
        setAwardYear2Error(false);
        setAwardYear3Error(false);

        setAffi1Error(false);
        setAffi2Error(false);
        setAffi3Error(false);
        setAffi4Error(false);
        setAffi5Error(false);
        setAffi6Error(false);
        setAffi7Error(false);
        setAffi8Error(false);
        setAffi9Error(false);
        setAffi10Error(false);
    };
   
    const renderAwardErrors = () => {
        let errList: any =[];

        errorAwardMessageArray.forEach((errAward) => {
            if(errAward.name.length>0)
            {
                errList.push( {
                    text: errAward.name[0],
                    type: 'error'
                });
            }
            if(errAward.yearRecieved.length>0){
                errList.push( {
                    text: errAward.yearRecieved[0],
                    type: 'error'
                });
            }
        });
        
        return errList;
    }

    const renderAffErrors = () => {        
        let errList: any =[];    
        errorAffiliationsMessageArray.forEach((errAffiliation) => {  
            if(errAffiliation.length>0){
                errList.push( {
                    text: errAffiliation[0],
                    type: 'error'
                }); 
            }
        });  
            
        return errList;
    }

    const renderAwards = () => {
        return copy.map((_award, index) => {
            
            return (
                
                <>
                    <GridCol className="rendered-cols" base={7}>                        
                            {
                            (index === 0 ) && 
                            <AlphanumericInput highlightType={awardName1Error? 'error' :undefined} innerRef= {award1} labelVisual="Award Name" value={copy[index].name} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].name = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} />}  
                            {(index === 1 ) && 
                            <AlphanumericInput highlightType={awardName2Error? 'error' :undefined} innerRef= {award2} labelVisual="Award Name" value={copy[index].name} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].name = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} />}  
                            {(index === 2 ) && 
                            <AlphanumericInput highlightType={awardName3Error? 'error' :undefined} innerRef= {award3} labelVisual="Award Name" value={copy[index].name} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].name = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} />}  
                    </GridCol>

                    <GridCol className="rendered-cols">
                            {(index === 0 ) &&
                            <AlphanumericInput highlightType={ awardYear1Error? 'error' :undefined} labelVisual="Year Recieved" value={copy[index].yearRecieved} filter={/^[0-9]*$/} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].yearRecieved = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} />}
                            {(index === 1 ) &&
                            <AlphanumericInput highlightType={ awardYear2Error? 'error' :undefined} labelVisual="Year Recieved" value={copy[index].yearRecieved} filter={/^[0-9]*$/} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].yearRecieved = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} />}
                            {(index === 2 ) &&
                            <AlphanumericInput highlightType={ awardYear3Error? 'error' :undefined} labelVisual="Year Recieved" value={copy[index].yearRecieved} filter={/^[0-9]*$/} onChange={(e) => {
                                var newArray = copy.slice();
                                newArray[index].yearRecieved = e.currentTarget.value;
                                setCopy([...newArray]);
                            }} />}
                        
                    </GridCol>
                    <div style={{alignItems:"center", display:"flex" }}>
                        <GridCol className="rendered-cols" base={1}>
                            <IconButton>
                                    <IconTrash size="24" onClick={(e: any) => {
                                    var newArray = copy.slice();
                                    newArray.splice(index, 1);
                                    setCopy([...newArray]);
                                    setErrorAwardMessageArray([{ name: [], yearRecieved: [] }, { name: [], yearRecieved: [] }, { name: [], yearRecieved: [] }]);
                                }}></IconTrash> </IconButton>
                
                        </GridCol>
                    </div>
    
                    
                </>
                
            );
        })
    };

    const renderAffiliations = () => {
        
        return copyAffiliations.map((_affiliation, index) => {

            return (
                
                    <GridRow gutterSize="fixed-16" gutters key={index}>
                        <GridCol>
                        {(index === 0 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}                                
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi1Error} label="Affiliation Name" inputRef={affl1} />}/>
                        }
                        {(index === 1 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi2Error} label="Affiliation Name" inputRef={affl2} />}/>
                        }
                        {(index === 2 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi3Error} label="Affiliation Name" inputRef={affl3} />}/>
                        }
                        {(index === 3 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi4Error} label="Affiliation Name" inputRef={affl4} />}/>
                        }
                        {(index === 4 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi5Error} label="Affiliation Name" inputRef={affl5} />}/>
                        }
                        {(index === 5 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi6Error} label="Affiliation Name" inputRef={affl6} />}/>
                        }
                        {(index === 6 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi7Error} label="Affiliation Name" inputRef={affl7} />}/>
                        } 
                        {(index === 7 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi8Error} label="Affiliation Name" inputRef={affl8} />}/>
                        }  
                        {(index === 8 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi9Error} label="Affiliation Name" inputRef={affl9} />}/>
                        }
                        {(index === 9 ) && 
                            <Autocomplete
                                freeSolo
                                options={affiliationOptions}
                                value={copyAffiliations[index]}                                
                                onInputChange={(event, value) => {
                                    var newArray = copyAffiliations.slice();
                                    newArray[index] = value;
                                    setCopyAffiliations([...newArray]);
                                    return;

                                }}
                                renderInput={(params) => <TextField {...params} error={affi10Error} label="Affiliation Name" inputRef={affl10} />}/>
                        }
                        </GridCol>
                        <div style={{alignItems:"center", display:"flex"}}>
                        <GridCol base={1}>
                            <IconButton onClick={(e: any) => {
                                var newArray = copyAffiliations.slice();
                                newArray.splice(index, 1);
                                setCopyAffiliations([...newArray]);
                                setErrorAffiliationsMessageArray([[],[],[],[],[],[],[],[],[],[]]);
                            }}>
                                <IconTrash size="24"></IconTrash>
                            </IconButton>
                        </GridCol>
                        </div>
                    </GridRow>

            );
        })
    };

    return (
        <>
            <Modal isOpen={modalsContext.awards || modalsContext.affiliations} size="small" onClose={() => cancelChanges()}>
            <ModalHeader><Heading className="modal-heading">Edit awards & affiliations</Heading></ModalHeader>
                <div ref={fieldRef}></div>
                <ModalBody className="modal-body">
                    <BodyText style={{ fontWeight:"bold", paddingBottom:"12px" }}>
                        <p>
                            Awards
                        </p>
                    </BodyText>
                    <FieldGroup isFull id="formField"
                        messages={
                            renderAwardErrors()
                        }
                    >
                    <GridRow gutters="horizontal" gutterSize="fixed-16">
                        {renderAwards()}
                    </GridRow>
                    </FieldGroup>
                    <GridRow>
                        <GridCol >
                        {copy.length < 3 && <AddItem text= {copy.length === 0 ? "Add awards" : "Add another award"} 
                            action={() => {  setCopy([...copy, { name: '', yearRecieved: '' }]); }} />}
                        </GridCol>
                    </GridRow>
                    {copy.length >= 3 && <Badge highlightType="caution">Only up to 3 awards can be added</Badge>}
                    <BodyText style={{ fontWeight:"bold", paddingTop:"24px", paddingBottom:"12px" }}>
                        <p>
                            Affiliations
                        </p>
                    </BodyText>
                    <FieldGroup isFull id="formField"  messages={
                                renderAffErrors()
                            }
                    >
                    {renderAffiliations()}
                    </FieldGroup>
                    <GridRow>
                        {(numAdditionalAffiliations <= 5 && copyAffiliations.length < 10 ) && <AddItem text= {copyAffiliations.length === 0 ? "Add affiliations" : "Add another affiliation"} 
                        action={() => { setCopyAffiliations([...copyAffiliations, ""]); }} />}
                    </GridRow>
                    <GridRow>
                        <span style={{marginLeft: '5px 0px 0px 5px'}}>
                        { (numAdditionalAffiliations >= 5 ) && <Badge className="errorMessage" highlightType="caution">Only up to 5 additional affiliations can be added</Badge>}
                        {duplicateAffiliations && <Badge className="errorMessage" highlightType="caution">Duplicate affiliations were detected</Badge>}
                        </span>
                    </GridRow>
                </ModalBody>
                <ModalFooter className="modal-footer">
                    <CancelAndSaveButtons
                        cancelFunction={cancelChanges}
                        saveFunction={() => {
                            if ( checkForErrors() === 0 ) {
                                setAwards(copy);
                                setAffiliations(copyAffiliations)
                                modalsContext.showModal(tiles.Awards, false);
                            } else {
                                handleClick();
                            }
                        }}/> 
                </ModalFooter>
            </Modal>

            <Heading type="h5" className="subtitle">Awards</Heading>        
                <div>
                    <ul style={{ listStyle: "none" }}>
                        {showAwards()}
                    </ul>
                </div>

            <GridRow onClick={() => {
                setTimeout(() => {
                    let newCopy = awards.slice();;
                    newCopy.push({ name: "", yearRecieved: "" });
                    setCopy(newCopy);                    
                    resetErrorHighlight();
                    checkForErrors();
                }, 500);                
                modalsContext.showModal(tiles.Awards, true);
            }
            }>
               {awards.length < 3 && <AddItem text= {awards.length === 0 ? "Add awards" : "Add another award"}/>}
            </GridRow>

            <Heading type="h5" className="subtitle">Affiliations</Heading>
                <div style={{ marginTop: "10px"}}>
                    <ul style={{ listStyle: "none" }}>
                        {showAffiliations()}
                    </ul>
                </div>

            <GridRow onClick={() => {
                setTimeout(() => {
                    let newCopy = affiliations.slice();;
                    newCopy.push("");
                    setCopyAffiliations(newCopy);                
                    resetErrorHighlight();
                    checkForErrors();
                }, 500);
                modalsContext.showModal(tiles.Awards, true);
            }
            }>
            {(affiliations.length < 10 ) && <AddItem text= {affiliations.length === 0 ? "Add affiliations" : "Add another affiliation"} />} 
            </GridRow>
        </>
    )
};

export default ModalAwards;