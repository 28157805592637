import { IconButton, IconEdit, List, ListItem, Heading } from "@lmig/lmds-react";
import { useContext, useEffect } from "react";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import ModalArticles from "./ModalArticles";

type ArticleDB = {
    Title: string,
    Url: string
}

const ShowArticles = () => {
    const { profileData, articles, setArticles } = useContext(ProfileDataContext);
    const modalsContext = useContext<IModalsContext>(ModalsContext);

    useEffect(() => {

        if (profileData.ProfileArticles)
            setArticles(profileData.ProfileArticles.filter((x: ArticleDB) => x.Title !== "" && x.Url !== ""))
    }, [profileData, setArticles]);

    return (
        <>

            {articles.length ? <IconButton className="edit-btn-align-absolute" onClick={() => modalsContext.showModal(tiles.Articles, true)}>
                <IconEdit size="24" />
            </IconButton> : null}
            <div className='indentContent'>
                    <List compact>
                            { articles.map((article:any) =>  {
                                return (<ListItem  key={article.Title}><Heading type="h6-small" className="articleList" >{`${article.Title} - ${article.Url}`}</Heading></ListItem>);
                            })}
                    </List>
                <ModalArticles />
            </div>
        </>
    );
};

export default ShowArticles;
