import { FieldGroup, Heading} from "@lmig/lmds-react";
import { Modal, ModalBody, ModalHeader, ModalFooter} from '@lmig/lmds-react-modal';
import _ from "lodash";
import { useState } from "react";
import { useContext } from "react";
import { AgentDataContext } from "../context/AgentDataContext";
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";
import { Textarea } from '@lmig/lmds-react-textarea';
import '../../styles/Modals.css';

interface AgentNotesModalProps {
    publish: any,
    notes: string,
    edit: boolean,
    updateEdit: (val : boolean) => void,
    cancel: any,
}


function AgentNotesModal(this: any, props: AgentNotesModalProps){
    const [edit, setEdit] = useState<boolean>(props.edit);
    const {notes, setNotes} = useContext(AgentDataContext);
    const [errorMessageArray, setErrorMessageArray] = useState<string[]>([]);
    const [textAreaCount, setTextAreaCount] = useState(0);
    const checkForErrors = () => {
        let errors: string[]=[];
            if (notes === "") {
                errors.push("Note is required.");
            }
            if (notes.length > 200) {
                errors.push("You have exceeded the character limit on notes.");
            }

        setErrorMessageArray(errors);

        return errors.length;
    };

        return(
        <Modal
        isOpen = {edit}
        size="small"
        onClose={() => {setEdit(false); props.updateEdit(false); props.cancel()}}
        >
        <ModalHeader><Heading className="modal-heading">Approve and publish profile</Heading></ModalHeader>
        <ModalBody className="modal-body">
        
            <FieldGroup isFull id="formField" style={{ marginTop: errorMessageArray.length ? ".5rem" : "0rem" }}
                messages={
                    errorMessageArray.map((error) => {
                        return {
                            text: error,
                            type: 'error'
                        };

                    })
                }
            >
                <p>Please enter notes associated with this change.({textAreaCount}/200 character max)</p>
                <Textarea className = "textarea-general" placeholder="Please enter your notes here." rows={4} cols={54} value = {notes} onChange={(e:any) => {setNotes(e.target.value); setTextAreaCount(e.target.value.length)}}>                   
                </Textarea>
                <p>Are you sure you want to publish your profile?</p>
                <br></br>
                <p line-height={0.5}>*Profile changes will reflect on FAA after clearing browser cache. You can do that by closing your browser or clicking ctrl F5 to clear cached content.</p>
                <p line-height={0.5}>*It may take up to 24 hours for the change to be reflected in the Safeco Find An Agent Portal search results.</p>
            </FieldGroup>
        </ModalBody>
            <ModalFooter className="modal-footer">
                <CancelAndSaveButtons 
                    saveTitle="Publish"
                    cancelFunction={() => {setEdit(false); props.updateEdit(false); props.cancel()}}
                    saveFunction={() => {if( checkForErrors()> 0 )return; setEdit(false); props.updateEdit(false);
                        setNotes(_.cloneDeep(notes)); props.publish() }}/>
            </ModalFooter>
        </Modal> 
        )
        


}
export default AgentNotesModal;

