import { BodyText, GridRow, Heading, IconAddItem, IconButton, IconCaretDown, IconCaretUp, IconEdit, Link} from "@lmig/lmds-react";
import { useState } from "react";
import TextInputModal from "../../modals/TextInputModal";
interface SummaryProps {
    summary: string;
    updateSummary: (val:string) => void;
}
function SummarySection(props: SummaryProps) {

    const [editSummary, setEditSummary] = useState<boolean>(false);
    const [showSummary, setShowSummary] = useState<boolean>(false);

    return (<>
        <div className="contact-information-section-edit-row">
            <Heading type="h5">Summary</Heading>
            {props.summary?.length > 0 && <IconButton id="summaryModalButton" style={{ marginBottom: '.75em'}} onClick={()=>{setEditSummary(true)}}>
                <IconEdit className="icon-edit-custom-size" size="24"/>
            </IconButton>}
        </div>
        {props.summary?.length > 0 ?
                            <div className = "filed-group-margin-items indent-content">
                                {props.summary.length < 172 ?
                                    <BodyText>{props.summary}</BodyText>
                                    :
                                    <>
                                        <div className={showSummary ? "" : "closed-summary"}>{props.summary}</div>
                                        {!showSummary ? <Link className="show-more-general" onClick={() => setShowSummary(true)} variant="standalone"  caretPosition="none" >Show more<IconCaretDown className="carrot-icon-margin" size="16" color="teal"/></Link> :
                                        <Link className="link-general" onClick={() => setShowSummary(false)} variant="standalone"  caretPosition="none">Show less<IconCaretUp className="carrot-icon-margin" size="16" color="teal"/></Link>}
                                    </>
                                }
                            </div>
                    :
                    <div className='indent-content-inner'>
                        <GridRow>
                            <div className = "filed-group-margin-items">
                                <IconButton id="summaryModalButton" onClick={() => {setEditSummary(true)}}>
                                    <IconAddItem size = "24"></IconAddItem>
                                </IconButton>
                            </div>
                            <div className="icon-text-general">
                                <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => {setEditSummary(true)}}>Add summary</Link>
                            </div>
                        </GridRow>
                    </div>
                }
            {editSummary ?
                <TextInputModal label={"Edit summary"} text={props.summary} edit={true} updateEdit={setEditSummary} updateText={props.updateSummary} />
                :
                null}
                </>
    );

}

export default SummarySection;