import { useState } from "react";
import { ImageFile } from "../../../../context/ProfileDataContext";
import { AlphanumericInput, Button, IconButton, IconSeeMore, Link, GridRow, GridCol} from "@lmig/lmds-react";
import { Modal, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { Popover } from '@lmig/lmds-react-popover';
import "../../../../../styles/AgencyPhotos.css"
import "../../../../../styles/Modals.css"
import { useEffect } from "react";

const AgencyPhoto = (props: {image: ImageFile,
                                update: (image: ImageFile) => void,
                                delete: (image: ImageFile) => void,
                                replace: (e: any) => void,
                                hidePopover: boolean}) => {

    const [image, setImage] = useState<ImageFile>(props.image);
    const [description, setDescription] = useState<string>(props.image.Description ?? "");
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [hidePopover, setHidePopover] = useState<boolean>(true);

    useEffect(() => {
        setHidePopover(props.hidePopover);
    }, [props.hidePopover]);

    useEffect(() => {
        if (props.image)
            setImage(props.image);
    }, [props.image]);

    return (
        <>
           <div className={`photo-box ${hidePopover ? "hide-popover" : ""}`}>
               <Popover className="gallery-popover" trigger={<IconButton background="transparent">
                   <IconSeeMore size="24" color="teal" onClick={() => setHidePopover(false)}  />
               </IconButton>}>
                   <ul>
                       <li>
                           <Link onClick={() => setIsEditing(true)}>Edit caption</Link>
                       </li>
                       <li>
                           <Link className="replace-button">
                               <label htmlFor="galleryPhotoUpload">Replace</label>
                               <input id="galleryPhotoUpload"
                                   type="file"
                                   accept="image/png, image/jpeg"
                                   onChange={(e) => { props.replace(e); setHidePopover(true)}} />
                           </Link>
                       </li>
                       <li>
                           <Link onClick={() => { props.delete(image); setHidePopover(true) }}>Remove</Link>
                       </li>
                   </ul>
               </Popover>
               <img key={image.name} src={image.url} alt="galleryPhoto" />
               <span className="agency-profile-picture">Agency Photo</span>
                <Modal size="small" isOpen={isEditing} onClose={() => setIsEditing(false)} className="agency-photo-modal">
                    <ModalBody className="modal-body">
                        <div>
                            <img key={image.name} src={image.url} alt="galleryPhoto" />
                            <AlphanumericInput labelVisual="Caption" value={image.Description ?? ""} onChange={(e) => setDescription(e.currentTarget.value)} />
                        </div>
                    </ModalBody>
                    <ModalFooter className="modal-footer">
                        <GridRow gutterSize="fixed-16" gutters justify="end">
                            <GridCol className="button-container" base={3}>
                                <Button  size="small" dynamicWidth variant="secondary" onClick={() => setIsEditing(false)}>Cancel</Button>
                            </GridCol>
                            <GridCol className="button-container" base={3}>
                                <Button  size="small" dynamicWidth variant="primary" onClick={() => { props.update({...image, Description: description});setIsEditing(false)}}>Save</Button>
                            </GridCol>
                        </GridRow>
                    </ModalFooter>
                </Modal>
           </div>

        </>
    );
};

export default AgencyPhoto;
