import axios from 'axios';
import { EditAgentWrapper } from '../models/Agent';
import { AgenciesWrapper, AgencyMetadata, AgentWrapper, HistoryWrapper, EditAgencyWrapper } from '../models/Agency';
import { SFDataFile } from '../models/SFDataFile';
export const getAllAgencies = async () => {
        const { data } = await axios.get<AgenciesWrapper>(`${window.env.GET_ALL_AGENCIES}`);
        return data;
};

export const getAgencyProfileData = async (id : string | undefined) => {

        const { data } = await axios.get<any>(`${window.env.GET_AGENCY_PROFILE_DATA}${id}`);
        return data;
};

export const getAssociatedAgencyData = async (id : string | undefined) => {
        const { data } = await axios.get<any>(`${window.env.GET_ASSOCIATED_AGENCY_DATA}${id}`);
        return data;
};

export const getMatchingAgencies = async (query : string | undefined) => {
        const { data } = await axios.get<AgenciesWrapper>(`${window.env.GET_MATCHING_AGENCIES}?query=${query}`);
        return data;
};

export const getMatchingDropdownAgencies = async (query : string | undefined) => {
        const { data } = await axios.get(`${window.env.GET_MATCHING_DROPDOWN_AGENCIES}?query=${query}`);
        return data;
};

export const getAgentData = async  (AgentId : string|undefined, AgencyId : string|undefined)  => {
        const { data } = await axios.get<EditAgentWrapper>(`${window.env.GET_AGENT_DATA}`, { params: { agencyId: AgencyId, agentId: AgentId } });
        return data;
}

export const getAgencyById = async (id : string | undefined) => {
        const { data } = await axios.get<AgencyMetadata>(`${window.env.GET_AGENCY_META_DATA}${id}`);
        return data;
};

export const getAgentsById = async (id : string | undefined) => {
        const { data } = await axios.get<AgentWrapper>(`${window.env.GET_AGENTS_META_DATA}${id}`);
        return data;
};

export const getAgencyActions = async (id : string | undefined) => {
        const { data } = await axios.get<HistoryWrapper>(`${window.env.GET_AGENCY_ACTIONS}${id}`);
        return data;
};

export const getAgentActions = async (agencyId : string | undefined, agentId : string | undefined) => {
        const { data } = await axios.get<HistoryWrapper>(`${window.env.GET_AGENT_ACTIONS}${agencyId}/${agentId}`);
        return data;
};

export const getPhotoById = async (imageId : string) => {
        const data = await fetch(window.location.origin + window.env.PHOTO_PATH + imageId);
        return data;
}

export const setAgencyShowStatus = async (agencyId : string | undefined, profileStatus : Boolean) => {
        const { data } = await axios.post<EditAgencyWrapper>(`${window.env.SET_AGENCY_SHOW_STATUS}?agencyId=${agencyId}&profileStatus=${profileStatus}`);
        return data;
}

export const setAgentShowStatus = async (agencyId : string | undefined, agentId : string | undefined, profileStatus : Boolean) => {
        const { data } = await axios.post<EditAgentWrapper>(`${window.env.SET_AGENT_SHOW_STATUS}?agencyId=${agencyId}&agentId=${agentId}&profileStatus=${profileStatus}`);
        return data;
}

export const publishAgencyChanges = async (agencyId: string, changes: any) => {
        //PUBLISH_AGENCY_CHANGES
        const { data } = await axios.post<EditAgencyWrapper>(`${window.env.PUBLISH_AGENCY_CHANGES}${agencyId}`, changes);
        return data;
};

export const publishAssociatedChanges = async (agencyId: string, changes: any) => {
        //PUBLISH_AGENCY_CHANGES
        const { data } = await axios.post<EditAgencyWrapper>(`${window.env.PUBLISH_ASSOCIATED_AGENCY_CHANGES}${agencyId}`, changes);
        return data;
};

export const publishAgentChanges = async (changes: any) => {
        const { data } = await axios.post(`${window.env.PUBLISH_AGENT_CHANGES}`, changes);
        return data;
};

export const saveAgentChanges = async (changes: any) => {
        const { data } = await axios.post(`${window.env.SAVE_AGENT_CHANGES}`, changes);
        return data;
};

export const savePhoto = async (file: File, ClientSessionToken: string, isProfilePicture: boolean) => {
        //PUBLISH_AGENCY_CHANGES

        var formData = new FormData();
        formData.append('Files', file)
        const res = await axios.post(`${window.env.SAVE_PHOTO}`,formData, { params: {ClientSessionToken: ClientSessionToken, profilePhotoType: isProfilePicture ? "cover" : "gallery"}, headers: {
                'Content-Type': 'multipart/form-data'
              }});
        return res.data;

};

export const restoreAgentChanges = async (changes: any) => {
        const { data } = await axios.post(`${window.env.RESTORE_AGENT_CHANGES}`, changes);
        return data;
};

export const getSFFileDetails = async () => {
        const { data } = await axios.get<SFDataFile>(`${window.env.GET_SFDATA_FILEINFO}`);
        return data;
};

export const uploadSFDataFile = async (token :string, file: File) => {
        var formData = new FormData();
        formData.append('Files', file)
        formData.append('ClientSessionToken', token)
        const res = await axios.post(`${window.env.UPLOAD_SFDATA_FILE}`,formData, { params: {profilePhotoType: file.type}, headers: {
                'Content-Type': 'multipart/form-data'
              }});
        return res.data;
};

export const updateSFData = async (token :string) => {
        var formData = new FormData();
        formData.append('ClientSessionToken', token)
        const res = await axios.post(`${window.env.UPDATE_SFDATA}`,formData);
        return res.data;
};

export function formatPhoneNumber(phoneNumberString: string) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return "";
};

export const getGoogleAPIKey = async () => {   
        const endPoint = "/googleapi/key";
        const { data } = await axios.get<string>(`${window.env.FAA_API_URL}${endPoint}`);
        return data;
};
