

import { createContext, useState } from "react"
import { Agency, Agent, AgentAction } from "../../models/Agency";

export type IAgenciesContext = {
    searchTerm?: string,
    setSearchTerm:(searchTerm: string) => void,
    agencies?: Agency[],
    setAgencies: (agencies: Agency[]) => void,
    agency: Agency | undefined,
    setAgency: (agency: Agency) => void,
    setAgencyId: (id: number) => void,
    setAgent: (agent : {agentId: string, profileStatus: string, percentComplete: number, agentHistory: AgentAction, modifiedDate: string}) => void
};

const initialState: IAgenciesContext = {
    setSearchTerm: (searchTerm: string) => {},
    agencies:[],
    setAgencies:(agencies: Agency[]) => {},
    agency:undefined,
    setAgency: (agency: Agency) => {},
    setAgencyId: (id: number) => {},
    setAgent: (agent : {agentId: string, profileStatus: string, percentComplete: number, agentHistory: AgentAction, modifiedDate: string}) => {}
};

export const AgenciesContext = createContext(initialState);

export function AgenciesContextProvider({ children }: { children: React.ReactNode }) {
    const [agencies, setAgencies] = useState<Agency[]>();
    const [agency, setAgency] = useState<Agency>();
    const [searchTerm, setSearchTerm] = useState<string>();

    let shared: IAgenciesContext = {
        searchTerm: searchTerm,
        setSearchTerm: (e: string) => setSearchTerm(e),
        agencies: agencies,
        setAgencies: (agencies: Agency[]) => setAgencies(agencies),
        agency: agency,
        setAgency: (agency: Agency) => {
            setAgency(agency);
            setAgencies(agencies?.map(x => (x.AgencyId === agency.AgencyId ? agency : x)))
        },
        setAgencyId: (id: number) => {
            let agency = agencies?.find(x => x.AgencyId === id);
            if (agency)
                setAgency(agency)
        },
        setAgent: (agent : {agentId: string, profileStatus: string, percentComplete: number, agentHistory: AgentAction, modifiedDate: string}) => {
          if (agency?.Agents) {
            setAgency({...agency, Agents:agency.Agents.map((x: Agent) => x.agentId.toString() === agent.agentId ? {...x, profileStatus: agent.profileStatus, percentComplete: agent.percentComplete, agentHistory: agent.agentHistory, modifiedDate: agent.modifiedDate} : x)})
          }
        }
    }

  return (
    <AgenciesContext.Provider value={shared}>
      {children}
    </AgenciesContext.Provider>
  );
}