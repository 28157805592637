import { Card, Heading, IconEmail, IconPhone, IconInfo } from "@lmig/lmds-react";
import Avatar from "@mui/material/Avatar";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LinearProgress from '@mui/material/LinearProgress';
import { useState, useEffect, useContext } from 'react';
import { getAgentData, getAgentsById} from "../../../services/FAAApi";
import { ProfileDataContext, agentData } from '../../context/ProfileDataContext';
import { CircularProgressSpinner } from '@lmig/sales-ui-components';
import { AgenciesContext } from "../../context/AgenciesContext";
import { Agent } from "../../../models/Agency";

interface AgentListProps {
    id: any,
    agenciesData: any[],
    awaitingAgenciesData : boolean,
    associatedAgencyIds: string [],
}

interface ProtoAgent {
    AgentId: string,
    Name: string,
    Position: number,
    AgencyId?: string,
}

const AgentList = ({ id, agenciesData, awaitingAgenciesData, associatedAgencyIds }: AgentListProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const { agency} = useContext(AgenciesContext);
    const [progressUpdated, setProgressUpdated] = useState<boolean>(false);
    const [agentDataList, setAgentDataList] = useState<agentData[] | null>();
    const [agents, setAgents] = useState<Agent[] | null>();
    const { sortedAgentDataList, setSortedAgentDataList } = useContext(ProfileDataContext); //null when loading, [] or [...] when loaded

    useEffect(() => { //the percent complete returned in the GetAgentData request is incorrect, so read value from AgentMetadata call
        if (agency?.Agents && !!agentDataList && !progressUpdated) {
            setAgentDataList(agentDataList => agentDataList?.map((x: agentData) => {
                let match = agency.Agents!.find((y: Agent) => y.agentId.toString() === x.agentId)
                if (match)
                    return {...x, percentComplete: match.percentComplete}
                else
                    return x;
            }));
            setProgressUpdated(true);
        }

    }, [agency?.Agents, agentDataList, progressUpdated, setAgentDataList]);

    useEffect(() => { //get agent data for photo,email,phone etc
        const agentsById = async () =>{
            setLoading(true);
            let associatedAgents : Agent[] = [];
            
            Promise.allSettled(associatedAgencyIds.map((agencyId)=>{
                return getAgentsById(agencyId)
            })).then((responseArr)=>{
                responseArr.forEach((response)=>{
                    if(response.status === "fulfilled"){
                        response.value.agentMetaViewItems.forEach((agent)=>{
                            associatedAgents.push(agent);
                        })
                    }
                } )

                let recentAgents : Agent [] = [];

                associatedAgents.forEach((agent)=>{
                    let add = true;
                    recentAgents.forEach((addedAgent, index, arr)=>{
                        if(addedAgent.agentId === agent.agentId){
                            add = false;
                            if(addedAgent.modifiedDate < agent.modifiedDate){
                                arr[index] = agent;
                            }
                        }
                    })

                    if(add){
                        recentAgents.push(agent);
                    }
                })

                setAgents(recentAgents);
                setLoading(false);
            });
        }

        const fetchData = async () => {
            if (agents){

                setLoading(true);
                let newAgentDataList: agentData[] = [];
                let allProfiles : any [] = [];

                agenciesData.forEach((data : any)=>{
                    allProfiles.push(data);
                })
                
                agents.forEach((agent: Agent)=>{
                    allProfiles.forEach((profile : any)=>{
                        if(agent.agencyId === profile.ID){
                            profile.OrderedAgents.forEach((orderedAgent: ProtoAgent)=>{
                                if(agent.agentId.toString() === orderedAgent.AgentId)
                                    agent.position = orderedAgent.Position;
                            })
                            profile.AvailableAgents.forEach((availableAgent: ProtoAgent)=>{
                                if(agent.agentId.toString() === availableAgent.AgentId)
                                    agent.position = availableAgent.Position;
                            })
                        }
                    })
                })

                let allAgents : Agent [] = [];

                allAgents = agents.sort((a: Agent, b: Agent) => a.agentName.localeCompare(b.agentName));
                allAgents = agents.sort((a: Agent, b: Agent) => {
                    if(a.position === undefined)
                        a.position = -1;
                    if(b.position === undefined)
                        b.position = -1;
                    if (a.position === -1 && b.position === -1)
                      return 0; //keep original order of a and b
                    else if (a.position === -1)
                      return 1; //sort b before a
                    else if (b.position === -1)
                      return -1; //sort a before b
                    else if (a.position < b.position)
                      return -1; //sort a before b
                    else
                      return 1; //sort b before a
                  });

                Promise.allSettled(allAgents.map((orderedAgent: Agent)=>{
                    return getAgentData(orderedAgent.agentId.toString(), orderedAgent.agencyId.toString());
                })).then((responseArr)=>{
                    responseArr.forEach((response, index)=>{
                        if(response.status !== "rejected"){ 
                            let agent = allAgents[index];
                            let { phone, email, CoverPhotoBlobId, agencyName, yearStarted, summary, show, preferredName, preferredEmail, preferredPhone } = response.value.AgentData;
                            let imageUrl = "";

                            if (CoverPhotoBlobId)
                                imageUrl = window.location.origin + window.env.PHOTO_PATH + CoverPhotoBlobId;
                            newAgentDataList.push({ show, phone: !! preferredPhone ? preferredPhone : phone, email: !! preferredEmail ? preferredEmail : email, photo: imageUrl, position: index, name: !!preferredName ? preferredName : agent.agentName, agentId: agent.agentId.toString(), percentComplete: agent.percentComplete, ordered: false, agencyId: agent.agencyId.toString(), agencyName, yearStarted, summary, profileStatus: agent.profileStatus });
                        }
                    })
                    setAgentDataList(newAgentDataList);
                })
            }
        }

        if (!loading && associatedAgencyIds.length > 0 && !agents){    
            agentsById();
        }
        if(!loading && !awaitingAgenciesData){
            fetchData();
        }

    }, [id, setAgentDataList, agentDataList, sortedAgentDataList, loading, agents, agency?.AgencyId, agency?.AgencyLocationId, agency, agenciesData, awaitingAgenciesData, associatedAgencyIds]);


    useEffect(() => {
        if (agentDataList) {
            const sortedArray = agentDataList.slice().sort((a, b) => a.position.valueOf() - b.position.valueOf());
            setSortedAgentDataList([...sortedArray]);
        }
    }, [agentDataList, setSortedAgentDataList])

    const handleOnDragEnd = (result: any) => {

        if (!result.destination || !sortedAgentDataList) return;

        var items: agentData[] = [...sortedAgentDataList];
        const maxIndex = Math.max(result.source.index, result.destination.index);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        items.forEach((item, index) => {//updates position of all elements

            item.position = index + 1;
            if (index <= maxIndex) {
                item.ordered = true;
            }
        });

        setAgentDataList([...items]);
    };

    const formatPhoneNumber = (phoneNumberString: string) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return '';
    };

    const renderAgentList = () => {

        return sortedAgentDataList!==null && sortedAgentDataList.map((agent: agentData, index) => {


            return (
                <Draggable key={agent.agentId} draggableId={agent.agentId} index={index}>
                    {(provided) => (
                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Card className={`agentCardSpacing ${agent.show ? "" : "hidden-agent"}`} style={{ height: "100%" }} >
                                <div className="linear-progress">
                                    <LinearProgress
                                    variant="determinate"
                                    value={agent.profileStatus === ( "Not Started" || null || undefined) ?  0 : agent.percentComplete as number}
                                    sx={{
                                        height: '20px !important',
                                        '& .MuiLinearProgress-bar': {
                                            background: `linear-gradient(90deg, rgba(151,225,148,1) 0%, rgba(162,222,93,1) 50%, rgba(77,160,21,1) 100%)`
                                        }
                                    }}/>
                                    <p>
                                        {agent.show ? (agent.profileStatus === ( "Not Started" || null || undefined) ?  "Not Started" : agent.percentComplete + "% Complete" ) : <><IconInfo style={{"margin":"0px 3px 3px 0px"}} size="16" /> Hidden</>}
                                    </p>
                                </div>

                                <div className="agentCardFlexBox" id="agentContainer">

                                    <Avatar src={agent.photo} variant="square" className="agentAvatar" sx={{ width: 100, height: 100, marginRight: 2 }} />
                                    <div className="agentDetails">
                                        <div className="agent-card-row">
                                            <span className="agentName agentDetail">{agent.name.toLocaleLowerCase()}</span>
                                        </div>

                                        <div className="agent-card-row">
                                            <IconPhone title="Phone Number" size="24" />
                                            <span className="agentDetail">{formatPhoneNumber(agent.phone)}</span>
                                        </div>
                                        <div className="agent-card-row">
                                            <IconEmail title="Email Address" size="24" />
                                            <span className="agentDetail">{agent.email ? agent.email : "NA"}</span>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </li>
                    )}
                </Draggable>
            );

        });
    }


    const renderAgentListContent = () => {
        if (sortedAgentDataList)
                return <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
                        <Droppable droppableId="agents">
                            {(provided: any) => (
                                <ul {...provided.droppableProps} ref={provided.innerRef} style={{ listStyle: "none" }}>
                                    {renderAgentList()}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
        else
                return <CircularProgressSpinner aria-label="Loading Data" size="96" style={{margin: '30px auto 0 auto'}}/>
    };

    return (
        <div style={{ padding: "0px 10px 0px 20px", marginTop: "20px" }}>
            <div className='agentlist-header-background'>
                <Heading type="h4" className='agentlist-header-text'>Agent Listing Order</Heading>
            </div>

            <div className="agentFlexLayout">
                {renderAgentListContent()}
            </div>

        </div>
    );


};

export default AgentList;