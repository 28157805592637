import { AlphanumericInput, Button, FieldGroup, GridCol, GridRow, Heading} from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import { useContext, useState } from "react";
import '../../styles/OptionsModal.css'
import '../../styles/Modals.css';
import { AgentDataContext } from "../context/AgentDataContext";
import { validateFBUrl, validateLIUrl } from "../../constants/constants"
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";

interface SocialMediaModalProps {
    edit: boolean,
    facebook: string,
    linkedIn: string,
    updateEdit: (val : boolean) => void,
    updateFacebook: (val : string) => void,
    updateLinkedIn: (val : string) => void,
}

function SocialMediaModal (props : SocialMediaModalProps) {
    const {socialMedia, setSocialMedia} = useContext(AgentDataContext);
    const [facebook, setFacebook] = useState<string>(_.cloneDeep(socialMedia.facebookUrl));
    const [linkedIn, setLinkedIn] = useState<string>(_.cloneDeep(socialMedia.linkedInUrl));
    const [edit, setEdit] = useState<boolean>(props.edit);
    
    type errorsObject = {
        linkedIn: string[],
        facebook: string[]
    };
    const [errorMessageObject, setErrorMessageObject] = useState<errorsObject>({ linkedIn: [], facebook: [] });

    const checkForErrors = () => {
        let errors: errorsObject = { linkedIn: [], facebook: [] };
        if (linkedIn.length > 2083) {
            errors.linkedIn.push("Your LinkedIn information cannot exceed 2083 characters");
        }
        if (linkedIn !== "" && linkedIn !== "http://" && !validateLIUrl.test(linkedIn)) {
            errors.linkedIn.push("Please enter valid LinkedIn profile URL. eg: https://linkedin.com/in/JohnDoe");
        }
        if (facebook.length > 2083) {
            errors.facebook.push("Your Facebook information cannot exceed 2083 characters");
        }
        if (facebook !== "" && facebook !== "http://" && !validateFBUrl.test(facebook)) {
            errors.facebook.push("Please enter valid Facebook profile URL. eg: https://facebook.com/JohnDoe");
        }

        setErrorMessageObject(errors);

        return errors.linkedIn.length + errors.facebook.length;
    };

    const cancelChanges = () => {
        setEdit(false); 
        props.updateEdit(false); 
        setFacebook(_.cloneDeep(socialMedia.facebookUrl)); 
        setLinkedIn(_.cloneDeep(socialMedia.linkedInUrl));
        setErrorMessageObject({ linkedIn: [], facebook: [] });
    }

    return (
        <>
          <Modal
          isOpen={edit}
          size="small"
          onClose={() => {  cancelChanges() }}
          >
            <ModalHeader><Heading className="modal-heading">Edit Social Media Links</Heading></ModalHeader>
            <ModalBody className="modal-body">
                <FieldGroup isFull style={{ marginTop: errorMessageObject.facebook.length ? "1rem" : "0rem" }}
                    messages={
                        errorMessageObject.facebook.map((error) => {
                            return {
                                text: error,
                                type: 'error'
                            };

                        })
                    }
                >
                    <GridRow gutters="horizontal" gutterSize="fixed-16">
                        <GridCol>
                            <AlphanumericInput autoFocus labelVisual="Facebook" value ={facebook} onBlur={(e) => {if (facebook !== ""){setFacebook( e.currentTarget.value.includes("http://") || e.currentTarget.value.includes("https://")? e.currentTarget.value : "https://" + e.currentTarget.value);}}}
                                onChange={(e) => {setFacebook(e.target.value); }} placeholder="http://facebook.com/JohnDoe"/>
                        </GridCol>
                 
                        <GridCol base={2}>
                            <a href={facebook} target="_blank" rel="noopener noreferrer">
                                <Button style={{marginTop:"6px"}} dynamicWidth size="small">Check</Button>
                            </a>
                        </GridCol>
                    </GridRow>
                </FieldGroup> 
                <FieldGroup isFull style={{ marginTop: errorMessageObject.linkedIn.length ? "1rem" : "0rem" }}
                    messages={
                        errorMessageObject.linkedIn.map((error) => {
                            return {
                                text: error,
                                type: 'error'
                            };

                        })
                    }
                >
                    <GridRow gutters="horizontal" gutterSize="fixed-16">    
                        <GridCol>
                                <AlphanumericInput labelVisual="LinkedIn" value ={linkedIn} onBlur={(e) => {if (linkedIn !== ""){setLinkedIn(e.currentTarget.value.includes("http://") || e.currentTarget.value.includes("https://")? e.currentTarget.value : "https://" + e.currentTarget.value);}}}
                                onChange={(e) => {setLinkedIn(e.target.value);}} placeholder="https://linkedin.com/in/JohnDoe"/>
                        </GridCol>
                        <GridCol base={2}>
                            <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                                <Button style={{marginTop:"6px"}} dynamicWidth size="small" >Check</Button>
                            </a>
                        </GridCol>
                    </GridRow>
                </FieldGroup>
            </ModalBody>
            <ModalFooter className="modal-footer">
                <CancelAndSaveButtons
                    cancelFunction={cancelChanges}
                    saveFunction={() => {
                        if(checkForErrors() === 0){
                            setEdit(false); 
                            props.updateEdit(false); 
                            props.updateFacebook(facebook); 
                            props.updateLinkedIn(linkedIn); 
                            setSocialMedia(_.cloneDeep({facebookUrl: facebook, linkedInUrl: linkedIn}))
                        }
                    }}/> 
            </ModalFooter>
          </Modal>
        </>
    )
}
export default SocialMediaModal;