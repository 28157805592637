import { AlphanumericInput, FieldGroup, GridRow, GridCol, Heading, Select, SelectOption } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useState, useContext } from "react";
import { states as stateList } from "../../../../../constants/constants";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import { useEffect } from "react";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import '../../../../../styles/Modals.css';
import '../../../../../styles/EditAgency.css';
import CancelAndSaveButtons from "../../../../modals/CancelAndSaveButtons";

type officeLocation = {
    officeName?: string,
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    state?: string,
    zip?: string,
    phone?: string,
    isNew?: string,
    isDeleted?: string,
    id?: string
}

type errorsObject = {
    officeName: string[],
    addressLine1: string[],
    addressLine2: string[],
    city: string[],
    state: string[],
    zip: string[],
    phone: string[]
};

const SelectState = (props:{officeLocation: officeLocation, setOfficeLocation: React.Dispatch<React.SetStateAction<officeLocation>>}) => {
    const [states] = useState<{ value:string, label: string}[]>(stateList.map((state: string) => ({ value:state, label:state })));
    const profileData = useContext(ProfileDataContext);

    if(props.officeLocation.state === undefined){
        props.setOfficeLocation({...props.officeLocation, state: profileData.profileData.State ? profileData.profileData.State : undefined})
    }

    return(
    <Select
        isRequired
        labelVisual="State"
        defaultValue={props.officeLocation.state ? props.officeLocation.state : profileData.profileData.State ? profileData.profileData.State : "" }
        value={props.officeLocation.state ? props.officeLocation.state : profileData.profileData.State ? profileData.profileData.State : "" }
        onChange={ (e) => { props.setOfficeLocation({...props.officeLocation, state: e.currentTarget?.value ? e.currentTarget.value : props.officeLocation.state})}}
        children={states.map(
            (state) => (
                <SelectOption>
                {state.value}
                </SelectOption>
            )
        )}
    />)
  };

const OfficeLocationsModal = (props: {
        save: (location: officeLocation) => void,
        title:string,
        office?:officeLocation}) => {

    const {modalOfficeOptions, setModalOfficeOptions} = useContext(ProfileDataContext);
    const [errorMessageObject, setErrorMessageObject] = useState<errorsObject>({ officeName: [], addressLine1: [], addressLine2: [], city: [], state: [], zip: [], phone: [] });
    const [officeLocation, setOfficeLocation] = useState<officeLocation>(props.office ?? {});    
    const modalsContext = useContext<IModalsContext>(ModalsContext);

    useEffect(() => {
        if (modalOfficeOptions?.office){
            setOfficeLocation(modalOfficeOptions.office);
        }
        else {
            setOfficeLocation({});
        }

    }, [modalOfficeOptions?.office, modalsContext.officeLocation])
    const checkForErrors = () => {

        let errors: errorsObject = { officeName: [], addressLine1: [], addressLine2: [], city: [], state: [], zip: [], phone: [] };
        if (!officeLocation?.officeName || officeLocation?.officeName?.length === 0) {
            errors.officeName.push("Office Name is required");
        }
        if (officeLocation?.officeName && officeLocation?.officeName?.length > 40) {
            errors.officeName.push("Office Name cannot exceed 40 characters");
        }
        if (!officeLocation?.addressLine1 || officeLocation?.addressLine1 === "") {
            errors.addressLine1.push("Address Line 1 is required");
        }
        if (officeLocation?.addressLine1 && officeLocation?.addressLine1?.length > 30) {
            errors.addressLine1.push("Address line 1 cannot exceed 30 characters");
        }
        if (officeLocation?.addressLine2 && officeLocation?.addressLine2?.length > 30) {
            errors.addressLine2.push("Address line 2 cannot exceed 30 characters");
        }
        if (!officeLocation?.city || officeLocation?.city === "") {
            errors.city.push("City is required");
        }
        if (officeLocation?.city && officeLocation?.city?.length > 30) {
            errors.city.push("City cannot exceed 30 characters");
        }
        if (!officeLocation?.state || officeLocation?.state === "State" || officeLocation?.state === "") {
            errors.state.push("State is required");
        }
        if (officeLocation?.state && officeLocation?.state?.length > 2) {
            errors.state.push("State cannot exceed 2 characters");
        }
        if (!officeLocation?.zip || officeLocation?.zip === "") {
            errors.zip.push("Zip is required");
        }
        if (officeLocation?.zip && officeLocation?.zip?.length > 10) {
            errors.zip.push("Zip cannot exceed 10 characters");
        }
        if (officeLocation?.zip !== "" && officeLocation?.zip && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(officeLocation?.zip)) {
            errors.zip.push("Invalid zip code");
        }
        if (officeLocation?.phone && officeLocation?.phone?.length > 14) {
            errors.phone.push("Phone number cannot exceed 14 characters");
        }
        const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        if (officeLocation?.phone && !phoneRegex.test(officeLocation?.phone)) {
            errors.phone.push("Invalid phone number");
        }

        setErrorMessageObject(errors);

        return errors.officeName.length + errors.addressLine1.length + errors.addressLine2.length + errors.city.length + errors.state.length + errors.zip.length + errors.phone.length;

    };

    const cancelChanges = () => {
        setModalOfficeOptions(null)
        modalsContext.showModal(tiles.OfficeLocation, false)
        setErrorMessageObject({ officeName: [], addressLine1: [], addressLine2: [], city: [], state: [], zip: [], phone: [] });
    };

    return (
        <>
            {modalOfficeOptions && <Modal
                isOpen={modalsContext.officeLocation}
                size="small"
                key={props?.office?.id}
                onClose={() => cancelChanges()}>
                <ModalHeader><Heading className="modal-heading">{modalOfficeOptions?.title}</Heading></ModalHeader>
                <ModalBody className="modal-body">
                <GridRow>
                <GridCol base={12}>
                    <FieldGroup isFull id="formField"
                        messages={
                            errorMessageObject.officeName.map((error) => {
                                return {type: 'error', text: error };
                            })
                        }>
                        <AlphanumericInput
                            labelVisual="Office Location Name"
                            value={officeLocation.officeName}
                            highlightType={errorMessageObject.officeName.length ? "error" : undefined}
                            onChange={(e) => setOfficeLocation({...officeLocation, officeName:e.currentTarget?.value})} />
                    </FieldGroup>
                </GridCol>
                <GridCol base={12}>
                    <FieldGroup isFull  id="formField"
                        messages={
                            errorMessageObject.addressLine1.map((error) => {
                                return { text: error, type: 'error' };
                            })
                        }>
                        <AlphanumericInput
                            labelVisual="Address Line 1"
                            value={officeLocation.addressLine1}
                            highlightType={errorMessageObject.addressLine1.length ? "error" : undefined}
                            onChange={(e) => setOfficeLocation({...officeLocation, addressLine1:e.currentTarget?.value})} />
                    </FieldGroup>
                </GridCol>
                <GridCol base={12}>
                    <FieldGroup isFull id="formField"
                        messages={
                            errorMessageObject.addressLine2.map((error) => {
                                return { text: error, type: 'error' };
                            })
                        }>
                    <AlphanumericInput
                        labelVisual="Address Line 2"
                        value={officeLocation.addressLine2}
                        highlightType={errorMessageObject.addressLine2.length ? "error" : undefined}
                        onChange={(e) => setOfficeLocation({...officeLocation, addressLine2:e.currentTarget?.value})} />
                    </FieldGroup>
                </GridCol>
                <GridCol base={12}>
                    <div className="threePerRow">
                        <FieldGroup isFull id="formField"
                            style={{marginTop: errorMessageObject.city.length === 0 && (errorMessageObject.zip.length > 0 || errorMessageObject.state.length > 0) ? "1.15rem":"0rem"}}
                            messages={
                                errorMessageObject.city.map((error) => {
                                    return { text: error, type: 'error' };
                                })
                            }>
                            <AlphanumericInput
                                labelVisual="City"
                                value={officeLocation.city}
                                highlightType={errorMessageObject.city.length ? "error" : undefined}
                                onChange={(e) => setOfficeLocation({...officeLocation, city: e.currentTarget?.value})} />
                        </FieldGroup>
                        <FieldGroup isFull id="formField"
                        style={{marginTop: errorMessageObject.state.length === 0 && (errorMessageObject.city.length > 0 || errorMessageObject.zip.length > 0) ? "1.15rem":"0rem"}}
                            messages={
                                errorMessageObject.state.map((error) => {
                                    return { text: error, type: 'error' };
                                })
                            }>
                        <SelectState officeLocation={officeLocation} setOfficeLocation={setOfficeLocation}/>    
                        </FieldGroup>
                        <FieldGroup isFull id="formField"
                            style={{marginTop: errorMessageObject.zip.length === 0  && (errorMessageObject.city.length > 0 || errorMessageObject.state.length > 0) ? "1.15rem":"0rem"}}
                            messages={
                                errorMessageObject.zip.map((error) => {
                                    return { text: error, type: 'error' };
                                })
                            }>
                            <AlphanumericInput
                                labelVisual="Zip Code"
                                mask='zipCode'
                                value={props.office?.zip ?? officeLocation?.zip}
                                highlightType={errorMessageObject.zip.length ? "error" : undefined}
                                onChange={(e) => setOfficeLocation({...officeLocation, zip: e.currentTarget?.value})} />
                        </FieldGroup>
                    </div>
                    </GridCol>
                    <GridCol base={12}>
                    <div className="twoPerRow">
                        <FieldGroup isFull id="formField"
                            messages={
                                errorMessageObject.phone.map((error) => {
                                    return { text: error, type: 'error' };
                                })
                            }>
                            <AlphanumericInput
                                labelVisual="Phone"
                                mask="phone"
                                placeholder="xxx-xxx-xxxx"
                                highlightType={errorMessageObject.phone.length ? "error" : undefined}
                                value={props.office?.phone ?? officeLocation?.phone}
                                onChange={(e) => setOfficeLocation({...officeLocation, phone: e.currentTarget?.value})} />
                        </FieldGroup>
                    </div>
                    </GridCol>
                </GridRow>
                </ModalBody>
                <ModalFooter className="modal-footer">
                    <CancelAndSaveButtons
                        cancelFunction={cancelChanges}
                        saveFunction={() => {
                            if (checkForErrors() === 0) {
                            if (officeLocation)
                                modalOfficeOptions?.function(officeLocation);

                            modalsContext.showModal(tiles.OfficeLocation, false);}}}/>
                </ModalFooter>
            </Modal>}
        </>
    )
};

export default OfficeLocationsModal;
