import { Link, Notification, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useContext, useEffect, useRef, useState } from "react";
import { ImageFile, ProfileDataContext } from "../../../../context/ProfileDataContext";
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from "react-image-crop";
import CancelAndSaveButtons from "../../../../modals/CancelAndSaveButtons";
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import { AgentDataContext } from "../../../../context/AgentDataContext";
import '../../../../../styles/Modals.css';

const CropModal = (props: { isOpen: boolean, close: () => void, save: (image: ImageFile) => void, newPic: ImageFile | undefined, isAgent: boolean | undefined, delete: (image: ImageFile) => void }) => {

    const scale = 1;
    const rotate = 0;
    const aspect = 1;

    const { photos } = useContext(ProfileDataContext);
    const { image }  = useContext(AgentDataContext);
    const [cropOptions, setCropOptions] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const [lastImage, setLastImage] = useState<ImageFile | undefined>(props.isAgent ? image.mainPhoto[0] : photos.mainPhoto[0]);
    const [nextImage, setNextImage] = useState<ImageFile>(props.newPic ?? (props.isAgent ? image.mainPhoto[0] : photos.mainPhoto[0]));

    const [errors, setErrors] = useState<{ tooBig:boolean, wrongType:boolean}>({ tooBig:false, wrongType:false});
    const [zoom, setZoom] = useState<boolean>(false);

    const imgRef = useRef<HTMLImageElement>(null)
    const canvas = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (nextImage.size && nextImage.size > 2097152) {// checks if file is greater than 2MB
            setErrors(errors => ({...errors, tooBig:true}));
            return;
        }
        else
            setErrors(errors => ({...errors, tooBig:false}));
    },[nextImage]);

    useDebounceEffect(async () => {
            if (completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                canvas.current)
            {
                canvasPreview(
                    imgRef.current,
                    canvas.current,
                    completedCrop,
                    scale,
                    rotate)
            }
        }, 100, [completedCrop, scale, rotate])

    function centerAspectCrop(
        mediaWidth: number,
        mediaHeight: number,
        aspect: number,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget;
        setZoom(width === height && width < 250 ? true : false);
        setCropOptions(centerAspectCrop(width, height, aspect));
    }


    const saveAndCrop = () => {
        if (canvas.current && cropOptions?.width !== 0) {
                canvas.current.toBlob((blob: Blob | null) => {
                    if (blob) {
                        let cropped: ImageFile = { name: nextImage.name, url: URL.createObjectURL(blob), IsDeleted: false, Blob_Id: "", base64: canvas.current?.toDataURL() };
                        setLastImage(nextImage)
                        setNextImage(cropped);
                        setCropOptions(undefined);
                        props.save({...cropped as ImageFile, IsDeleted:false});
                        props.close();
                    }
                }, "image/jpeg"); // this seems to work for both png and jpg images
        }
        else{
            props.save({...nextImage as ImageFile, IsDeleted:false});
            props.close();
        }
    }

    const resetCrop = () => {
        setErrors({ tooBig: false, wrongType: false });
        setCropOptions(undefined);
        if (lastImage)
            setNextImage(lastImage);
    }

    const updateImage =  (e:any) => {
        setZoom(false);
        if (e.target.files) {
            let file:File = e.target.files[0];
            if (file && file.size > 2097152) {// checks if file is greater than 2MB
                setErrors(errors => ({...errors, tooBig:true}));
                return;
            }
            else
                setErrors(errors => ({...errors, tooBig:false}))
            if (file && file.type !== "image/png" && file.type !== "image/jpeg"){
                setErrors(errors => ({...errors, wrongType:true}));
                return;
            }
            else
                setErrors(errors => ({...errors, wrongType:false}));


            setLastImage(nextImage);
            setNextImage({ name: file.name, url: URL.createObjectURL(file as any), Blob_Id: "", IsDeleted: false });

            setCropOptions(undefined);
        }
    }

    return (
        <>
            <Modal
                isOpen={props.isOpen}
                size="small"
                onClose={() => props.close()}>
                <ModalHeader><Heading className="modal-heading">Edit profile picture</Heading></ModalHeader>
                <ModalBody className="modal-body">
                    {errors && <div className="errorWarningContainer">
                {errors && errors.tooBig && <Notification highlightType="negative" alert="The maximum image size is 2MB. Please select a smaller image" isDismissible />}
                {errors && errors.wrongType && <Notification highlightType="negative" alert="Image must be a .JPG or .PNG" isDismissible />}
                </div>}
                    <div className="crop-image-modal-content">
                        <div>
                            <div
                                style={{
                                    height: "260px",
                                    width: "260px",
                                    marginBottom:"1rem",
                                    border:"5px solid #02455387"
                                }}>
                                <div id='crop-area'>
                                    <ReactCrop
                                        className="crop-area"
                                        crop={cropOptions}
                                        onChange={(pixelCrop, percentCrop) => setCropOptions(percentCrop)}
                                        onComplete={(c) => setCompletedCrop(c)}
                                        aspect={aspect}>
                                        <img
                                            id="crop-image"
                                            ref={imgRef}
                                            alt=""
                                            src={nextImage.base64 ?? nextImage.url}
                                            onLoad={onImageLoad}
                                            className={zoom ? "zoom" : ""}
                                            style={{
                                                maxHeight: "260px",
                                                display: "block",
                                                marginLeft: "auto",
                                                marginRight: "auto"
                                            }}/>
                                    </ReactCrop>
                                </div>
                            </div>
                            <div className="crop-photo-options">
                                <Link>
                                    <label htmlFor="replaceProfilePicture">Replace</label>
                                    <input id="replaceProfilePicture"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => updateImage(e)} />
                                </Link>
                                {lastImage && lastImage.url !== nextImage.url && <Link style={{paddingLeft: "1rem"}} onClick={() => resetCrop()}>Undo</Link>}
                                <Link style={{paddingLeft: "1rem"}} onClick={() => props.delete(nextImage)}>Remove</Link>
                            </div>
                        </div>
                    </div>
                    {Boolean(completedCrop) && (
                        <canvas
                            ref={canvas}
                            id='resetCanvas'
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: "130px",
                                height: "130px"
                            }}
                        />
                    )}
                </ModalBody>
                <ModalFooter className="modal-footer">
                    <CancelAndSaveButtons
                        cancelFunction={() => props.close()}
                        saveFunction={() => saveAndCrop()}
                        saveTitle="Crop & Save"
                        saveDisabled={errors.tooBig || errors.wrongType}/>   
                </ModalFooter>
            </Modal >

        </>
    )

};

export default CropModal;
