import { AlphanumericInput, FieldGroup, IconTrash, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useContext, useRef, useState } from "react";
import { ProfileDataContext } from "../../../../context/ProfileDataContext";
import { useEffect } from "react";
import AddItem from "../../../../../helpers/AddItem";
import { IModalsContext, ModalsContext, tiles } from "../../../../context/ModalsContext";
import '../../../../../styles/Modals.css';
import CancelAndSaveButtons from '../../../../modals/CancelAndSaveButtons';

type Chat = {
   google?:string, skype?:string, web?:string
}

const ModalChatOptions = (props:{ focus?:string}) => {
    const {preferredContactInfo, setPreferredContactInfo } = useContext(ProfileDataContext);
    const [copy, setCopy] = useState<Chat>(preferredContactInfo.chat);
    const modalsContext = useContext<IModalsContext>(ModalsContext);

    /*ref elements used for focusing*/
    const googleChatInput= useRef<HTMLInputElement>(null);
    const skypeInput = useRef<HTMLInputElement>(null);
    const webChatInput = useRef<HTMLInputElement>(null);

    const [showGoogleChat, setShowGoogleChat] = useState<boolean>((preferredContactInfo.chat.google ?? []).length > 0);
    const [showSkype, setShowSkype] = useState<boolean>((preferredContactInfo.chat.skype ?? []).length > 0);
    const [showWebChat, setShowWebChat] = useState<boolean>((preferredContactInfo.chat.web ?? []).length > 0);

    useEffect(() => {
        setCopy(preferredContactInfo.chat);
    }, [preferredContactInfo]);

    useEffect(()=> {
        switch(props.focus) {
            case "GOOGLE":
              setShowGoogleChat(true);
              webChatInput.current?.focus();
              break;
            case "SKYPE":
              setShowSkype(true);
              skypeInput.current?.focus();
              break;
            case "WEBCHAT":
              setShowWebChat(true);
              webChatInput.current?.focus()
              break;
            default:
              // code block
          }
    },[props.focus, modalsContext.contactInfo]);

    useEffect(() => {
        if (showGoogleChat && props.focus) {
            googleChatInput.current?.focus()
        }}, [showGoogleChat, props.focus]);

    useEffect(() => {
        if (showSkype && props.focus) {
            skypeInput.current?.focus()
        }}, [showSkype, props.focus]);

    useEffect(() => {
        if (showWebChat && props.focus) {
            webChatInput.current?.focus()
    }}, [showWebChat, props.focus]);

    const cancelChanges = () => {

        setShowGoogleChat(false);
        setShowSkype(false);
        setShowWebChat(false);

        modalsContext.showModal(tiles.Chat, false);
    };

    return (
        <>
            <Modal isOpen={modalsContext.chat} size="small" onClose={() => cancelChanges()}>
                <ModalHeader>
                    <Heading className="modal-heading">{copy.web || copy.skype || copy.google ? "Edit chat options" : "Add chat options"}</Heading>
                </ModalHeader>
                <ModalBody className="modal-body">
                    {/* GOOGLE */}
                        <FieldGroup isFull id="formField">
                        {showGoogleChat ? <div className="trashable"><AlphanumericInput labelVisual="Google chat" value={copy.google} onChange={(e) =>
                            setCopy((copy: Chat)  => ({ ...copy, google : e.currentTarget.value }))
                        } innerRef={googleChatInput} /><IconTrash size="24" onClick={() => {setCopy((copy: Chat) => ({ ...copy, google:'' }));setShowGoogleChat(false)}} /></div> : <AddItem text="Add Google chat" action={() => setShowGoogleChat(true) } />}
                        </FieldGroup>

                    {/* SKYPE */}
                        <FieldGroup isFull id="formField">
                        {showSkype ? <div className="trashable"><AlphanumericInput labelVisual="Skype username" value={copy.skype} onChange={(e) =>
                            setCopy((copy: Chat)  => ({ ...copy, skype: e.currentTarget.value }))
                        } innerRef={skypeInput} /><IconTrash size="24" onClick={() => {setCopy((copy: Chat)  => ({ ...copy, skype:'' }));setShowSkype(false)}} /></div> : <AddItem text="Add Skype" action={() => setShowSkype(true) } />}
                        </FieldGroup>

                    {/* WEB CHAT */}
                        <FieldGroup isFull id="formField">
                        {showWebChat ? <div className="trashable"><AlphanumericInput labelVisual="Website chat" value={copy.web} onChange={(e) =>
                            setCopy((copy: Chat)  => ({...copy, web: e.currentTarget.value}))
                        } innerRef={webChatInput} /><IconTrash size="24" onClick={() => {setCopy((copy: Chat)  => ({ ...copy, web:'' }));setShowWebChat(false)}} /></div> : <AddItem text="Add Website chat" action={() => setShowWebChat(true) } />}
                        </FieldGroup>
                </ModalBody>
                <ModalFooter className="modal-footer">
                    <CancelAndSaveButtons
                        cancelFunction={cancelChanges}
                        saveFunction={() => {
                            setPreferredContactInfo(preferredContactInfo => ({...preferredContactInfo, chat:copy}));
                            modalsContext.showModal(tiles.Chat, false);}}/>
                </ModalFooter>
            </Modal>
        </>
    )

};

export default ModalChatOptions;
