import { IconButton, IconEdit, GridRow, IconAddItem, Link } from "@lmig/lmds-react";
import { useState } from "react";
import { getIcon } from "../../helpers/ProductIconsMapper";
import { ProductSpecialty } from "../../models/Agent";
import OptionsModal from "../modals/OptionsModal";

interface ProductProps {
    productSpecialties: ProductSpecialty[],
    updateProductSpecialties: (val: ProductSpecialty[]) => void,
}

function Product(props: ProductProps) {
    const [productBoxes, setProductBoxes] = useState<ProductSpecialty[]>(props.productSpecialties ?? []);
    const [productEdit, setProductEdit] = useState<boolean>(false);

    // displays selected languages / products
    const getNamesCheckedInTable = (boxes: ProductSpecialty[]) => {
        boxes.sort();
        return boxes.filter(x => x.selected).map((item: ProductSpecialty, index: number) =>
            <div key={index} className="product-and-languages-wrapper">
                <div className="product-icon-wrapper-small">{getIcon(item.productName, "16")}</div> <p className="product-text-spacing">{item.productName}</p>
            </div>);
    }

    return (<>
        <div className='indent-content products-tile'>
            {getNamesCheckedInTable(productBoxes)}
            {productBoxes.filter(x => x.selected).length > 0 && <div className="edit-btn-align-absolute">
                <IconButton id="productsModalButton" onClick={() => { setProductEdit(true) }}>
                    <IconEdit className="icon-edit-custom-size" size="24" />
                </IconButton>
            </div>}
            {productEdit ? <OptionsModal
                edit={productEdit}
                updateEdit={setProductEdit}
                options={productBoxes}
                updateOptions={setProductBoxes}
                title="Select products you specialize in"
                isProduct={true}></OptionsModal> : <></>}
        </div>
        {productBoxes.find(x => !x.selected) ?
            <div style={{ "paddingLeft": "20px" }}><GridRow onClick={() => setProductEdit(productEdit => !productEdit)}>
            <IconButton id="productsModalButton" >
                <IconAddItem size="24"></IconAddItem>
            </IconButton>
            <Link className="link-general" variant="standalone" caretPosition="none">
            {productBoxes.find(x => x.selected) ? "Add more products" : "Add products"} 
            </Link>
        </GridRow>
    </div> : null}

            

            
    </>
    );
}

export default Product;