import { useContext, useEffect } from "react";
import { AgentHoursContext } from '../../context/AgentHoursContext'

import AgentHoursOfOperationModal from "../../modals/AgentHoursOfOperationModal";
import { hoursObject, dayOpHours } from "../../../models/Agency";




const ShowAgentHoursOfOperation = (props: any) => {

    var { operationHours, setOperationHours } = useContext( AgentHoursContext);

    useEffect(() => {
        try {
            var newOperationHours: hoursObject;
            var opHours: dayOpHours[] = [];
            if (props?.hoursOfOperation !== undefined && props?.hoursOfOperation !== null) {
                props?.hoursOfOperation.forEach((element: any) => {
                    opHours.push({day: element.dayName, hours: {begin: element.start, end: element.stop}, open: element.start !== '-----' || element.stop  !== '-----', dayAbrv: ""});
                });
                newOperationHours = {timezone: props?.timeZone, otherhours: props?.otherHours, opHours };
                setOperationHours(newOperationHours);
            }
        }
        catch  (err) {
            console.error("Error when rendering hours of operation", err);
            return;
        }
    }, [ setOperationHours, props?.hoursOfOperation, props?.timeZone, props?.otherHours]);

    function renderHoursOfOperation(): JSX.Element[] {
        return operationHours.opHours.map((opHour: dayOpHours) => {

            return (

                <tr key={opHour.day as string}>
                    <td style={{paddingRight:'60px'}}>
                        {opHour.day}:
                    </td>
                    <td style={{width:'70px'}}>
                        {opHour.open ? opHour.hours.begin : <p>Closed</p>}
                    </td>
                    <td style={{ padding: " 0 2px" }}>
                        {opHour.open ? <p>to</p> : null}
                    </td>
                    <td style={{width:'70px'}}>
                        {opHour.open ? opHour.hours.end : null}
                    </td>

                </tr>

            );
        });
    }


    return (
        <div>

            <div className="edit-btn-align-absolute">
                <AgentHoursOfOperationModal agencyData={props.agencyData? props.agencyData : undefined} isAgent={props?.isAgent} />
            </div>

            <table className='indentHoursContent'>
                <tbody >
                    {renderHoursOfOperation()}
                    <tr>
                        <td>Time zone: </td>
                        <td>
                            {operationHours.timezone}

                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='indentHoursContent'>
                <div style={{}}>Other hours available: <span style={{textTransform: 'capitalize'}}>{`${operationHours.otherhours}`}</span></div>
            </div>

        </div >
    );
};

export default ShowAgentHoursOfOperation;