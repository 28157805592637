import { AlphanumericInput, FieldGroup, GridCol, GridRow, IconAddItem, IconButton, IconTrash, Link, Heading} from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import '../../styles/OptionsModal.css'
import { AgentDataContext } from "../context/AgentDataContext";
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";
import '../../styles/Modals.css';

interface ChatOptionsModalProps {
    skype: boolean;
    skypeUsername: string;
    google: boolean;
    googleUsername: string;
    none: boolean;
    edit: boolean,
    addSkype: boolean,
    addGoogle: boolean,
    updateEdit: (val : boolean) => void,
    updateSkypeUsername: (val : string) => void,
    updateGoogleUsername: (val : string) => void,
    updateNone: (val : boolean) => void,
    setAddSkype: (val : boolean) => void,
    setAddGoogle: (val : boolean) => void,
}

function ChatOptionsModal (props : ChatOptionsModalProps) {
    const {supportedCommunication, setSupportedCommunication} = useContext(AgentDataContext);
    const [edit, setEdit] = useState<boolean>(props.edit);
    const [skype, setSkype] = useState<boolean>(props.skype);
    const [skypeUsername, setSkypeUsername] = useState<string>(_.cloneDeep(supportedCommunication.skype));
    const [google, setGoogle] = useState<boolean>(props.google);
    const [googleUsername, setGoogleUsername] = useState<string>(_.cloneDeep(supportedCommunication.googleChat));
    const [none, setNone] = useState<boolean>(_.cloneDeep(supportedCommunication.supportedCommunicationChannelsNA));
    const [addSkype, setAddSkype] = useState<boolean>(props.addSkype);
    const [addGoogle, setAddGoogle] = useState<boolean>(props.addGoogle);

    useEffect(() => {
        if(addGoogle){
            setGoogle(true);
        }
        if(addSkype){
            setSkype(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    type errorsObject = {
        googleChat: string[],
        skype: string[]
    };
    const [errorMessageArray, setErrorMessageArray] = useState<errorsObject>({ googleChat: [], skype: [] });

    const checkForErrors = () => {
        let errors: errorsObject = { googleChat: [], skype: [] };
        if (googleUsername === "" && google) {
            errors.googleChat.push(`This field cannot be left blank if selected`);
        }
        if (googleUsername.length > 40) {
            errors.googleChat.push("Your Google Chat user name cannot exceed 40 characters.");
        }
        if (skypeUsername === "" && skype) {
            errors.skype.push(`This field cannot be left blank if selected`);
        }
        if (skypeUsername.length > 40) {
            errors.skype.push("Your Skype user name cannot exceed 40 characters.");
        }
    
        setErrorMessageArray(errors);

        return errors.googleChat.length + errors.skype.length;
    };

    const cancelChanges = () => {
        setEdit(false); 
        props.updateEdit(false); 
        setSkypeUsername(_.cloneDeep(supportedCommunication.skype)); 
        setGoogleUsername(_.cloneDeep(supportedCommunication.googleChat)); 
        setNone(_.cloneDeep(supportedCommunication.supportedCommunicationChannelsNA));
        setErrorMessageArray({ googleChat: [], skype: [] });
        setAddSkype(false);
        props.setAddSkype(false);
        setAddGoogle(false);
        props.setAddGoogle(false);
    };

    return (

        <Modal isOpen={edit} size="small" onClose={() =>  cancelChanges()}>
            <ModalHeader><Heading className="modal-heading">Edit chat options</Heading></ModalHeader>
            <ModalBody className="modal-body">
                {google ?
                    <GridRow gutters="horizontal" gutterSize="fixed-16">  
                        <GridCol>
                            <FieldGroup isFull id="formField"
                                messages={
                                    errorMessageArray.googleChat.map((error) => {
                                        return {
                                            text: error,
                                            type: 'error'
                                        };
                                    })
                                }
                            >
                                <AlphanumericInput autoFocus={google} labelVisual="Google Chat Username" value={googleUsername} onChange={(e : any) => {setGoogleUsername(e.target.value);}}/>
                            </FieldGroup>
                        </GridCol>
                        <div className="trashable">
                            <GridCol base = {1}>
                                    <IconTrash size = "24" onClick={() => [
                                    setGoogle(false),
                                    setGoogleUsername(""),
                                    setSupportedCommunication(_.cloneDeep({
                                        skype: supportedCommunication.skype, 
                                        googleChat: "", 
                                        supportedCommunicationChannelsNA: supportedCommunication.supportedCommunicationChannelsNA
                                    })),
                                    setErrorMessageArray({ ...errorMessageArray, googleChat: []}),
                                ]}></IconTrash>
                            </GridCol>
                        </div>
                    </GridRow>
                :
                    <div className='indent-content-inner' style={{marginTop: "0px", marginLeft: "-18px"}}>
                        <GridRow onClick={() => {
                            setGoogle(true);
                        }}>
                            <IconButton>
                                <IconAddItem size = "24"></IconAddItem>
                            </IconButton>
                            <Link className="link-general" variant="standalone" caretPosition="none">
                                Add Google chat
                            </Link>
                        </GridRow>
                    </div>     
                }
                
                {skype ?
                    <GridRow gutters="horizontal" gutterSize="fixed-16">
                        <GridCol>
                            <FieldGroup isFull id="formField"
                                messages={
                                    errorMessageArray.skype.map((error) => {
                                        return {
                                            text: error,
                                            type: 'error'
                                        };
                                    })
                                }
                            >  
                                <AlphanumericInput autoFocus={skype} labelVisual="Skype Username" value={skypeUsername} onChange={(e : any) => {setSkypeUsername(e.target.value);}}/> 
                            </FieldGroup>
                        </GridCol>
                        <div className="trashable">
                        <GridCol base={1}>
                            
                            <IconTrash size = "24" onClick={() => [
                                setSkype(false),
                                setSkypeUsername(""),
                                setSupportedCommunication(_.cloneDeep({
                                    skype: "", 
                                    googleChat: supportedCommunication.googleChat, 
                                    supportedCommunicationChannelsNA: supportedCommunication.supportedCommunicationChannelsNA
                                })),
                                setErrorMessageArray({ ...errorMessageArray, skype: []}),
                            ]}></IconTrash>
                        </GridCol>
                        </div>
                    </GridRow>
                :
                    <div className='indent-content-inner' style={{marginTop: "0px", marginLeft: "-18px"}}>
                        <GridRow onClick={() => {
                            setSkype(true);
                        }}>
                            <IconButton>
                                <IconAddItem size = "24"></IconAddItem>
                            </IconButton>
                            <Link className="link-general" variant="standalone" caretPosition="none">
                                Add Skype chat
                            </Link>
                        </GridRow>
                    </div>   
                }   
                </ModalBody>
                <ModalFooter className="modal-footer"> 
                    <CancelAndSaveButtons
                        cancelFunction={cancelChanges}
                        saveFunction={() => {
                            if(checkForErrors() === 0){
                                setEdit(false); 
                                props.updateEdit(false);
                                props.updateGoogleUsername(googleUsername); 
                                props.updateSkypeUsername(skypeUsername); 
                                props.updateNone(none); 
                                setSupportedCommunication(_.cloneDeep({skype: skypeUsername, googleChat: googleUsername, supportedCommunicationChannelsNA: none}));
                                setAddSkype(false);
                                props.setAddSkype(false);
                                setAddGoogle(false);
                                props.setAddGoogle(false);
                            }
                        }}/>  
                </ModalFooter>
        </Modal>

    )
}

export default ChatOptionsModal;