import { FieldGroup, Form, GridRow, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import { useContext, useState } from "react";
import { getIcon } from "../../helpers/ProductIconsMapper";
import { Language, ProductSpecialty } from "../../models/Agent";
import '../../styles/OptionsModal.css'
import '../../styles/Modals.css';
import { AgentDataContext } from "../context/AgentDataContext";
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";

interface OptionsModalProps {
  title: string,
  edit: boolean,
  updateEdit: (val:boolean) => void,
  options: any,
  updateOptions: (val: any) => void,
  isProduct: boolean,
}

function OptionsModal (props : OptionsModalProps) {

  const [edit, setEdit] = useState<boolean>(props.edit);
  const { languages, setLanguages, productSpecialties, setProductSpecialties} = useContext(AgentDataContext);
  const [options, setOptions] = useState<Language[] | ProductSpecialty[]>(_.cloneDeep(props.isProduct ? productSpecialties : languages));

  const changeProductCheckbox = (e : React.ChangeEvent<HTMLInputElement>) => {
    let p : any = options ? (options as ProductSpecialty[]).map((x : any) => Object.assign({}, x)) : [];
    for (let i = 0; i < p.length; i++) {
        if (e.target.name === p[i].productName) {
            p[i].selected = !p[i].selected;
            break;
        }
    }
    setOptions(p);
  }

  const changeLanguageCheckbox = (e : React.ChangeEvent<HTMLInputElement>) => {
    let p : any = options ? (options as Language[]).map((x : any) => Object.assign({}, x)) : [];
    for (let i = 0; i < p.length; i++) {
        if (e.target.name === p[i].englishLanguageName) {
            p[i].selected = !p[i].selected;
            break;
        }
    }
    setOptions(p);
}

  const generateProductCheckboxes = () => {
    return (options as ProductSpecialty[])?.map((product : any) => {
        return (
                <div className={"checkbox custom-size"}>
                  <input type="checkbox" onChange={changeProductCheckbox} name={product.productName} id={product.productName} checked = {product.selected}/>  
                  <div className={"box-with-icon"}> 
                    <div className="product-icon-wrapper">
                      {
                        getIcon(product.productName, "32")
                      }
                    </div> 
                    <p>{product.productName}</p>  
                  </div>  
               </div>     
        );
    })
  }
  
  const generateLanguageCheckboxes = () => {
    return (options as Language[])?.map((language : any) => {
        return (
                <div className="checkbox">  
                  <input type="checkbox" onChange={changeLanguageCheckbox} name={language.englishLanguageName} id={language.languageCode} checked = {language.selected}/>  
                  <div className="box">  
                    <p>{language.englishLanguageName}</p>  
                  </div>  
               </div>
        );
    })
  }

  return (
        <>
          <Modal
          isOpen={edit}
          size="small"
          onClose={() => {setEdit(false); props.updateEdit(false); setOptions(_.cloneDeep(props.isProduct ? productSpecialties : languages)); }}
          >
            <ModalHeader><Heading className="modal-heading">{props.title}</Heading></ModalHeader>
            <ModalBody className="modal-body">
            <Form id="form--isFull">
                <FieldGroup isFull id="formField">    
                  <GridRow className="center-content"> 
                    {props.isProduct? generateProductCheckboxes() : generateLanguageCheckboxes()}
                  </GridRow> 
                </FieldGroup>
            </Form>
            </ModalBody>
            <ModalFooter className="modal-footer"> 
              <CancelAndSaveButtons
                cancelFunction={() => {setEdit(false); props.updateEdit(false); setOptions(_.cloneDeep(props.isProduct ? productSpecialties : languages)); }}
                saveFunction={() => {setEdit(false); props.updateEdit(false);  props.updateOptions(options); props.isProduct ? setProductSpecialties(_.cloneDeep(options as ProductSpecialty[])): setLanguages(_.cloneDeep(options as Language[])) }}/> 
            </ModalFooter>
          </Modal>
        </>
      )
}
export default OptionsModal;
