import { useContext, useEffect, useRef, useState } from 'react';
import { dayAbrvs } from '../../constants/time'
import { Link, useParams } from "react-router-dom";
import { formatPhoneNumber, getAgentData, getAllAgencies, publishAgentChanges, saveAgentChanges, savePhoto, restoreAgentChanges, getAgencyProfileData, getAssociatedAgencyData, getMatchingAgencies, getAgentsById } from '../../services/FAAApi';
import { EditAgentWrapper, Education, Group, Job, Language, ProductSpecialty } from '../../models/Agent';
import { Badge, Button, Card, GridRow, Heading, IconLinkCaretRight, Toggle, List, ListItem } from '@lmig/lmds-react';
import { Modal, ModalBody} from '@lmig/lmds-react-modal';
import '../../styles/EditAgentProfile.css'
import AgencyContactInformation from '../agentEditProfileSubComponents/AgencyContactInformation';
import PreferredAgentContactInformation from '../agentEditProfileSubComponents/PreferredAgentContactInformationTile';
import AgentInformation from '../agentEditProfileSubComponents/AgentAboutMe';
import { CircularProgressSpinner} from '@lmig/sales-ui-components';
import ShowAgentHoursOfOperation from '../agentEditProfileSubComponents/sections/ShowAgentHoursOfOperation';
import SocialMedia from '../agentEditProfileSubComponents/SocialMediaTile';
import Product from '../agentEditProfileSubComponents/ProductTile';
import Languages from '../agentEditProfileSubComponents/LanguagesTile';
import { toast } from 'react-toast';
import { nameCase } from '../../helpers/StringFormatter';
import { AgentHoursContext } from '../context/AgentHoursContext';
import { LinearProgress } from '@mui/material';
import ModalDeleteContents from './EditAgencyPage/sections/ModalDeleteContents';
import { AgentDataContext, ImageFile } from '../context/AgentDataContext';
import { useNavigate } from 'react-router';
import AgentNotesModal from '../modals/AgentNotesModal';
import AgentNotesModalNonAdmin from '../modals/AgentNotesModalNonAdmin';
import CancelChangesModal from '../modals/cancelChangesModal';
import RestoreConfirmationModal from '../modals/RestoreConfirmationModal';
import { UserContext } from '../context/UserContext';
import { AgenciesContext } from '../context/AgenciesContext';
import { Agent } from "../../models/Agency";

let iFrameResize = require("iframe-resizer/js/iframeResizer.js");

function EditAgentProfile() {
    let navigate = useNavigate();
    const { agencyId, agentId } = useParams();
    const [isMarketingAdmin, setIsMarketingAdmin] = useState<boolean | undefined>();
    const { user, setUser} = useContext(UserContext);
    const { agency, setAgent } = useContext(AgenciesContext);
    const [agentData, setAgentData] = useState<EditAgentWrapper | undefined>();
    const [useAgencyPrimaryLocation, setUseAgencyPrimaryLocation] = useState<boolean>(false);
    const [agencyProfileOtherOfficeId, setAgencyProfileOtherOfficeId] = useState<any>(null);
    const [agencyData, setAgencyData] = useState<any>(undefined);
    const [agentMetaViewItem, setAgentMetaViewItem] = useState<Agent | null>(null);
    const [addressLine1, setAddressLine1] = useState<string>("");
    const [hoursOfOperation, setHoursOfOperation] = useState<any[]>([]);
    const [timeZone, setTimeZone] = useState<string>("");
    const [agentOffHours, setAgentOffHours] = useState<boolean>(false);
    const [previewURL, setPreviewURL] = useState<string>("*");
    const [previewModal, setPreviewModal] = useState<boolean>();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showRestoreModal, setShowRestoreModal] = useState(false);
	const [showProcessing, setShowProcessing] = useState(false);
    const iframe = useRef<HTMLIFrameElement>(null);
    const { setAgentProfileData,
        preferredContactInfo, setPreferredContactInfo,
        additionalPhone, setAdditionalPhone,
        supportedCommunication, setSupportedCommunication,
        socialMedia, setSocialMedia,
        otherInfo, setOtherInfo,
        languages, setLanguages,
        productSpecialties, setProductSpecialties,
        summary, setSummary,
        specialties, setSpecialties,
        groups, setGroups,
        currentJob, setCurrentJob,
        pastJobs, setPastJobs,
        education, setEducation,
        image, setImage,
        deleteModalClose2,
        clearLanguages, setClearLanguages,
        notes,
        profileAdmins, setProfileAdmins
    } = useContext(AgentDataContext);
    const { operationHours, setOperationHours } = useContext(AgentHoursContext);

    const [checklistProgress, setChecklistProgress] = useState(0);
    const [checkListContents, setChecklistContents] = useState({
        addPhone: false,
        supportedComms: false,
        socialMedia: false,
        languages: false,
        groups: false,
        pastJobs: false,
        education: false
    })
    const [savedToggle, setSavedToggle] = useState({
        firstRun: false,
        addPhone: false,
        supportedComms: false,
        socialMedia: false,
        languages: false,
        groups: false,
        pastJobs: false,
        education: false
    })
    const [displayChecklist, setDisplayChecklist] = useState({
        addressAndPhone: false,
        onlineRes: false,
        addPhone: false,
        supportedComms: false,
        hoursAvailable: false,
        socialMedia: false,
        otherInfo: false,
        productSpecialties: false,
        languages: false,
        summary: false,
        specialties: false,
        groups: false,
        currentJob: false,
        pastJobs: false,
        education: false,
        image: false
    })
    const [displayModal, setDisplayModal] = useState({
        addPhone: false,
        supportedComms: false,
        socialMedia: false,
        languages: false,
        groups: false,
        pastJobs: false,
        education: false
    })

    useEffect(() => {
        document.title = "Agent Profile"
        if (user && user.IsAdmin === undefined && agentData?.AgentData.isAdmin !== undefined)
            setUser({...user, IsAdmin:agentData?.AgentData.isAdmin ?? false});
    }, [agentData?.AgentData.isAdmin, user, setUser])

    useEffect(() => {
        const setup = async () => {
            const [allAgenciesResult, agencyProfileDataResult, agentDataResult, matchingAgenciesResult, agentsByIdResult] = await Promise.allSettled([getAllAgencies(), getAgencyProfileData(agencyId), getAgentData(agentId,agencyId), getMatchingAgencies(agencyId?.toString().slice(8,)), getAgentsById(agencyId)]);
            
            let liveProfileStatCode = agencyId;
            
            if(matchingAgenciesResult.status !== 'rejected' && matchingAgenciesResult.value !== undefined){
                let modDate = '';
                matchingAgenciesResult.value.agencies.ListItems.forEach((e)=>{
                    if(e.ModifiedDate > modDate && e.Show){
                        modDate = e.ModifiedDate;
                        liveProfileStatCode = e.AgencyId.toString();
                    }
                })
            }

            const [associatedAgencyProfileDataResult]  = await Promise.allSettled([getAssociatedAgencyData(liveProfileStatCode)]);
            
            if(associatedAgencyProfileDataResult.status !== 'rejected' && associatedAgencyProfileDataResult.value !== undefined){
                setAgencyData(associatedAgencyProfileDataResult);
            }
            if(allAgenciesResult.status !== 'rejected' && allAgenciesResult.value !== undefined){
                setUser({...allAgenciesResult.value.agencies.User, IsAdmin:user.IsAdmin});
            }
            if(agencyProfileDataResult.status !== 'rejected'){
                setIsMarketingAdmin(true);
            }
            else{
                setIsMarketingAdmin(false);
            }
            if(agentDataResult.status !== 'rejected' && agentDataResult.value !== undefined){
                setAgentData(agentDataResult.value);
                setAgentProfileData(agentDataResult.value.AgentData);
            }
            if(agentsByIdResult.status !== 'rejected' && agentsByIdResult.value !== undefined){
                agentsByIdResult.value.agentMetaViewItems.forEach((item:any)=>{
                    if(item.agentId === agentId){
                        setAgentMetaViewItem(item);
                    }
                })
            }
        }

        setup();
        return () => {
            setAgentProfileData(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencyId, agentId, setAgentProfileData]);

    useEffect(() => {
        if (agentData) {
            setPreferredContactInfo({
                name: agentData.AgentData.preferredName !== null ? agentData.AgentData.preferredName : "",
                email: agentData.AgentData.preferredEmail !== null ? agentData.AgentData.preferredEmail : "",
                phone: agentData.AgentData.preferredPhone !== null ? agentData.AgentData.preferredPhone : ""
            });
            setSocialMedia({ facebookUrl: agentData.AgentData.facebookUrl !== null ? agentData.AgentData.facebookUrl : "", linkedInUrl: agentData.AgentData.linkedInUrl !== null ? agentData.AgentData.linkedInUrl : "" })
            setProductSpecialties(agentData.AgentData.productSpecialties !== null ? agentData.AgentData.productSpecialties : []);
            setLanguages(agentData.AgentData.languages !== null ? agentData.AgentData.languages : []);
            setSummary(agentData.AgentData.summary !== null ? agentData.AgentData.summary : "");
            setSpecialties(agentData.AgentData.specialties !== null ? agentData.AgentData.specialties : "")
            setAdditionalPhone({ otherPhone: agentData.AgentData.otherPhone !== null ? "("+(agentData.AgentData.otherPhone).slice(0,3)+") "+agentData.AgentData.otherPhone.slice(3,6)+"-"+agentData.AgentData.otherPhone.slice(6,10) : "", selectedOtherPhoneType: agentData.AgentData.selectedOtherPhoneType !== null ? agentData.AgentData.selectedOtherPhoneType : "" })
            setOtherInfo(agentData.AgentData.yearStarted !== null ? agentData.AgentData.yearStarted : "")
            setSupportedCommunication({
                skype: agentData.AgentData.skype !== null ? agentData.AgentData.skype : "",
                googleChat: agentData.AgentData.googleChat !== null ? agentData.AgentData.googleChat : "",
                supportedCommunicationChannelsNA: agentData.AgentData.supportedCommunicationChannelsNA !== null ? agentData.AgentData.supportedCommunicationChannelsNA : false
            })
            setUseAgencyPrimaryLocation(agentData.AgentData.useAgencyPrimaryLocation !== null ? agentData.AgentData.useAgencyPrimaryLocation : false);
            setAddressLine1(agentData.AgentData.addressLine1);
            setGroups(agentData.AgentData.groupsAndAssociations);
            setEducation(agentData.AgentData.educationList);
            setPastJobs(agentData.AgentData.pastJobs);
            setCurrentJob(agentData.AgentData.currentJobs);
            setHoursOfOperation(agentData.AgentData.agentOperatingTimes);
            setTimeZone(agentData.AgentData.agentTimeZone !== null ? agentData.AgentData.agentTimeZone : "");
            setAgentOffHours(agentData.AgentData.agentOffHours !== null ? agentData.AgentData.agentOffHours : false);
            setAgencyProfileOtherOfficeId(agentData.AgentData.agencyProfileOtherOfficeId);
            setProfileAdmins(agentData.AgentData.profileAdmins);
            if (agentData.AgentData.CoverPhotoBlobId)
                setImage(image => ({...image, mainPhoto:[{ Blob_Id:agentData.AgentData.CoverPhotoBlobId, IsDeleted: false, name:'', url: ''}]}))
        }
    }, [agentData, setProductSpecialties, setLanguages, setSocialMedia, setAdditionalPhone, setSupportedCommunication, setOperationHours, setOtherInfo, setSummary, setSpecialties, setGroups, setCurrentJob, setPastJobs, setEducation, setPreferredContactInfo, setImage, setProfileAdmins ]);

    const createSavedObject = async () => {
        var OperatingTimes: any[] = [];
        var index: number = 0;

        operationHours.opHours.forEach(element => {
            OperatingTimes.push({
                day: hoursOfOperation[index].day,
                start: element.open? element.hours.begin : "-----",
                stop: element.open? element.hours.end : "-----",
                id: hoursOfOperation[index].id,
                dayName: hoursOfOperation[index].dayName,
                agentProfileId: hoursOfOperation[index].agentProfileId
            });
            index++;
        });

        let coverPhotoID: string = agentData?.AgentData.CoverPhotoBlobId ? agentData.AgentData.CoverPhotoBlobId : "";
        let newPhoto: ImageFile | undefined = image.mainPhoto.find(x => !x.IsDeleted && x.Blob_Id === "");
        
        if (newPhoto) {
            if(newPhoto.base64)
                newPhoto.base64 = null;
            var imageBlob = await fetch(newPhoto.url).then(r => r.blob());
            var imageFile = new File([imageBlob], newPhoto.name, { type: "image/png" });
            let id = await savePhoto(imageFile, agentData?.AgentData.ClientSessionToken ? agentData.AgentData.ClientSessionToken : "", true)
            if (id)
                coverPhotoID = id.replaceAll('/blobs/', '').replaceAll('/FAAProfilesMgt', '');
        }else{
            if(image.mainPhoto.find(x => x.IsDeleted)){
                //photo removed, and no new image has been added
                coverPhotoID ="";
            }
        }

        const newAgentData = {
            ...agentData?.AgentData,
            preferredEmail: preferredContactInfo.email === agentData?.AgentData.email ? "" : preferredContactInfo.email,
            preferredName: preferredContactInfo.name === nameCase(agentData?.AgentData.name as string) ? "" : preferredContactInfo.name,
            preferredPhone: preferredContactInfo.phone.match(/\d+/g)?.join('') === agentData?.AgentData.phone.match(/\d+/g)?.join('') ? "" : preferredContactInfo.phone,
            otherPhone: additionalPhone.otherPhone.match(/\d+/g)?.join(''),
            selectedOtherPhoneType: additionalPhone.selectedOtherPhoneType,
            otherPhoneNA: !savedToggle.addPhone,
            googleChat: supportedCommunication.googleChat,
            skype: supportedCommunication.skype,
            supportedCommunicationChannelsNA: !savedToggle.supportedComms,
            agentOffHours: operationHours.otherhours,
            agentOperatingTimes: OperatingTimes,
            agentTimeZone: operationHours.timezone,
            facebookUrl: socialMedia.facebookUrl,
            linkedInUrl: socialMedia.linkedInUrl,
            socialMediaNA: !savedToggle.socialMedia,
            yearStarted: otherInfo,
            productSpecialties: productSpecialties,
            languages: languages,
            languagesNA: !savedToggle.languages,
            summary: summary,
            specialties: specialties,
            groupsAndAssociations: groups ? groups : [],
            groupsAndAssociationsNA: !savedToggle.groups,
            currentJobs: currentJob,
            pastJobs: pastJobs ? pastJobs : [],
            pastJobsNA: !savedToggle.pastJobs,
            educationList: education ? education : [],
            educationNA: !savedToggle.education,
            CoverPhotoBlobId: coverPhotoID,
            CoverPhotoList: image.mainPhoto,
            percentComplete: checklistProgress,
            notes : notes,
            useAgencyPrimaryLocation: useAgencyPrimaryLocation,
            addressLine1: addressLine1,
            profileAdmins: profileAdmins,
            agencyProfileOtherOfficeId: agencyProfileOtherOfficeId,
            selectedLocation: await getLocation(agencyProfileOtherOfficeId)
        }

        console.log("Data to be published",newAgentData);
        return (newAgentData);
    }

    const saveChanges = async () => {

        const newAgentData = await createSavedObject();

        if (agentId && agencyId) {
            await saveAgentChanges(newAgentData)
            toast.info("Changes to your profile have been saved. To make these changes viewable, please select the Approve and Publish button.", {
                backgroundColor: "#28387E",
                color: "#ffffff"
            });
        }
    }

    const restoreChanges = async () => {

        const newAgentData = await createSavedObject();

        if (agentId && agencyId) {
            setShowRestoreModal(false);
            await restoreAgentChanges(newAgentData);
            try {
                await getAgentData(agentId, agencyId)
                    .then((response) => {
                        setAgentData(response);
                        setAgentProfileData(response.AgentData);
                    }).catch(() => {
                        //console.log(err);
                    });
            } catch (e) { }
        }
    };

    const publishChanges = async () => {

        const newAgentData = await createSavedObject();

        if (agentId && agencyId) {
            await publishAgentChanges(newAgentData)
            if (agency?.Agents && agentId){
                let upadateAgencyData: any = agency;
                for (var i = 0; i < upadateAgencyData?.Agents?.length; i++) {
                    if (upadateAgencyData?.Agents[i].agentId === agentId) {
                        upadateAgencyData.Agents[i]!.show = agentData?.AgentData?.show;
                        upadateAgencyData.Agents[i]!.profileStatus = !agentData?.AgentData?.show ? "Hidden" : "Published";
                        upadateAgencyData.Agents[i]!.percentComplete = checklistProgress;
                        upadateAgencyData.Agents[i]!.modifiedDate = new Date().toLocaleDateString();
                    }
                  }
                setAgent(upadateAgencyData)
            }
            navigate(`${window.env.AGENCY_PROFILE_PATH}${agencyId}`);
        };

        if ((user.Type === "EMPLOYEE" && user.IsLMAdmin === true) || (user.Type === "AGENT" && isMarketingAdmin === true)) { <AgentNotesModal publish={publishChanges} notes={notes} edit={true} updateEdit={setEditNotes} cancel={cancelHandler}/> };
        if (user.Type === "AGENT" && isMarketingAdmin === false ) { <AgentNotesModalNonAdmin publish={publishChanges} notes={notes} profileAdmins={profileAdmins} edit={true} updateEdit={setEditNotes} cancel={cancelHandler} /> };
    };

    /* ------------------------ Callback functions -----------------------------*/
    const updatePreferredName = (name: string) => {
        setPreferredContactInfo({ ...preferredContactInfo, name: name });
    }
    const updatePreferredEmail = (email: string) => {
        setPreferredContactInfo({ ...preferredContactInfo, email: email });
    }
    const updatePreferredPhone = (phone: string) => {
        setPreferredContactInfo({ ...preferredContactInfo, phone: phone });
    }

    const updateProducts = (products: ProductSpecialty[]) => {
        setProductSpecialties(products);
    }

    const updateLanguages = (languages: Language[]) => {
        setLanguages(languages);
    }

    const updateFacebook = (facebook: string) => {
        setSocialMedia({ ...socialMedia, facebookUrl: facebook });
    }

    const updateLinkedIn = (linkedIn: string) => {
        setSocialMedia({ ...socialMedia, linkedInUrl: linkedIn });
    }

    const updateSummary = (summary: string) => {
        setSummary(summary);
    }

    const updateSkills = (skills: string) => {
        setSpecialties(skills);
    }

    const updateOtherPhone = (otherPhone: string) => {
        setAdditionalPhone({ ...additionalPhone, otherPhone: otherPhone });
    }
    const updateOtherPhoneType = (otherPhoneType: string) => {
        setAdditionalPhone({ ...additionalPhone, selectedOtherPhoneType: otherPhoneType });
    }

    const updateAgentSince = (agentSince: string) => {
        setOtherInfo(agentSince);
    }

    const updateSkype = (skype: string) => {
        setSupportedCommunication({ ...supportedCommunication, skype: skype });
    }

    const updateGoogleChat = (googleChat: string) => {
        setSupportedCommunication({ ...supportedCommunication, googleChat: googleChat });
    }

    const updateCommunicationsNA = (communicationsNA: boolean) => {
        setSupportedCommunication({ ...supportedCommunication, supportedCommunicationChannelsNA: communicationsNA });
    }

    const updateUseAgencyPrimaryLocation = (useAgencyPrimaryLocation: boolean) => {
        setUseAgencyPrimaryLocation(useAgencyPrimaryLocation);
    }

    const updateAgencyProfileOtherOfficeId = (agencyProfileOtherOfficeId: any) => {
        setAgencyProfileOtherOfficeId(agencyProfileOtherOfficeId);

    }

    const getLocation = async(id: string) => {
        let addr: any = "";
        agentData?.AgentData.otherOfficeLocations.forEach(item =>{
            if (item.Id === Number(id)){
                addr = item;
        } });
        return addr;
    }

    const updateAddressLine1 = (addressLine1: string) => {
        setAddressLine1(addressLine1);
    }

    const updateGroups = (groups: Group[]) => {
        setGroups(groups);
    }

    const updateEducation = (education: Education[]) => {
        setEducation(education);
    }

    const updatePastJobs = (pastJobs: Job[]) => {
        setPastJobs(pastJobs);
    }

    const updateCurrentJobs = (currentJob: Job[]) => {
        setCurrentJob(currentJob);
    }

    /* ------------------------ End Callback functions -------------------------*/

    const onPreviewClick = () => {


        setPreviewModal(true);

        let url = `${window.env.PREVIEW_AGENT}/agency/${agencyId}/agent/${agentId}`;
        setPreviewURL(url);
    }

    const formatLanguages = () => {
        let languagesCopy: { english_Language_Name: string; }[] = [];

        languages.forEach(language => {
            if (language.selected) {
                languagesCopy.push({ english_Language_Name: language.englishLanguageName });
            }

        });

        return languagesCopy;
    }

    const convertOperationHours = () => {

        var OperatingTimes: any[] = [];

        operationHours.opHours.forEach((element, index) => {
            if (element.open) {
                OperatingTimes.push({ day: dayAbrvs[index], start: element.hours.begin, stop: element.hours.end });
            }


        });

        return OperatingTimes;

    };

    const onIFrameLoad = async () => {

        let previewAgentObj: any = {
            isAgentData: true,
            preview: true,
            coverPhotoRaw: getMatchingPhoto('url') !== '' ? getMatchingPhoto('url') : undefined,
            summary: summary,
            coverPhotoBlob_Id: getMatchingPhoto('id') !== '' ? getMatchingPhoto('id') : undefined,
            currentJobs: (!currentJob[0]?.company || !currentJob[0]?.title) ? null : currentJob,
            educations: (!education[0]?.fieldOfStudy || !education[0]?.schoolName) ? null : education,
            facebookUrl: socialMedia.facebookUrl,
            groupsAndAssociations: (!groups[0]?.name) ? null : groups,
            linkedInMemberToken: '', // what was this for?
            linkedInUrl: socialMedia.linkedInUrl,
            otherPhone: additionalPhone.otherPhone ? additionalPhone.otherPhone.replace(/\s|-|\(|\)/g, '') : null,
            pastJobs: (!pastJobs[0]?.company || !pastJobs[0]?.title) ? null : pastJobs,
            profileStatus: '',
            specialties: specialties,
            productSpecialtiesNA: false,
            workflowStatus: '',
            currentJobsNA: (!currentJob[0]?.company || !currentJob[0]?.title) ? true : false, //not sure if used or not?
            educationNA: (!education[0]?.fieldOfStudy || !education[0]?.schoolName) ? true : false,
            groupsAndAssociationsNA: (!groups[0]?.name) ? true : false,
            languagesNA: languages.length === 0 ? true : false,
            otherPhoneNA: false,
            pastJobsNA: (!pastJobs[0]?.company || !pastJobs[0]?.title) ? true : false,
            supportedCommunicationChannelsNA: false,
            useAgencyPrimaryLocation: useAgencyPrimaryLocation,
            addressLine1: addressLine1,
            profileId: 0,
            show: false,
            offHours: false,
            yearStarted: otherInfo,
            percentComplete: 100,
            modifiedBy: '',
            modifiedDate: '',
            productSpecialties: productSpecialties.filter(x => x.selected)?.length ? productSpecialties.filter(x => x.selected) : null,
            languages: formatLanguages().length ? formatLanguages() : null,
            agentModel: {
                name: agentData ? agentData.AgentData.name : "",
                agencyID: agencyId ? agencyId?.toString() : "",
                agentID: agentId ? agentId?.toString() : "",
                phone: agentData ? agentData.AgentData.phone : "",
                email: agentData ? agentData.AgentData.email : "",
                position: ''
            },
            socialMediaNA: false,
            operatingTimes: convertOperationHours(),
            timeZone: agentData ? agentData.AgentData.agentTimeZone : "",
            preferredEmail: preferredContactInfo.email,
            preferredName: preferredContactInfo.name,
            preferredPhone: preferredContactInfo.phone ? preferredContactInfo.phone.replace(/\s|-|\(|\)/g, '') : null,
            skype: supportedCommunication ? supportedCommunication.skype : '',
            googleChat: supportedCommunication ? supportedCommunication.googleChat : ''
        };
        if (iframe && iframe.current) {
            iframe.current.contentWindow?.postMessage(previewAgentObj, previewURL);
            iFrameResize({}, "#agentPreviewFrame")
        }
    }

    useEffect(() => {
        calculateProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayChecklist]);

    const calculateProgress = () => {
        var percentCount = 0;
        
        if (displayChecklist.addressAndPhone === true && 
            displayChecklist.onlineRes === true ) { 
            percentCount += 25; 
        } else {
            percentCount += 25;
        }

        if (displayChecklist.addPhone === true) { percentCount += 10; }

        if (displayChecklist.supportedComms === true) { percentCount += 6; }

        if (displayChecklist.hoursAvailable === true) { percentCount += 10; }

        if (displayChecklist.socialMedia === true) { percentCount += 11; }

        if (displayChecklist.otherInfo === true) { percentCount += 5; }

        if (displayChecklist.productSpecialties === true) { percentCount += 3; }

        if (displayChecklist.languages === true) { percentCount += 5; }

        if (displayChecklist.summary === true) { percentCount += 10; }

        if (displayChecklist.specialties === true) { percentCount += 3; }

        if (displayChecklist.groups === true) { percentCount += 3; }

        if (displayChecklist.currentJob === true) { percentCount += 2; }

        if (displayChecklist.pastJobs === true) { percentCount += 2; }

        if (displayChecklist.education === true) { percentCount += 2; }

        if (displayChecklist.image === true) { percentCount += 3; }

        setChecklistProgress(percentCount);
    }

    useEffect(() => {
        validateChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentData, preferredContactInfo, additionalPhone, supportedCommunication, operationHours, socialMedia, otherInfo, productSpecialties, languages, summary, specialties, groups, currentJob, pastJobs, education, deleteModalClose2, clearLanguages, image, savedToggle.firstRun]);

    const validateChecklist = () => {
        var demoContents = {
            addressAndPhone: false,
            onlineRes: false,
            addPhone: false,
            supportedComms: false,
            hoursAvailable: false,
            socialMedia: false,
            otherInfo: false,
            productSpecialties: false,
            languages: false,
            summary: false,
            specialties: false,
            groups: false,
            currentJob: false,
            pastJobs: false,
            education: false,
            image: false,
            isAdmin: false,
        };

        if (agentData?.AgentData.addressLine1 &&
            agentData.AgentData.city &&
            agentData.AgentData.state &&
            agentData.AgentData.zip &&
            agentData.AgentData.phone
        ) {
            demoContents.addressAndPhone = true;
        }

        if (agentData?.AgentData.email || agentData?.AgentData.preferredEmail || preferredContactInfo.email){
            demoContents.onlineRes = true;
        }

        if (additionalPhone.otherPhone.length === 14 && additionalPhone.selectedOtherPhoneType.length !== 0) {
            demoContents.addPhone = true;
        }

        if (supportedCommunication.googleChat.length !== 0 || supportedCommunication.skype.length !== 0) {
            demoContents.supportedComms = true;
        }

        if (operationHours.otherhours) {
            demoContents.hoursAvailable = true;
        }
        else {
            var validHours = false;
            operationHours.opHours.forEach(element => {
                if (element.hours.begin !== "-----" && element.hours.end !== "-----" && operationHours.timezone !== "-----") {
                    validHours = true;
                }
            });
            if (validHours) {
                demoContents.hoursAvailable = true;
            }
        }

        if (socialMedia.facebookUrl.length > 7 || socialMedia.linkedInUrl.length > 7) {
            demoContents.socialMedia = true;
        }

        if (otherInfo) {
            if (otherInfo.length === 4 && Number(otherInfo) >= 1850) {
                demoContents.otherInfo = true;
            }
        }

        var selectedProducts: Number[] = [];
        productSpecialties.forEach(product => {
            if (product.selected) {
                selectedProducts.push(product.id);
            }
        });
        if (selectedProducts.length !== 0) {
            demoContents.productSpecialties = true;
        }

        if (clearLanguages === true) {
            var demoLanguages = languages;
            demoLanguages.forEach(language => {
                if (language.selected) {
                    demoLanguages[demoLanguages.indexOf(language)].selected = false;
                }
            })
        }
        else {
            var selectedLanguages: string[] = [];
            languages.forEach(language => {
                if (language.selected) {
                    selectedLanguages.push(language.languageCode);
                }
            });
            if (selectedLanguages.length !== 0) {
                demoContents.languages = true;
            }
        }

        if (summary.length !== 0) {
            demoContents.summary = true;
        }

        if (specialties.length !== 0) {
            demoContents.specialties = true;
        }

        var groupFlag = true;
        if (groups.length === 0) {
            groupFlag = false;
        }
        else {
            groups.forEach(el => {
                if (el.name.length === 0) {
                    groupFlag = false;
                }
            })
        }
        if (groupFlag === true) {
            demoContents.groups = true;
        }

        var currentJobFlag = true;
        if (currentJob.length === 0) {
            currentJobFlag = false;
        }
        else {
            currentJob.forEach(el => {
                if (el.title !== null && el.company !== null) {
                    if (el.title.length === 0 || el.company.length === 0) {
                        currentJobFlag = false;
                    }
                }
            })
        }
        if (currentJobFlag === true) {
            demoContents.currentJob = true;
        }

        var pastJobsFlag = true;
        if (pastJobs.length === 0) {
            pastJobsFlag = false;
        }
        else {
            pastJobs.forEach(el => {
                if (el.title !== null) {
                    if (el.title.length === 0 || el.company.length === 0) {
                        pastJobsFlag = false;
                    }
                }
            })
        }
        if (pastJobsFlag === true) {
            demoContents.pastJobs = true;
        }

        var educationFlag = true;
        if (education.length === 0) {
            educationFlag = false;
        }
        else {
            education.forEach(el => {
                if (el.schoolName !== null) {
                    if (el.schoolName.length === 0) {
                        educationFlag = false;
                    }
                }
            })
        }
        if (educationFlag)
            demoContents.education = true;

        if (image.mainPhoto.find((element:ImageFile) => element.IsDeleted === false))
            demoContents.image = true;

        setChecklistContents({
            ...checkListContents,
            addPhone: demoContents.addPhone,
            supportedComms: demoContents.supportedComms,
            socialMedia: demoContents.socialMedia,
            languages: demoContents.languages,
            groups: demoContents.groups,
            pastJobs: demoContents.pastJobs,
            education: demoContents.education
        })

        if (savedToggle.addPhone === true) { demoContents.addPhone = true; }

        if (savedToggle.supportedComms === true) { demoContents.supportedComms = true; }

        if (savedToggle.socialMedia === true) { demoContents.socialMedia = true; }

        if (savedToggle.languages === true) { demoContents.languages = true; }

        if (savedToggle.groups === true) { demoContents.groups = true; }

        if (savedToggle.pastJobs === true) { demoContents.pastJobs = true; }

        if (savedToggle.education === true) { demoContents.education = true; }

        setDisplayModal({
            ...displayModal,
            addPhone: false,
            supportedComms: false,
            socialMedia: false,
            languages: false,
            groups: false,
            pastJobs: false,
            education: false
        })
        setDisplayChecklist(demoContents);
    }

    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
    function simulateMouseClick(element: HTMLElement) {
        //Added a check to element to be not null
        if(element){
            mouseClickEvents.forEach(mouseEventType =>
                element.dispatchEvent(
                    new MouseEvent(mouseEventType, {
                        view: window,
                        bubbles: true,
                        cancelable: true,
                        buttons: 1
                    })
                )
            )
        }
    }

    const showStatusToast = (name: string, bool: boolean) => {
        var toastText = (bool === true ? name + " will be set to be show" : name + " will be set to be hidden") + " on Safeco's Find An Agent Portal once the change is published. Note: It may take up to 24 hours for the change to be reflected in the Safeco Find An Agent Portal search results."
        toast.info(toastText, {
            backgroundColor: "#28387E",
            color: "#ffffff"
        });
    }

    const agentToggle = () => {
        if (agentData === undefined || (user.Type === "EMPLOYEE" && user.IsLMAdmin === false)) {
            return (<Toggle checked={false} disabled dynamicWidth labelVisual={"Show Profile"} showOnOff={false} />);
        }
        else {
            return(<Toggle checked = {agentData?.AgentData.show} dynamicWidth labelVisual={"Show Profile"} showOnOff = {false} onChange = {() => {
                setAgentData({...agentData, AgentData: {...agentData.AgentData, show: !agentData.AgentData.show} })
                showStatusToast(nameCase(agentData?.AgentData.name), !agentData?.AgentData.show );
            }}/>);
        }
    }

    const [isOpen, setModalIsOpen] = useState(false);
    const confirmHandler = () =>{
		setShowProcessing(true);
        setModalIsOpen(true);
    }
    const cancelHandler = () =>{
                setShowProcessing(false);
                setModalIsOpen(false);
            }
    let [, setEditNotes] = useState<boolean>(false);

    const getMatchingPhoto = (type:string) => {
        var copy = image;
        if (copy.mainPhoto !== null || copy.mainPhoto !== undefined) {
            var newMainPhotos = copy.mainPhoto.slice();
            const index = copy.mainPhoto.findIndex(element => element.IsDeleted === false);
            if (index === -1) {
                return "";
            }
            else {
                if (type === 'id') {
                    return (newMainPhotos[index].Blob_Id.toLocaleUpperCase());
                }
                else {
                    return (newMainPhotos[index].url);
                }
            }
        }
        else {
            return ("");
        }
    }

    const updateImage = (img: ImageFile) => {
        if (image.mainPhoto.length && image.mainPhoto[0].Blob_Id.length) {
            if (!img.IsDeleted)
                setImage(image => ({...image, mainPhoto:[{...image.mainPhoto[0], IsDeleted:true}, img]}));
            else if (img.IsDeleted)
                setImage(image => ({...image, mainPhoto:[{...image.mainPhoto[0], IsDeleted:true}]}));
        }
        else {
            if (!img.IsDeleted)
                setImage(image => ({...image, mainPhoto:[img]}));
            else if (img.IsDeleted)
                setImage(image => ({...image, mainPhoto:[]}));
        }
    }

    return (

            <div>
                <h6 className='breadcrumb-line'>
                    <Link className='breadcrumb-links' to={`${window.env.ROOT}`} >{"Agency List"}</Link>
                    <IconLinkCaretRight color='blue' />
                    <Link className='breadcrumb-links' to={`${window.env.AGENCY_PROFILE_PATH}${agencyId}`} >
                        {"Manage Profiles - " + (agentData !== undefined ? nameCase(agentData?.AgentData.agencyName) : "")}
                    </Link>
                    <IconLinkCaretRight color='blue' />
                    <p className='breadcrumb-p'>{((user.Type === "AGENT" || (user.Type === "EMPLOYEE" && user.IsLMAdmin === true)) ? "Edit " : "View ") + "Agent"}</p>
                </h6>

                <div className="colContainer">
                    {!agentData &&
                        <div className="loading-style">
                            <div>
                                <CircularProgressSpinner aria-label="Loading Data" size="96" />
                            </div>
                        </div>
                    }
                    {agentData &&
                        <>
                            <Heading type="h3" className='safeco-color'>{nameCase(agentData.AgentData.name+ " - " + agentData.AgentData.agencyName)}</Heading>
                            {agentToggle()}
                        </>
                    }
                </div>
                {agentData && <AgencyContactInformation
                    agentName={agentData.AgentData.name}
                    agencyName={agentData?.AgentData?.agencyName}
                    addressLine1={agentData.AgentData.addressLine1}
                    city={agentData.AgentData.city}
                    state={agentData.AgentData.state}
                    zip={agentData.AgentData.zip}
                    phone={formatPhoneNumber(agentData.AgentData.phone)}
                    email={agentData.AgentData.email}
                    agencyTimeZone={agentData.AgentData.agencyTimeZone}
                    agencyOperatingTimes={agentData.AgentData.agencyOperatingTimes}
                    updateImage={(image: ImageFile) => updateImage(image)}
                    image={image.mainPhoto?.length > 0 ? image.mainPhoto.find(x => !x.IsDeleted) : undefined}
                />
                }
{agentData &&
                <div className="pageLayoutEdit">
                <div className="agent-profile-page-column">
                        <div className='checklist-title-background'>
                            <Heading type="h4" className='checklist-title-text'>Agent Profile Updates</Heading>
                        </div>
                        <Card className="profileChecklist" >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='checklist-header-background'>
                                <Heading type="h6" style={{ marginTop: "0px" }} className='checklist-header-text'>Profile Approval Process</Heading>
                            </div>

                            <div style={{ padding: "1%" }}>
                            <p className='paragraph-style'>Agents without agency administration access will need to submit their profiles for approval.</p>

                            <p className='paragraph-style'>Steps to have your profile changes approved and published.</p>

                            <List compact>
                            <ListItem>From the list of approvers, select someone you know in the agency.</ListItem>
                            <ListItem>Follow up with the person that you selected as the approver. </ListItem>
                            <ListItem>The approver will need to open your profile in Profile Manager to approve and publish your changes.</ListItem>
                            <ListItem>The profile updates will then be published to FAA. </ListItem>
                            </List>
                            <br></br>

                            <p className='paragraph-style'>If you feel like you should have agency administration rights to update and publish both your agency and agent profiles, request access from the person that set up your agent ID.</p>
                            </div>

                        </div>
                        </Card>
                    </div>

                    <div>
                            <div className='rowContainer'>
                                <div className='flexItemRow'>
                                    <div className='flexItem'>
                                        <div className='agent-contact-information'>
                                        <PreferredAgentContactInformation
                                            preferredName={preferredContactInfo.name}
                                            preferredEmail={preferredContactInfo.email}
                                            preferredPhone={formatPhoneNumber(preferredContactInfo.phone)}
                                            otherPhone={formatPhoneNumber(additionalPhone.otherPhone)}
                                            otherPhoneType={additionalPhone.selectedOtherPhoneType}
                                            skype={supportedCommunication.skype}
                                            googleChat={supportedCommunication.googleChat}
                                            communicationsNA={supportedCommunication.supportedCommunicationChannelsNA}
                                            primaryLocation={useAgencyPrimaryLocation}
                                            addressLine1={addressLine1}
                                            agencyProfileOtherOfficeId={agencyProfileOtherOfficeId}
                                            updatePreferredName={updatePreferredName}
                                            updatePreferredEmail={updatePreferredEmail}
                                            updatePreferredPhone={updatePreferredPhone}
                                            updateOtherPhone={updateOtherPhone}
                                            updateOtherPhoneType={updateOtherPhoneType}
                                            updateSkype={updateSkype}
                                            updateGoogleChat={updateGoogleChat}
                                            updateCommunicationsNA={updateCommunicationsNA}
                                            updatePrimaryLocation={updateUseAgencyPrimaryLocation}
                                            updateAddressLine1={updateAddressLine1}
                                            otherOfficeLocations={agentData.AgentData.otherOfficeLocations}
                                            updateAgencyProfileOtherOfficeId = {updateAgencyProfileOtherOfficeId}

                                        />
                                        </div>
                                        <Card title="Core Hours of Operation" className="inner-card">
                                            <ShowAgentHoursOfOperation agencyData={agencyData} hoursOfOperation={hoursOfOperation} timeZone={timeZone} otherHours={agentOffHours} />
                                        </Card>
                                        <Card title="Products I Specialize In" className="inner-card">
                                            <Product productSpecialties={agentData.AgentData.productSpecialties} updateProductSpecialties={updateProducts} />
                                        </Card>
                                    </div>
                                    <div className='flexItem'>
                                        <AgentInformation
                                            image={image.mainPhoto?.length ? image.mainPhoto.find(x => !x.IsDeleted) : undefined}
                                            updateImage={(image: ImageFile) => updateImage(image)}
                                            groups={groups}
                                            education={education}
                                            pastJobs={pastJobs}
                                            currentJobs={currentJob}
                                            summary={summary}
                                            skills={specialties}
                                            agentSince={otherInfo}
                                            updateGroups={updateGroups}
                                            updateEducation={updateEducation}
                                            updatePastJobs={updatePastJobs}
                                            updateCurrentJobs={updateCurrentJobs}
                                            updateSummary={updateSummary}
                                            updateSkills={updateSkills}
                                            updateAgentSince={updateAgentSince}
                                        />
                                    </div>
                                </div>
                            </div>
                                <div className='flexItemRow'>
                                    <div className='flexItem'>
                                        <SocialMedia
                                            facebook={socialMedia.facebookUrl}
                                            linkedIn={socialMedia.linkedInUrl}
                                            updateFacebook={updateFacebook}
                                            updateLinkedIn={updateLinkedIn}
                                        />
                                    </div>
                                    <div className='flexItem'>
                                        <Languages
                                            languages={languages}
                                            updateLanguages={updateLanguages}
                                        />
                                    </div>
                                </div>
                            <div>
                                <CancelChangesModal open={showCancelModal} yesCancel={() => { navigate(`${window.env.AGENCY_PROFILE_PATH}${agencyId}`)}} noCancel={() => setShowCancelModal(false)}/>
                                <RestoreConfirmationModal open={showRestoreModal} yesRestore={()  => { restoreChanges(); }}  noRestore={() => setShowRestoreModal(false)}/>
                                <div className="agentPageButtons">
                                    {showProcessing &&
                                        <div className="overlay">
                                            <div className='processing'>
                                                <CircularProgressSpinner aria-label="Loading Data" size="96" />
                                            </div>
                                        </div>
                                    }
									<Button onClick={() => setShowCancelModal(true)}>Cancel</Button>
                                    {user.Type === "EMPLOYEE" && !user.IsLMAdmin
                                        ? <Button disabled>Save</Button>
                                        : <Button onClick={() => saveChanges()}>Save</Button>
                                    }
                                    <Button onClick={() => setShowRestoreModal(true)}>Restore</Button>
                                    <Button variant="primary" onClick={onPreviewClick}>Preview</Button>
                                    { (() => { if ((user.Type === "EMPLOYEE" && user.IsLMAdmin) || (user.Type === "AGENT" && isMarketingAdmin)) { return (
                                            <Button variant="primary" id="approve-and-publish" onClick={() => confirmHandler()}>Approve and Publish</Button>
                                        ) } else if (user.Type === "EMPLOYEE") { return (
                                            <Button disabled variant="primary" id="approve-and-publish">Publish</Button>
                                        ) } else if (user.Type === "AGENT" && isMarketingAdmin === false)  { return (
                                        <Button variant="primary" id="approve-and-publish" onClick={() => confirmHandler()}>Submit for Approval</Button>
                                        ) }
                                         })()
                                    }
                                    { (() => { if ( (user.Type === "EMPLOYEE" && user.IsLMAdmin) || (user.Type === "AGENT" && isMarketingAdmin === true)) { return (isOpen ? <AgentNotesModal publish={publishChanges} notes={notes} edit={true} updateEdit={setEditNotes} cancel={cancelHandler}/> : null
                                        ) } else if (user.Type === "AGENT" && isMarketingAdmin === false)  { return (isOpen ? <AgentNotesModalNonAdmin publish={publishChanges} notes={notes} profileAdmins={profileAdmins} edit={true} updateEdit={setEditNotes} cancel={cancelHandler} /> : null ) }
                                        })()
                                    }
                                </div>
                            </div>
                    </div>
                        <div className="agent-profile-page-column">
                            <div className='checklist-title-background'>
                                <Heading type="h4" className='checklist-title-text'>Profile Checklist</Heading>
                            </div>
                            <Card className="profileChecklist">
                                <LinearProgress
                                    variant="determinate"
                                    value={agentMetaViewItem?.profileStatus !=="Not Started" ? checklistProgress : 0}
                                    sx={{
                                        height: '20px !important',
                                        '& .MuiLinearProgress-bar': {
                                            background: `linear-gradient(90deg, rgba(151,225,148,1) 0%, rgba(162,222,93,1) 50%, rgba(77,160,21,1) 100%)`
                                        }
                                    }}/>
                                <p
                                    style={{
                                        marginTop: '-22.5px',
                                        position: 'relative',
                                        marginLeft: checklistProgress !== 25 ? `calc(50% - 50px)` : `calc(50% - 42px)`
                                    }}>   
                                    {agentMetaViewItem?.profileStatus !=="Not Started" ? checklistProgress + "% Complete" : "Not Started"}
                                </p>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>
                                        <div className='checklist-header-background'>
                                            <Heading type="h6" style={{ marginTop: "0px" }} className='checklist-header-text'>Contact Information</Heading>
                                        </div>
                                        <div className="indentContent">
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('officeAddressModalButton');
                                                    if(element){
                                                        simulateMouseClick(element as HTMLElement);
                                                    }
                                                }}>
                                                    <Badge className={agentData?.AgentData.otherOfficeLocations.length > 0 ? "checklistBadge" : "disableBadge"} highlightType={displayChecklist.addressAndPhone ? "positive" : "caution"}>Address</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('preferredContactModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.onlineRes ? "positive" : "caution"}>Email</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <Toggle className='checklistToggle' checked={displayChecklist.addPhone} compact={true} showOnOff={false} onChange={() => {
                                                    setDisplayChecklist({ ...displayChecklist, addPhone: !displayChecklist.addPhone })
                                                    setSavedToggle({ ...savedToggle, addPhone: !displayChecklist.addPhone })
                                                    if (!displayChecklist.addPhone === false) {
                                                        setDisplayModal({ ...displayModal, addPhone: true })
                                                    }
                                                    else {
                                                        setDisplayModal({ ...displayModal, addPhone: false })
                                                    }
                                                }}>
                                                </Toggle>
                                                {displayModal.addPhone && checkListContents.addPhone
                                                    ? <ModalDeleteContents normalOpen={true} removeType={"additionalPhone"} />
                                                    : null
                                                }
                                                <div onClick={() => {
                                                    var element = document.getElementById('preferredContactModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.addPhone ? "positive" : "caution"}>Additional Phone</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <Toggle className='checklistToggle' checked={displayChecklist.supportedComms} compact={true} showOnOff={false} onChange={() => {
                                                    setDisplayChecklist({ ...displayChecklist, supportedComms: !displayChecklist.supportedComms })
                                                    setSavedToggle({ ...savedToggle, supportedComms: !displayChecklist.supportedComms })
                                                    if (!displayChecklist.supportedComms === false) {
                                                        setDisplayModal({ ...displayModal, supportedComms: true })
                                                    }
                                                    else {
                                                        setDisplayModal({ ...displayModal, supportedComms: false })
                                                    }
                                                }}>
                                                </Toggle>
                                                {displayModal.supportedComms && checkListContents.supportedComms
                                                    ? <ModalDeleteContents normalOpen={true} removeType={"supportedCommunication"} />
                                                    : null
                                                }
                                                <div onClick={() => {
                                                    var element = document.getElementById('communicationModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.supportedComms ? "positive" : "caution"}>Chat options</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('hoursModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.hoursAvailable ? "positive" : "caution"}>Office hours</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <Toggle className='checklistToggle' checked={displayChecklist.socialMedia} compact={true} showOnOff={false} onChange={() => {
                                                    setDisplayChecklist({ ...displayChecklist, socialMedia: !displayChecklist.socialMedia })
                                                    setSavedToggle({ ...savedToggle, socialMedia: !displayChecklist.socialMedia })
                                                    if (!displayChecklist.socialMedia === false) {
                                                        setDisplayModal({ ...displayModal, socialMedia: true })
                                                    }
                                                    else {
                                                        setDisplayModal({ ...displayModal, socialMedia: false })
                                                    }
                                                }}>
                                                </Toggle>
                                                {displayModal.socialMedia && checkListContents.socialMedia
                                                    ? <ModalDeleteContents normalOpen={true} removeType={"socialMedia"} />
                                                    : null
                                                }
                                                <div onClick={() => {
                                                    var element = document.getElementById('socialMediaModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.socialMedia ? "positive" : "caution"}>Social media</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('agentSinceModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.otherInfo ? "positive" : "caution"}>Year established</Badge>
                                                </div>
                                            </GridRow>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='checklist-header-background'>
                                            <Heading type="h6" className='checklist-header-text'>Products and Languages</Heading>
                                        </div>
                                        <div className="indentContent">
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('productsModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.productSpecialties ? "positive" : "caution"}>Products</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <Toggle className='checklistToggle' checked={displayChecklist.languages} compact={true} showOnOff={false} onChange={() => {
                                                    setDisplayChecklist({ ...displayChecklist, languages: !displayChecklist.languages })
                                                    setSavedToggle({ ...savedToggle, languages: !displayChecklist.languages })
                                                    setClearLanguages(false);
                                                    if (!displayChecklist.languages === false) {
                                                        setDisplayModal({ ...displayModal, languages: true })
                                                    }
                                                    else {
                                                        setDisplayModal({ ...displayModal, languages: false })
                                                    }
                                                }}>
                                                </Toggle>
                                                {displayModal.languages && checkListContents.languages
                                                    ? <ModalDeleteContents normalOpen={true} removeType={"languages"} />
                                                    : null
                                                }
                                                <div onClick={() => {
                                                    var element = document.getElementById('languagesModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.languages ? "positive" : "caution"}>Languages</Badge>
                                                </div>
                                            </GridRow>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='checklist-header-background'>
                                            <Heading type="h6" className='checklist-header-text'>Agent Information</Heading>
                                        </div>
                                        <div className="indentContent">
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('summaryModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.summary ? "positive" : "caution"}>Summary</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('skillsModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.specialties ? "positive" : "caution"}>Skills</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <Toggle className='checklistToggle'  checked={displayChecklist.groups} compact={true} showOnOff={false} onChange={() => {
                                                    setDisplayChecklist({ ...displayChecklist, groups: !displayChecklist.groups })
                                                    setSavedToggle({ ...savedToggle, groups: !displayChecklist.groups })
                                                    if (!displayChecklist.groups === false) {
                                                        setDisplayModal({ ...displayModal, groups: true })
                                                    }
                                                    else {
                                                        setDisplayModal({ ...displayModal, groups: false })
                                                    }
                                                }}>
                                                </Toggle>
                                                {displayModal.groups && checkListContents.groups
                                                    ? <ModalDeleteContents normalOpen={true} removeType={"groups"} />
                                                    : null
                                                }
                                                <div onClick={() => {
                                                    var element = document.getElementById('groupsModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.groups ? "positive" : "caution"}>Groups</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('currentJobModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.currentJob ? "positive" : "caution"}>Current Job</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <Toggle className='checklistToggle' checked={displayChecklist.pastJobs} compact={true} showOnOff={false} onChange={() => {
                                                    setDisplayChecklist({ ...displayChecklist, pastJobs: !displayChecklist.pastJobs })
                                                    setSavedToggle({ ...savedToggle, pastJobs: !displayChecklist.pastJobs })
                                                    if (!displayChecklist.pastJobs === false) {
                                                        setDisplayModal({ ...displayModal, pastJobs: true })
                                                    }
                                                    else {
                                                        setDisplayModal({ ...displayModal, pastJobs: false })
                                                    }
                                                }}>
                                                </Toggle>
                                                {displayModal.pastJobs && checkListContents.pastJobs
                                                    ? <ModalDeleteContents normalOpen={true} removeType={"pastJobs"} />
                                                    : null
                                                }
                                                <div onClick={() => {
                                                    var element = document.getElementById('pastJobsModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.pastJobs ? "positive" : "caution"}>Past Jobs</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <Toggle className='checklistToggle' checked={displayChecklist.education} compact={true} showOnOff={false} onChange={() => {
                                                    setDisplayChecklist({ ...displayChecklist, education: !displayChecklist.education })
                                                    setSavedToggle({ ...savedToggle, education: !displayChecklist.education })
                                                    if (!displayChecklist.education === false) {
                                                        setDisplayModal({ ...displayModal, education: true })
                                                    }
                                                    else {
                                                        setDisplayModal({ ...displayModal, education: false })
                                                    }
                                                }}>
                                                </Toggle>
                                                {displayModal.education && checkListContents.education
                                                    ? <ModalDeleteContents normalOpen={true} removeType={"education"} />
                                                    : null
                                                }
                                                <div onClick={() => {
                                                    var element = document.getElementById('educationModalButton')
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.education ? "positive" : "caution"}>Education</Badge>
                                                </div>
                                            </GridRow>
                                            <GridRow className='checklistSpacing'>
                                                <div onClick={() => {
                                                    var element = document.getElementById('profileTitlePictureUpload')
                                                    if(displayChecklist.image){
                                                        element = document.getElementById('addPhotoButton')
                                                    }
                                                    simulateMouseClick(element as HTMLElement)
                                                }}>
                                                    <Badge className='checklistBadge' highlightType={displayChecklist.image ? "positive" : "caution"}>Profile photo</Badge>
                                                </div>
                                            </GridRow>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                </div>}

                <Modal
                    className="preview-modal"
                    isOpen={previewModal}
                    size="large"
                    takeover={['base', 'sm', 'md', 'lg', 'xl']}
                    onClose={() => setPreviewModal(false)}>
                    <ModalBody className="modal-body">
                        {previewModal && <iframe ref={iframe} src={previewURL} className="iframe-preview" title="Preview" id="agentPreviewFrame" name="previewFrame" scrolling="no" onLoad={onIFrameLoad}></iframe>}
                    </ModalBody>
                </Modal>
            </div>

    );
}

export default EditAgentProfile;