export const findPlaceFromPhoneNumber = async (phoneNumber: string, coords?: google.maps.LatLng) => { 

    //https://developers.google.com/maps/documentation/javascript/places
    let placesService = new google.maps.places.PlacesService(document.getElementById("grac") as HTMLDivElement);

        return new Promise(function (resolve, reject) {
            let request: google.maps.places.FindPlaceFromPhoneNumberRequest = {
                phoneNumber :`+1${phoneNumber}`,
                fields:['formatted_address','place_id','user_ratings_total','rating']
            };
            placesService.findPlaceFromPhoneNumber(request, (data, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    return resolve(data);
                }
            });
        });
}

export const getPlaceDetails = async (place_id: string | undefined) => {

    let placesService = new google.maps.places.PlacesService(document.getElementById("grac") as HTMLDivElement);

    if (place_id) {
        return new Promise(function (resolve, reject) {
            let req: google.maps.places.PlaceDetailsRequest = { fields: ['name', 'reviews'], placeId: place_id };
            placesService.getDetails(req, (data, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && data?.reviews)
                    return resolve(data.reviews.sort((a, b) => { return b.time - a.time }));

            });
        });
    }
}