import { BodyText, Heading, IconAddItem, IconButton, IconEdit, Link} from "@lmig/lmds-react";
import { useState } from "react";
import AgentSinceModal from "../../modals/AgentSinceModal";


interface AgentSinceProps {
    agentSince : string;
    updateAgentSince: (val : string) => void;
}
function AgentSinceSection(props : AgentSinceProps) {

    const [editAgentSince, setEditAgentSince] = useState<boolean>(false);

    return (<>
                    <div className="contact-information-section-edit-row">
                        <Heading type="h5">Agent Since</Heading>
                        {props.agentSince !== "" &&
                                            <IconButton id="agentSinceModalButton" onClick={()=>{setEditAgentSince(true)}}>
                                                <IconEdit className="icon-edit-custom-size" size="24"/>
                                            </IconButton>}
                    </div>
                    <div className = "filed-group-margin-items">
                        {props.agentSince !== "" ? <div className="indent-content"><BodyText>{props.agentSince}</BodyText></div>
                            :
                            <div className='filed-group-margin-items indent-content-inner'>
                                    <IconButton id="agentSinceModalButton" onClick={() => {setEditAgentSince(true)}}>
                                        <IconAddItem size = "24"></IconAddItem>
                                    </IconButton>
                                    <div className="icon-text-long">
                                        <Link className="link-general" variant="standalone" caretPosition="none" onClick={() => {setEditAgentSince(true)}}>Add year</Link>
                                    </div>
                            </div>
                        }
                    </div>
            {editAgentSince ? <AgentSinceModal agentSince={props.agentSince} edit={true} updateEdit={setEditAgentSince} updateAgentSince={props.updateAgentSince}/>
                : null}
        </>
    );
}

export default AgentSinceSection;