import { AlphanumericInput, Badge, FieldGroup, GridCol, GridRow, Heading, IconAddItem, IconButton, IconTrash, Link } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { Job } from "../../models/Agent";
import '../../styles/AgentModals.css'
import { AgentDataContext } from "../context/AgentDataContext";
import '../../styles/Modals.css';
import CancelAndSaveButtons from  '../modals/CancelAndSaveButtons';

interface JobModalProps {
    title: string,
    jobs: Job[],
    edit: boolean,
    add: boolean,
    updateEdit: (val : boolean) => void,
    updateJobs: (val : Job[]) => void,
    setAdd: (val : boolean) => void,
}

function JobModal (props : JobModalProps) {
    const [edit, setEdit] = useState<boolean>(props.edit);
    const [jobId, setJobId] = useState<number>(props.jobs.length);
    const [add, setAdd] = useState<boolean>(props.add);
    const { currentJob, setCurrentJob, pastJobs, setPastJobs } = useContext(AgentDataContext);
    const [jobs, setJobs] = useState<Job[]>(_.cloneDeep(props.title === "Current Jobs" ? currentJob : pastJobs));

    useEffect(() => {
        if(add || jobId === 0){
            setJobs([...jobs, {agentProfileId: 0,company: "",companyHtmlEncoded: null,id: 0,title: "",titleHtmlEncoded: null}]);
            setJobId(jobId + 1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    type errorsObject = {
        title: string[],
        company: string[]
    };
    const [errorMessageArray, setErrorMessageArray] = useState<errorsObject[]>([{ title: [], company: [] }, { title: [], company: [] }, { title: [], company: [] }]);

    const checkForErrors = () => {
        let errors: errorsObject[] = [{ title: [], company: [] }, { title: [], company: [] }, { title: [], company: [] }];
        jobs.forEach((job, index) => {
            if (job.title === "") {
                errors[index].title.push(`Title must have a name`);
            }
            if (job.title.length > 100) {
                errors[index].title.push("Job title cannot exceed 100 characters");
            }
            if (job.company === "") {
                errors[index].company.push(`Company must have a name`);
            }
            if (job.company.length > 100) {
                errors[index].company.push("Company name cannot exceed 100 characters");
            }
        });

        setErrorMessageArray(errors);

        let countErrors = 0;
        errors.forEach((error) => {
            countErrors = countErrors + error.title.length + error.company.length;

        });

        return countErrors;
    };

    const cancelChanges = () => {
        setEdit(false);
        setAdd(false);
        props.updateEdit(false);
        props.setAdd(false);
        setJobs(_.cloneDeep(props.title === "Current Jobs" ? currentJob : pastJobs));
        setErrorMessageArray([{ title: [], company: [] }, { title: [], company: [] }, { title: [], company: [] }]);
    };

    const renderJobs = () => {
        let temp = [...jobs];
        return temp.map((element : Job, idx : number) => {
            return (
                <FieldGroup isFull id="formField">
                    <GridRow gutterSize="fixed-16" gutters="horizontal" key={idx}>
                        <GridCol>
                            <FieldGroup isFull id="formField"
                            style={{ marginTop: errorMessageArray[idx].title.length === 0 && errorMessageArray[idx].company.length ? "1.5rem" : "0rem" }}
                                messages={
                                    errorMessageArray[idx].title.map((error) => {
                                        return {
                                            text: error,
                                            type: 'error'
                                        };
                                    })
                                }
                            >
                                <AlphanumericInput autoFocus labelVisual="Job title" name = {idx + ""} value={element.title} onChange ={(e) => {updateJobObject(e.target.name, "Job Title", e.target.value)}}/>
                            </FieldGroup>
                        </GridCol>
                        <div style={{alignItems:"center", display:"flex"}}>
                            <GridCol base={1}
                                style={{ marginTop: errorMessageArray[idx].title.length || errorMessageArray[idx].company.length ? "1.5rem" : "0rem" }}>
                                <Heading className="heading-connector" type="h6">at</Heading>
                            </GridCol>
                        </div>
                        <GridCol base={5}>
                            <FieldGroup isFull id="formField"
                            style={{ marginTop: errorMessageArray[idx].company.length === 0 && errorMessageArray[idx].title.length ? "1.5rem" : "0rem" }}
                                messages={
                                    errorMessageArray[idx].company.map((error) => {
                                        return {
                                            text: error,
                                            type: 'error'
                                        };
                                    })
                                }
                            >
                                <AlphanumericInput labelVisual="Company" name = {idx + ""} value={element.company} onChange ={(e) => {updateJobObject(e.target.name, "Company", e.target.value)}}/>
                            </FieldGroup>
                        </GridCol>
                        <div style={{alignItems:"center", display:"flex"}}>
                                <GridCol base={1}>
                                <IconButton><IconTrash  size="24" id = {idx + ""}
                                style={{ marginTop: errorMessageArray[idx].title.length || errorMessageArray[idx].company.length ? "1.5rem" : "0rem" }}
                                    onClick = {(e:any) => {console.log(e); removeJobObject(idx + ""); setErrorMessageArray([{ title: [], company: [] }, { title: [], company: [] }, { title: [], company: [] }])}}>
                                    Clear
                                </IconTrash></IconButton>
                                </GridCol>
                        </div>
                    </GridRow>
                </FieldGroup>
            )
        });
    }

    const updateJobObject = (id :string, field: string, val : string) => {
        let temp = [...jobs];
        if (field === "Company") {
            temp[parseInt(id)]["company"] = val;

        } else {
            temp[parseInt(id)]["title"] = val;
        }
        setJobs([...temp]);
    }

    const removeJobObject = (id: string) => {
        console.log("Here", id, jobs)
        let temp = [...jobs];
        temp.splice(parseInt(id), 1);
        setJobId(temp.length);
        setJobs([...temp]);
    }

    return (
        <Modal
        isOpen={edit}
        size="small"
        onClose={() => {cancelChanges()}}
        >
        <ModalHeader><Heading className="modal-heading">{props.title}</Heading></ModalHeader>
        <ModalBody className="modal-body">
            <FieldGroup isFull id="formField">
                    {renderJobs()}
                    <>
                        {jobId < 3 ?
                        <GridRow gutters="horizontal" gutterSize="fixed-16">
                                <div style={{alignItems:"center", display:"flex"}}>
                                    <IconButton onClick={()=>{setJobs([...jobs, {agentProfileId: 0,company: "",companyHtmlEncoded: null,id: 0,title: "",titleHtmlEncoded: null}]); setJobId(jobId + 1);}}>
                                        <IconAddItem size = "24"></IconAddItem>
                                    </IconButton>
                                </div>

                                <Link  className="link-general" variant="standalone" caretPosition="none" onClick={()=>{setJobs([...jobs, {agentProfileId: 0,company: "",companyHtmlEncoded: null,id: 0,title: "",titleHtmlEncoded: null}]); setJobId(jobId + 1);}}>
                                {props.title === "Current Jobs"
                                    ?   jobId === 0
                                        ?"Add current role"
                                        :"Add another current role"
                                    :   jobId === 0
                                        ? "Add past jobs"
                                        :"Add another past job"
                                }
                                </Link>
                        </GridRow>
                        : <Badge style={{marginTop:"1rem"}}highlightType="caution">Only up to 3 jobs may be added</Badge>}
                    </>
            </FieldGroup>
        </ModalBody>
        <ModalFooter className="modal-footer">
            <CancelAndSaveButtons
                cancelFunction={cancelChanges}
                saveFunction={() => {
                    if(checkForErrors() === 0){
                        setEdit(false);
                        setAdd(false);
                        props.updateEdit(false);
                        props.setAdd(false);
                        props.updateJobs([...jobs]);
                        props.title === "Current Jobs" ? setCurrentJob(_.cloneDeep(jobs)) : setPastJobs(_.cloneDeep(jobs));}}}/>
        </ModalFooter>
        </Modal>
    )
}
export default JobModal;
