import { useEffect, useRef, useState } from 'react';
import "../../../styles/EditAgency.css";
import "../../../styles/Modals.css";
import { Link, useParams } from "react-router-dom";
import { Button, Card, FieldGroup, Form, Heading, IconLinkCaretRight, Toggle, GridRow, Badge, Notification, Link as InlineLink} from '@lmig/lmds-react';
import { Modal, ModalBody} from '@lmig/lmds-react-modal';
import { AgencyMetadata } from '../../../models/Agency';
import { formatPhoneNumber, getAgencyProfileData, getAssociatedAgencyData, getAllAgencies, savePhoto } from '../../../services/FAAApi';
import { getAgencyById, getMatchingAgencies } from '../../../services/FAAApi';
import { CircularProgressSpinner } from '@lmig/sales-ui-components';
import ShowHoursOfOperation from './sections/HoursOfOperation/ShowHoursOfOperation';

import { formatAgencyName, nameCase } from '../../../helpers/StringFormatter';
import ShowPreferredContactInfo from './sections/PreferredContactInfo/ShowPreferredContactInfo';

import ShowOfficeLocations, { officeLocation } from './sections/OfficeLocations/ShowOfficeLocations';
import ShowAboutAgency from './sections/AboutAgency/ShowAboutAgency';
import { useContext } from "react";
import { ImageFile, ProfileDataContext } from '../../context/ProfileDataContext';
import Products from './Products';
import Carriers from './Carriers';

import ShowSocialMedia from './sections/SocialMedia/ShowSocialMedia';
import ShowPhotos from './sections/Photos/ShowPhotos';

import { useNavigate } from 'react-router';
import ShowArticles from './sections/Articles/ShowArticles';
import { publishAgencyChanges, publishAssociatedChanges } from '../../../services/FAAApi';
import { toast } from 'react-toast';
import LinearProgress from '@mui/material/LinearProgress';
import ShowAwardsAffiliations from './sections/AwardsAndAffiliations/ShowAwardsAffiliations';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ModalDeleteContents from './sections/ModalDeleteContents';
import AgentList from './AgentList';
import NotesModal from '../../modals/AgencyNotesModal';

import { dayAbrvs } from '../../../constants/time';
import { IModalsContext, ModalsContext, tiles } from '../../context/ModalsContext';
import ShowGoogleReviews from './sections/GoogleReviews/ShowGoogleReviews';
import CancelChangesModal from '../../modals/cancelChangesModal';
import MainPhotoHeader from './MainPhotoHeader';
import { AgencyPreview } from '../../../models/AgencyPreview';
import { AgenciesContext } from '../../context/AgenciesContext';
import { UserContext } from '../../context/UserContext';
import CropModal from './sections/Photos/CropModal';
import _ from "lodash";
import { guid, checkImageSize } from '../../../helpers/Helper';
let iFrameResize = require("iframe-resizer/js/iframeResizer.js");


function EditAgency() {
    let navigate = useNavigate();
    const {id} = useParams();
    const modalsContext = useContext<IModalsContext>(ModalsContext);
    const { agency, setAgency } = useContext(AgenciesContext);
    const [ agenciesToPublish, setAgenciesToPublish] = useState<string[]>([]);
    const [ agenciesData, setAgenciesData] = useState<any[]>([]);
    const [ awaitingRelatedAgencies, setAwaitingRelatedAgencies] = useState<boolean>(false);
    const [ awaitingAgenciesData, setAwaitingAgenciesData] = useState<boolean>(false);
    const [associatedAgencyIds, setAssociatedAgencyIds] = useState<string[]>([]);
    const { user, setUser, loaded } = useContext(UserContext);
    const { profileData, setProfileData, aboutAgency, operationHours, preferredContactInfo, officeLocations, setOfficeLocations, photos, setPhotos, socialMediaLinks, articles, awards, affiliations, deleteModalClose, carriers, products, sortedAgentDataList, showReviews, notes , setModalOfficeOptions, gaqDisabled  } = useContext(ProfileDataContext)
    const [agentListLoaded, setAgentListLoaded] = useState<boolean>(false);
    const [agencyLoaded, setAgencyLoaded] = useState<boolean>(false);
    const [metaData, setMetaData] = useState<AgencyMetadata>();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [checklistProgress, setChecklistProgress] = useState(0);
    const [previewURL, setPreviewURL] = useState<string>("*");
    const [previewModal, setPreviewModal] = useState<boolean>();
    const [previewObj, setPreviewObj] = useState<AgencyPreview>();
    const [isMarketingAdmin, setIsMarketingAdmin] = useState<boolean>(true);
    const [displayType, setDisplayType] = useState('');
    const iframe = useRef<HTMLIFrameElement>(null);
	const [showProcessing, setShowProcessing] = useState(false);
    const profilePicInputRef = useRef<HTMLInputElement>(null);
    const galleryPicInputRef = useRef<HTMLInputElement>(null);
    const [prospectiveProfilePic, setProspectiveProfilePic] = useState<ImageFile>();

    const [open, setOpen] = useState<boolean>(false);
    const [checkListContents, setChecklistContents] = useState({
        contactInfo: false,
        addOfficeLocations: false,
        socialMedia: false,
        otherCarriers: false,
        professionalAffiliations: false,
        awards: false,
        articles: false,
        galleryPhotos: false
    })
    const [savedToggle, setSavedToggle] = useState({
        firstRun: false,
        contactInfo: false,
        addOfficeLocations: false,
        socialMedia: false,
        otherCarriers: false,
        professionalAffiliations: false,
        awards: false,
        articles: false,
        galleryPhotos: false
    })
    const [displayChecklist, setDisplayChecklist] = useState({
        address: false,
        onlineRes: false,
        moreInfo: false,
        contactInfo: false,
        coreHours: false,
        addOfficeLocations: false,
        socialMedia: false,
        productSpecialties: false,
        otherCarriers: false,
        aboutAgency: false,
        yearEst: false,
        awards: false,
        professionalAffiliations: false,
        articles: false,
        mainPhoto: false,
        galleryPhotos: false
    })
    const [displayModal, setDisplayModal] = useState({
        contactInfo: false,
        addOfficeLocations: false,
        socialMedia: false,
        otherCarriers: false,
        professionalAffiliations: false,
        awards: false,
        articles: false,
        galleryPhotos: false
    })

    const theme = createTheme({
        palette: {
            primary: {
                main: '#28A3AF',
            },
        },
    })
  
    useEffect(() => {
        document.title="Agency Profile";

        const getAgency = async () => {
            try {
                if(!agencyLoaded){
                    setAgencyLoaded(true);
                    await getAgencyById(id)
                        .then((response) => {
                            setMetaData(response);
                            setAgency({ AgencyId: response.agencyId, AgencyName: response.agencyName, FollowUp: response.followUp, PercentComplete: response.percentComplete, ModifiedDate: response.modifiedDate, Show: response.show === "True", ProfileStatus: response.profileStatus, AgencyLocationId: response.agencyLocationId});
                        }).catch(err => {

                        });
                    await getAgencyProfileData(id)
                    .then((response) => {
                        setIsMarketingAdmin(true)
                        setProfileData(response);
                        setSavedToggle({
                            firstRun: true,
                            contactInfo: !!response.OtherContactInfoNA,
                            addOfficeLocations: !!response.AdditionalOfficeLocationNA,
                            socialMedia: !!response.SocialMediaNA,
                            otherCarriers: !!response.CarriersNA,
                            awards: !!response.AwardsNA,
                            professionalAffiliations: !!response.AffiliationsNA,
                            articles: !!response.ArticlesNA,
                            galleryPhotos: !!response.AgencyImagesNA
                        }); 
                    }).catch((err) => {
                        if (err.response.data.indexOf("403") !== -1)
                            setIsMarketingAdmin(false);
                    });
                } 
                else if(agency && agencyLoaded){
                    setMetaData({ agencyId: agency.AgencyId, agencyLocationId: agency.AgencyLocationId, agencyName:agency.AgencyName, followUp: agency.FollowUp, modifiedDate: agency.ModifiedDate, percentComplete:agency.PercentComplete, profileStatus:agency.ProfileStatus, show:agency.Show ? "True" : "False"});
                }
            }
            catch (e) {
                setAgencyLoaded(false);
            }
        }

        const relatedAgencies = async () =>{
            if(associatedAgencyIds.length === 0 && !awaitingRelatedAgencies && id){
                try{
                    setAwaitingRelatedAgencies(true);
                    await getMatchingAgencies(id.toString().slice(8,))
                        .then((response)=>{
                            let agencyIds : string[] = [];
                            response.agencies.ListItems.forEach((agencyResponse)=>{
                                agencyIds.push(agencyResponse.AgencyId.toString());
                            });
                            setAssociatedAgencyIds(agencyIds);
                        }).catch(err => {
                        });
                } catch (e) {

                }
            }
        }

        const relatedData = async () =>{
            if(associatedAgencyIds.length > 0 && agenciesData.length === 0 && !awaitingAgenciesData && agency){
                try{
                    setAwaitingAgenciesData(true);
                    Promise.allSettled(associatedAgencyIds.map((aaId)=>{
                        return getAssociatedAgencyData(aaId)
                    })).then((responseArr)=>{
                        let temp: any[] = [];
                        responseArr.forEach((response)=>{ 
                            if(response.status !== "rejected"){
                                temp.push(response.value);
                            }
                        })
                        setAgenciesData(temp);
                        setAwaitingAgenciesData(false);
                    })
                } catch (e) {
                    setAwaitingAgenciesData(false);
                }
            }
        }

        const getUserType = async () => {
            try {
                await getAllAgencies()
                    .then((response) => {
                        setUser(response.agencies.User);
                        if (response.agencies.User.Type === "AGENT" || (response.agencies.User.Type === "EMPLOYEE" && response.agencies.User.IsLMAdmin === true)) {
                            setDisplayType("editProfile");
                        }
                        else {
                            setDisplayType("viewProfile");
                        }
                    }).catch(err => {
                        //console.log(err);
                    });
            } catch (e) { }
        }
  
        if (!loaded)
            getUserType();
           
        else
            setDisplayType(user.Type === "AGENT" || (user.Type === "EMPLOYEE" && user.IsLMAdmin) ? "editProfile" : "viewProfile")

        getAgency();
        relatedAgencies();
        relatedData();

    }, [id, setProfileData, agency,loaded, setAgency, setUser, user.IsLMAdmin, user.Type, agenciesData, associatedAgencyIds, profileData, awaitingAgenciesData, setAwaitingAgenciesData, awaitingRelatedAgencies, agencyLoaded]);

    const formatPhone = (phoneNumber: string | undefined) => {
        if (phoneNumber)
            return phoneNumber.replaceAll('(', '').replaceAll(')', '-').replaceAll(' ', '');
    }

    const isEmpty = (field?: string) => {
        return field === '---' || field === '' || field === undefined || field === null;
    }

    const prepareDataForPost = () => {
        const newAbout = { About: aboutAgency.summary, Year: aboutAgency.yearEstablished };

        var selectedCarriers: Number[] = [];
        carriers.forEach(carrier => {
            if (carrier.selected) {
                selectedCarriers.push(carrier.id);
            }
        });
        var selectedProducts: Number[] = [];
        products.forEach(product => {
            if (product.selected) {
                selectedProducts.push(product.id);
            }
        });
        var OperatingTimes: any[] = [];
        operationHours.opHours.forEach(element => {
            OperatingTimes.push({ Day: element.dayAbrv, Start: element.hours.begin, Stop: element.hours.end });

        });

        var OtherOfficeLocations: any[] = [];
        officeLocations.forEach((location) => {
            if (location.isNew === 'true' && location.isDeleted === 'false') {
                OtherOfficeLocations.push({ AddressLine1: location.addressLine1, AddressLine2: location.addressLine2, City: location.city, State: location.state, Zip: location.zip, Phone: formatPhone(location.phone), OfficeName: location.officeName, IsNew: true, IsDeleted: false });
                return;
            }

            if (location.isNew === 'false' && location.isDeleted === 'true') {
                OtherOfficeLocations.push({ AddressLine1: location.addressLine1, AddressLine2: location.addressLine2, City: location.city, State: location.state, Zip: location.zip, Phone: formatPhone(location.phone), OfficeName: location.officeName, IsNew: false, IsDeleted: true, Id: location.id ? Number.parseInt(location.id) : undefined });
                return;
            }

            if (location.isNew === 'false' && location.isDeleted === 'false') {
                OtherOfficeLocations.push({ AddressLine1: location.addressLine1, AddressLine2: location.addressLine2, City: location.city, State: location.state, Zip: location.zip, Phone: formatPhone(location.phone), OfficeName: location.officeName, IsNew: false, IsDeleted: false, Id: location.id ? Number.parseInt(location.id) : undefined });
                return;
            }
        })

        let newCoverPhotos: ImageFile[] = [];
        let newPhotos: ImageFile[] = [];

        photos.mainPhoto.forEach((el) => {
            if (!el.IsDeleted)
                newCoverPhotos.push(el);
        })

        photos.galleryPhotos.forEach((el) => {
            if (!el.IsDeleted)
                newPhotos.push(el);
        })

        const socialMediaObj = { FacebookUrl: socialMediaLinks.facebook, LinkedInUrl: socialMediaLinks.linkedIn, TwitterUrl: socialMediaLinks.twitter, SocialMediaNA: savedToggle.socialMedia };

        var profileArticles: any[] = [];
        articles.forEach(el => {
            if (el.Title !== "") {
                profileArticles.push({ Title: el.Title, Url: el.Url });
            }

        });
        for (let i = 0; i < 3 - articles.length; i++) {
            profileArticles.push({ Title: "", Url: "" });
        }

        var Awards: any[] = [];
        awards.forEach(el => {
            if (el.name !== "") {
                Awards.push({ Name: el.name, YearReceived: el.yearRecieved });
            }

        });
        for (let i = 0; i < 3 - awards.length; i++) {
            Awards.push({ Name: "", YearReceived: "" });
        }
        const originalOptions: string[] = ['CPCU', 'NAIFA', 'PIA', 'NAHU', 'IIABA'];
        const selectedAffiliations = affiliations.filter(el => originalOptions.includes(el));
        const otherAffiliations = affiliations.filter(el => !originalOptions.includes(el));
        var additionalAffiliations: any[] = [];
        otherAffiliations.forEach(el => {
            additionalAffiliations.push({ Name: el });
        });

        const assignedAffiliatoinCB = { additionalAffiliations: additionalAffiliations.length === 0 ? [{ Name: "" }, { Name: "" }, { Name: "" }] : additionalAffiliations };
        const newOperationHours = { OperatingTimes, ddlTimeZone: operationHours.timezone, OffHours: operationHours.otherhours };

        let chatInfo = {};
        if (!isEmpty(preferredContactInfo.chat?.google)) {
            chatInfo = {...chatInfo, HasGoogleChat: true, GoogleChatUsername: preferredContactInfo.chat.google};

        }
        else {
            chatInfo = {...chatInfo, HasGoogleChat: false, GoogleChatUsername: ""};

        }
        if (!isEmpty(preferredContactInfo.chat?.skype)) {
            chatInfo = {...chatInfo, HasSkype: true, SkypeUsername: preferredContactInfo.chat.skype};
        }
        else {
            chatInfo = {...chatInfo, HasSkype: false, SkypeUsername: ""};
        }
        if (!isEmpty(preferredContactInfo.chat?.web)) {
            chatInfo = {...chatInfo, HasWebsiteChat: true, WebsiteChatUrl: preferredContactInfo.chat.web};
        }
        else {
            chatInfo = {...chatInfo, HasWebsiteChat: false, WebsiteChatUrl: ""};
        }

        const newPreferredContactInfo = {
            PreferredAgencyEmail: preferredContactInfo.email,
            PreferredAgencyName: preferredContactInfo.agencyName,
            PreferredAgencyPhone: formatPhone(preferredContactInfo.phone),
            PreferredAgencyWebsite: preferredContactInfo.website,
            MainContact: preferredContactInfo.mainContactName,
            MobilePhone: formatPhone(preferredContactInfo.mobile),
            OtherContactInfoNA: savedToggle.contactInfo,
            FaxNumber: formatPhone(preferredContactInfo.fax),
            ...chatInfo
        };
   
        var SelectedAvailableAgentIds: Number[] = [];
        var SelectedOrderedAgentIds: Number[] = [];

        sortedAgentDataList?.forEach((agent) => {
            if (agent.ordered) {
                if(agent.agencyId === id){
                    if(agent.position >= 0)
                        SelectedOrderedAgentIds.push(parseInt(agent.agentId));
                    else
                        SelectedAvailableAgentIds.push(parseInt(agent.agentId));
                }else{
                    SelectedOrderedAgentIds.push(0);
                }
            }
        });

        const newProfileData = {
            ...profileData,
            ...newAbout,
            ...newPreferredContactInfo,
            selectedCarriers,
            CarriersNA: savedToggle.otherCarriers,
            selectedProducts,
            ...newOperationHours,
            OtherOfficeLocations,
            AdditionalOfficeLocationNA: savedToggle.addOfficeLocations,
            CoverPhotos: newCoverPhotos,
            Photos: newPhotos,
            AgencyImagesNA: savedToggle.galleryPhotos,
            ...socialMediaObj,
            ArticlesNA: savedToggle.articles,
            profileArticles,
            Awards,
            AwardsNA: savedToggle.awards,
            AffiliationsNA: savedToggle.professionalAffiliations,
            selectedAffiliations,
            assignedAffiliatoinCB,
            PercentComplete: checklistProgress,
            SelectedOrderedAgentIds,
            SelectedAvailableAgentIds,
            Notes: notes,
            ShowGoogleReviews: showReviews,
            Show: metaData?.show === "False" ? false : true,
            isAgentData: false,
            GAQDisabled: gaqDisabled,
        };

        return newProfileData;
    };

    const publishPhoto = async (el: ImageFile, isProfilePicture: boolean) => {
        var id = el.Blob_Id;

        if (el.base64)
            el.base64 = null; //dont post long strings to the server on publish to avoid serialization exceptions.

        if (el.Blob_Id === "" && !el.IsDeleted) {
            var imageBlob = await fetch(el.url).then(r => r.blob());
            var imageFile = new File([imageBlob], el.name, { type: "image/png" });
            id = await savePhoto(imageFile, profileData.ClientSessionToken, isProfilePicture)
            id = id.replaceAll('/blobs/', '');
            id = id.replaceAll('/FAAProfilesMgt', '');
        }
        return {...el, Blob_Id: id};
    }

    const publishChanges = async () => {
        let newProfileData = prepareDataForPost();
        let copyOfAgenciesData: any[] = [];
        let newCoverPhotos: ImageFile[] = [];
        let newPhotos: ImageFile[] = [];

        //profile picture
        for (let i : number = 0; i < photos.mainPhoto.length; i++) {
            let newPhotoObj : ImageFile = await publishPhoto(photos.mainPhoto[i], true)
            newCoverPhotos.push(newPhotoObj);
        }

        //associated agencies
        agenciesToPublish.forEach((agency)=>{
            agenciesData.forEach((data)=>{
                if(agency === data.ID){
                    copyOfAgenciesData.push(prepareAssocicatedForPost(data));
                }
            })
        })

        Promise.allSettled(copyOfAgenciesData.map((data)=>{
            return publishAssociatedChanges(data.ID, {...data})
        })) 

        //agency photos
        for (let i : number = 0; i < photos.galleryPhotos.length; i++) {
            let newPhotoObj : ImageFile = await publishPhoto(photos.galleryPhotos[i], false);
            newPhotos.push(newPhotoObj);
        }

        if (id) {
            await publishAgencyChanges(id, { ...newProfileData, CoverPhotos: newCoverPhotos, Photos: newPhotos });
            if (agency && agency.AgencyId)
                setAgency({...agency, PercentComplete: checklistProgress, Show: metaData?.show === "False" ? false : true, ProfileStatus: metaData?.show === "False" ? "Hidden" : "Published", ModifiedDate:`${new Date().toLocaleDateString()}`})
            navigate(`${window.env.AGENCY_PROFILE_PATH}${id}`);
        }

        <NotesModal publish={publishChanges} notes={notes} edit={true} updateEdit={setEditNotes} cancel={cancelHandler}/>
    };

    useEffect(()=>{
        if(sortedAgentDataList?.length !== undefined && agenciesData.length > 0){
            sortedAgentDataList.forEach((agent)=>{
                if(agent.ordered && agent.agencyId !== id){
                    let add = true;
                    agenciesToPublish.forEach((agencyId)=>{
                        if(agencyId === agent.agencyId){
                            add = false;
                        }
                    })
                    if(add){
                        let temp = agenciesToPublish;
                        temp.push(agent.agencyId)
                        setAgenciesToPublish(temp);
                    }
                }
                
            })

            agenciesData.forEach((data)=>{
                let publish = false; 
                agenciesToPublish.forEach((agencyId)=>{
                    if(data.ID === agencyId){
                        publish = true;
                    }
                })
                if(publish){
                    var SelectedAvailableAgentIds: Number[] = [];
                    var SelectedOrderedAgentIds: Number[] = [];

                    sortedAgentDataList.forEach((agent) =>{
                        if(agent.ordered){
                            if(agent.agencyId === data.ID){
                                SelectedOrderedAgentIds.push(parseInt(agent.agentId));
                            }else{
                                SelectedOrderedAgentIds.push(0);
                            }
                        }
                        else{
                            SelectedAvailableAgentIds.push(parseInt(agent.agentId));
                        }
                    })

                    data.SelectedAvailableAgentIds = SelectedAvailableAgentIds;
                    data.SelectedOrderedAgentIds = SelectedOrderedAgentIds;
                }
            })
        }
    }, [sortedAgentDataList, agenciesData, agenciesToPublish, id])

    const prepareAssocicatedForPost = (rawAgency : any) => {
        const newAbout = { About: aboutAgency.summary, Year: aboutAgency.yearEstablished };
        
        var selectedCarriers: Number[] = rawAgency.selectedCarriers? rawAgency.selectedCarriers : [];
        var selectedProducts: Number[] = rawAgency.selectedProducts? rawAgency.selectedProducts : [];
        var OperatingTimes: any[] = [];

        if(rawAgency.OperatingTimes){
            rawAgency.OperatingTimes.forEach((element: any)=>{
                OperatingTimes.push({ Day: element.Day, Start: element.Start, Stop: element.Stop });
            })
        }

        var OtherOfficeLocations: any[] = rawAgency.OtherOfficeLocations? rawAgency.OtherOfficeLocations : [];
        let newCoverPhotos: ImageFile[] = rawAgency.CoverPhotos? rawAgency.CoverPhotos : [];
        let newPhotos: ImageFile[] =  rawAgency.Photos? rawAgency.Photos : [];
        const socialMediaObj = { FacebookUrl: rawAgency.FacebookUrl? rawAgency.FacebookUrl : "", LinkedInUrl: rawAgency.LinkedInUrl? rawAgency.LinkedInUrl : "", TwitterUrl: rawAgency.TwitterUrl? rawAgency.TwitterUrl :"", SocialMediaNA: rawAgency.SocialMediaNA? rawAgency.SocialMediaNa : true};
        var profileArticles: any[] = rawAgency.profileArticles? rawAgency.profileArticles: [];
        var Awards: any[] = rawAgency.Awards? rawAgency.Awards : [];
       
        const selectedAffiliations = rawAgency.selectedAffiliations? rawAgency.selectedAffiliations : [];
        var additionalAffiliations: any[] = rawAgency.assignedAffilitaionCB?.additionalAffiliations ? rawAgency.assignedAffilitaionCB.additionalAffiliations :  [];
        const assignedAffiliatoinCB = { additionalAffiliations: additionalAffiliations.length === 0 ? [{ Name: "" }, { Name: "" }, { Name: "" }] : additionalAffiliations };
 
        const newOperationHours = { OperatingTimes, ddlTimeZone: rawAgency.ddlTimeZone ? rawAgency.ddlTimeZone: null, OffHours: rawAgency.OffHours ? rawAgency.OffHours :false };

        let chatInfo = {};
            chatInfo = {...chatInfo, HasGoogleChat: rawAgency.HasGoogleChat? rawAgency.HasGoogleChat : false, GoogleChatUsername: rawAgency.GoogleChatUsername ? rawAgency.GoogleChatUsername : ""};
            chatInfo = {...chatInfo, HasSkype: rawAgency.HasSkype? rawAgency.HasSkype : false, SkypeUsername: rawAgency.SkypeUsername ? rawAgency.SkypeUsername : ""};
            chatInfo = {...chatInfo, HasWebsiteChat: rawAgency.HasWebdsiteChat? rawAgency.HasWebsiteChat :false, WebsiteChatUrl: rawAgency.WebsiteChatUrl? rawAgency.WebsiteChatUrl : ""};

        const newPreferredContactInfo = {
            PreferredAgencyEmail: rawAgency.PreferredAgencyEmail ? rawAgency.PreferredAgencyEmail : "",
            PreferredAgencyName: rawAgency.PreferredAgencyName ? rawAgency.PreferredAgencyName : "" ,
            PreferredAgencyPhone: rawAgency.PreferredAgencyPhone? rawAgency.PreferredAgencyPhone : undefined,
            PreferredAgencyWebsite: rawAgency.PreferredAgencyWebsite ? rawAgency.PreferredAgencyWebsite : "",
            MainContact: rawAgency.MainContact ? rawAgency.MainContact : "",
            MobilePhone: rawAgency.MobilePhone ? rawAgency.MobilePhone : undefined ,
            OtherContactInfoNA: rawAgency.OtherContactInfoNA ? rawAgency.OtherContactInfoNA : false,
            FaxNumber: rawAgency.FaxNumber ? rawAgency.FaxNumber : undefined,
            ...chatInfo
        };
   
        var SelectedAvailableAgentIds: Number[] = rawAgency.SelectedAvailableAgentIds? rawAgency.SelectedAvailableAgentIds : [];
        var SelectedOrderedAgentIds: Number[] = rawAgency.SelectedOrderedAgentIds ? rawAgency.SelectedOrderedAgentIds : [];

        const newProfileData = {
            ...rawAgency,
            ...newAbout,
            ...newPreferredContactInfo,
            selectedCarriers,
            CarriersNA: rawAgency.CarriersNA ? rawAgency.CarriersNA : false ,
            selectedProducts,
            ...newOperationHours,
            OtherOfficeLocations,
            AdditionalOfficeLocationNA: rawAgency.AdditionalOfficeLocationNA ? rawAgency.AdditionalOfficeLocationNA : false,
            CoverPhotos: newCoverPhotos,
            Photos: newPhotos,
            AgencyImagesNA: rawAgency.AgencyImagesNA ? rawAgency.AgencyImagesNA : false,
            ...socialMediaObj,
            ArticlesNA: rawAgency.ArticlesNA ? rawAgency.ArticlesNA : false,
            profileArticles,
            Awards,
            AwardsNA: rawAgency.AwardsNA ? rawAgency.AwardsNA : false,
            AffiliationsNA: rawAgency.AffiliationsNA ? rawAgency.AffiliationsNA : false,
            selectedAffiliations,
            assignedAffiliatoinCB,
            PercentComplete: rawAgency.PercentComplete ? rawAgency.PercentComplete : 25,
            SelectedOrderedAgentIds,
            SelectedAvailableAgentIds,
            Notes: rawAgency.Notes ? rawAgency.Notes : "",
            ShowGoogleReviews: showReviews,
            Show: rawAgency.Show ? rawAgency.Show : false,
            isAgentData: false,
            GAQDisabled: gaqDisabled,
        };
        return newProfileData;
    };

    useEffect(() => {
        calculateProgress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayChecklist]);

    const calculateProgress = () => {
        var percentCount = 0;

        if (displayChecklist.address === true && 
            displayChecklist.onlineRes === true && 
            displayChecklist.moreInfo === true) {
                percentCount += 25; 
            } else {
                percentCount += 25;
            }

        if (displayChecklist.contactInfo === true) { percentCount += 13; }//adjust percent later

        if (displayChecklist.aboutAgency === true) { percentCount += 3; }

        if (displayChecklist.yearEst === true) { percentCount += 3; }

        if (displayChecklist.otherCarriers === true) { percentCount += 5; }

        if (displayChecklist.productSpecialties === true) { percentCount += 5; }

        if (displayChecklist.coreHours === true) { percentCount += 5; }

        if (displayChecklist.addOfficeLocations === true) { percentCount += 5; }

        if (displayChecklist.socialMedia === true) { percentCount += 12; }

        if (displayChecklist.articles === true) { percentCount += 3; }

        if (displayChecklist.awards === true) { percentCount += 3; }

        if (displayChecklist.professionalAffiliations === true) { percentCount += 3; }

        if (displayChecklist.mainPhoto === true) { percentCount += 10; }

        if (displayChecklist.galleryPhotos === true) { percentCount += 5; }

        setChecklistProgress(percentCount);
    }

    useEffect(() => {
        checkList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileData, preferredContactInfo, aboutAgency, carriers, products, operationHours, officeLocations, photos, socialMediaLinks, articles, awards, affiliations, photos, deleteModalClose, savedToggle.firstRun]);


    const onPreviewClick = () => {
        setPreviewModal(true);
        let url = `${window.env.PREVIEW_AGENT}/agency/${id}`;
        setPreviewURL(url);
    };

    const isFieldEmpty = (field?: string) => {
        return field === '---' || field?.trim() === '' || field === undefined || field === null;
    }

    const checkList = () => {
        var demoContents = {
            address: false,
            onlineRes: false,
            moreInfo: false,
            contactInfo: false,
            coreHours: false,
            addOfficeLocations: false,
            socialMedia: false,
            productSpecialties: false,
            otherCarriers: false,
            aboutAgency: false,
            yearEst: false,
            awards: false,
            professionalAffiliations: false,
            articles: false,
            mainPhoto: false,
            galleryPhotos: false
        };

        if (( !isFieldEmpty(profileData.AddressLine1) &&
              !isFieldEmpty(profileData.City) &&
              !isFieldEmpty(profileData.State) &&
              !isFieldEmpty(profileData.Zip) ) ||
            ( !isFieldEmpty(preferredContactInfo.address) &&
              !isFieldEmpty(preferredContactInfo.phone) )
            ) {
            demoContents.address = true;
        }

        if ((!isFieldEmpty(profileData.AgencyWeb) || !isFieldEmpty(preferredContactInfo.website)) &&
            (!isFieldEmpty(profileData.AgencyEmail) || !isFieldEmpty(preferredContactInfo.email)))
            demoContents.onlineRes = true;

        if ( !isFieldEmpty(profileData.ID) || profileData.KeyAgent || profileData.PremierPartner ) {
            demoContents.moreInfo = true;
        }

        if (preferredContactInfo.chat.google || preferredContactInfo.chat.skype || preferredContactInfo.chat.web) {
            demoContents.contactInfo = true;
        }

        if (aboutAgency.summary.length !== 0) {
            demoContents.aboutAgency = true;
        }

        if (aboutAgency.yearEstablished !== undefined && aboutAgency.yearEstablished !== null) {
            if (aboutAgency.yearEstablished.length === 4 && Number(aboutAgency.yearEstablished) >= 1850) {
                demoContents.yearEst = true;
            }
        }

        var selectedCarriers: Number[] = [];
        carriers.forEach((carrier) => {
            if (carrier.selected) {
                selectedCarriers.push(carrier.id);
            }
        });
        if (selectedCarriers.length !== 0) {
            demoContents.otherCarriers = true;
        }

        var selectedProducts: Number[] = [];
        products.forEach(product => {
            if (product.selected) {
                selectedProducts.push(product.id);
            }
        });
        if (selectedProducts.length !== 0) {
            demoContents.productSpecialties = true;
        }

        if (operationHours.otherhours) {
            demoContents.coreHours = true;
        }
        else {
            var validHours = false;
            operationHours.opHours.forEach(element => {
                if (element.hours.begin !== "-----" && element.hours.end !== "-----" && operationHours.timezone !== "-----") {
                    validHours = true;
                }
            });
            if (validHours) {
                demoContents.coreHours = true;
            }
        }

        var locationsAreDeleted = true;
        officeLocations.forEach(element => {
            if (element.isDeleted === "false") {
                locationsAreDeleted = false;
            }
        })
        if (officeLocations.length !== 0 && !locationsAreDeleted) {
            demoContents.addOfficeLocations = true;
        }

        if (socialMediaLinks.facebook.length > 7 || socialMediaLinks.linkedIn.length > 7 || socialMediaLinks.twitter.length > 7) {
            demoContents.socialMedia = true;
        }

        var articleFlag = true;
        if (articles.length === 0) {
            articleFlag = false;
        }
        else {
            articles.forEach(el => {
                if ((el?.Url && el.Url.length <= 7) || (el?.Title && el.Title.length === 0)) {
                    articleFlag = false;
                }
            })
        }
        if (articleFlag === true) {
            demoContents.articles = true;
        }

        var awardFlag = true;
        if (awards.length === 0) {
            awardFlag = false;
        }
        else {
            awards.forEach(el => {
                if (el.name.length === 0 || el.yearRecieved.length !== 4) {
                    awardFlag = false;
                }
            })
        }
        if (awardFlag === true) {
            demoContents.awards = true;
        }

        var affilFlag = true;
        if (affiliations.length === 0) {
            affilFlag = false;
        }
        else {
            affiliations.forEach(el => {
                if (el.length === 0) {
                    affilFlag = false;
                }
            })
        }
        if (affilFlag === true) {
            demoContents.professionalAffiliations = true;
        }

        const mainIndex = photos.mainPhoto.findIndex(element => element.IsDeleted === false);
        if (mainIndex !== -1) {
            demoContents.mainPhoto = true;
        }

        const galleryIndex = photos.galleryPhotos.findIndex(element => element.IsDeleted === false);
        if (galleryIndex !== -1) {
            demoContents.galleryPhotos = true;
        }

        setChecklistContents({
            ...checkListContents,
            contactInfo: demoContents.contactInfo,
            addOfficeLocations: demoContents.addOfficeLocations,
            socialMedia: demoContents.socialMedia,
            otherCarriers: demoContents.otherCarriers,
            professionalAffiliations: demoContents.professionalAffiliations,
            awards: demoContents.awards,
            articles: demoContents.articles,
            galleryPhotos: demoContents.galleryPhotos
        });

        if (savedToggle.contactInfo === true) { demoContents.contactInfo = true; }

        if (savedToggle.addOfficeLocations === true) { demoContents.addOfficeLocations = true; }

        if (savedToggle.socialMedia === true) { demoContents.socialMedia = true; }

        if (savedToggle.otherCarriers === true) { demoContents.otherCarriers = true; }

        if (savedToggle.professionalAffiliations === true) { demoContents.professionalAffiliations = true; }

        if (savedToggle.awards === true) { demoContents.awards = true; }

        if (savedToggle.articles === true) { demoContents.articles = true; }

        if (savedToggle.galleryPhotos === true) { demoContents.galleryPhotos = true; }

        setDisplayModal({ ...displayModal, contactInfo: false, addOfficeLocations: false, otherCarriers: false, socialMedia: false, awards: false, professionalAffiliations: false, articles: false, galleryPhotos: false })
        setDisplayChecklist(demoContents);
    }

    const mapLanguages = (languages: String[]) => {
        if (languages) {
            return languages.map((language, index) => {


                return <span key={index}>{index === languages.length - 1 ? `${language.trim()}` : `${language.trim()}, `}</span>
            })
        };
    }

    const convertOperationHours = () => {
        var OperatingTimes: any[] = [];
        operationHours.opHours.forEach((element, index) => {
            if (element.open) {
                OperatingTimes.push({ day: dayAbrvs[index], start: element.hours.begin, stop: element.hours.end });
            }
        });

        return OperatingTimes;
    };

    const onIFrameLoad = async () => {
        const data = prepareDataForPost();

        let newPhotos: {
            photoId?: number;
            description?: string;
            blob_id?: string;
            agencyProfile_Id?: number;
            url?: string;
          }[] | undefined = data.Photos.map((el:ImageFile) => (
                { blob_id : el.Blob_Id, description: el.Description, base64: el.base64}
          ));

        let profilePicSrc = data.CoverPhotos.length ? data.CoverPhotos[0].base64 ? data.CoverPhotos[0].base64 : data.CoverPhotos[0].url : "";

        let previewAwards: any[] = [];

        data.Awards.forEach((award: any) => {
            if (award.Name || award.YearReceived)
                previewAwards.push({ awardName: award.Name, awardYear: award.YearReceived })
        });

        let previewArticles: any[] = [];

        data.profileArticles.forEach((article: any) => {
            if (article.Title && article.Url)
                previewArticles.push({ articleURL: article.Url, articleTitle: article.Title })
        });

        let previewAgentList: any[] = [];

        sortedAgentDataList?.forEach(agent => {
            if (agent.show)
                previewAgentList.push({...agent, agencyID:agent.agencyId, agentID:agent.agentId});
        });
        let affiliations = (data?.assignedAffiliatoinCB?.additionalAffiliations && data.assignedAffiliatoinCB.additionalAffiliations.filter((x:{Name:string}) => !!x.Name).length) || data.selectedAffiliations.length ? [] : null;
        if (affiliations)
             affiliations = data.selectedAffiliations.map((x:string) => ({affiliationName: x})).concat(data.assignedAffiliatoinCB.additionalAffiliations.map((x : { Name : string }) =>  ({affiliationName:x.Name})))

        let previewAgencyObj : AgencyPreview = {
            preview: true,
            agencyModel: {
                agencyWebsite: data.PreferredAgencyWebsite === "" ? data?.AgencyWeb : data.PreferredAgencyWebsite,
                agencyEmail: data.PreferredAgencyEmail === "" ? data?.AgencyEmail : data.PreferredAgencyEmail,
                agencyID: data.ID,
                agencyPrimaryName: data.PreferredAgencyName === "" ? formatAgencyName(data?.AgencyName) : data.PreferredAgencyName,
                agencyAddress1: data.AddressLine1,
                agencyAddress2: data.AddressLine2,
                agencyCity: data.City,
                agencyState: data.State,
                agencyZip: data.Zip,
                agencyAreaCode: data.AgencyPhone ? (data.AgencyPhone).replaceAll("-","").substring(0,3) : "",
                agencyPhone: (data.PreferredAgencyPhone ? data.PreferredAgencyPhone : data.AgencyPhone).replaceAll("-","").substring(3),
                agentModel: previewAgentList,
                agentListLoaded:agentListLoaded,
                showGoogleReviews:data.ShowGoogleReviews,
                lng:null,
                lat:null,
                gaqDisabled:data.GAQDisabled

            },
            logos: {
                blob_id: data.CoverPhotos[0]?.Blob_Id ? data.CoverPhotos[0].Blob_Id.toUpperCase() : ""
            },
            operatingTimes: convertOperationHours(),
            twitter: data.TwitterUrl,
            facebook: data.FacebookUrl,
            linkedIn: data.LinkedInUrl,
            mainContact: data.MainContact,
            faxNumber: data.FaxNumber,
            mobilePhone: data.MobilePhone,
            websiteChatURL: data.WebsiteChatUrl,
            googleChatUsername:data.GoogleChatUsername,
            skypeUsername:data.SkypeUsername,
            about: data.About,
            yearStarted: data.Year,
            productSpecialties: products && products.filter((product) => product.selected).length ? products : null,
            carriers: carriers && carriers.filter((carrier) => carrier.selected).length ? carriers.filter((carrier) => carrier.selected) : null,
            photos: newPhotos && newPhotos.length ? newPhotos : null,
            awardsList: previewAwards.length ? previewAwards : null,
            articles: previewArticles.length ? previewArticles : null,
            affiliations: affiliations,
            languages: profileData.Languages && profileData.Languages.length ? profileData.Languages : null,
            coverPhotoRaw : profilePicSrc,
            otherOffices : data.OtherOfficeLocations && data.OtherOfficeLocations.length > 0 ? data.OtherOfficeLocations.filter((x:officeLocation) => x.isDeleted !== 'true').map((x: any) => ({ officeId: x.Id, adressLine1: x.AddressLine1, city: x.City, state: x.State, zip: x.Zip, phone: x.Phone, officeName: x.OfficeName })) : null

        };
        setPreviewObj(previewAgencyObj)

        //get lat and lng for primary location
        new google.maps.Geocoder().geocode({ address: `${data.adressLine1}, ${data.City}, ${data.State} ${data.Zip}` }, //look up lat lng from google
            async (results, status) => {
                if (status === "OK" && results !==null) {
                    previewAgencyObj.agencyModel.lat = results[0].geometry!.location.lat();
                    previewAgencyObj.agencyModel.lng = results[0].geometry!.location.lng();
                }
                if (iframe && iframe.current)
                    iframe.current.contentWindow?.postMessage(previewAgencyObj, previewURL)
                const frames = iFrameResize({}, "#agencyPreviewFrame");
                /* this extra resize call may be necessary bc there are some async calls being ran in the agency profile preview frame.
                long term, it would probably be best to post message all data needed by the frame so that we dont need to wait for it to fully load
                */
                setTimeout(() => {
                    if (frames[0] && frames[0].iFrameResizer)
                        frames[0].iFrameResizer.resize();
                },1000)
        });
    }

    const [isOpen, setModalIsOpen] = useState(false);
            const confirmHandler = () =>{
			   setShowProcessing(true);
               setModalIsOpen(true);

            }
            const cancelHandler = () =>{
                setShowProcessing(false);
                setModalIsOpen(false);
            }
    const [, setEditNotes] = useState<boolean>(false);

    useEffect(() => {
        if (sortedAgentDataList) //if preview is triggered after agent list has loaded, do not show a spinner
            setAgentListLoaded(true);

        if (iframe && iframe.current && sortedAgentDataList && previewObj) { //otherise if preview is initiated before agent list has loaded, update iframe once it has
            previewObj!.agencyModel.agentListLoaded = true;
            previewObj!.agencyModel.agentModel = sortedAgentDataList.map(x => ({
                agencyID:x.agencyId,
                agentID:x.agentId,
                name:x.name,
                email:x.email,
                phone:x.phone,
                position:x.position,
                show:x.show,
                photo:x.photo
            })).filter(x => x.show)
            iframe.current.contentWindow?.postMessage(previewObj, previewURL);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedAgentDataList]);

    const updateImage = (image: ImageFile) => {
        
        if (photos.mainPhoto[0] && photos.mainPhoto[0].Blob_Id && photos.mainPhoto[0].Blob_Id !== "") //keep track of deleted image so we can remove it from server later
            setPhotos(photos => ({ ...photos, mainPhoto: [...photos.mainPhoto.slice(0,0), image, ...photos.mainPhoto.slice(1), {...photos.mainPhoto[0], IsDeleted: true}] }));
        else
            setPhotos(photos => ({ ...photos, mainPhoto: [...photos.mainPhoto.slice(0,0), image, ...photos.mainPhoto.slice(1)] }));
        
        if (image.IsDeleted){
            let deletedPhotoObj = _.cloneDeep(photos.mainPhoto);;
            deletedPhotoObj.forEach(item => {
                if (item.Blob_Id === image.Blob_Id) {
                    item.IsDeleted = true }
            });
            setPhotos({...photos, mainPhoto: deletedPhotoObj });
        }
    }

    
   
    const addLocation = (location: officeLocation) => {
        setOfficeLocations(officeLocations => ([...officeLocations, {...location, id:guid(),  isDeleted: 'false', isNew: 'true'}]))
        setModalOfficeOptions(null);
    }

    const selectProfilePicture = () => {
        // 👇️ open file explorer to profile picture
        if (profilePicInputRef.current && !photos?.mainPhoto ) {
            profilePicInputRef.current.click();
        } else if (profilePicInputRef.current && photos.mainPhoto.filter(x => !x.IsDeleted).length === 0) {
            profilePicInputRef.current.click();
        } else{
            setProspectiveProfilePic(photos?.mainPhoto[0]);
            setOpen(true);
        }
    };

    const selectGalleryPicture = () => {
        // 👇️ open file explorer to gallery picture
        if (galleryPicInputRef.current) {
            galleryPicInputRef.current.click();
        }
    };

    const addProfilePicture = async (e: any) => {
        if (e.target.files) {
            let file:File = e.target.files[0];
            setProspectiveProfilePic({ name: file.name, url: URL.createObjectURL(file as any), Blob_Id: "", IsDeleted: false });
            setOpen(true); 
        }
    }

    const validateGalleryImages = (agencyPhotos: FileList, isReplacing: boolean = false): boolean => {
        let hasErrors: boolean = false;
        if (agencyPhotos.length) {
            //check if too many photos were uploaded
            if (!isReplacing && (photos.galleryPhotos.filter(x => !x.IsDeleted).length + agencyPhotos.length) > 5) { //dont check for too many photos when replacing one photo for another
                hasErrors = true;
            }
            //check for duplicate files
            photos.galleryPhotos.filter(x => !x.IsDeleted).forEach((photo: ImageFile) => {
                if ([...agencyPhotos].filter(x => photo.name && x.name === photo.name).length >= 1) {
                    hasErrors = true;
                }
            });
            //check size and type
            for (let i:number = 0; i<agencyPhotos.length; i++ ) {
                let hasError: boolean = checkImageSize(agencyPhotos[i]);
                if (hasError)
                    return true;
            }
        }
        return hasErrors;
    }

    const replaceGalleryPicture = (newPhoto: ImageFile, index: number) => {        
        if (photos.galleryPhotos[index] && photos.galleryPhotos[index].Blob_Id && photos.galleryPhotos[index].Blob_Id !== "") //keep track of deleted image so we can remove it from server later
            setPhotos(photos => ({ ...photos, galleryPhotos: [...photos.galleryPhotos.slice(0, index), newPhoto, ...photos.galleryPhotos.slice(index + 1), {...photos.galleryPhotos[index], IsDeleted: true}] }));
        else
            setPhotos(photos => ({ ...photos, galleryPhotos: [...photos.galleryPhotos.slice(0, index), newPhoto, ...photos.galleryPhotos.slice(index + 1)] }));
    }

    const addGalleryPictures = async (e: any, index: number = -1) => {
        let target: HTMLInputElement = e.target;
        if (target.files !== null) {
            let error: boolean = validateGalleryImages(target.files, index === -1 ? false : true);
            if (error)
                return;

            [...target.files].forEach((file: File) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        const newPhoto = { name: file.name, url: URL.createObjectURL(file), IsDeleted: false, Blob_Id: "", base64:reader.result as string };
                        if (index === -1) // add a gallery photo
                            setPhotos(photos => ({ ...photos, galleryPhotos: [...photos.galleryPhotos, newPhoto] }));
                        else //replace a gallery photo
                            replaceGalleryPicture(newPhoto, index);
                    }
            });
        }
    }

    const renderAgencyContent = () => {

        return (
            <>
                <Card id="contactInfoCard">
                    <div>
                        <div className="edit-profile-banner">
                                <MainPhotoHeader updateImage={(image: ImageFile) => updateImage(image)} image={photos?.mainPhoto ? photos.mainPhoto.find((x:ImageFile) => !x.IsDeleted) : undefined} />
                                <div className="edit-profile-banner-section">
                                    <Heading type="h4-light">Address and Phone</Heading>
                                    <p>
                                        {profileData ? profileData.AddressLine1 : null} <br />
                                        {profileData ? `${profileData.City} ${profileData.State} ${profileData.Zip}` : null}
                                    </p>
                                    <p>
                                        {profileData ? formatPhoneNumber(profileData.AgencyPhone) : null}
                                    </p>
                                </div>
                                <div className="edit-profile-banner-section">
                                <Heading type="h4-light">Online Resources</Heading>
                                    <p>
                                        {profileData ? profileData.AgencyWeb : null}
                                    </p>
                                    <p>
                                        {profileData ? profileData.AgencyEmail : null}
                                    </p>
                                </div>
                                <div className="edit-profile-banner-section">
                                <Heading type="h4-light">More Information</Heading>
                                    <p>
                                        {profileData && profileData.ID ? (profileData.ID as string).slice(8, 10) + "-" + (profileData.ID as string).slice(10, 12) + "-" + (profileData.ID as string).slice(12, 16) : null}
                                    </p>
                                    <p>
                                        Status:	Safeco Premier Partner
                                    </p>
                                    <p>
                                        Languages:	{profileData !== null ? mapLanguages(profileData.Languages) : null}
                                    </p>
                                </div>

                        </div>
                    </div>
                </Card>

                <div className="pageLayoutEdit">
                    <AgentList id={id} agenciesData={agenciesData} awaitingAgenciesData={awaitingAgenciesData} associatedAgencyIds={associatedAgencyIds}/>
                    <div>
                        <div className='rowContainer'>
                            <div className='flexItemRow' style={{marginBottom: "0"}}>
                                <div className='flexItem' style={{marginTop: "0"}}>
                                    <Card className='preferred-contact-info' style={{ height: "100%" }} title="Contact Information">
                                        <ShowPreferredContactInfo />
                                    </Card>
                                </div>
                                <div className='flexItem' style={{marginTop: "0"}}>
                                    <Card style={{ height: "100%" }} title="Office Hours">
                                        <div>
                                            <ShowHoursOfOperation />
                                        </div>
                                    </Card>
                                </div>
                            </div>
                            <div className='flexItemRow'>
                                <div className='flexItem'>
                                    <Card style={{ height: "100%" }} title="About the Agency">
                                        <div>
                                            <ShowAboutAgency />
                                        </div>
                                    </Card>
                                </div>
                                <div className='flexItem'>
                                    <Card style={{ height: "100%" }} title="Other Offices">
                                        <ShowOfficeLocations />
                                    </Card>
                                </div>
                            </div>
                            <div className='flexItemRow'>
                                <div className='flexItem'>
                                    <Card style={{ height: "100%" }} title="Agency Photos">
                                        <ShowPhotos />
                                    </Card>
                                </div>
                                <div className='flexItem'>
                                        <Card title="Social Media" style={{ "marginBottom" : "20px"}}>
                                            <ShowSocialMedia />
                                        </Card>
                                        <Card title="Articles About Agency">
                                            <ShowArticles />
                                        </Card>
                                </div>
                            </div>
                            <div className='flexItemRow'>
                                <div className='flexItem'>
                                    <Products />
                                </div>
                                <div className='flexItem'>
                                    <Carriers />
                                </div>
                            </div>
                            <div className='flexItemRow'>
                                <div className='flexItem'>
                                    <Card style={{ height: "100%" }} title="Awards & Affiliations">
                                        <ShowAwardsAffiliations />
                                    </Card>
                                </div>
                                <div className='flexItem'>
                                    <Card style={{ height: "100%" }} title="Google Reviews">
                                        <ShowGoogleReviews />
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <CancelChangesModal open={showCancelModal} yesCancel={() => { navigate(`${window.env.AGENCY_PROFILE_PATH}${id}`)}} noCancel={() => setShowCancelModal(false)}/>
                        <div className="agencyPageButtons">
                            {showProcessing &&
                                <div className="overlay">
                                    <div className='processing'>
                                        <CircularProgressSpinner aria-label="Loading Data" size="96" />
                                    </div>
                                </div>
                            }
							<Button onClick={() => setShowCancelModal(true)}>Cancel</Button>

                            <Button variant="primary" onClick={onPreviewClick}>Preview</Button>
                            {displayType === "viewProfile"
                                ? <Button variant="primary" disabled>Publish</Button>
                                : <Button variant="primary" onClick={() => confirmHandler()}>Publish</Button>
                            }
                            {isOpen ? <NotesModal publish={publishChanges} notes={notes} edit={true} updateEdit={setEditNotes} cancel={cancelHandler}/> :  null}
                        </div>
                    </div>
                    <div style={{ padding: "0px 20px 0px 10px", marginTop: "20px" }}>
                        <div className='checklist-title-background'>
                            <Heading type="h4" className='checklist-title-text'>Profile Checklist</Heading>
                        </div>
                        <Card className="profileChecklist">
                            <LinearProgress
                                variant="determinate"
                                value={checklistProgress !== 25 ? checklistProgress : 0}
                                sx={{
                                    height: '20px !important',
                                    '& .MuiLinearProgress-bar': {
                                        background: `linear-gradient(90deg, rgba(151,225,148,1) 0%, rgba(162,222,93,1) 50%, rgba(77,160,21,1) 100%)`
                                    }
                                }}
                            />
                            <p
                                style={{
                                    marginTop: '-22.5px',
                                    position: 'relative',
                                    marginLeft: checklistProgress !== 25 ? `calc(50% - 50px)` : `calc(50% - 42px)`
                                }}
                            >
                                {checklistProgress !== 25 ? checklistProgress + "% Complete" : "Not Started"}
                            </p>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <div className='checklist-header-background'>
                                        <Heading type="h6" style={{ marginTop: "0px" }} className='checklist-header-text'>Contact Information</Heading>
                                    </div>
                                    <div className="indentContent">
                                        <GridRow className='checklistSpacing'>
                                            <div onClick={() => modalsContext.showModal(tiles.ContactInfo, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.address ? "positive" : "caution"}>Address</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <div onClick={() => modalsContext.showModal(tiles.ContactInfo, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.onlineRes ? "positive" : "caution"}>Website and email</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <div onClick={() => modalsContext.showModal(tiles.ContactInfo, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.moreInfo ? "positive" : "caution"}>More info</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <Toggle className='checklistToggle' checked={displayChecklist.contactInfo} compact={true} showOnOff={false} onChange={() => {
                                                setDisplayChecklist({ ...displayChecklist, contactInfo: !displayChecklist.contactInfo })
                                                setSavedToggle({ ...savedToggle, contactInfo: !displayChecklist.contactInfo })
                                                if (!displayChecklist.contactInfo === false) {
                                                    setDisplayModal({ ...displayModal, contactInfo: true })
                                                }
                                                else {
                                                    setDisplayModal({ ...displayModal, contactInfo: false })
                                                }
                                            }}>
                                            </Toggle>
                                            {displayModal.contactInfo && checkListContents.contactInfo
                                                ? <ModalDeleteContents normalOpen={true} removeType={"contactInfo"} />
                                                : null
                                            }
                                            <div onClick={() => modalsContext.showModal(tiles.Chat, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.contactInfo ? "positive" : "caution"}>Chat options</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <div onClick={() => modalsContext.showModal(tiles.HoursOfOperation, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.coreHours ? "positive" : "caution"}>Office hours</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <Toggle className='checklistToggle' checked={displayChecklist.addOfficeLocations} compact={true} showOnOff={false} onChange={() => {
                                                setDisplayChecklist({ ...displayChecklist, addOfficeLocations: !displayChecklist.addOfficeLocations })
                                                setSavedToggle({ ...savedToggle, addOfficeLocations: !displayChecklist.addOfficeLocations })
                                                if (!displayChecklist.addOfficeLocations === false) {
                                                    setDisplayModal({ ...displayModal, addOfficeLocations: true })
                                                }
                                                else {
                                                    setDisplayModal({ ...displayModal, addOfficeLocations: false })
                                                }
                                            }}>
                                            </Toggle>
                                            {displayModal.addOfficeLocations && checkListContents.addOfficeLocations
                                                ? <ModalDeleteContents normalOpen={true} removeType={"addOfficeLocations"} />
                                                : null
                                            }
                                            <div onClick={() =>  { setModalOfficeOptions({ title: "Add a new office location", office: undefined, function: (location: officeLocation) => addLocation(location)});modalsContext.showModal(tiles.OfficeLocation, true) } }>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.addOfficeLocations ? "positive" : "caution"}>Other offices</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <Toggle className='checklistToggle' checked={displayChecklist.socialMedia} compact={true} showOnOff={false} onChange={() => {
                                                setDisplayChecklist({ ...displayChecklist, socialMedia: !displayChecklist.socialMedia })
                                                setSavedToggle({ ...savedToggle, socialMedia: !displayChecklist.socialMedia })
                                                if (!displayChecklist.socialMedia === false) {
                                                    setDisplayModal({ ...displayModal, socialMedia: true })
                                                }
                                                else {
                                                    setDisplayModal({ ...displayModal, socialMedia: false })
                                                }
                                            }}>
                                            </Toggle>
                                            {displayModal.socialMedia && checkListContents.socialMedia
                                                ? <ModalDeleteContents normalOpen={true} removeType={"socialMediaLinks"} />
                                                : null
                                            }
                                            <div onClick={() => modalsContext.showModal(tiles.SocialMedia, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.socialMedia ? "positive" : "caution"}>Social media</Badge>
                                            </div>
                                        </GridRow>
                                    </div>
                                </div>
                                <div>
                                    <div className='checklist-header-background'>
                                        <Heading type="h6" className='checklist-header-text'>Products and Carriers</Heading>
                                    </div>
                                    <div className="indentContent">
                                        <GridRow className='checklistSpacing'>
                                            <div onClick={() => modalsContext.showModal(tiles.Products, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.productSpecialties ? "positive" : "caution"}>Products</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <Toggle className='checklistToggle' checked={displayChecklist.otherCarriers} compact={true} showOnOff={false} onChange={() => {
                                                setDisplayChecklist({ ...displayChecklist, otherCarriers: !displayChecklist.otherCarriers })
                                                setSavedToggle({ ...savedToggle, otherCarriers: !displayChecklist.otherCarriers })
                                                if (!displayChecklist.otherCarriers === false) {
                                                    setDisplayModal({ ...displayModal, otherCarriers: true })
                                                }
                                                else {
                                                    setDisplayModal({ ...displayModal, otherCarriers: false })
                                                }
                                            }}>
                                            </Toggle>
                                            {displayModal.otherCarriers && checkListContents.otherCarriers
                                                ? <ModalDeleteContents normalOpen={true} removeType={"otherCarriers"} />
                                                : null
                                            }
                                            <div onClick={() => modalsContext.showModal(tiles.Carriers, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.otherCarriers ? "positive" : "caution"}>Other carriers</Badge>
                                            </div>
                                        </GridRow>
                                    </div>
                                </div>
                                <div>
                                    <div className='checklist-header-background'>
                                        <Heading type="h6" className='checklist-header-text'>Agency Background</Heading>
                                    </div>
                                    <div className="indentContent">
                                        <GridRow className='checklistSpacing'>
                                            <div onClick={() => modalsContext.showModal(tiles.AboutTheAgency, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.aboutAgency ? "positive" : "caution"}>Agency summary</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <div onClick={() => modalsContext.showModal(tiles.AboutTheAgency, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.yearEst ? "positive" : "caution"}>Year established</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <Toggle className='checklistToggle' checked={displayChecklist.awards} compact={true} showOnOff={false} onChange={() => {
                                                setDisplayChecklist({ ...displayChecklist, awards: !displayChecklist.awards })
                                                setSavedToggle({ ...savedToggle, awards: !displayChecklist.awards })
                                                if (!displayChecklist.awards === false) {
                                                    setDisplayModal({ ...displayModal, awards: true })
                                                }
                                                else {
                                                    setDisplayModal({ ...displayModal, awards: false })
                                                }
                                            }}>
                                            </Toggle>
                                            {displayModal.awards && checkListContents.awards
                                                ? <ModalDeleteContents normalOpen={true} removeType={"awards"} />
                                                : null
                                            }
                                            <div onClick={() => modalsContext.showModal(tiles.Awards, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.awards ? "positive" : "caution"}>Awards</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'> 
                                            <Toggle className='checklistToggle' checked={displayChecklist.professionalAffiliations} compact={true} showOnOff={false} onChange={() => {
                                                setDisplayChecklist({ ...displayChecklist, professionalAffiliations: !displayChecklist.professionalAffiliations })
                                                setSavedToggle({ ...savedToggle, professionalAffiliations: !displayChecklist.professionalAffiliations })
                                                if (!displayChecklist.professionalAffiliations === false) {
                                                    setDisplayModal({ ...displayModal, professionalAffiliations: true })
                                                }
                                                else {
                                                    setDisplayModal({ ...displayModal, professionalAffiliations: false })
                                                }
                                            }}>
                                            </Toggle>
                                            {displayModal.professionalAffiliations && checkListContents.professionalAffiliations
                                                ? <ModalDeleteContents normalOpen={true} removeType={"professionalAffiliations"} />
                                                : null
                                            }
                                            <div onClick={() => modalsContext.showModal(tiles.Awards, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.professionalAffiliations ? "positive" : "caution"}>Affiliations</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <Toggle className='checklistToggle' checked={displayChecklist.articles} compact={true} showOnOff={false} onChange={() => {
                                                setDisplayChecklist({ ...displayChecklist, articles: !displayChecklist.articles })
                                                setSavedToggle({ ...savedToggle, articles: !displayChecklist.articles })
                                                if (!displayChecklist.articles === false) {
                                                    setDisplayModal({ ...displayModal, articles: true })
                                                }
                                                else {
                                                    setDisplayModal({ ...displayModal, articles: false })
                                                }
                                            }}>
                                            </Toggle>
                                            {displayModal.articles && checkListContents.articles
                                                ? <ModalDeleteContents normalOpen={true} removeType={"articles"} />
                                                : null
                                            }
                                            <div onClick={() => modalsContext.showModal(tiles.Articles, true)}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.articles ? "positive" : "caution"}>Agency articles</Badge>
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                        {open && <CropModal newPic={prospectiveProfilePic} isOpen={open} close={() => setOpen(false)} save={(image: ImageFile) => updateImage(image)} isAgent={false} delete={(image: ImageFile) => {updateImage({...image, IsDeleted: true});setOpen(false)}} />}
                                            <div onClick={() => selectProfilePicture()}>
                                                <Badge className='checklistBadge' highlightType={displayChecklist.mainPhoto ? "positive" : "caution"}>Profile photo  </Badge>
                                                <input id="profileTitlePictureUpload"
                                                        type="file"
                                                        ref={profilePicInputRef}
                                                        name="profilePicture"
                                                        accept="image/png, image/jpeg"
                                                        onChange={(e) => addProfilePicture(e)}
                                                    />
                                            </div>
                                        </GridRow>
                                        <GridRow className='checklistSpacing'>
                                            <Toggle className='checklistToggle' checked={displayChecklist.galleryPhotos} compact={true} showOnOff={false} onChange={() => {
                                                setDisplayChecklist({ ...displayChecklist, galleryPhotos: !displayChecklist.galleryPhotos })
                                                setSavedToggle({ ...savedToggle, galleryPhotos: !displayChecklist.galleryPhotos })
                                                if (!displayChecklist.galleryPhotos === false) {
                                                    setDisplayModal({ ...displayModal, galleryPhotos: true })
                                                }
                                                else {
                                                    setDisplayModal({ ...displayModal, galleryPhotos: false })
                                                }
                                            }}>
                                            </Toggle>
                                            {displayModal.galleryPhotos && checkListContents.galleryPhotos
                                                ? <ModalDeleteContents normalOpen={true} removeType={"galleryPhotos"} />
                                                : null
                                            }
                                            <div onClick={() => selectGalleryPicture()}>
                                                <Badge className={photos.galleryPhotos.filter(x => !x.IsDeleted).length < 5 ? "checklistBadge" : "disableBadge"} highlightType={displayChecklist.galleryPhotos ? "positive" : "caution"}>Agency images</Badge>
                                                { photos.galleryPhotos.filter(x => !x.IsDeleted).length < 5 &&
                                                <input id="galleryPhotoUpload"
                                                    type="file"
                                                    name="galleryPhotos"
                                                    ref={galleryPicInputRef}
                                                    multiple
                                                    accept="image/png, image/jpeg"
                                                    onChange={(e) => addGalleryPictures(e)} /> }   
                                            </div>
                                        </GridRow>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </>
        );
    };

    const showStatusToast = (name: string, bool: string) => {
        var toastText = (bool === "True" ? name + " will now be displayed" : name + " will now be hidden") + " on Safeco's Find An Agent Portal once the change is published.  Note: it may take up to 24 hours for the change to be reflected in the Safeco Find An Agent Portal search results."
        toast.info(toastText, {
            backgroundColor: "#28387E",
            color: "#ffffff"
        });
    }

    const agencyToggle = () => {
        if (metaData === undefined) {
            return (<Toggle checked={false} disabled dynamicWidth labelVisual={"Show Profile"} showOnOff={false} />);
        }
        else if (displayType === 'viewProfile') {
            return (<Toggle checked={metaData.show === "True"} disabled dynamicWidth labelVisual={"Show Profile"} showOnOff={false} />);
        }
        else if (displayType === 'editProfile') {
            return (<Toggle checked={metaData.show === "True"} dynamicWidth labelVisual={"Show Profile"} showOnOff={false} onChange={() => {
                setMetaData({ ...metaData, show: metaData.show === "True" ? "False" : "True" })
                showStatusToast(nameCase(metaData.agencyName), metaData.show === "True" ? "False" : "True");
            }} />);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <h6 className='breadcrumb-line'>
                <Link className='breadcrumb-links' to={`${window.env.ROOT}`} >{"Agency List"}</Link>
                <IconLinkCaretRight color='blue' />
                <Link className='breadcrumb-links' to={`${window.env.AGENCY_PROFILE_PATH}${id}`} >
                    {"Manage Profiles - " + (metaData !== undefined ? formatAgencyName(metaData?.agencyName) : "")}
                </Link>
                <IconLinkCaretRight color='blue' />
                <p className='breadcrumb-p'>{(displayType === "editProfile" ? "Edit " : "View ") + "Agency"}</p>
            </h6>

            <div className="colContainerHeader">
                <div>
                    <Heading type="h3" className='safeco-color'>{metaData && formatAgencyName(metaData.agencyName)}</Heading>
                </div>
                {isMarketingAdmin ? <div>
                    <Form id="form--default">
                        <FieldGroup className="agency-toggle">
                            {agencyToggle()}
                        </FieldGroup>
                    </Form>
                </div> : null}
            </div>
            {isMarketingAdmin ? <>
            {metaData && renderAgencyContent()}
            <Modal
                className="preview-modal"
                isOpen={previewModal}
                size="large"
                takeover={['base', 'sm', 'md', 'lg', 'xl']}
                onClose={() => setPreviewModal(false)}>
                <ModalBody className="modal-body">
                {previewModal && <iframe ref={iframe} src={previewURL} className="iframe-preview" title="Preview" id="agencyPreviewFrame" name="previewFrame" scrolling="no" onLoad={onIFrameLoad}></iframe>}
                </ModalBody>
            </Modal></>
             : <Notification className="no-access-message" alert="You do not have access to view this page." highlightType="negative">
                              Please contact your <InlineLink onClick={() => window.open(window.env.DPEC_AGENCY_ADMINS,'','location=0,status=0,scrollbars=0,resizable=1,width=645,height=332')}>Safeco Now administrator</InlineLink> to assist you with your security permissions.
            </Notification>}
        </ThemeProvider>
    );
}

export default EditAgency;