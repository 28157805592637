import { Card, FieldGroup, GridRow, IconAddItem, IconButton, IconEdit, Link, List, ListItem } from "@lmig/lmds-react";
import { useContext, useEffect, useState } from "react";
import { Language } from "../../models/Agent";
import { AgentDataContext } from "../context/AgentDataContext";
import OptionsModal from "../modals/OptionsModal";


interface LanguagesProps {
    languages: Language[],
    updateLanguages: (val:Language[]) => void,

}

function Languages(props : LanguagesProps) {
    const [languageBoxes, setLanguageBoxes] = useState<undefined|Language[]>(props.languages);
    const [languageEdit,setLanguageEdit] = useState<boolean>(false);
    const {languages, setClearLanguages} = useContext(AgentDataContext);

    useEffect(() => {
        setClearLanguages(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[languageEdit])

    const getSelectLanguages = (boxes : any) => {
        const p = boxes ? [...boxes] : [];
        let count: string[] = [];
        for (let i = 0; i < p.length; i++) {
            if (p[i].selected) {
                count.push(p[i].englishLanguageName);
            }
        }
        return count;
    }

    // displays selected languages / products
    const getNamesCheckedInTable = (boxes : any) => {
        let count = getSelectLanguages(boxes);
        count.sort();
        return count.map((item : string, index: number) => {
            return (<div key={index} className="languages-wrapper"><List> <ListItem> {item} </ListItem> </List></div>);
        });
    }

    return (
            <Card title="Languages">
                <p className="additional-text-format">(In addition to English)</p>
                {getSelectLanguages(languages ? languages : []).length > 0 &&
                                    <div className="edit-btn-align-absolute">
                                        <IconButton onClick={()=>{setLanguageEdit(true)}}>
                                            <IconEdit className="icon-edit-custom-size" size="24" />
                                        </IconButton>
                                    </div>
                                }
                <div className='indent-content'>
                    <FieldGroup className = "field-group-margin-top">
                                <div className="language-table">
                                    <GridRow>
                                        {getNamesCheckedInTable(languages ? languages : [])}
                                    </GridRow>
                                    {getSelectLanguages(languages ? languages : []).length < 24 &&
                                        <GridRow onClick={() => {setLanguageEdit(true)}}>
                                            <IconButton id="languagesModalButton" >
                                                <IconAddItem size = "24"></IconAddItem>
                                            </IconButton>
                                            <div className="icon-text-long">
                                                <Link className="link-general" variant="standalone" caretPosition="none">
                                                    {getSelectLanguages(languages ? languages : []).length === 0
                                                        ? "Add languages"
                                                        : "Add additional languages"
                                                    }
                                                </Link>
                                            </div>
                                        </GridRow>
                                    }
                                </div>
                                {languageEdit ? <OptionsModal edit={languageEdit} updateEdit={setLanguageEdit} options = {languageBoxes? languageBoxes : []} updateOptions = {setLanguageBoxes} title = "Select Additional Languages" isProduct= {false}></OptionsModal> : <></>}
                    </FieldGroup>
                </div>
            </Card>
    );
}

export default Languages;