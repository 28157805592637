import AddItem from '../../../helpers/AddItem';
import { useEffect, useState } from 'react';
import { ImageFile } from '../../context/ProfileDataContext';
import CropModal from './sections/Photos/CropModal';

const MainPhotoHeader= (props: { image:ImageFile | undefined, updateImage:(image: ImageFile) => void}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [currentProfilePic, setCurrentProfilePic] = useState<ImageFile>();
    const [prospectiveProfilePic, setProspectiveProfilePic] = useState<ImageFile>();

    useEffect(() => {
        if (props.image?.Blob_Id === "")
            setCurrentProfilePic(props.image)
        if (props.image?.Blob_Id && currentProfilePic === undefined) {
            setCurrentProfilePic({...props.image, url: window.location.origin + window.env.PHOTO_PATH + props.image.Blob_Id})
        }
        else if (props.image === undefined)
            setCurrentProfilePic(undefined)
        }, [props.image, currentProfilePic]);


    const addProfilePic = async (e: any) => {
        if (e.target.files) {
            let file:File = e.target.files[0];
            setProspectiveProfilePic({ name: file.name, url: URL.createObjectURL(file as any), Blob_Id: "", IsDeleted: false, size: file.size });
            setOpen(true);
        }
    }

    return(
        <>
        {open && <CropModal newPic={prospectiveProfilePic} isOpen={open} close={() => setOpen(false)} save={(image: ImageFile) => props.updateImage(image)} isAgent={false} delete={(image: ImageFile) => {setCurrentProfilePic(undefined);props.updateImage({...image, IsDeleted: true});setOpen(false)}} />}
        <div className="profile-photo-container">{currentProfilePic ?
            <img id="addPhotoButton" className="profile-photo-image" key={currentProfilePic.name} src={currentProfilePic.base64 ?? currentProfilePic.url} alt={currentProfilePic.name} onClick={() => {setProspectiveProfilePic(currentProfilePic);setOpen(true)}} /> :
            <div className="add-item-box top-banner-item-box" style={{width: "130px", border: "2px solid", borderColor: "#28387E", backgroundColor: "white"}}>
                <AddItem>
                    <label htmlFor="profileTitlePictureUpload">Add profile picture</label>
                    <input id="profileTitlePictureUpload"
                        type="file"
                        name="profilePicture"
                        accept="image/png, image/jpeg"
                        onChange={(e) => addProfilePic(e)}
                    />
                </AddItem>
            </div>
        }</div>
        </>
    );
}

export default MainPhotoHeader;