import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@lmig/lmds-react/base.css';
import * as local from './config/env.local.json';
import * as qa from './config/env.qa.json';
import * as prod from './config/env.prod.json';
import { BrowserRouter } from 'react-router-dom';
import { LoadGoogleMapApi } from './helpers/LoadGoogleMapApi';
declare global {
  interface Window { env?: any; }
}

const init = async(env:any) => {
  try{
    window.env = env;
    await LoadGoogleMapApi();
    
    // Get the root element from the DOM
    const container = document.getElementById('root');
    // Ensure the container is not null
    if (container) {
      ReactDOM.render(
        <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        </React.StrictMode>,
        container
      );
    } else {
      console.error('Root container not found');
    }
  } catch (error) {
    console.error('Error loading Google Maps API:', error);
  }  
}

//only load the one environment config file we need
if (window.location.hostname === "localhost")
    init(local);
else if (window.location.hostname === "safesite.ts.safeco.com" || window.location.hostname === "safesite.qa.safeco.com" || window.location.hostname === "safesite.qa.libertymutual.com")
    init(qa);
else
    init(prod);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
