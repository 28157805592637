import { AlphanumericInput, FieldGroup, GridCol, GridRow, IconAddItem, IconButton, Link, Select, SelectOption, Heading } from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { nameCase } from "../../helpers/StringFormatter";
import '../../styles/OptionsModal.css'
import { AgentDataContext } from "../context/AgentDataContext";
import '../../styles/Modals.css';
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons";

interface PreferredAgentContactInformationModalProps {
    edit: boolean,
    updateEdit: (val:boolean) => void,
    preferredName: string,
    preferredEmail: string,
    preferredPhone: string,
    addPhone: string,
    type: string,
    add: boolean,
    updatePreferredName: (val:string) => void,
    updatePreferredEmail: (val:string) => void,
    updatePreferredPhone: (val:string) => void,
    updateAddPhone: (val:string) => void,
    updateType: (val:string) => void,
    setAdd: (val : boolean) => void
}

function PreferredAgentContactInformationModal (props : PreferredAgentContactInformationModalProps) {
    const {agentProfileData, additionalPhone, setAdditionalPhone, preferredContactInfo, setPreferredContactInfo} = useContext(AgentDataContext)
    const [edit, setEdit] = useState<boolean>(props.edit);
    const [preferredEmail, setPreferredEmail] = useState<string>(_.cloneDeep(preferredContactInfo.email));
    const [preferredPhone, setPreferredPhone] = useState<string>(_.cloneDeep(preferredContactInfo.phone));
    const [preferredName, setPreferredName] = useState<string>(_.cloneDeep(preferredContactInfo.name));
    const [addPhone, setAddPhone] = useState<string>(_.cloneDeep(additionalPhone.otherPhone));
    const [type, setType] = useState<string>(_.cloneDeep(additionalPhone.selectedOtherPhoneType));
    const [showAddPhone, setShowAddPhone] = useState<boolean>(false);
    const [add, setAdd] = useState<boolean>(props.add);

    useEffect(() => {
        if(props.preferredName === ""){
            setPreferredName(nameCase(agentProfileData.name));
        }
        if(props.preferredEmail === ""){
            setPreferredEmail(agentProfileData.email);
        }
        if(props.preferredPhone === ""){
            setPreferredPhone("("+agentProfileData.phone.slice(0,3)+") "+agentProfileData.phone.slice(3,6)+"-"+agentProfileData.phone.slice(6,10));
        }
        if(add){
            setShowAddPhone(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(addPhone !== "" || type !== ""){
            setShowAddPhone(true);
        }
    }, [addPhone, type])

    type errorsObject = {
        agentName: string[],
        email: string[],
        phone: string[],
        addPhone: string[],
        type: string[]
    };

    const [errorMessageObject, setErrorMessageObject] = useState<errorsObject>({ agentName: [], email: [], phone: [], addPhone: [], type: [] });

    const checkForErrors = () => {
        let errors: errorsObject = { agentName: [], email: [], phone: [], addPhone: [], type: [] };

        if (preferredName.length > 100) {
            errors.agentName.push("Preferred Agent Name cannot exceed 100 characters")
        }
        if (preferredName.includes('<') || preferredName.includes('>') || preferredName.includes('%') || preferredName.includes('=')) {
            errors.agentName.push("Please avoid use of the following characters: '<', '>', '%', and '='.");
        }
        if (preferredEmail.length > 60) {
            errors.email.push("Preferred email address must be less than 60 characters");
        }

        //eslint-disable-next-line
        const emailRegex = /([.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(([a-zA-Z0-9_-])*.([a-zA-Z0-9_-])+)+/;

        if (preferredEmail !== "" && !emailRegex.test(preferredEmail)) {
            errors.email.push("Invalid email format");
        }
        if (preferredPhone.length > 14) {
            errors.phone.push("Phone number cannot exceed 14 characters");
        }
        const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        if (preferredPhone !== "" && !phoneRegex.test(preferredPhone)) {
            errors.phone.push("Invalid phone number");
        }
        if (addPhone.length > 14) {
            errors.addPhone.push("Additional phone number cannot exceed 14 characters");
        }
        if (addPhone !== "" && !phoneRegex.test(addPhone)) {
            errors.addPhone.push("Invalid additional phone number");
        }
        if (type === "" && addPhone) {
            errors.type.push("Type cannot be left blank");
        }

        setErrorMessageObject(errors);

        return errors.agentName.length + errors.email.length + errors.phone.length + errors.addPhone.length + errors.type.length;
    };

    const cancelChanges = () => {
        setEdit(false);
        props.updateEdit(false);
        setAddPhone(_.cloneDeep(additionalPhone.otherPhone));
        setType(_.cloneDeep(additionalPhone.selectedOtherPhoneType));
        setPreferredEmail(_.cloneDeep(preferredContactInfo.email));
        setPreferredPhone(_.cloneDeep(preferredContactInfo.phone));
        setPreferredName(_.cloneDeep(preferredContactInfo.name));
        setAdd(false);
        props.setAdd(false);
        setErrorMessageObject({ agentName: [], email: [], phone: [], addPhone: [], type: [] });
    };

    return (
        <>
          <Modal isOpen={edit} size="small" onClose={() => cancelChanges()}>
            <ModalHeader><Heading className="modal-heading">Edit contact information</Heading></ModalHeader>
            <ModalBody className="modal-body">
                    <FieldGroup isFull id="formField">
                        <GridRow gutters="horizontal" gutterSize="fixed-16">
                            <GridCol base={12}>
                                <FieldGroup isFull id="formField" style={{ marginTop: errorMessageObject.agentName.length ? "1.7rem" : "0rem" }}
                                    messages={
                                        errorMessageObject.agentName.map((error) => {
                                            return {
                                                text: error,
                                                type: 'error'
                                            };
                                        })
                                    }
                                >
                                    <AlphanumericInput labelVisual="Full Name" value={preferredName} onChange={(e) => {setPreferredName(e.target.value);} }/>
                                </FieldGroup>
                            </GridCol>
                            <GridCol base={8}>
                                <FieldGroup isFull id="formField"
                                    style={{ marginTop: errorMessageObject.email.length === 0 && errorMessageObject.phone.length ? "1.7rem" : "0rem" }}
                                    messages={
                                        errorMessageObject.email.map((error) => {
                                            return {
                                                text: error,
                                                type: 'error'
                                            };
                                        })
                                    }
                                >
                                    <AlphanumericInput labelVisual="Email" value={preferredEmail} onChange={(e) => {setPreferredEmail(e.target.value);} }/>
                                </FieldGroup>
                            </GridCol>
                            <GridCol base={4}>
                                <FieldGroup isFull id="formField"
                                    style={{ marginTop: errorMessageObject.phone.length === 0 && errorMessageObject.email.length ? "1.7rem" : "0rem" }}
                                    messages={
                                        errorMessageObject.phone.map((error) => {
                                            return {
                                                text: error,
                                                type: 'error'
                                            };
                                        })
                                    }
                                >
                                    <AlphanumericInput labelVisual="Phone" mask='phone' value={preferredPhone} onChange={(e) => {setPreferredPhone(e.target.value);} }/>
                                </FieldGroup>
                            </GridCol>
                            {showAddPhone ?
                                <>
                                    <GridCol base={8}>
                                        <FieldGroup isFull id="formField"
                                            messages={
                                                errorMessageObject.addPhone.map((error) => {
                                                    return {
                                                        text: error,
                                                        type: 'error'
                                                    };
                                                })
                                            }
                                        >
                                            <AlphanumericInput labelVisual="Additional Phone" mask='phone' value = {addPhone} onChange = {(e : any) => {setAddPhone(e.target.value)} }/>
                                        </FieldGroup>
                                    </GridCol>
                                    <GridCol base={4}>
                                        <FieldGroup isFull id="formField"
                                            messages={
                                                errorMessageObject.type.map((error) => {
                                                    return {
                                                        text: error,
                                                        type: 'error'
                                                    };
                                                })
                                            }
                                        >
                                            <Select
                                                id="select--default"
                                                isRequired
                                                onChange={(e : any) => {setType(e.target.value)}}
                                                labelVisual="Type"
                                                value = {type}>
                                                <SelectOption value="Work">
                                                    Work
                                                </SelectOption>
                                                <SelectOption value="Mobile">
                                                    Mobile
                                                </SelectOption>
                                                <SelectOption value="Other">
                                                    Other
                                                </SelectOption>
                                            </Select>
                                        </FieldGroup>
                                    </GridCol>
                                </>
                            :
                                <div className='indent-content-inner' style={{marginTop: "0px", marginLeft: "-18px"}}>
                                    <GridRow onClick={()=> setShowAddPhone(true)}>
                                        <IconButton>
                                            <IconAddItem size = "24"></IconAddItem>
                                        </IconButton>
                                        <Link className="link-general" variant="standalone" caretPosition="none">
                                            Add additional phone
                                        </Link>
                                    </GridRow>
                                </div>
                            }
                        </GridRow>
                    </FieldGroup>
            </ModalBody>
            <ModalFooter className="modal-footer">
                <CancelAndSaveButtons
                    cancelFunction={cancelChanges}
                    saveFunction={() => {
                        if(checkForErrors() === 0){
                            setEdit(false);
                            props.updateEdit(false);
                            props.updatePreferredName(preferredName);
                            props.updatePreferredEmail(preferredEmail);
                            props.updatePreferredPhone(preferredPhone);
                            props.updateType(type);
                            props.updateAddPhone(addPhone);
                            setAdditionalPhone(_.cloneDeep({otherPhone: addPhone, selectedOtherPhoneType:type}));
                            setPreferredContactInfo(_.cloneDeep({email: preferredEmail, phone: preferredPhone, name: preferredName}));
                            setAdd(false);
                            props.setAdd(false);}}}/>
            </ModalFooter>
          </Modal>
        </>
    )
}
export default PreferredAgentContactInformationModal;
