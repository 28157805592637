import { IconButton, IconEdit, Checkbox, CheckboxGroup, GridCol, Toggle, GridRow, Heading, Button} from "@lmig/lmds-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lmig/lmds-react-modal';
import { useContext, useState, useRef } from "react";
import { AgentHoursContext } from "../context/AgentHoursContext";
import { hours, timezones } from "../../constants/constants";
import { weekDays } from "../../constants/time";
import { dayOpHours, hoursObject } from "../../models/Agency"
import '../../styles/Modals.css';
import CancelAndSaveButtons from "../modals/CancelAndSaveButtons"
import Select from 'react-select'
import _ from 'lodash';


interface IState {
    isNormalOpen: boolean;
}

interface IOption {
    value: string;
    label: string;
}

type weekday =  {
    Day: string,
    DayName: string,
    Start: string,
    Stop: string
};

const AgentHoursOfOperationModal = (props : any) => {

    const [state, setState] = useState<IState>({
        isNormalOpen: false,
    });

    const { operationHours, setOperationHours } = useContext(AgentHoursContext);
    var agencyHours : weekday[] = props.agencyData?.value.OperatingTimes ? props.agencyData.value.OperatingTimes : [];
    var agencyTimeZone : string = props.agencyData?.value.TimeZone ? props.agencyData.value.TimeZone : '';
    let noAgencyHours = agencyHours.filter((day)=>{return day.Start && day.Stop === "-----"}).length === 7;
    

    const [copy, setCopy] = useState<hoursObject>(_.cloneDeep(operationHours));
    var hourOptions: IOption[] = [];
    var timezoneOptions: IOption[] = [];
    hours.forEach((time) => { hourOptions.push({ value: time, label: time }) });
    timezones.forEach((timezone) => { timezoneOptions.push({ value: timezone, label: timezone }) });

    const fieldRef  = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        fieldRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const updateTimezone = (value: String | undefined) => {

        if (value != null) {
            setCopy({ ...copy, timezone: value });
        }


    }

    const getIndexOfDay = (day: String) => {

        return copy.opHours.findIndex((element: dayOpHours) => element.day === day);

    }



    const findAndUpdateHours = (day: String, value: string | undefined, am: boolean) => {
        if (value != null) {
            if (am) {// start time
                const index = copy.opHours.findIndex((element: dayOpHours) => element.day === day);
                let newOpHours = _.cloneDeep(copy.opHours);
                newOpHours[index].hours.begin = value;
                setCopy({ ...copy, opHours: newOpHours });
            }
            else {//end time
                const index = copy.opHours.findIndex((element: dayOpHours) => element.day === day);
                let newOpHours = _.cloneDeep(copy.opHours);
                newOpHours[index].hours.end = value;
                setCopy({ ...copy, opHours: newOpHours });
            }
        }
    };

    const findAndUpdateOpenValue = (day: String) => {
        const index = copy.opHours.findIndex((element: dayOpHours) => element.day === day);
        let newOpHours = _.cloneDeep(copy.opHours);
        let fillBegin : String = '-----';
        let fillEnd : String = '-----';
        newOpHours[index].open = !newOpHours[index].open;
        
        if(index > 0 && newOpHours[index].open){
            for(let i = index; i >= 0; i--){
                if(!newOpHours[i].hours.begin.includes('-----') && !newOpHours[i].hours.end.includes('-----')){
                    fillBegin = newOpHours[i].hours.begin;
                    fillEnd = newOpHours[i].hours.end;
                    break;
                }
            }
        }

        newOpHours[index].hours = { begin: fillBegin, end: fillEnd};
        
        setCopy({ ...copy, opHours: newOpHours });
    };

    const getDefaultValue = (day: string, am: boolean) => {
        if (am) {
            const hourDefault = copy.opHours[getIndexOfDay(day)].hours.begin as string;
            return { value: hourDefault, label: hourDefault }

        }
        else {
            const hourDefault = copy.opHours[getIndexOfDay(day)].hours.end as string;
            return { value: hourDefault, label: hourDefault }
        }
    };

    const checkIfClosedAM = (day: string) => {
        if(copy.opHours[getIndexOfDay(day)].open) {
            return (
                <Select maxMenuHeight={300} minMenuHeight={300} options={hourOptions} id="max-content" onChange={(e) => { findAndUpdateHours(day, e?.value, true) }} value={getDefaultValue(day, true)} />
            );
        }

        return (
            <p>Closed</p>
        );
    }

    const checkIfClosedPM = (day:string) => {
        if(copy.opHours[ getIndexOfDay(day) ].open) {
            return (
                <Select maxMenuHeight={300} minMenuHeight={300} options={_.cloneDeep(hourOptions).reverse()} id="max-content" onChange={(e) => { findAndUpdateHours(day, e?.value, false) }} value={getDefaultValue(day, false)} />
            );
        }

        return null;

    }



    const renderHoursOfOperation = () => {

        return weekDays.map((day) => {

            return (
                <tr key={day}>
                    <td>
                        <h4>{day}: </h4>
                    </td>

                    <td><Toggle checked={copy.opHours[getIndexOfDay(day)].open} onChange={() => {findAndUpdateOpenValue(day);}} compact dynamicWidth showOnOff={false} /></td>
                    <td>

                        {checkIfClosedAM(day)}

                    </td>
                    <td>
                        {copy.opHours[getIndexOfDay(day)].open ? <h4 id="spacing">to</h4> : null}
                    </td>
                    <td>

                       {checkIfClosedPM(day)}

                    </td>

                </tr>

            );
        });
    }

    const cancelChanges = () => {
        setCopy(_.cloneDeep(operationHours));
        setState({ ...state, isNormalOpen: false });
    };

    
    const saveChanges = () => {
        copy.opHours.forEach((day)=>{
            if(day.hours.begin.includes('-----') || day.hours.end.includes('-----')){
                day.open = false;
                day.hours.begin = '-----';
                day.hours.end = '-----';
            }
        })

        setOperationHours(copy);
        setState({ ...state, isNormalOpen: false });
    }

    const fillFromAgency = () => {
        if(agencyHours !== undefined){
            let newOpHours = _.cloneDeep(copy.opHours);
            newOpHours.forEach((day)=>{
                newOpHours[getIndexOfDay(day.day)].open = true;
            })
            newOpHours.forEach((day)=>{
                newOpHours[getIndexOfDay(day.day)].hours.begin = agencyHours[getIndexOfDay(day.day)].Start;
                newOpHours[getIndexOfDay(day.day)].hours.end = agencyHours[getIndexOfDay(day.day)].Stop;
            })
            newOpHours.forEach((day)=>{
                if(day.hours.begin.includes('-----') || day.hours.end.includes('-----')){
                    day.open = false;
                    day.hours.begin = '-----';
                    day.hours.end = '-----';
                }
            })
            setCopy({ ...copy, opHours: newOpHours, timezone: agencyTimeZone? agencyTimeZone: '' });
        }
    }
   


    return (
        <>
            <Modal
                isOpen={state.isNormalOpen}
                size="medium"
                onClose={() => cancelChanges()}>
                <ModalHeader>
                    <GridRow>
                        <GridCol>
                            <Heading className="modal-heading">Edit Hours</Heading>
                        </GridCol>
                        <GridCol className="header-button">
                            <Button dynamicWidth variant="secondary" disabled={noAgencyHours} onClick={fillFromAgency}>
                                Fill with agency hours
                            </Button>
                        </GridCol>
                    </GridRow>                    
                </ModalHeader>
                <ModalBody className="modal-body">
                    {state.isNormalOpen === true ?
                    <GridRow>
                        <GridCol base={12}>
                            <table style={{display:"flex"}}>
                                <tbody>
                                    {state.isNormalOpen ? renderHoursOfOperation() : null}
                                    <tr>
                                        <td><h4>Time Zone:</h4></td>
                                        <td>
                                            <Select maxMenuHeight={125} options={timezoneOptions} id="max-content" onFocus={handleClick} onChange={(e) => { updateTimezone(e?.value) }} value={{ label: copy.timezone, value: copy.timezone }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div id="otherHours" style={{zIndex: "-1"}}>
                                <h4 style={{ paddingTop: "11px"}}>Other hours available upon request:</h4>
                                <span style={{ paddingLeft: "10px"}}>
                                <span style={{ position: "fixed"}}>
                                <CheckboxGroup  onChange={() => {
                                
                                    const newOtherHours = !copy.otherhours;

                                    setCopy({ ...copy, otherhours: newOtherHours });
                                }}>
                                    <Checkbox 
                                        labelVisual="" checked={copy.otherhours}
                                    />
                                </CheckboxGroup>
                                </span>
                                </span>
                            </div>
                            </GridCol>
                        </GridRow>
                        : null}
                </ModalBody>
                <ModalFooter className="modal-footer">
                    <CancelAndSaveButtons
                    cancelFunction={cancelChanges}
                    saveFunction={saveChanges}/>
                </ModalFooter>
            </Modal>


            <IconButton id="hoursModalButton" onClick={() => {
                setCopy(operationHours);
                setOperationHours(_.cloneDeep(operationHours));
                setState({ ...state, isNormalOpen: true });
            }}>
                <IconEdit className="icon-edit-custom-size" size="24"/>
            </IconButton>

        </>
    )

};

export default AgentHoursOfOperationModal;
