import { IconAuto, IconCondo, IconHome, IconLandlord, IconMotorcycle, IconRenters, IconRV, IconUmbrella, IconWatercraft } from "@lmig/lmds-react";

export const getIcon = (productName: string, size: any) => {
    switch (productName) {
      case 'Auto':
        return (<IconAuto size={size}/>);
      case 'Home':
        return (<IconHome size={size} />);
      case 'Renters':
        return (<IconRenters size={size} />);
      case 'Condo':
        return (<IconCondo size={size} />);
      case 'Boat/Watercraft':
        return (<IconWatercraft size={size} />);
      case 'Motorcycle':
        return (<IconMotorcycle size={size} />);
      case 'Recreational Vehicle':
        return (<IconRV size={size} />);
      case 'Umbrella': 
        return (<IconUmbrella size={size} />);
      case 'Landlord Protection': 
        return (<IconLandlord size={size} />);
      default: 
        console.error("Unknown product name:", productName);
        return (<></>)
    }
  }